import React, { useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import cx from 'classnames';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import colors from '@packs/assets/theme/base/colors';
import { I18n } from '@packs/components/I18n';
import ArrowRightIcon from '@packs/images/icons/UI/ArrowRightIcon';
import { getSortedCategories } from '@packs/models/category/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { popularCategories } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { CategoriesTreeContent } from './components/CategoriesTreeContent';
import { CategoriesTreeNav } from './components/CategoriesTreeNav';

export const CategoriesTree = ({
  categories,
  customActions,
  handleUpdateCategory,
  toggleShowCategoriesRegister,
  defaultMessages,
  handleSelectedCategory,
  filterCategory,
  toggle
}) => {
  const { flattenCategories } = useCategories();
  const popularCategoriesList = useMemo(() => {
    const list = [];
    popularCategories.forEach(code => {
      const cat = flattenCategories.find(item => item.code === code);
      if (cat) {
        if (cat.parentId) {
          list.push(cat);
        } else {
          cat.leafChildren.forEach(child => {
            list.push(child);
          });
        }
      }
    });

    return list;
  }, [flattenCategories]);

  const popularCategory = {
    code: 'popular',
    name: 'Popular',
    leafChildren: popularCategoriesList
  };
  const sortedCategories = getSortedCategories(categories);
  const categoriesWithExcluded = [popularCategory, ...toJS(sortedCategories, { recurseEverything: true })];
  const defaultCategoryIndex = categoriesWithExcluded.findIndex(item => item.code === filterCategory.code);
  const [activeCategory, setActiveCategory] = useState(defaultCategoryIndex >= 0 ? defaultCategoryIndex : 0);
  const { isMobile, isDesktop } = useWindowSize();

  const currentCategory = categoriesWithExcluded.find((item, categoryIndex) => {
    if (categoryIndex === activeCategory) {
      item.categoryIndex = categoryIndex;

      return item;
    }
  });

  const handleClickPrev = () => {
    setActiveCategory(activeCategory - 1);
  };

  const handleClickNext = () => {
    setActiveCategory(activeCategory + 1);
  };

  useEffect(() => {
    if (handleSelectedCategory) {
      const currentCategory = categoriesWithExcluded.find((item, categoryIndex) => {
        if (categoryIndex === activeCategory) {
          item.categoryIndex = categoryIndex;

          return item;
        }
      });
      handleSelectedCategory(currentCategory);
    }
  }, [activeCategory]);

  const subCategories = currentCategory?.leafChildren;
  const lastCategoryIsActive = categoriesWithExcluded.length === activeCategory + 1;
  const firstStep = activeCategory === 0;

  return (
    <div id='categories-tree' className='groups-block'>
      <CategoriesTreeNav
        {...{
          categoriesWithExcluded,
          activeCategory,
          setActiveCategory,
          toggle
        }}
      />
      <div className='categories-tree-content d-flex flex-column justify-content-between position-relative'>
        {isDesktop && (
          <IconButton
            onClick={toggle}
            size='large'
            sx={{ position: 'absolute', top: '16px', right: '16px', color: colors.dark.main }}
          >
            <CloseRoundedIcon sx={{ fontSize: '32px' }} />
          </IconButton>
        )}
        <CategoriesTreeContent
          {...{
            toggle,
            currentCategory,
            subCategories,
            handleUpdateCategory
          }}
        />

        <div className='modal-bottom d-flex align-items-center justify-content-end'>
          {customActions ? (
            customActions({ lastCategoryIsActive, handleClickNext })
          ) : (
            <div
              className={cx(
                'd-flex align-items-center w-100',
                isDesktop || !firstStep ? 'justify-content-between' : 'justify-content-center'
              )}
            >
              {isDesktop && (
                <div>
                  <div className='skip-categories fs-14'>
                    Skip and Go to{' '}
                    <span
                      className='theme-color cursor-pointer fw-600'
                      onClick={() => {
                        toggleShowCategoriesRegister();
                      }}
                    >
                      Sign Up
                    </span>
                  </div>
                </div>
              )}

              {lastCategoryIsActive ? (
                <button
                  className='btn full white theme'
                  onClick={() => {
                    toggleShowCategoriesRegister();
                  }}
                >
                  <span>
                    <I18n text={defaultMessages.signUp} />
                  </span>
                </button>
              ) : (
                <>
                  {!firstStep && isMobile && (
                    <div className='prev-step' onClick={handleClickPrev}>
                      Previous step
                    </div>
                  )}
                  <button className='btn full black' onClick={handleClickNext}>
                    Next / skip
                    {!isDesktop && <ArrowRightIcon />}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
