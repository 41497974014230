import colors from '@packs/assets/theme/base/colors';

const { white, grey, socialMediaColors } = colors;

export default () => ({
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 4,
    pb: 1,
    '& .social-link': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer'
    }
  },
  socialIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '56px',
    borderRadius: '4px',
    mb: 1,
    color: white.main,
    '&.facebook': {
      backgroundColor: socialMediaColors.facebook.main
    },
    '&.twitter': {
      backgroundColor: socialMediaColors.twitter.main
    },
    '&.linkedin': {
      backgroundColor: socialMediaColors.linkedin.main
    },
    '&.whatsApp': {
      backgroundColor: socialMediaColors.whatsApp.main
    },
    '&.copyLink': {
      backgroundColor: grey[200]
    }
  },
  socialName: {
    fontSize: '15px',
    fontWeight: '700'
  }
});
