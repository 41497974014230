import React from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FiltersDrawer } from '@packs/components/MaterialUi/FiltersDrawer/FiltersDrawer';
import { OffersSearch } from '@packs/components/MaterialUi/Offers/MyOffersFilters/components/OffersSearch/OffersSearch';
import { MyOffersFilter } from '@packs/components/MaterialUi/Offers/MyOffersFilters/MyOffersFilter';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const MyOffersTopBar = observer(
  ({ showFilters, title, onResetFilters, disabledRemove, defaultFilters, defaultCategory }) => {
    const { offersStore } = useStore();
    const { isDesktop, isMobile } = useWindowSize();
    const styles = useStyles();

    return (
      <Box sx={styles.wrapper}>
        <Typography variant='h6'>{title}</Typography>
        <Box sx={styles.searchFilterWrapper}>
          {!isDesktop && <OffersSearch {...{ defaultCategory }} />}
          {!isDesktop && (
            <Box sx={styles.actionSectionWrapper}>
              <Box sx={styles.actionSection}>
                <FiltersDrawer
                  buttonProps={{
                    variant: 'filledTonal',
                    color: 'primary',
                    startIcon: <FilterAltIcon />,
                    sx: { width: { xs: '100%', lg: 'auto' } }
                  }}
                  handleResetFilters={onResetFilters}
                  disabledResetFilters={disabledRemove}
                  showResultText={`Show ${offersStore?.totalCount} offers`}
                >
                  <MyOffersFilter showFilters={showFilters} defaultFilters={defaultFilters} />
                </FiltersDrawer>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
