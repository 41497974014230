import colors from '@packs/assets/theme/base/colors';
import typography from '@packs/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { white, text, primary, secondary, dark } = colors;
const { size } = typography;

const filledTonal = {
  base: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(10)} ${pxToRem(24)}`,
    color: white.main,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .4)'
    },

    '&:active, &:active:focus, &:active:hover': {
      opacity: 1
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  xs: {
    minHeight: pxToRem(24),
    padding: `${pxToRem(4)}`,
    fontSize: `${pxToRem(13)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(12)} !important`
    }
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: `${pxToRem(13)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  smallToMedium: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  medium: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: pxToRem(16),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  large: {
    minHeight: pxToRem(56),
    padding: `${pxToRem(14)} ${pxToRem(24)}`,
    fontSize: pxToRem(18),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  primary: {
    backgroundColor: primary.tonal,
    color: dark.main,

    '&:hover': {
      backgroundColor: primary.main
    }
  },

  secondary: {
    backgroundColor: secondary.filledTonal,
    color: dark.main,

    '&:hover': {
      backgroundColor: secondary.main,
      color: white.main
    }
  },

  white: {
    backgroundColor: white.tonal,

    '&:hover': {
      backgroundColor: white.main,
      color: dark.main
    }
  }
};

export default filledTonal;
