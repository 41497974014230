import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { MaterialRadioGroup } from '@packs/components/MaterialUi/MaterialRadioGroup/MaterialRadioGroup';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useCategories } from '@packs/models/category/useCategories';
import { offerBusinessType, offerTypes } from '@packs/models/company/constants';
import { useStore } from '@packs/stores';
import { defaultCategory } from '@packs/stores/OffersStore';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { OffersSearch } from './components/OffersSearch/OffersSearch';

import { observer } from 'mobx-react';

export const MyOffersFilter = observer(
  ({ showFilters = ['offerType', 'categoryId', 'rootCategoryCode', 'customerType'], sidebarFilters }) => {
    const { offersStore } = useStore();
    const { flattenCategoriesOptions, rootCategoriesOptions } = useCategories();
    const { currentUser } = useUserSelectors();
    const { isMobile } = useWindowSize();

    const filters = offersStore?.filters;

    const rootCategoriesList = [defaultCategory, ...rootCategoriesOptions];
    const categoriesList = useMemo(() => {
      if (!filters?.rootCategoryCode) {
        return [defaultCategory];
      }

      const filteredList = flattenCategoriesOptions.filter(item => item.rootCode === filters?.rootCategoryCode?.value);
      return [defaultCategory, ...filteredList];
    }, [filters?.rootCategoryCode]);

    const offerTypesList = useMemo(() => {
      let list = [...offerTypes];

      if (!currentUser.id) {
        list = list.filter(({ value }) => value !== 'trust_network');
      }

      return list;
    }, []);

    const disableCategorySelect = !filters?.rootCategoryCode || filters?.rootCategoryCode?.value === 'all';

    const changeFilter = (key, value) => {
      if (key === 'rootCategoryCode') {
        offersStore.load({ [key]: value, categoryId: defaultCategory }, false);
      } else {
        offersStore.load({ [key]: value }, false);
      }
    };

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {showFilters.some(item => item === 'rootCategoryCode') && (
          <>
            <AutocompleteMUI
              value={rootCategoriesList.find(item => item.value === filters?.rootCategoryCode?.value)}
              options={rootCategoriesList}
              onChange={data => changeFilter('rootCategoryCode', data)}
              label='Сategory'
              withTitle={sidebarFilters}
              size={sidebarFilters ? 'small' : 'medium'}
              mobileSelect
              filter
            />
          </>
        )}
        {showFilters.some(item => item === 'categoryId') && (
          <>
            {!sidebarFilters && <Divider />}
            <AutocompleteMUI
              value={categoriesList.find(item => item.value === filters?.categoryId?.value)}
              options={categoriesList}
              onChange={data => {
                if (!disableCategorySelect) {
                  changeFilter('categoryId', data);
                }
              }}
              disabled={disableCategorySelect}
              label='Sub category'
              withTitle={sidebarFilters}
              size={sidebarFilters ? 'small' : 'medium'}
              mobileSelect
              filter
            />
          </>
        )}
        {showFilters.some(item => item === 'offerType') && (
          <>
            {!sidebarFilters && <Divider />}
            <Typography variant='body3'>Type of offer:</Typography>
            <MaterialRadioGroup
              row={false}
              name='offerType'
              options={offerTypesList}
              value={offerTypesList.find(item => item.value === filters?.offerType)}
              onChange={data => {
                changeFilter('offerType', data.value);
              }}
            />
          </>
        )}
        {showFilters.some(item => item === 'customerType') && (
          <>
            {!sidebarFilters && <Divider />}
            <Box>
              <Typography variant='body3'>Type of business:</Typography>
              <MaterialRadioGroup
                row={false}
                name='customerType'
                options={offerBusinessType}
                value={offerBusinessType.find(item => item.value === filters?.customerType)}
                onChange={data => {
                  changeFilter('customerType', data.value);
                }}
              />
            </Box>
          </>
        )}
      </Box>
    );
  }
);
