import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { general, offerTypeLabel } from '@shared/constants/offers';

import useStyles from './styles';

const { offers } = colors;

export const OfferType = ({ offerType = general, withTooltip = false }) => {
  const styles = useStyles();
  const offerTypeText = offerTypeLabel[offerType];
  const offerColor = offers[offerType];

  if (withTooltip) {
    return (
      <TooltipMUI title={offerTypeText} placement='top' arrow>
        <Box sx={styles.wrapper(offerColor)}>
          <Typography variant='caption'>{offerTypeText}</Typography>
        </Box>
      </TooltipMUI>
    );
  }

  return (
    <Box sx={styles.wrapper(offerColor)}>
      <Typography variant='caption'>{offerTypeText}</Typography>
    </Box>
  );
};
