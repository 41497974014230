import React, { useState } from 'react';

import { I18n } from '@packs/components/I18n';
import { Loader } from '@packs/components/Loader';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { Form } from './components';

import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordUpdate = () => {
  const [inProcess, setInProcess] = useState(false);
  const { userStore } = useStore();
  const { ROOT_PATH } = usePath();
  const { defaultMessages, formatMessage } = useTranslationMessages();
  const navigate = useNavigate();

  const submitHandler = ({ password, passwordConfirmation }) => {
    const callback = () => {
      setInProcess(false);
      navigate(ROOT_PATH);
      toastr.success(formatMessage(defaultMessages.devisePasswordsUpdated));
    };

    const errorCallback = () => {
      setInProcess(false);
    };

    setInProcess(true);
    const resetPasswordToken = queryString.parse(location.selectCategories).reset_password_token || '';
    userStore.changeUserResetedPassword({
      password,
      passwordConfirmation,
      resetPasswordToken,
      callback,
      errorCallback
    });
  };

  return (
    <div>
      {inProcess && <Loader />}
      <section className='auth-container'>
        <div className='auth-block forgot-password'>
          <h3 className='auth-title'>
            <I18n text={defaultMessages.forgotPasswordUpdatePageTitle} />
          </h3>
          <Form onSubmit={submitHandler} />
        </div>
      </section>
    </div>
  );
};

export default ForgotPasswordUpdate;
