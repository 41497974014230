import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography, TypographyProps } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import MemberBgIcon from '@packs/images/svg/ui/member-bg.svg';

const { grey, text } = colors;

export const MembersMobile = ({ members, value, handleChange }) => {
  return (
    <Box>
      {members.map((member, index) => (
        <Accordion
          key={member.label + index}
          expanded={value === index}
          onChange={handleChange(index)}
          sx={{
            borderBottom: `1px solid ${grey[200]}`,
            boxShadow: 'none',
            '&:before': {
              content: 'none'
            },
            '&.Mui-expanded': {
              margin: '0 !important'
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant={'body4' as TypographyProps['variant']}>{member.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                maxWidth: '600px',
                width: '100%',
                mx: 'auto',
                borderRadius: 4,
                backgroundImage: `url(${MemberBgIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }}
              mb={3}
            >
              <img src={member.imgSrc} alt={member.title} style={{ borderRadius: 'inherit' }} />
            </Box>
            <Box>
              <Typography variant={'body3' as TypographyProps['variant']} color={text.secondary}>
                {member.description}
              </Typography>
            </Box>

            {member.list && (
              <Stack spacing={2} sx={{ mt: 2 }}>
                {member.list.map((el, index) => (
                  <Stack key={el.title} spacing={1}>
                    <Stack direction='row' spacing={1}>
                      {member.numerate ? (
                        <Typography variant={'body3' as TypographyProps['variant']}>{index + 1}.</Typography>
                      ) : (
                        <CheckIcon color='primary' />
                      )}
                      <Typography
                        variant={(member.numerate ? 'body3' : 'body4') as TypographyProps['variant']}
                        color={!member.numerate ? text.secondary : undefined}
                      >
                        {el.title}
                      </Typography>
                    </Stack>
                    {el.desc && (
                      <Typography variant={'body3' as TypographyProps['variant']} color={text.secondary}>
                        {el.desc}
                      </Typography>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}

            {member?.description2 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant={'body3' as TypographyProps['variant']} color={text.secondary}>
                  {member.description2}
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
