import React from 'react';

const CopyIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0005 12.9354V7.24169C10.0005 6.95849 9.88797 6.68689 9.68765 6.48671L8.17903 4.97808C7.97884 4.77776 7.70724 4.66528 7.42405 4.66528H3.73117C3.14182 4.66528 2.66406 5.14305 2.66406 5.73239L2.66406 12.9354C2.66406 13.5247 3.14182 14.0025 3.73117 14.0025H8.93334C9.52269 14.0025 10.0005 13.5247 10.0005 12.9354V12.9354Z'
        stroke='#F8B500'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.99971 11.3345H12.2673C12.8567 11.3345 13.3344 10.8568 13.3344 10.2674V3.3312C13.3344 2.59452 12.7372 1.99731 12.0005 1.99731L7.06516 1.99731C6.47581 1.99731 5.99805 2.47508 5.99805 3.06443V4.66509'
        stroke='#F8B500'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.99981 7.33306H7.86559C7.57091 7.33306 7.33203 7.09418 7.33203 6.79951V4.66528'
        stroke='#F8B500'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CopyIcon;
