import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { SearchBusinesses } from '@packs/components/UI';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';

import useStyles from './styles';

import { useNavigate } from 'react-router-dom';

type VoteSearchBusinessProps = {
  toggleModal?: () => void;
};

export const VoteSearchBusiness: FC<VoteSearchBusinessProps> = ({ toggleModal }) => {
  const styles = useStyles();
  const { toggleNewCompanyModal } = useModalsContext();
  const { countryCode } = useStore();
  const navigate = useNavigate();

  const onSelect = data => {
    handleVisitCompany(data);
    navigate(pathGen('BUSINESS', { countryCode, company: { urlId: data.value } }).concat('?voteModalIsOpen=true'));
    toggleModal && toggleModal();
  };

  return (
    <Box sx={styles.searchWrapper}>
      <Box sx={styles.searchContainer}>
        <Typography variant='subtitle1' sx={styles.subTitle}>
          Search for the business you would like to write a review for!{' '}
        </Typography>
        <SearchBusinesses
          noOptionsMessage={() => <>Start typing</>}
          placeholder='Choose a business'
          onSelect={onSelect}
        />
        <Box sx={styles.contentWrapper}>
          <Typography variant='body1' sx={styles.content}>
            Do you want to write a review for a business not in our directory? <br /> Please{' '}
            <Typography component='span' onClick={toggleNewCompanyModal} sx={styles.addBusiness}>
              add it here
            </Typography>{' '}
            and once approved you can write a review for that business. <br /> Thank you!
          </Typography>
          <Box sx={styles.extraDescription}>
            <Typography variant='body1' sx={styles.content}>
              At Bill Winner, our mission is to help you to find the best{' '}
              <Typography variant='body2' component='span'>
                Providers, Agents / Brokers and Price Comparison Sites
              </Typography>{' '}
              matched to your specific needs! We allocate awards to the best businesses in your country! The winning
              businesses are determined by the votes and insights from you the community!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
