import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import useStyles from '../../styles';

export const SidebarTitle = ({ icon, title, children }) => {
  const styles = useStyles();

  return (
    <Box sx={styles.sidebarBox}>
      <Box sx={{ mb: 2 }}>
        <Box sx={styles.titleWrapper}>
          {icon && icon}
          <Typography variant='body2'>{title}</Typography>
        </Box>
        <Divider />
      </Box>
      {children}
    </Box>
  );
};
