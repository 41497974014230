import React from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import { SignInModalLink } from '@packs/components';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import { EmailInvite } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import Facebook from '@packs/images/icons/Facebook';
import LinkedIn from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';
import CopyIcon from '@packs/images/icons/UI/CopyIcon';
import WhatsApp from '@packs/images/icons/WhatsApp';
import GlobeIcon from '@packs/images/ui/Globe.png';
import { inviteMessage } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { FriendsList } from './components/FriendsList';

import Clipboard from 'react-clipboard.js';
import { Helmet } from 'react-helmet';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

const InviteFriends = () => {
  const { currentUser } = useUserSelectors();
  const { isMobile, isDesktop } = useWindowSize();

  const BASE_APP_URL_WITH_TOKEN = currentUser?.socialInvitationUrl;

  const onSuccess = () => {
    toastr.success('Successfully copied');
  };

  if (!currentUser.id) {
    return (
      <>
        <Helmet>
          <title>My Trust Network</title>
        </Helmet>
        <Box
          className={cx(
            'request-summary invite-friends-unauthorized d-flex align-items-center justify-content-center',
            isDesktop ? 'text-left' : 'text-center'
          )}
          sx={{
            pb: {
              xs: 4,
              lg: 6
            }
          }}
        >
          <div className='request-summary-confirm d-flex align-items-center justify-content-between text-left'>
            <div className='body-container'>
              <div className='title'>Invite Friends!</div>
              <div className='description'>
                Do you wish to invite members to your trust network? Please log in to start inviting your friends!
              </div>
              <div className='btn-container d-flex justify-content-start mt-24'>
                <SignInModalLink className='btn full white'>
                  <span>Login</span>
                </SignInModalLink>
              </div>
            </div>
            {isDesktop && (
              <div className='img-container'>
                <img src={GlobeIcon} alt='Email' />
              </div>
            )}
          </div>
        </Box>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>My Trust Network</title>
      </Helmet>
      <PageTitleWrapper
        homeIsLanding
        title='My Trust Network'
        currentPage='My Trust Network'
        subTitle='Get trusted recommendations and network offers!'
      />
      <Box
        className='invite-friends-wrapper'
        sx={{
          pb: {
            xs: 4,
            lg: 6
          }
        }}
      >
        <div className='invite-friends'>
          <div className='inner-container'>
            <div
              className={cx(
                'invite-friends-container align-items-start d-flex justify-content-between',
                isMobile ? 'gap-8' : 'gap-32',
                { 'flex-column': isMobile }
              )}
            >
              <div className={cx('flex-grow-1', { 'w-100': isMobile })}>
                <div>
                  <div className='title d-flex align-items-center justify-content-between'>
                    <span>Invite a friend:</span>
                    <Clipboard component='a' data-clipboard-text={BASE_APP_URL_WITH_TOKEN} onSuccess={onSuccess}>
                      <span className='cursor-pointer primary-text d-flex align-items-center gap-4'>
                        Copy invite link
                        <CopyIcon />
                      </span>
                    </Clipboard>
                  </div>
                  <EmailInvite />
                </div>
              </div>
              <div className='invite-section d-flex flex-column align-items-center justify-content-center'>
                <div className={cx('title', isMobile && 'text-center mb-16')}>Share on social media</div>
                <div className='invite-socials-btn-list d-flex justify-content-center gap-16'>
                  <FacebookShareButton
                    url={BASE_APP_URL_WITH_TOKEN}
                    quote={inviteMessage}
                    className='invite-social-btn facebook'
                  >
                    <Facebook />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={BASE_APP_URL_WITH_TOKEN}
                    title={inviteMessage}
                    className='invite-social-btn linkedin'
                  >
                    <LinkedIn />
                  </LinkedinShareButton>
                  <TwitterShareButton
                    url={BASE_APP_URL_WITH_TOKEN}
                    title={inviteMessage}
                    className='invite-social-btn twitter'
                  >
                    <Twitter />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={BASE_APP_URL_WITH_TOKEN}
                    title={inviteMessage}
                    className='invite-social-btn whatsApp'
                  >
                    <WhatsApp />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
            <FriendsList />
          </div>
        </div>
      </Box>
    </>
  );
};

export default InviteFriends;
