import colors from '@packs/assets/theme/base/colors';

const { grey, dark } = colors;

export default () => ({
  wrapper: {
    position: 'relative',
    borderRadius: '24px',
    py: {
      xs: 2,
      sm: 3
    },
    backgroundColor: grey[100]
  },
  item: {
    px: {
      xs: 1,
      sm: 3
    },
    py: 1
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    pl: {
      xs: 1,
      sm: 3
    },
    pr: {
      xs: 6,
      sm: 7
    }
  },
  sliderArrow: {
    position: 'absolute',
    top: '-13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    color: `${dark.main} !important`,
    cursor: 'pointer',
    '&:before': {
      content: 'none'
    }
  },
  prevArrow: {
    left: {
      xs: 'calc(100% - 108px)',
      sm: 'calc(100% - 120px)'
    }
  },
  nextArrow: {
    right: {
      xs: '8px',
      sm: '16px'
    }
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 1
  }
});
