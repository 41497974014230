import React, { useMemo } from 'react';

import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { CompanyPage } from '@packs/components/MaterialUi/CompanyPage/CompanyPage';
import { useBusiness } from '@packs/hooks/useBusiness';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const IS_BUSINESS_PAGE = true;

export const Business = observer(({ category = null }) => {
  const { policiesListStore, insightsCompanyStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { DASHBOARD_MY_RESULTS_PATH_FN } = usePath();
  const { toggleBusinessVoteModal } = useModalsContext();

  const { feedbackType, handleInsightsSwitcher, fetchDetailsAndInsights } = useBusiness({
    category,
    IS_BUSINESS_PAGE
  });

  const isLoading = policiesListStore.isLoading;
  const company = insightsCompanyStore.object;

  const firstCategory = useMemo(() => {
    return company?.categories ? company?.categories[0] : null;
  }, [company]);

  const handleVoteNowClick = () => {
    toggleBusinessVoteModal({
      company,
      category: IS_BUSINESS_PAGE ? null : category,
      IS_BUSINESS_PAGE,
      fetchDetailsAndInsights
    });
  };

  return (
    <>
      <Helmet>
        <title>{company.name}</title>
      </Helmet>
      <CompanyPage
        IS_BUSINESS_PAGE={true}
        {...{ company, colors, insightsCompanyStore }}
        breadcrumbs={
          <BreadcrumbsMUI
            routes={[{ to: firstCategory?.id && DASHBOARD_MY_RESULTS_PATH_FN(firstCategory), label: 'Leaderboard' }]}
            currentPage={company.name}
          />
        }
        withOutInsightCompany
        companyId={company?.id}
        {...{
          company,
          category,
          isLoading,
          currentUser,
          feedbackType,
          handleInsightsSwitcher,
          handleVoteNowClick
        }}
      />
    </>
  );
});
