import React from 'react';

// Components
import { InfoComponent } from '@packs/components';
// Images
import LampIcon from '@packs/images/svg/ui/lamp.svg';
import ToDoIcon1 from '@packs/images/svg/ui/to-do-1.svg';
import ToDoIcon2 from '@packs/images/svg/ui/to-do-2.svg';
import ToDoIcon3 from '@packs/images/svg/ui/to-do-3.svg';

import { Helmet } from 'react-helmet';

const howItWorksList = [
  {
    icon: ToDoIcon1,
    title: 'SIGN UP!',
    description: 'Sign up & pick the bill categories that are relevant to you!',
    voting: false
  },
  {
    icon: ToDoIcon2,
    title: 'Get Quotes!',
    description: 'Shop around and get quotes!',
    voting: false
  },
  {
    icon: ToDoIcon3,
    title: 'Vote!',
    description: 'Once you have shopped around, we will send you a notification to vote for your winning business!',
    voting: true
  }
];

const benefitsListFirst = [
  'Helps people like you find the best businesses!',
  'Help reduce bill poverty in your country!',
  'Reward your winning business with your vote!'
];

const benefitsListSecond = [
  'Help create a more transparent marketplace!',
  'Enter a free monthly renewal draw! (Proof of purchase required if you win!)'
];

const description =
  'At Bill Winner, our mission is to find the best Providers, Agents/ Brokers and Price Comparison Sites in your country! With your help, we can! We award the best businesses in a country as voted by you the people!';

const OurSharedMission = () => {
  return (
    <>
      <Helmet>
        <title>Our Community Awards</title>
        <meta name='description' content={description} />
        <meta name='og:description' content={description} />
      </Helmet>
      <div className='app-main-container'>
        <div className='main-wrapper'>
          <div className='how-it-works-container'>
            <div className='fs-25 fw-600 text-center mb-20'>Our Community Awards</div>
            <div className='fs-16 lh-5 text-center mb-45'>{description}</div>
            <div className='how-it-works-title fw-600 text-center'>What do I need to do?</div>
            <div className='how-it-works-list d-flex'>
              {howItWorksList.map(({ icon, title, description, voting }, index) => (
                <div className='how-it-works-item' key={title + index}>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='img-container d-flex align-items-center justify-content-center'>
                      <img src={icon} alt='Icon' />
                    </div>
                    <div className='index'>{`${index + 1}.`}</div>
                  </div>
                  <div className='how-it-works-item-title fs-20 fw-600 text-uppercase'>{title}</div>
                  <div className='how-it-works-item-description fs-16'>{description}</div>
                  {voting && (
                    <div className='d-flex align-items-center'>
                      <div className='how-it-works-voting-text fs-16'>See the benefits of voting!</div>
                      <InfoComponent popoverId='benefits' placement='top' className='benefits-info-container' circle>
                        <div className='transparency-box'>
                          <div className='benefits-info-title d-flex align-items-center fs-16 fw-700'>
                            <img src={LampIcon} alt='Lap' />
                            Benefits of voting!
                          </div>
                          <div className='benefits-info-column-wrapper d-flex align-items-start'>
                            <div className='benefits-info-column'>
                              {benefitsListFirst.map(item => (
                                <div key={item} className='benefits-info-item'>
                                  {item}
                                </div>
                              ))}
                            </div>
                            <div className='benefits-info-column'>
                              {benefitsListSecond.map(item => (
                                <div key={item} className='benefits-info-item'>
                                  {item}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </InfoComponent>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className='campaigns-footer fs-14 fw-600 text-center mt-40'># Operation Transparency</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurSharedMission;
