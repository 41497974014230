import colors from '@packs/assets/theme/base/colors';

const { white, dark } = colors;

export default () => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white.main,
    zIndex: 1000
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderBottom: `1px solid ${dark.transparent}`,
    py: 2
  },
  stepperWrapper: {
    width: '100%',
    maxWidth: '620px',
    mx: 'auto'
  },
  contentWrapper: {
    flexGrow: 1,
    overflowY: 'auto'
  },
  stepButton: {
    '& .MuiStepLabel-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: {
        xs: 'column',
        lg: 'row'
      }
    },
    '& .MuiStepLabel-label': {
      fontSize: '14px'
    }
  }
});
