import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useCategories } from '@packs/models/category/useCategories';
import { QuoteRequestStatus } from '@packs/screens/MyQuoteRequests/components/QuoteRequestStatus/QuoteRequestStatus';
import { useStore } from '@packs/stores';

import useStyles from './style';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

export const MobileList = ({ list }) => {
  const { countryCode } = useStore();
  const { currentCategory } = useCategories();

  const onVisitCompanyPage = (company: any) => () => {
    handleVisitCompany(company, currentCategory);
  };

  const styles = useStyles();
  return (
    <Box sx={styles.wrapper}>
      {list.map(quote => {
        const company = quote?.quoteAgent?.company;
        const companyName = company?.name;
        const avatarUrl = company.avatarUrl;
        const link = pathGen('PRODUCT', {
          countryCode,
          category: currentCategory,
          company
        });

        return (
          <Box sx={styles.requestItem} key={quote.id}>
            <Box sx={styles.topSectionWrapper}>
              <Box component={Link} to={link} sx={styles.companyWrapper} onClick={onVisitCompanyPage(company)}>
                <Avatar
                  src={avatarUrl}
                  sx={{
                    width: '72px',
                    height: '72px'
                  }}
                  variant='rounded'
                />
                <Box sx={styles.nameStatusWrapper}>
                  <Typography variant='h6'>{companyName}</Typography>
                  <QuoteRequestStatus status={quote?.externalStatus} />
                </Box>
              </Box>
              <Box>
                <Typography variant='body4'>{dayjs(quote?.createdAt).format('DD MMM YYYY')}</Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 1.5 }} />
            <Box sx={styles.policyWrapper}>
              <Typography variant='body6'>POLICY NAME:</Typography>
              <Typography variant='body4'>{quote?.renewalDetail?.smartReminder?.aliasName}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
