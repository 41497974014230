import React from 'react';
import cx from 'classnames';

import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';

import ModalForm from './Form';

import { Modal, ModalHeader } from 'reactstrap';

export const NewAssociationModal = props => {
  const { isNewAssociationModalOpen, toggle, enableUnAuthBusiness, onCreate } = props;
  const { currentCategory } = useStore();

  if (!currentCategory?.id) {
    return null;
  }

  const appContext = useModalsContext();

  return (
    <Modal
      isOpen={isNewAssociationModalOpen}
      toggle={() => toggle('isNewAssociationModalOpen')}
      className={cx('new-provider-modal', currentCategory.root.enName)}
    >
      <ModalHeader toggle={() => toggle('isNewAssociationModalOpen')}>Add new Association</ModalHeader>

      <ModalForm
        initialValues={{
          name: props.newCompanyName,
          categoryId: currentCategory.id
        }}
        toggle={() => {
          toggle('isNewAssociationModalOpen');
        }}
        {...{
          enableUnAuthBusiness,
          appContext,
          onCreate
        }}
      />
    </Modal>
  );
};
