import React from 'react';

import { ServerRendering } from '@packs/serverRendering';

import 'idempotent-babel-polyfill';

import { createRoot } from 'react-dom/client';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('application_container');
  const props = JSON.parse(container.attributes.props.value);
  const context = JSON.parse(container.attributes.context.value);

  const root = createRoot(container);
  root.render(<>{ServerRendering(props, context)}</>);
});
