import React, { useState } from 'react';

import { I18n } from '@packs/components/I18n';
import { Loader } from '@packs/components/Loader';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { Form } from './components';

import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

const InvitationAccept = () => {
  const { invitationToken } = queryString.parse(location?.search);
  const { userStore } = useStore();
  const { ROOT_PATH } = usePath();
  const navigate = useNavigate();

  const { defaultMessages } = useTranslationMessages();
  const [inProcess, setInProcess] = useState(false);

  const submitHandler = attr => {
    const callback = () => {
      navigate(ROOT_PATH);
      toastr.success(<I18n text={defaultMessages.deviseRegistrationsSignedUp} />);
    };

    const errorCallback = () => {
      setInProcess(false);
    };

    const variables = {
      ...attr,
      countryCode: attr?.location?.country?.countryCode,
      state: attr?.location?.state?.value,
      invitationToken
    };
    delete variables.location;

    setInProcess(true);
    userStore.userAcceptInvitationByEmail({
      variables,
      callback,
      errorCallback
    });
  };

  return (
    <>
      {inProcess && <Loader />}
      <div className='accept-invite'>
        <h3 className='title text-center fs-28 lh-9 mb-16 fw-700'>
          <I18n text={defaultMessages.acceptYourInvitation} />
        </h3>
        <Form onSubmit={submitHandler} />
      </div>
    </>
  );
};

export default InvitationAccept;
