import React, { useState } from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { NavLink } from 'react-router-dom';

const { white, dark, secondary } = colors;

export const CategoryItemPopular = ({
  category,
  categoryIcon,
  filledCard = null,
  customUrl = null,
  transparent = null,
  maxWidth = '258px',
  activeHover = false
}) => {
  const { categoryStore, companiesStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { DASHBOARD_MY_RESULTS_PATH_FN, DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();

  const { isMobile } = useWindowSize();
  const [onMouseEnter, setOnMouseEnter] = useState(activeHover);

  const categoryUrl = () => {
    if (customUrl) {
      return customUrl;
    }

    if (category?.root?.code) {
      let url = DASHBOARD_LANDING_PATH_FN(category);
      if (categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)) {
        url = DASHBOARD_MY_RESULTS_PATH_FN(category);
      }

      return url;
    } else {
      return DASHBOARD_ROOT_LANDING_PATH_FN({ root: { code: category.code } });
    }
  };

  const handleOnMouseEnter = () => {
    if (!activeHover) {
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (!activeHover) {
      setOnMouseEnter(false);
    }
  };

  const onClickCategory = () => {
    if (category?.parentId) {
      categoryStore.setCurrentCategory(category);
      companiesStore.clearFilters();
    }
  };

  if (isMobile) {
    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        component={NavLink}
        to={categoryUrl()}
        onClick={onClickCategory}
        sx={{
          width: '100%',
          borderRadius: '8px',
          border: '1px solid rgba(255, 255, 255, 0.16)',
          p: 1,
          backgroundColor: transparent ? 'transparent' : white.tonal
        }}
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <IconBox
            category={category}
            containerSize='40px'
            backgroundColor={transparent ? secondary.main : white.tonal}
            icon={categoryIcon}
            borderRadius='8px'
          />
          <Typography variant='body2' color={transparent ? dark.main : white.main}>
            {category.name}
          </Typography>
        </Stack>
        <Box
          sx={{
            color: transparent ? dark.main : white.main
          }}
        >
          <KeyboardArrowRightIcon />
        </Box>
      </Stack>
    );
  }

  return (
    <Box
      component={NavLink}
      to={categoryUrl()}
      onClick={onClickCategory}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth,
        height: '136px',
        borderRadius: '8px',
        p: 2,
        border: '1px solid rgba(255, 255, 255, 0.16)',
        backgroundColor: transparent ? 'transparent' : filledCard ? filledCard : white.tonal,
        color: transparent ? dark.main : onMouseEnter ? dark.main : white.main,
        transition: 'all .2s',
        '&:hover': {
          color: dark.main,
          backgroundColor: transparent ? 'transparent' : white.main
        }
      }}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '32px',
            height: '32px',
            borderRadius: '8px',
            backgroundColor: transparent
              ? 'rgba(1, 103, 205, 0.08)'
              : onMouseEnter
              ? 'rgba(1, 103, 205, 0.08)'
              : 'rgba(255, 255, 255, 0.16)',
            transition: 'backgroundColor .500s',
            '& svg path': {
              fill: transparent ? secondary.main : onMouseEnter ? secondary.main : white.main
            }
          }}
        >
          {categoryIcon}
        </Box>
      </Stack>
      <Stack direction='column' spacing={1}>
        <Typography
          variant='body2'
          mt={1}
          sx={{
            color: 'inherit',
            transform: `translateY(${onMouseEnter ? 0 : '40px)'}`,
            transition: 'transform .4s'
          }}
        >
          {category.name}
        </Typography>
        <Fade in={onMouseEnter} timeout={500}>
          <Button variant='contained' size='small' fullWidth>
            Get Cheaper Bills
          </Button>
        </Fade>
      </Stack>
    </Box>
  );
};
