import { useMemo } from 'react';

import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import Button from '@mui/material/Button';
import { usePath } from '@packs/layouts/constants';
import { saveState } from '@packs/lib/utils/localStorage';
import { ContentWrapper } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/ContentWrapper';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/styles';
import { rootCategoriesLanding } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Link } from 'react-router-dom';

export const CommunityTips = ({ item, data, isLoading, setCurrentCategory, category, renewalDetail }) => {
  const { isDesktop } = useWindowSize();
  const { DASHBOARD_QA_GENERAL_PATH_FN, DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();
  const styles = useStyles();
  const totalCount = data?.totalCount || 0;
  const isEmpty = totalCount === 0;

  const isPopularRootCategory = rootCategoriesLanding.some(popular => popular === category?.root?.code);
  const categoryUrl = useMemo(() => {
    let url = DASHBOARD_LANDING_PATH_FN(category);

    if (renewalDetail?.id) {
      if (isEmpty) {
        url = DASHBOARD_QA_GENERAL_PATH_FN(category);
      } else {
        url = DASHBOARD_QA_GENERAL_PATH_FN(category).concat('?selectedType=tip');
      }
    } else if (isPopularRootCategory) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(category).concat(category.code ? `?initialCategory=${category.code}` : '');
    }

    return url;
  }, [category.id, renewalDetail?.id, isPopularRootCategory, category, isEmpty]);

  const handleRedirectToLeaderboard = () => {
    setCurrentCategory();
    saveState('openDashboardItemId', item.id);
  };

  return (
    <ContentWrapper
      wrapperSx={styles.shortSectionItemWrapper}
      title={isEmpty ? 'No Community Tips Yet!' : 'Community Tips'}
      counter={`${totalCount} tip${totalCount === 1 ? '' : 's'}`}
      sectionLabelIcon={<GroupsRoundedIcon />}
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyText='Powered by the people of Ireland!'
      component={Link}
      to={categoryUrl}
      onClick={handleRedirectToLeaderboard}
      bottomButton={
        <Button
          size={isDesktop ? 'xs' : 'small'}
          variant='contained'
          fullWidth
          sx={styles.sectionButton}
          className='sectionButton'
        >
          See All Tips
        </Button>
      }
    />
  );
};
