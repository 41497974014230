import Box from '@mui/material/Box';

import { Contact } from './components/Contact/Contact';
import { MembersAlsoLooked } from './components/MembersAlsoLooked/MembersAlsoLooked';
import { OtherCategories } from './components/OtherCategories/OtherCategories';
import { Performance } from './components/Performance/Performance';
import { SentimentAnalysis } from './components/SentimentAnalysis/SentimentAnalysis';

export const Sidebar = ({ insightsCompanyStore, company, category }) => {
  return (
    <Box>
      <Performance {...{ insightsCompanyStore }} />
      <SentimentAnalysis {...{ insightsCompanyStore }} />
      <Contact {...{ company }} />
      <OtherCategories {...{ company }} />
      {category?.id && company?.id && <MembersAlsoLooked {...{ company, category }} />}
    </Box>
  );
};
