import React from 'react';

import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { CompanyPage } from '@packs/components/MaterialUi/CompanyPage/CompanyPage';
import { useBusiness } from '@packs/hooks/useBusiness';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { pathGen, usePath } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const IS_BUSINESS_PAGE = false;

export const ProductDetails = observer(() => {
  const { insightsCompanyStore, policiesListStore, countryCode } = useStore();
  const { currentCategory } = useCategories();
  const { toggleBusinessVoteModal } = useModalsContext();
  const { DASHBOARD_MY_RESULTS_PATH_FN } = usePath();

  const { feedbackType, handleInsightsSwitcher, fetchDetailsAndInsights } = useBusiness({
    category: currentCategory,
    IS_BUSINESS_PAGE
  });
  const { currentUser } = useUserSelectors();

  const isLoading = policiesListStore.isLoading;
  const company = insightsCompanyStore.object;

  const handleVoteNowClick = () => {
    toggleBusinessVoteModal({
      company,
      category: IS_BUSINESS_PAGE ? null : currentCategory,
      IS_BUSINESS_PAGE,
      fetchDetailsAndInsights
    });
  };

  const onVisitCompanyPage = () => {
    handleVisitCompany(company);
  };

  return (
    <>
      <Helmet>
        <title>{company.name}</title>
      </Helmet>
      <CompanyPage
        IS_BUSINESS_PAGE={false}
        {...{ company, colors, insightsCompanyStore }}
        breadcrumbs={
          <BreadcrumbsMUI
            routes={[
              { to: DASHBOARD_MY_RESULTS_PATH_FN(currentCategory), label: 'Leaderboard' },
              { to: pathGen('BUSINESS', { countryCode, company }), label: company.name, onClick: onVisitCompanyPage }
            ]}
            currentPage={`${currentCategory.name} ${company.name}`}
          />
        }
        withOutInsightCompany
        companyId={company?.id}
        category={currentCategory}
        {...{
          company,
          isLoading,
          currentUser,
          feedbackType,
          handleInsightsSwitcher,
          handleVoteNowClick
        }}
      />
    </>
  );
});
