import Member1Icon from '@packs/screens/RootCategoryLanding/membersSection/mobileImages/member-1.webp';
import Member2Icon from '@packs/screens/RootCategoryLanding/membersSection/mobileImages/member-2.webp';
import Member3Icon from '@packs/screens/RootCategoryLanding/membersSection/mobileImages/member-3.webp';
import Member4Icon from '@packs/screens/RootCategoryLanding/membersSection/mobileImages/member-4.webp';
import Member5Icon from '@packs/screens/RootCategoryLanding/membersSection/mobileImages/member-5.webp';

const mobile = [
  {
    label: 'Here’s why you should use Bill Winner to shop for your mobile bill!',
    title: 'Here’s why you should use Bill Winner to shop for your mobile bill!',
    description: '',
    numerate: false,
    list: [
      { title: 'We compare the best providers, brokers, and price comparison sites in one place!', desc: '' },
      { title: 'See the most popular businesses on our leaderboard!', desc: '' },
      { title: 'Get customer insights and ratings for businesses!', desc: '' },
      { title: 'Get support from our members and business experts!', desc: '' },
      { title: 'Get quotes from multiple businesses within one quote request!', desc: '' }
    ],
    imgSrc: Member1Icon
  },
  {
    label: 'What are the key steps to evaluating the best mobile deals?',
    title: 'What are the key steps to evaluating the best mobile deals?',
    description:
      'Evaluating the best mobile deals in Ireland involves considering various factors to ensure you choose a plan that meets your communication needs and budget. Here are the key steps to help you evaluate mobile deals:',
    numerate: true,
    list: [
      {
        title: 'Assess Your Usage Needs:',
        desc: 'Determine your mobile usage patterns, including the number of minutes, text messages, and data you typically use. This will help you choose a plan that aligns with your needs.'
      },
      {
        title: 'Consider Network Coverage:',
        desc: 'Research the network coverage and reliability of different mobile providers in your area. Make sure the provider you choose offers strong coverage in the places you frequent.'
      },
      {
        title: 'Compare Providers:',
        desc: 'Research and compare mobile providers and the plans they offer. Pay attention to factors like pricing, data allowances, contract terms, and available features.'
      },
      {
        title: 'Check for Bundled Services:',
        desc: 'Some mobile providers offer bundled services that include mobile plans, broadband, and TV packages. Consider whether bundling services can save you money compared to getting them separately.'
      },
      {
        title: 'Choose a Pay-As-You-Go or Contract Plan:',
        desc: 'Decide whether you want a pay-as-you-go plan or a contract plan. Pay-as-you-go plans offer flexibility and no long-term commitments, while contract plans may provide better value for heavy users.'
      },
      {
        title: 'Data Allowance:',
        desc: 'Consider your data usage and select a plan with an appropriate data allowance. Ensure it aligns with your browsing, streaming, and app usage habits.'
      },
      {
        title: 'Roaming and International Calls:',
        desc: 'If you frequently travel abroad, check whether the mobile provider offers affordable roaming options and international call packages.'
      },
      {
        title: 'Compare Prepaid and Postpaid Plans:',
        desc: 'Compare prepaid (pay-as-you-go) plans with postpaid (contract) plans. Prepaid plans allow you to control your spending, while postpaid plans may offer more features and larger data allowances.'
      },
      {
        title: 'Read Customer Reviews:',
        desc: "Research customer reviews and feedback about the mobile providers' network quality, customer service, and overall satisfaction."
      },
      {
        title: 'Consider Handset Options:',
        desc: 'If you need a new smartphone, evaluate whether the provider offers attractive handset deals or financing options as part of the plan.'
      },
      {
        title: 'Examine Additional Perks:',
        desc: 'Some providers offer additional perks, such as family plans, entertainment packages, and free access to streaming services. Assess whether these extras are valuable to you.'
      },
      {
        title: 'Review Contract Terms:',
        desc: 'Carefully read the terms and conditions of the mobile contract, including contract length, early termination fees, and any potential price increases during the contract period.'
      },
      {
        title: 'Check for Special Offers:',
        desc: 'Look for special offers, promotions, and discounts that mobile providers may be running. These can provide cost savings, especially during the initial contract period.'
      },
      {
        title: 'Test Network Speeds:',
        desc: 'If possible, test the network speeds and quality of the mobile provider in your area. Fast and reliable data connections are crucial for a smooth mobile experience.'
      },
      {
        title: 'Customer Support:',
        desc: 'Evaluate the quality of customer support and technical assistance provided by the mobile provider. Good customer support can be important when you need assistance.'
      },
      {
        title: 'Analyze Total Cost:',
        desc: 'Calculate the total annual cost of the mobile plan, including any extra fees, to get a clear picture of the long-term expenses.'
      }
    ],

    description2:
      "By following these steps, you can make an informed decision when evaluating mobile deals and select a plan that aligns with your communication needs and budget. Additionally, it's a good idea to review your mobile plan periodically to ensure it continues to meet your requirements as your usage patterns change.",
    imgSrc: Member2Icon
  },
  {
    label: 'How good is mobile coverage in Ireland?',
    title: 'How good is mobile coverage in Ireland?',
    description:
      'Mobile coverage in Ireland can vary depending on the region, the mobile network operator, and the type of coverage (2G, 3G, 4G, or 5G). Generally, mobile coverage in urban and populated areas is quite good, with strong network signals and high-speed data services. However, coverage may be less consistent in rural and remote areas.',
    numerate: true,
    list: [
      {
        title: 'Urban Areas:',
        desc: ' In major cities and towns, mobile coverage is excellent, and you can expect high-speed 4G and 5G connectivity. You should have no issues with voice calls, text messaging, and data services in these areas.'
      },
      {
        title: 'Rural Areas:',
        desc: 'Rural and remote regions of Ireland may have less consistent mobile coverage, especially in isolated areas. While major roads and highways typically have good coverage, you may experience coverage gaps in remote countryside and mountainous areas. Some networks are working to improve rural coverage, but it can still be variable.'
      },
      {
        title: 'Coastal Areas: ',
        desc: 'Coastal areas and islands may experience fluctuations in mobile coverage. Some islands and more isolated coastal regions may have limited or no coverage, while popular coastal towns and tourist destinations usually have reliable coverage.'
      },
      {
        title: 'Network Operators: ',
        desc: "Ireland has several mobile network operators. Coverage can vary from one operator to another, so it's a good idea to check coverage maps and inquire about the specific network's coverage in your area."
      },
      {
        title: '4G and 5G Services: ',
        desc: 'Most urban areas in Ireland have access to 4G (LTE) and, in some cases, 5G services, which provide high-speed data connectivity for mobile devices. These services are continually expanding, and 5G is becoming more widely available in urban centers.'
      },
      {
        title: 'Roaming: ',
        desc: "While traveling in Ireland, it's essential to understand your roaming capabilities and charges if you're using a mobile plan from another country. Roaming agreements vary among providers and countries, so check with your mobile provider for the specific terms and conditions."
      }
    ],
    description2:
      'To get the most accurate information about mobile coverage in your specific area, you can use online coverage maps provided by mobile network operators or contact them directly. These maps show coverage areas and signal strengths, helping you determine the quality of coverage in your location. Keep in mind that technology is continually evolving, and mobile network operators are working to improve and expand coverage throughout the country. If you are concerned about mobile coverage, you may also want to explore options such as signal boosters or femtocells to enhance connectivity in your home or business, particularly in areas with limited coverage.',
    imgSrc: Member3Icon
  },
  {
    label: 'What are the key steps to switching your mobile service?',
    title: 'What are the key steps to switching your mobile service?',
    description:
      "Switching your mobile service provider in Ireland involves several key steps to ensure a smooth transition. Here's a guide on how to switch your mobile service:",
    numerate: true,
    list: [
      {
        title: 'Research New Mobile Providers:',
        desc: 'Start by researching and identifying potential mobile service providers in Ireland. Consider factors such as network coverage, data plans, pricing, and any special offers.'
      },
      {
        title: 'Check Network Compatibility:',
        desc: "Ensure that the new provider's network is compatible with your current mobile device. Check whether your phone is locked to your current provider, and if it is, consider unlocking it."
      },
      {
        title: 'Compare Mobile Plans:',
        desc: 'Compare the mobile plans and packages offered by different providers. Look at features like data allowances, call minutes, text messages, contract lengths, and any additional services.'
      },
      {
        title: 'Check for Special Offers:',
        desc: 'Keep an eye out for any special offers, promotions, or discounts that mobile providers may be offering. These can provide cost savings or added value.'
      },
      {
        title: 'Calculate Your Usage:',
        desc: 'Evaluate your typical mobile usage, including the number of calls, text messages, and data consumption. Choose a plan that matches your usage patterns.'
      },
      {
        title: 'Contact the New Provider:',
        desc: "Contact the new mobile provider you're interested in switching to. Inquire about their available plans and express your intention to switch. They can guide you through the process."
      },
      {
        title: 'Request a PAC Code:',
        desc: "If you wish to keep your current mobile number, request a Porting Authorization Code (PAC) from your current provider. You'll need to provide this code to your new provider."
      },
      {
        title: 'Choose a New Plan:',
        desc: "Select the mobile plan and any additional services (e.g., data add-ons or international calling options) that best suit your needs. Ensure you understand the plan's contract terms."
      },
      {
        title: 'Complete the Application:',
        desc: 'Fill out the necessary application or contract forms with the new provider. This may involve providing personal details, proof of identity, and contact information.'
      },
      {
        title: 'Keep Your Current Service Active:',
        desc: 'Do not cancel your current mobile service until the switch is complete to avoid losing your mobile number. Your new provider will inform you when to do so.'
      },
      {
        title: 'Port Your Mobile Number:',
        desc: 'Provide the PAC code you obtained from your current provider to your new provider. They will initiate the number porting process, which should be completed within one working day.'
      },
      {
        title: 'Test Your New Service:',
        desc: "After the number porting is complete, test your new mobile service to ensure it's working as expected. Make calls, send text messages, and use data to verify connectivity."
      },
      {
        title: 'Cancel Your Old Service:',
        desc: 'Once you have confirmed that your new service is operational, contact your old mobile provider to cancel your previous service. Be aware of any notice period or early termination fees.'
      },
      {
        title: 'Update Your Contact Information:',
        desc: 'Ensure that your contact information, such as your new mobile number, is updated with your contacts and with any online accounts that rely on two-factor authentication.'
      },
      {
        title: 'Check Your Bills:',
        desc: 'Monitor your billing statements from both your old and new providers to ensure accuracy and that you are no longer being charged by your previous provider.'
      }
    ],
    imgSrc: Member4Icon
  },
  {
    label: 'What are the key factors that you should consider when purchasing a new mobile phone?',
    title: 'What are the key factors that you should consider when purchasing a new mobile phone?',
    description:
      'When purchasing a new mobile phone, there are several key factors to consider to ensure that you select a device that meets your needs and preferences. Here are the important factors to keep in mind:',
    numerate: true,
    list: [
      {
        title: 'Operating System (OS):',
        desc: 'Choose between the major mobile operating systems: iOS (Apple), Android (Google), or, to a lesser extent, Windows or KaiOS. Your choice will determine the user interface, available apps, and ecosystem compatibility.'
      },
      {
        title: 'Budget:',
        desc: 'Determine your budget for a new phone. Mobile phones come in a wide price range, from budget-friendly to premium models. Stick to your budget to avoid overspending.'
      },
      {
        title: 'Brand and Model:',
        desc: 'Research different brands and models to find one that suits your needs. Consider factors such as brand reputation, build quality, and features.'
      },
      {
        title: 'Screen Size and Quality:',
        desc: 'Consider the screen size, resolution, and display technology. Larger screens are better for media consumption, while higher resolutions offer sharper visuals.'
      },
      {
        title: 'Processor and Performance:',
        desc: "Evaluate the phone's processor (CPU) and RAM. A faster processor and more RAM generally lead to better performance, faster app loading, and multitasking capabilities."
      },
      {
        title: 'Camera Quality:',
        desc: 'Assess the camera quality, including the number of lenses, megapixels, and camera features. If photography is important to you, look for devices with good camera capabilities.'
      },
      {
        title: 'Battery Life:',
        desc: 'Consider the battery capacity and battery life of the phone. A larger battery usually means longer usage between charges. Also, check if it supports fast charging or wireless charging.'
      },
      {
        title: 'Storage Capacity:',
        desc: 'Determine how much storage you need for apps, photos, videos, and files. Most phones offer various storage options, so choose one that suits your needs. Some phones also support expandable storage via microSD cards.'
      },
      {
        title: 'Connectivity:',
        desc: "Check for connectivity features like 4G/5G support, Wi-Fi 6, Bluetooth, and NFC. Ensure that the phone is compatible with your carrier's network."
      },
      {
        title: 'Design and Build:',
        desc: "Consider the phone's design, materials, and build quality. Some people prefer slim and lightweight phones, while others prioritize durability."
      },
      {
        title: 'Water Resistance:',
        desc: 'If you anticipate exposure to water or want added protection, look for a phone with water and dust resistance (IP rating).'
      },
      {
        title: 'Security and Biometrics:',
        desc: 'Check for security features such as fingerprint sensors, facial recognition, or in-display fingerprint scanners. Consider your preferred method of unlocking your phone.'
      },
      {
        title: 'Software Updates:',
        desc: "Research the manufacturer's track record for providing software updates and security patches. Frequent updates help keep your device secure and up to date."
      },
      {
        title: 'Brand and Ecosystem:',
        desc: "Consider how the phone fits into your existing tech ecosystem. For example, if you're heavily invested in Apple products, an iPhone may be a more seamless choice."
      },
      {
        title: 'User Reviews:',
        desc: 'Read user reviews and expert opinions to get an idea of real-world performance, user satisfaction, and potential issues with the phone.'
      },
      {
        title: 'Accessories and Add-Ons:',
        desc: 'Check the availability of accessories, such as cases, screen protectors, and wireless chargers. Availability can affect your overall experience.'
      },
      {
        title: 'Warranty and Support:',
        desc: 'Understand the warranty terms and customer support offered by the manufacturer. A strong warranty and responsive customer support can be valuable.'
      },
      {
        title: 'Carrier Compatibility:',
        desc: "Ensure that the phone you choose is compatible with your mobile carrier's network and supports the required frequency bands."
      }
    ],
    description2: '',
    imgSrc: Member5Icon
  }
];

export default mobile;
