import React, { useState } from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Box, Dialog, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import AuthIcon from '@packs/images/ui/sign-up-sidebar.webp';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import Form from './Form';
import useStyles from './styles';

import { MacScrollbar } from 'mac-scrollbar';
import { useNavigate } from 'react-router-dom';

const benefits = [
  'Get personalized results and insights!',
  'Get competitor offers!',
  'All your bill data is kept securely in one place!',
  'Get member rewards!',
  'Get reminder notifications prior to your next renewal!',
  'Create a more competitive and transparent marketplace for bills!'
];

export const ChangePasswordModal = ({ toggle, isOpen }) => {
  const styles = useStyles();
  const { isDesktop } = useWindowSize();

  const { userStore } = useStore();
  const { ROOT_PATH } = usePath();
  const navigate = useNavigate();

  const [inProcess, setInProcess] = useState(false);

  const submitHandler = ({ password, passwordConfirmation }) => {
    const resetPasswordToken = location.search.slice(22);

    setInProcess(true);

    const callback = () => {
      setInProcess(false);
      toggle('isChangePasswordModalOpen');
      toastr.success('Password successfully updated');
      navigate(ROOT_PATH);
    };

    const errorCallback = () => {
      setInProcess(false);
    };

    userStore.changeUserResetedPassword({
      password,
      passwordConfirmation,
      resetPasswordToken,
      callback,
      errorCallback
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={() => toggle('isChangePasswordModalOpen')}
      PaperProps={{
        style: {
          maxWidth: '1440px',
          width: '100%',
          margin: '24px 16px',
          borderRadius: '24px',
          overflow: 'hidden'
        }
      }}
    >
      <Box sx={styles.wrapper}>
        {isDesktop && (
          <Box sx={styles.infoSection}>
            <MacScrollbar
              style={{ flex: 1, maxHeight: 'calc(100vh - 82px)' }}
              trackStyle={horizontal => ({
                [horizontal ? 'height' : 'width']: 0,
                right: 2,
                border: 0
              })}
              thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
              suppressAutoHide
            >
              <Box sx={styles.infoSectionContainer}>
                <Box sx={styles.infoSectionContent}>
                  <Typography variant='h4' sx={styles.title}>
                    Become a member of the Bill Winner community!
                  </Typography>
                  <Typography variant='h6' sx={styles.subTitle}>
                    Bill Winner Member Benefits:
                  </Typography>
                  <Box component='ul' sx={styles.benefitsWrapper}>
                    {benefits.map(item => (
                      <li key={item}>
                        <CheckRoundedIcon />
                        <Typography variant='body1'>{item}</Typography>
                      </li>
                    ))}
                  </Box>
                </Box>
                <Box sx={styles.authSidebarImgWrapper}>
                  <img src={AuthIcon} alt='Auth' />
                </Box>
              </Box>
            </MacScrollbar>
          </Box>
        )}
        <Box sx={styles.formWrapper}>
          <DialogTitle sx={styles.modalTitle}>
            <Box />
            <IconButton edge='end' color='inherit' onClick={toggle}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <MacScrollbar
            style={{ flex: 1, maxHeight: 'calc(100vh - 145px)' }}
            trackStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 0, right: 2, border: 0 })}
            thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
            suppressAutoHide
          >
            <Box sx={styles.formContainer}>
              <Stack sx={{ width: '100%', maxWidth: '480px', height: '100%', mx: 'auto' }}>
                <Typography variant='h5' textAlign='center' sx={{ mb: 1 }}>
                  Change my password!
                </Typography>
                <Typography variant='subtitle1' textAlign='center' sx={{ mb: 3, color: colors.text.secondary }}>
                  Enter your new password!
                </Typography>
                <Form onSubmit={submitHandler} inProcess={inProcess} />
              </Stack>
            </Box>
          </MacScrollbar>
        </Box>
      </Box>
    </Dialog>
  );
};
