import colors from '@packs/assets/theme/base/colors';

const { white, secondary } = colors;

export default () => ({
  container: {
    maxWidth: '1162px',
    mx: 'auto',
    px: 2,
    py: {
      xs: 6,
      lg: 15
    }
  },
  benefitsContainer: {
    display: 'flex',
    gap: 3,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { xs: 'column', md: 'row' },
    mt: { xs: '-165px', md: '-127px' },
    position: 'relative',
    zIndex: 3
  },
  benefitItem: {
    maxWidth: {
      xs: '256px',
      md: '316px'
    },
    minHeight: {
      xs: '225px',
      md: '276px'
    },
    borderRadius: '24px',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    pt: 4,
    px: 3,
    pb: {
      xs: 4,
      md: 0
    },
    backgroundColor: white.main
  },
  benefitItemImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    mb: 3,
    mx: 'auto',
    borderRadius: '16px',
    backgroundColor: secondary.transparent
  },
  startButton: {
    minWidth: { xs: '256px', md: '280px' }
  }
});
