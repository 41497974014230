import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash-es';
import moment from 'moment';

import { Calendar } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { FILTER_CATEGORIES } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';

const MyReminders = observer(() => {
  const { currentCategory, smartRemindersStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { flattenCategories } = useCategories();
  const { onEditReminder } = useModalsContext();
  const { rootCategoryCode, editSmartReminder } = queryString.parse(location.search);

  const [filterRootCode, setFilterRootCode] = useState(rootCategoryCode || FILTER_CATEGORIES[0].value);

  const handleSelectChange = ({ value }) => {
    setFilterRootCode(value);
    smartRemindersStore.fetchSmartReminders({ categoryCode: value, userId: +currentUser.id });
  };

  const loadSmartReminders = () => {
    const onSuccess = res => {
      const event = res.find(item => item.id === +editSmartReminder);
      const category = event?.category || currentCategory;

      if (event) {
        onEditReminder(event, { parentType: 'calendar', category });
      }
    };

    if (currentUser.id) {
      smartRemindersStore.fetchSmartReminders({ userId: +currentUser.id, categoryCode: rootCategoryCode }, onSuccess);
    } else {
      smartRemindersStore.resetSmartReminders();
    }
  };

  useEffect(() => {
    loadSmartReminders();
  }, []);

  // calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object

  const { onDeleteReminder, onAddNewReminder } = useModalsContext();

  const onCalendarDateClick = ({ date }) => {
    if (moment(date) >= moment()) {
      const findCategory = _.find(flattenCategories, { root: { code: filterRootCode } });

      onAddNewReminder(date, {
        selectedDate: date,
        selectedReminder: null,
        parentType: 'profile/my-reminders',
        category: findCategory || _.get(flattenCategories, '0')
      });
    } else {
      toastr.warning("You can't set a reminder for a date in the past");
    }
  };

  const groupedCategory = _.groupBy(flattenCategories, 'id');
  const events = _.map(smartRemindersStore.smartReminders.slice(), obj => {
    return {
      ...obj,
      category: _.get(groupedCategory[obj.categoryId], 0),
      title: obj.aliasName || obj.name,
      allDay: true,
      start: new Date(obj.triggeredAt)
    };
  });

  const onCalendarDeleteReminder = item => {
    const handleDeleteReminder = () => {
      smartRemindersStore.destroy(
        { ids: [item.id] },
        () => {
          toastr.success('Smart reminder deleted');
        },
        () => {
          toastr.error('Smart reminder NOT deleted');
        }
      );
      loadSmartReminders();
    };

    onDeleteReminder(item, handleDeleteReminder);
  };

  return (
    <>
      <Helmet>
        <title>My Reminders</title>
      </Helmet>
      <Calendar
        {...{
          events,
          currentUser,
          filterRootCode,
          onCalendarDateClick,
          handleSelectChange,
          onCalendarDeleteReminder
        }}
      />
    </>
  );
});

export default MyReminders;
