import React, { useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { BASE_APP_URL } from '@packs/lib/constants';

export const PrivacyModal = ({ isOpen, toggle, className }) => {
  const descriptionElementRef = useRef(null);
  const { defaultMessages, formatMessage } = useTranslationMessages();

  return (
    <Dialog open={isOpen} onClose={toggle} scroll='paper' fullWidth maxWidth='md'>
      <DialogTitle>{formatMessage(defaultMessages.privacyPolicyModalTitle)}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1} className='info-text'>
          <h5 className='info-title'>{formatMessage(defaultMessages.privacyPolicyModalSubtitle)}</h5>
          <p>
            {formatMessage(defaultMessages.privacyPolicyModalDesc1)}
            <a href={BASE_APP_URL}>www.BillWinner.com</a>
            {formatMessage(defaultMessages.privacyPolicyModalDesc2)}
          </p>
          <p>{formatMessage(defaultMessages.privacyPolicyModalDesc3)}</p>
          <ul>
            <li>
              <h6> 1. {formatMessage(defaultMessages.privacyPolicyModalChapter1Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter1P1)}</p>
              <strong>{formatMessage(defaultMessages.privacyPolicyModalChapter1S1)}</strong>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter1P2)}</p>
              <strong>{formatMessage(defaultMessages.privacyPolicyModalChapter1S2)}</strong>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter1P3)}</p>
              <strong>{formatMessage(defaultMessages.privacyPolicyModalChapter1S3)}</strong>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter1P4)}</p>
            </li>
            <li>
              <h6> 2. {formatMessage(defaultMessages.privacyPolicyModalChapter2Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter2P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter2P2)}</p>
              <p>
                {formatMessage(defaultMessages.privacyPolicyModalChapter2P3)}:
                <a href='mailto:security@billwinner.com'>security@billwinner.com.</a>
              </p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter2P4)}: +35314434405.</p>
            </li>
            <li>
              <h6> 3. {formatMessage(defaultMessages.privacyPolicyModalChapter3Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter3P1)}</p>
            </li>
            <li>
              <h6> 4. {formatMessage(defaultMessages.privacyPolicyModalChapter4Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter4P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter4P2)}</p>
            </li>
            <li>
              <h6> 5. {formatMessage(defaultMessages.privacyPolicyModalChapter5Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter5P1)}</p>
              <ol type='a'>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P11)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P12)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P13)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P14)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P15)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P16)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P17)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P18)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter5P19)}</li>
              </ol>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter5P2)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter5P3)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter5P4)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter5P5)}</p>
            </li>
            <li>
              <h6> 6. {formatMessage(defaultMessages.privacyPolicyModalChapter6Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter6P1)}</p>
              <table>
                <thead>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr1Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr1Td2)}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr2Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr2Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr3Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr3Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr4Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr4Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr5Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr5Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr6Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr6Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr7Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr7Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr8Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr8Td2)}</td>
                  </tr>

                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr9Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr9Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr10Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr10Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr11Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr11Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr12Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter6Tr12Td2)}</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <h6> 7. {formatMessage(defaultMessages.privacyPolicyModalChapter7Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P1)}</p>
              <table className='second-table'>
                <thead>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr1Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr1Td2)}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr2Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr2Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr3Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr3Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr4Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr4Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr5Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr5Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr6Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr6Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr7Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr7Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr8Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr8Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr9Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr9Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr10Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr10Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr11Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr11Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr12Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr12Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr13Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr13Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr14Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr14Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr15Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter7Tr15Td2)}</td>
                  </tr>
                </tbody>
              </table>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P2)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P3)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P4)}</p>
              <ul>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter7Li1)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter7Li2)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter7Li3)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter7Li4)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter7Li5)}</li>
              </ul>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P5)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P6)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P7)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P8)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P9)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter7P10)}</p>
            </li>
            <li>
              <h6> 8. {formatMessage(defaultMessages.privacyPolicyModalChapter8Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter8P1)}</p>
              <table>
                <thead>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr1Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr1Td2)}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr2Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr2Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr3Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr3Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr4Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr4Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr5Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr5Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr6Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr6Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr7Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr7Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr8Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr8Td2)}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr9Td1)}</td>
                    <td>{formatMessage(defaultMessages.privacyPolicyModalChapter8Tr9Td2)}</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <h6> 9. {formatMessage(defaultMessages.privacyPolicyModalChapter9Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter9P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter9P2)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter9P3)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter9P4)}</p>
              <ul>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter9Li1)}</li>
                <li>{formatMessage(defaultMessages.privacyPolicyModalChapter9Li2)}</li>
              </ul>
            </li>
            <li>
              <h6> 10. {formatMessage(defaultMessages.privacyPolicyModalChapter10Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P2)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P3)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P4)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P5)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P6)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P7)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P8)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter10P9)}</p>
            </li>
            <li>
              <h6> 11. {formatMessage(defaultMessages.privacyPolicyModalChapter11Title)}</h6>
              <ul style={{ listStyle: 'none' }}>
                <li> 11.1 {formatMessage(defaultMessages.privacyPolicyModalChapter11Li1)}</li>
                <li>
                  11.2 {formatMessage(defaultMessages.privacyPolicyModalChapter11Li2)}
                  <ul>
                    <li>
                      UK:
                      <a href='http://www.mpsonline.org.uk/tps'>
                        {formatMessage(defaultMessages.privacyPolicyModalChapter11A1)}
                      </a>
                    </li>
                    <li>
                      {formatMessage(defaultMessages.privacyPolicyModalChapter11RepublicOfIreland)}:
                      <a href='http://www.dataprotection.ie/viewdoc.asp?DocID=908'>
                        {formatMessage(defaultMessages.privacyPolicyModalChapter11A2)}
                      </a>
                    </li>
                    <li>
                      US:
                      <a href='https://www.donotcall.gov/default.aspx'>
                        {formatMessage(defaultMessages.privacyPolicyModalChapter11A3)}
                      </a>
                    </li>
                    <li>
                      Australia:
                      <a href='https://www.donotcall.gov.au/'>
                        {formatMessage(defaultMessages.privacyPolicyModalChapter11A4)}
                      </a>
                    </li>
                  </ul>
                  {formatMessage(defaultMessages.privacyPolicyModalChapter11Li3)}
                  <p>{formatMessage(defaultMessages.privacyPolicyModalChapter11P1)}</p>
                  <p>
                    {formatMessage(defaultMessages.privacyPolicyModalChapter11P2)}
                    <a href='https://support.google.com/analytics/answer/181881?hl=en-GB'>
                      {formatMessage(defaultMessages.privacyPolicyModalChapter11Here)}
                    </a>
                  </p>
                  <p>{formatMessage(defaultMessages.privacyPolicyModalChapter11P3)}</p>
                  <ul>
                    <li>
                      <a href='https://support.google.com/chrome/answer/95647?hl=en-GB'>Google Chrome</a>
                    </li>
                    <li>
                      <a href='https://support.microsoft.com/en-us/kb/260971'>Internet Explorer</a>
                    </li>
                    <li>
                      <a href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer'>
                        Mozilla Firefox
                      </a>
                    </li>
                    <li>
                      <a href='https://support.apple.com/kb/ph21411?locale=en_US'>Safari (Desktop)</a>
                    </li>
                    <li>
                      <a href='https://support.apple.com/en-us/HT201265'>Safari (Mobile)</a>
                    </li>
                    <li>
                      <a href='http://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067'>
                        Android Browser
                      </a>
                    </li>
                    <li>
                      <a href='http://www.opera.com/help'>Opera</a>
                    </li>
                    <li>
                      <a href='https://www.opera.com/help/tutorials/security/privacy/'>Opera Mobile</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <h6> 12. {formatMessage(defaultMessages.privacyPolicyModalChapter12Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter12P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter12P2)}</p>
            </li>
            <li>
              <h6> 13. {formatMessage(defaultMessages.privacyPolicyModalChapter13Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter13P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter13P2)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter13P3)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter13P4)}</p>
            </li>
            <li>
              <h6> 14. {formatMessage(defaultMessages.privacyPolicyModalChapter14Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter14P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter14P2)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter14P3)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter14P4)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter14P5)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter14P6)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter14P7)}</p>
            </li>
            <li>
              <h6> 15. {formatMessage(defaultMessages.privacyPolicyModalChapter15Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter15P1)}</p>
              <p>
                {formatMessage(defaultMessages.privacyPolicyModalChapter15P2)}:
                <a href='mailto:security@billwinner.com'>security@billwinner.com.</a>
              </p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter15P3)}: +35314434405.</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter15P4)}</p>
            </li>
            <li>
              <h6> 16. {formatMessage(defaultMessages.privacyPolicyModalChapter16Title)}</h6>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter16P1)}</p>
              <p>{formatMessage(defaultMessages.privacyPolicyModalChapter16P2)}</p>
            </li>
            <li>
              <h6>17. Extra</h6>
              <p>
                By signing up to our site you agree to receiving notifications from our business. If you wish to
                unsubscribe from our notifications, please click unsubscribe within our emails.
              </p>
              <p>
                While every effort is made to ensure that our market prices are up to date they may not be 100%
                accurate. We always advise to check the market prices of the provider , broker and price comparison site
                in your shopping journey to ensure 100% accuracy.
              </p>
              <p>
                Our application is created to help you make smarter decisions with your money. We accept no
                responsibility for any loss you incur as a result from data or insights on our application. We always
                recommend you get professional financial advice when making financial decisions.
              </p>
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='dark' size='large' onClick={toggle}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
