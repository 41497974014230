import React, { useEffect, useMemo, useRef } from 'react';
import { toJS } from 'mobx';
import _ from 'lodash-es';

import { TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { renewalDetailApi } from '@packs/apis';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { MaterialRadioGroup } from '@packs/components/MaterialUi/MaterialRadioGroup/MaterialRadioGroup';
import { DashboardCriteriaFilters, DashboardLocationFilter, DashboardPolicyFilter } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { toastr } from '@packs/lib/helpers';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { sortOptions } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { isWikiTableCategory } from '@packs/models/category/functions';
import { COMPANY_TYPE_EACH_TYPE, COMPANY_TYPE_OPTIONS, TIME_OPTIONS } from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { categoriesWithoutPersonalizeFlow } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const MyResultsFilters = observer(({ sidebarFilter = false }) => {
  const { companiesStore, currentCountry, currentCategory, policyStore, offersStore } = useStore();
  const { currentPolicy } = useDetails();
  const { currentUser } = useUserSelectors();
  const { isMobile } = useWindowSize();

  const personalized = hasPolicyCriteria(currentPolicy);
  const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(currentCategory?.root?.code);
  const showPersonalizedOffers = currentPolicy?.id && !isCategoriesWithoutPersonalizeFlow;

  const isUserInteract = useRef<boolean>(false);

  const onLoadCompanies = filters => {
    if (filters) {
      companiesStore.load(filters);
    } else {
      companiesStore.load({});
    }
  };

  const onChangeLocationFilter = newStateOption => {
    const location = {
      countryCode: currentCountry?.isoA2Code || 'ie',
      address: `${(newStateOption || {}).value || ''}`
    };

    if (location.countryCode || location.address) {
      const filters: { city?: string; country?: string } = {
        city: location.address,
        country: location.countryCode
      };

      if (_.get(newStateOption, 'value') === 'All') {
        filters.city = 'all';
        delete filters.country;
      }

      onLoadCompanies({ filters });
      companiesStore.loadRequestQuotesCount();
      if (showPersonalizedOffers) {
        offersStore.loadPersonalizedOffers(currentCategory.id, {
          ...companiesStore?.filters?.criteriaQuestions,
          location: filters?.city || null
        });
      }
    } else {
      toastr.warning('no any location detected');
    }

    // User change preference
    isUserInteract.current = true;
  };

  const onChangePolicy = async ({ object: policy }) => {
    isUserInteract.current = false;
    policyStore.setCurrentPolicy(policy);

    if (policy.id) {
      await policyStore.loadPolicy(policy.id);
      onLoadCompanies({ filters: { city: policy?.criteriaQuestions?.location }, prefillCriteria: true });
      await companiesStore.loadRequestQuotesCount();

      if (showPersonalizedOffers) {
        offersStore.loadPersonalizedOffers(currentCategory.id, {
          ...companiesStore?.filters?.criteriaQuestions,
          location: companiesStore?.filters?.city || null
        });
      }
    }
  };

  const onChangeFilter = key => {
    return data => {
      onLoadCompanies({ filters: { [key]: typeof data === 'object' ? data?.value : data } });
    };
  };

  const onChangeMaterialFilter = key => {
    return data => {
      onLoadCompanies({ filters: { [key]: typeof data === 'object' ? data?.value : data } });
    };
  };

  const handleChangeCriteriaQuestions = (value, key) => {
    let criteriaQuestions: Record<string, string> | undefined;

    let newValue = value;
    if (!_.isNull(value) && !_.isUndefined(value)) {
      if (value === 'all') {
        newValue = '';
      }
      criteriaQuestions = {
        ...companiesStore.filters.criteriaQuestions,
        [key]: newValue
      };
    } else {
      criteriaQuestions = companiesStore.filters.criteriaQuestions;
    }

    onLoadCompanies({ filters: { criteriaQuestions } });
    companiesStore.loadRequestQuotesCount();
    if (showPersonalizedOffers) {
      offersStore.loadPersonalizedOffers(currentCategory.id, {
        ...criteriaQuestions,
        location: companiesStore?.filters?.city || null
      });
    }
    // User change preference
    isUserInteract.current = true;
  };

  const companyTypeOptions = useMemo(() => {
    let list = isWikiTableCategory(currentCategory)
      ? [...COMPANY_TYPE_OPTIONS, COMPANY_TYPE_EACH_TYPE]
      : [COMPANY_TYPE_EACH_TYPE, ...COMPANY_TYPE_OPTIONS];

    if (!isWikiTableCategory(currentCategory) && !personalized) {
      list = list.filter(item => item.value !== 'all_types');
    }

    if (currentCategory?.root?.code === 'subscriptions') {
      return [{ value: 'provider', label: 'Top Providers' }];
    }

    return list;
  }, [currentCategory.id, currentPolicy?.id]);

  // const isPersonalized = hasPolicyCriteria(currentPolicy);

  const isInsuranceCategory =
    ['insurance'].includes(currentCategory.root?.enName) || currentCategory.code?.includes('insurance');

  const sortList = useMemo(() => {
    return sortOptions({ isInsuranceCategory, currentUser });
  }, [currentUser.id, isInsuranceCategory]);

  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const currentFilters = toJS(companiesStore?.filters);

  // Skip user action on page change
  useEffect(() => {
    isUserInteract.current = false;
  }, [currentFilters?.page]);

  // Reset current timer state by location change
  useEffect(() => {
    if (!isUserInteract.current || !currentPolicy?.id) {
      return;
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }

    async function updateMyCriteria(id, criteriaQuestions) {
      await renewalDetailApi.updateRenewalDetailCriteria({ id, criteriaQuestions });
      toastr.success('Your details was successfully changed!');
    }

    timer.current = setTimeout(() => {
      toastr.custom('You have updated your details!', {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        preventDuplicate: true,
        btnMessage: 'Save changes!',
        callback: () => {
          updateMyCriteria(currentPolicy?.id, { ...currentFilters.criteriaQuestions, location: currentFilters.city });
        }
      });
    }, 2000);
  }, [currentFilters, currentPolicy?.id, isUserInteract]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: sidebarFilter ? 3 : 2 }}>
      <Box>
        <DashboardPolicyFilter
          // initialExpanded={false}
          onChange={onChangePolicy}
          size={sidebarFilter ? 'small' : 'medium'}
          withTitle={sidebarFilter}
        />
      </Box>
      {!sidebarFilter && <Divider />}
      <Box>
        <DashboardLocationFilter
          {...{ currentCountry }}
          size={sidebarFilter ? 'small' : 'medium'}
          onChange={onChangeLocationFilter}
          withTitle={sidebarFilter}
        />
      </Box>
      {!sidebarFilter && <Divider />}
      {companiesStore?.filters?.orderColumn !== 'wiki_price' && (
        <>
          <Box>
            <AutocompleteMUI
              value={TIME_OPTIONS.find(item => item.value === companiesStore.filters.lastBuy)}
              options={TIME_OPTIONS}
              onChange={onChangeMaterialFilter('lastBuy')}
              label='Please select time'
              size={sidebarFilter ? 'small' : 'medium'}
              withTitle={sidebarFilter}
              mobileSelect
              filter
            />
          </Box>
          {!sidebarFilter && <Divider />}
        </>
      )}
      <Box>
        <Typography variant={'body6' as TypographyProps['variant']}>Source type</Typography>
        <MaterialRadioGroup
          row={false}
          name='companyType'
          options={companyTypeOptions}
          value={companyTypeOptions.find(item => item.value === companiesStore?.filters?.companyType)}
          onChange={onChangeFilter('companyType')}
        />
      </Box>
      {!sidebarFilter && <Divider />}
      <Box>
        <DashboardCriteriaFilters onChange={handleChangeCriteriaQuestions} {...{ sidebarFilter }} />
      </Box>
      {isMobile && (
        <>
          <Box>
            {!sidebarFilter && <Divider />}
            <MaterialRadioGroup
              row={false}
              name='orderColumn'
              options={sortList}
              value={sortList.find(item => item.value === companiesStore?.filters?.orderColumn)}
              onChange={onChangeFilter('orderColumn')}
            />
          </Box>
        </>
      )}
    </Box>
  );
});
