import colors from '@packs/assets/theme/base/colors';

const { secondary, dark, primary } = colors;

const hoverStyles = {
  backgroundColor: {
    xs: 'rgba(0, 85, 217, 0.04)',
    lg: 'rgba(0, 85, 217, 0.12)'
  },
  '& .sectionButton': {
    mb: 0,
    mt: 0
  },
  '@media(min-width: 992px)': {
    '& .topBusinessTitle': {
      display: 'none'
    }
  },
  '& .topBusinessButton': {
    display: 'block'
  }
};

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    alignItems: 'stretch',
    gap: 1.5,
    mt: 2
  },
  sectionItemWrapper: isEmpty => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    width: '100%',
    minHeight: '136px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 85, 217, 0.08)',
    p: 1.5,
    backgroundColor: isEmpty ? 'rgba(248, 181, 0, 0.04)' : 'rgba(0, 85, 217, 0.04)',
    color: dark.main,
    overflow: 'hidden',
    transition: 'background .2s'
  }),
  sectionItemWrapperHover: {
    '&:hover': {
      ...hoverStyles
    },
    '@media (max-width: 991px)': {
      ...hoverStyles
    }
  },
  shortSectionItemWrapper: {
    maxWidth: {
      xs: '100%',
      lg: '192px'
    }
  },
  quotesSectionItemWrapper: {
    maxWidth: {
      xs: '100%',
      lg: '228px'
    }
  },
  sectionTopInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 1
  },
  sectionBottomInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  },
  sectionLabel: isEmpty => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    minWidth: '40px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: isEmpty ? 'rgba(248, 181, 0, 0.12)' : 'rgba(0, 85, 217, 0.12)',
    color: isEmpty ? primary.secondary : secondary.light
  }),
  sectionButton: {
    mb: '-40px',
    mt: 0.5,
    transition: 'all .2s'
  },
  companiesList: {
    display: 'flex',
    alignItems: 'center'
  },
  companyItemWrapper: {
    width: '40px',
    height: '40px',
    ml: '-12px',
    borderRadius: '8px',
    border: '2px solid #EBF1FC',
    overflow: 'hidden',
    '&:first-child': { ml: 0 },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  }
});
