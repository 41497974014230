import React from 'react';

import { MyNotes } from '@packs/components/UI';

import { Helmet } from 'react-helmet';

const MyNotesGeneral = () => {
  return (
    <>
      <Helmet>
        <title>My Documents & Notes</title>
      </Helmet>
      <MyNotes general />
    </>
  );
};

export default MyNotesGeneral;
