import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import BenefitIcon1 from '@packs/images/svg/ui/leaderboard-banner-icon-1.svg';
import BenefitIcon2 from '@packs/images/svg/ui/leaderboard-banner-icon-2.svg';
import BenefitIcon3 from '@packs/images/svg/ui/leaderboard-banner-icon-3.svg';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

const { secondary } = colors;

export const LeaderboardHowItsWork = () => {
  const styles = useStyles();
  const { isMobile } = useWindowSize();

  return (
    <Box sx={styles.wrapper}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        How it works?
      </Typography>
      <Box sx={styles.list}>
        <Box sx={styles.item}>
          <IconBox
            containerSize={isMobile ? '36px' : '40px'}
            iconSize={isMobile ? '24px' : '32px'}
            borderRadius='8px'
            backgroundColor={secondary.tonal}
            icon={<img src={BenefitIcon1} alt='Benefit' />}
          />
          <Typography variant='body3'>
            <Typography as='span' variant='body4'>
              Explore the best businesses{' '}
            </Typography>
            on the leaderboard!
          </Typography>
          <Box sx={styles.arrow}>
            <ChevronRightRoundedIcon />
          </Box>
        </Box>
        <Box sx={styles.item}>
          <IconBox
            containerSize={isMobile ? '36px' : '40px'}
            iconSize={isMobile ? '24px' : '32px'}
            borderRadius='8px'
            backgroundColor={secondary.tonal}
            icon={<img src={BenefitIcon2} alt='Benefit' />}
          />
          <Typography variant='body3'>
            <Typography as='span' variant='body4'>
              Request a quote{' '}
            </Typography>
            from your chosen businesses!
          </Typography>
          <Box sx={styles.arrow}>
            <ChevronRightRoundedIcon />
          </Box>
        </Box>
        <Box sx={styles.item}>
          <IconBox
            containerSize={isMobile ? '36px' : '40px'}
            iconSize={isMobile ? '24px' : '32px'}
            borderRadius='8px'
            backgroundColor={secondary.tonal}
            icon={<img src={BenefitIcon3} alt='Benefit' />}
          />
          <Typography variant='body3'>
            <Typography as='span' variant='body4'>
              Vote for your chosen business{' '}
            </Typography>
            to win rewards!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
