import colors from '@packs/assets/theme/base/colors';

const { white, grey, dark } = colors;

export default () => ({
  wrapper: {
    maxWidth: '387px',
    width: '100%',
    mt: {
      xs: 5,
      lg: 0
    },
    backgroundColor: white.main,
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    overflow: 'hidden'
  },
  container: {
    px: {
      xs: 2,
      sm: 4
    },
    pt: {
      xs: 2,
      sm: 4
    },
    pb: 3
  },
  formContainer: {
    display: 'flex',
    gap: 2,
    alignItems: 'flex-start',
    justifyContent: {
      xs: 'center',
      md: 'flex-start'
    },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    flexWrap: 'wrap'
  },
  formItem: {
    flex: 1,
    width: { xs: '100%', md: 'auto' },
    minWidth: '211px'
  },
  formBtn: {},
  formTitle: {
    mb: 3,
    textAlign: 'center',
    color: dark.main
  },
  formFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 1,
    borderRadius: '0 0 16px 16px',
    py: 1,
    px: 2,
    backgroundColor: grey[200],
    textAlign: 'center'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    px: {
      xs: 2,
      lg: 4
    },
    py: 1.5,
    backgroundColor: grey[200],
    color: dark.main
  }
});
