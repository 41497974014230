import { isCompanyPerMonth, isWikiTableCategory } from '@packs/models/category/functions';

export const categoriesWithLowestPrice = ['broadband', 'mobile', 'energy'];
export const defaultBestPriceCategories = ['broadband', 'mobile', 'energy', 'mortgages'];
export const categoriesWithCountryFilter = ['life_insurance'];

export const calcSaveMoney = ({ currentPrice, company, selectedCost, category }) => {
  const isPerMonth = selectedCost?.value === 'renewalCostMonthly';
  const isPerCategoryPerMonth = isCompanyPerMonth(category);
  const isCategoryLowestPrice = categoriesWithLowestPrice.some(item => item === category?.root?.code);
  const isWikiTable = isWikiTableCategory(category);

  if (!isWikiTable) {
    const averagePrice = isPerMonth
      ? company?.additionalData?.averagePriceData?.averageMonthly || 0
      : company?.additionalData?.averagePriceData?.averageYearly || 0;
    const minPrice = isPerMonth
      ? company?.additionalData?.minPriceData?.minMonthly || 0
      : company?.additionalData?.minPriceData?.minYearly || 0;

    if (isCategoryLowestPrice) {
      if (!minPrice) {
        return 0;
      }
      return currentPrice - minPrice;
    }
    if (!averagePrice) {
      return 0;
    }

    return currentPrice - averagePrice;
  } else {
    const wikiTable = company?.wikiTable;
    const isDynamicPrice = ['loans', 'mortgages'].includes(category.code);
    if (isDynamicPrice) {
      if (isPerCategoryPerMonth) {
        return currentPrice - (isPerMonth ? wikiTable?.variablePrice || 0 : (wikiTable?.variablePrice || 0) * 12);
      }
      return currentPrice - (isPerMonth ? (wikiTable?.variablePrice || 0) / 12 : wikiTable?.variablePrice || 0);
    }
    if (wikiTable?.aerRate || wikiTable?.aprRate) {
      return 0;
    }

    if (isPerCategoryPerMonth) {
      return currentPrice - (isPerMonth ? wikiTable?.planPrice || 0 : (wikiTable?.planPrice || 0) * 12);
    }

    return currentPrice - (isPerMonth ? (wikiTable?.planPrice || 0) / 12 : wikiTable?.planPrice || 0);
  }
};

export const defaultOrderColumn = (category: { code: string; root: { code: string } }, sortList: any[]) => {
  const isWikiTable = isWikiTableCategory(category);

  if (defaultBestPriceCategories.some(item => item === category.code || item === category.root.code)) {
    if (isWikiTable) {
      return sortList.find(item => item.value === 'wiki_price');
    }

    return sortList.find(item => item.value === 'best_avg_prices');
  }

  return sortList.find(item => item.value === 'most_popular');
};
