import React, { useEffect } from 'react';
import cx from 'classnames';

import Zoom from '@mui/material/Zoom';
import colors from '@packs/assets/theme/base/colors';
import { Loader } from '@packs/components';
import { OfferType } from '@packs/components/MaterialUi/Offers/OfferItem/components/OfferType/OfferType';
import { BreadCrumbsComp } from '@packs/components/UI';
import { usePath } from '@packs/layouts/constants';
import { LandingFooter } from '@packs/screens/Landing/components/LandingFooter';
import { VIEW_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { useStore } from '@packs/stores';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import ClockIcon from '@shared/images/offers-general-points/clock.svg';

import { OfferContent } from './components/OfferContent';
import { OfferSidebar } from './components/OfferSidebar';
import { OtherOffers } from './components/OtherOffers';

import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import Countdown from 'react-countdown';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const MyOffersDetailed = observer(() => {
  const { offersStore } = useStore();
  const { MY_HOME_PATH, MY_OFFERS_PATH } = usePath();
  const { isMobile } = useWindowSize();

  const { id } = useParams();
  const offer = offersStore?.offerDetailed;
  const { category } = offer || {};

  const countdownRenderer = ({ hours, minutes, seconds, days }) => {
    return <span>{`${days} days ${hours}:${minutes}:${seconds}`}</span>;
  };

  useEffect(() => {
    if (id) {
      offersStore.loadDetailed({ id: +id });
    }
  }, [id]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  if (offersStore?.loader || !size(offer)) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{offer?.offerMessage}</title>
        <meta name='description' content={offer?.offerDetails} />
        <meta name='og:description' content={offer?.offerDetails} />
      </Helmet>
      <Zoom in={true} timeout={500}>
        <div className='offer-detailed-page'>
          <BreadCrumbsComp
            maxItems={isMobile ? 2 : 3}
            list={[
              { label: 'My Home', to: MY_HOME_PATH },
              { label: 'My Offers', to: MY_OFFERS_PATH },
              { label: offer?.offerMessage }
            ]}
          />
          <div className={cx('fw-700 lh-7 mb-4 wb-bw', isMobile ? 'fs-19' : 'fs-28')}>{offer?.offerMessage}</div>
          <div
            className={cx(
              'd-flex lh-5 mb-16',
              isMobile ? 'fs-13 flex-column gap-8' : 'fs-16 align-items-center gap-16'
            )}
          >
            {offer?.untilDate && (
              <div className='timer-container d-flex gap-8'>
                <div className='d-flex align-items-center gap-4'>
                  <span className='secondary-text'>End date:</span>
                  <span className='fw-500'>{moment(offer?.untilDate).format(VIEW_DATE_FORMAT)}</span>
                </div>
                <div className='timer-wrapper d-flex align-items-center gap-4'>
                  <div className='d-flex align-items-center secondary-text gap-4'>
                    <img src={ClockIcon} alt='Clock' />
                    Time left.
                  </div>
                  <div>
                    <Countdown date={offer?.untilDate} renderer={countdownRenderer} />
                  </div>
                </div>
              </div>
            )}
            <div className='d-flex align-items-center gap-4'>
              <span className='secondary-text'>Offer category:</span>
              <span className='fw-500 d-flex align-items-center gap-4'>
                {SUB_ICONS({ category, fill: colors.category[category?.root?.enName].main })}
                {category.name}
              </span>
            </div>
            <div className='d-flex align-items-center gap-4'>
              <span className='secondary-text'>Offer type:</span>
              <div className='fs-13'>
                <OfferType offerType={offer?.offerType} />
              </div>
            </div>
          </div>

          <div className={cx('d-flex gap-32', isMobile && 'flex-column')}>
            <OfferContent {...{ offer }} />
            <OfferSidebar {...{ offer }} />
            {isMobile && <OtherOffers offer={offer} />}
          </div>
        </div>
      </Zoom>
    </>
  );
});

export default MyOffersDetailed;
