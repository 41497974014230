import Form from './form';

import { reduxForm, reset } from 'redux-form';

const onSubmitSuccess = (_result, dispatch) => dispatch(reset('associationEnquiry'));

const validate = ({ email, body, associationName }) => {
  const errors = {};
  if (!email) errors.email = 'Required';
  if (!body) errors.body = 'Required';
  if (!associationName) errors.associationName = 'Required';
  return errors;
};

const createReduxForm = reduxForm({ form: 'associationEnquiry', validate, onSubmitSuccess });
export default createReduxForm(Form);
