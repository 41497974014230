import React, { useRef } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';

export const TermsModal = ({ isOpen, toggle, className }) => {
  const descriptionElementRef = useRef(null);
  const { defaultMessages, formatMessage } = useTranslationMessages();

  return (
    <Dialog open={isOpen} onClose={toggle} scroll='paper' fullWidth maxWidth='md'>
      <DialogTitle>Terms and conditions</DialogTitle>
      <DialogContent dividers>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1} className='info-text'>
          <h5 className='info-title'>{formatMessage(defaultMessages.termsAndConditionsModalSubtitle)}</h5>
          <p>{formatMessage(defaultMessages.termsAndConditionsModalDescription)}</p>
          <ul>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter1Title)}</h6>
              <p> 1.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter1Desc)}</p>
              <strong>&quot;{formatMessage(defaultMessages.termsAndConditionsModalChapter1S1)}&quot;</strong>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter1P1)}</p>
              <strong>&quot;{formatMessage(defaultMessages.termsAndConditionsModalChapter1S2)}&quot;</strong>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter1P2)}</p>
              <strong>&quot;{formatMessage(defaultMessages.termsAndConditionsModalChapter1S3)}&quot;</strong>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter1P3)}</p>
              <strong>&quot;{formatMessage(defaultMessages.termsAndConditionsModalChapter1S4)}&quot;</strong>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter1P4)}</p>
              <strong>&quot;{formatMessage(defaultMessages.termsAndConditionsModalChapter1S5)}&quot;</strong>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter1P5)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter2Title)}</h6>
              <p> 2.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter2Desc)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter3Title)}</h6>
              <p> 3.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter3P1)}</p>
              <p> 3.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter3P2)}</p>
              <p> 3.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter3P3)}</p>
              <div>
                <p> 3.3.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter3P31)}</p>
                <p> 3.3.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter3P32)}</p>
                <p> 3.3.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter3P33)}</p>
              </div>
              <p> 3.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter3P4)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter4Title)}</h6>
              <p> 4.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter4P1)}</p>
              <p> 4.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter4P2)}</p>
              <p> 4.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter4P3)}</p>
              <p> 4.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter4P4)}</p>
              <p> 4.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter4P5)}</p>
              <p> 4.6 {formatMessage(defaultMessages.termsAndConditionsModalChapter4P6)}</p>
              <p> 4.7 {formatMessage(defaultMessages.termsAndConditionsModalChapter4P7)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter5Title)}</h6>
              <p> 5.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P1)}</p>
              <p> 5.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P2)}</p>
              <p> 5.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P3)}</p>
              <p> 5.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P4)}</p>
              <div>
                <p> 5.4.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P41)}</p>
                <p> 5.4.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P42)}</p>
                <p> 5.4.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P43)}</p>
                <p> 5.4.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter5P44)}</p>
              </div>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter6Title)}</h6>
              <p> 6.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P1)}</p>
              <div>
                <p> 6.1.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P11)}</p>
                <p> 6.1.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P12)}</p>
                <p> 6.1.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P13)}</p>
                <p> 6.1.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P14)}</p>
              </div>
              <p>
                6.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P2)}
                <a href='https://www.billwinner.com'>https://www.billwinner.com.</a>
                {formatMessage(defaultMessages.termsAndConditionsModalChapter6P22)}
              </p>
              <p> 6.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P3)}</p>
              <div>
                <p> 6.3.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P31)}</p>
                <p> 6.3.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P32)}</p>
                <p> 6.3.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P33)}</p>
                <p> 6.3.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P34)}</p>
                <p> 6.3.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P35)}</p>
                <p> 6.3.6 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P36)}</p>
                <p> 6.3.7 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P37)}</p>
                <p> 6.3.8 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P38)}</p>
                <p> 6.3.9 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P39)}</p>
                <p> 6.3.10 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P310)}</p>
                <p> 6.3.11 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P311)}</p>
                <p> 6.3.12 {formatMessage(defaultMessages.termsAndConditionsModalChapter6P312)}</p>
              </div>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter7Title)}</h6>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter7Desc)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter8Title)}</h6>
              <p> 8.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter8P1)}</p>
              <p> 8.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter8P2)}</p>
              <p> 8.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter8P3)}</p>
              <p> 8.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter8P4)}</p>
              <p> 8.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter8P5)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter9Title)}</h6>
              <p> 9.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter9P1)}</p>
              <p> 9.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter9P2)}</p>
              <p> 9.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter9P3)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter10Title)}</h6>
              <p> 10.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P1)}</p>
              <div>
                <p> 10.1.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P11)}</p>
                <p> 10.1.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P12)}</p>
                <p> 10.1.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P13)}</p>
                <p> 10.1.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P14)}</p>
              </div>
              <p> 10.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P2)}</p>
              <div>
                <p> 10.2.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P21)}</p>
                <p> 10.2.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P22)}</p>
                <p> 10.2.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P23)}</p>
                <p> 10.2.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P24)}</p>
                <p> 10.2.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P25)}</p>
                <p> 10.2.6 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P26)}</p>
                <p> 10.2.7 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P27)}</p>
                <p> 10.2.8 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P28)}</p>
                <p> 10.2.9 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P28)}</p>
                <p> 10.2.10 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P210)}</p>
                <p> 10.2.11 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P211)}</p>
                <p> 10.2.12 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P212)}</p>
                <p> 10.2.13 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P213)}</p>
                <p> 10.2.14 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P214)}</p>
              </div>
              <p> 10.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P3)}</p>
              <div>
                <p> 10.3.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P31)}</p>
                <p> 10.3.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P32)}</p>
                <p> 10.3.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P33)}</p>
                <p> 10.3.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P34)}</p>
                <p> 10.3.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P35)}</p>
                <p> 10.3.6 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P36)}</p>
                <p> 10.3.7 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P37)}</p>
              </div>
              <p> 10.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter10P4)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter11Title)}</h6>
              <p> 11.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter11P1)}</p>
              <p> 11.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter11P2)}</p>
              <p> 11.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter11P3)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter12Title)}</h6>
              <p> 12.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter12P1)}</p>
              <p> 12.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter12P2)}</p>
              <p> 12.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter12P3)}</p>
              <p> 12.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter12P4)}</p>
              <p> 12.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter12P5)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter13Title)}</h6>
              <p> 13.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P1)}</p>
              <p> 13.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P2)}</p>
              <p> 13.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P3)}</p>
              <p> 13.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P4)}</p>
              <p> 13.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P5)}</p>
              <p> 13.6 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P6)}</p>
              <p> 13.7 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P7)}</p>
              <p> 13.8 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P8)}</p>
              <p> 13.9 {formatMessage(defaultMessages.termsAndConditionsModalChapter13P9)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter14Title)}</h6>
              <p> 14.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter14P1)}</p>
              <p> 14.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter14P2)}</p>
              <p> 14.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter14P3)}</p>
              <p> 14.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter14P4)}</p>
              <p> 14.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter14P5)}</p>
              <p> 14.6 {formatMessage(defaultMessages.termsAndConditionsModalChapter14P6)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter15Title)}</h6>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter15Desc)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter16Title)}</h6>
              <p> 16.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter16P1)}</p>
              <p> 16.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter16P2)}</p>
              <div>
                <p> 16.2.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter16P21)}</p>
                <p> 16.2.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter16P22)}</p>
                <p> 16.2.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter16P23)}</p>
              </div>
              <p> 16.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter16P3)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter17Title)}</h6>
              <p> 17.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter17P1)}</p>
              <p> 17.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter17P2)}</p>
              <p> 17.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter17P3)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter18Title)}</h6>
              <p> 18.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter18P1)}</p>
              <p> 18.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter18P2)}</p>
              <p> 18.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter18P3)}</p>
              <p> 18.4 {formatMessage(defaultMessages.termsAndConditionsModalChapter18P4)}</p>
              <p> 18.5 {formatMessage(defaultMessages.termsAndConditionsModalChapter18P5)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter19Title)}</h6>
              <p> 19.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter19P1)}</p>
              <p> 19.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter19P2)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter20Title)}</h6>
              <p>{formatMessage(defaultMessages.termsAndConditionsModalChapter20Desc)}</p>
            </li>
            <li>
              <h6>{formatMessage(defaultMessages.termsAndConditionsModalChapter21Title)}</h6>
              <p> 21.1 {formatMessage(defaultMessages.termsAndConditionsModalChapter21P1)}</p>
              <p> 21.2 {formatMessage(defaultMessages.termsAndConditionsModalChapter21P2)}</p>
              <p> 21.3 {formatMessage(defaultMessages.termsAndConditionsModalChapter21P3)}</p>
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='dark' size='large' onClick={toggle}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
