import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import colors from '@packs/assets/theme/base/colors';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useCategories } from '@packs/models/category/useCategories';
import { MobileList } from '@packs/screens/MyQuoteRequests/components/MobileList/MobileList';
import { QuoteRequestStatus } from '@packs/screens/MyQuoteRequests/components/QuoteRequestStatus/QuoteRequestStatus';
import { IQuote, IQuoteRequestFilters } from '@packs/screens/MyQuoteRequests/MyQuoteRequests';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { Pagination } from '@shared/components/Pagination/Pagination';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { tableWrapperStyles } from '@shared/styles/tableWrapperStyles';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const { dark } = colors;

interface Props {
  loading: boolean;
  list: IQuote[];
  totalCount: number;
  filters: IQuoteRequestFilters;
  fetchData: (arg0: IQuoteRequestFilters) => void;
}

export const QuoteRequestsTable = ({ loading, list = [], totalCount, filters, fetchData }: Props) => {
  const { countryCode } = useStore();
  const { currentCategory } = useCategories();
  const { isMobile } = useWindowSize();

  const onVisitCompanyPage = company => () => {
    handleVisitCompany(company, currentCategory);
  };

  const columns = [
    {
      field: 'quoteAgent',
      headerName: 'COMPANY NAME:',
      minWidth: 324,
      flex: 1,
      sortable: false,
      headerClassName: 'table-th',
      renderCell: ({ row }) => {
        const company = row?.quoteAgent?.company;
        const companyName = company?.name;
        const avatarUrl = company.avatarUrl;

        const link = pathGen('PRODUCT', {
          countryCode,
          category: currentCategory,
          company
        });

        return (
          <Stack
            component={Link}
            to={link}
            direction='row'
            alignItems='center'
            spacing={1}
            onClick={onVisitCompanyPage(company)}
          >
            <Avatar
              src={avatarUrl}
              sx={{
                width: '40px',
                height: '40px'
              }}
              variant='rounded'
            />
            <Typography variant='body4' sx={{ color: dark.main }}>
              {companyName}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: 'policy',
      headerName: 'POLICY NAME:',
      minWidth: 200,
      flex: 1,
      sortable: false,
      headerClassName: 'table-th',
      renderCell: ({ row }) => <Typography variant='body4'>{row?.renewalDetail?.smartReminder?.aliasName}</Typography>
    },
    {
      field: 'externalStatus',
      headerName: (
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <span>STATUS:</span>
          <TooltipMUI
            title='Your status will change to "Viewed" when the business reviews your Policy. After this, in a very short time you can expect a call from the business you are interested in.'
            placement='top'
            arrow
          >
            <InfoOutlinedIcon fontSize='small' />
          </TooltipMUI>
        </Stack>
      ),
      minWidth: 146,
      flex: 1,
      sortable: false,
      headerClassName: 'table-th',
      renderCell: ({ row }) => <QuoteRequestStatus status={row?.externalStatus} />
    },
    {
      field: 'createdAt',
      headerName: 'SENT DATE:',
      minWidth: 180,
      flex: 1,
      sortable: false,
      headerClassName: 'table-th',
      renderCell: ({ row }) => {
        return (
          <Box sx={{ width: '100%', textAlign: 'right' }}>
            <Typography variant='body4'>{dayjs(row?.createdAt).format('DD MMM YYYY')}</Typography>
          </Box>
        );
      }
    }
  ];

  const onPageChange = newPaginationModel => {
    fetchData({ page: newPaginationModel });
  };

  function CustomPagination() {
    return (
      <Box>
        <Pagination
          count={Math.ceil(totalCount / filters?.perPage) || 1}
          page={filters?.page}
          onChange={onPageChange}
        />
      </Box>
    );
  }

  if (!loading && list?.length === 0) {
    return (
      <Box sx={{ px: 2, py: 3 }}>
        <EmptyPlaceholder title='You have not added any quote requests yet!' description='Let’s get started'>
          <Box>
            <Button
              variant='contained'
              size='smallToMedium'
              component={Link}
              color='primary'
              to={pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory })}
            >
              Request a quote!
            </Button>
          </Box>
        </EmptyPlaceholder>
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Box>
        <MobileList list={list} />
        <CustomPagination />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        '& .table-th:last-child .MuiDataGrid-columnHeaderTitleContainer': {
          justifyContent: 'flex-end'
        },
        ...tableWrapperStyles
      }}
    >
      <DataGrid
        rows={list}
        columns={columns}
        loading={loading}
        components={{
          Pagination: list.length > 0 ? CustomPagination : null
        }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        columnHeaderHeight={48}
        rowHeight={64}
        disableSelectionOnClick
        autoHeight
        resizable
      />
    </Box>
  );
};
