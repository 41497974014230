import colors from '@packs/assets/theme/base/colors';
import typography from '@packs/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { size, fontWeightBold } = typography;
const borderRadius = '8px';
const { dark, white } = colors;

const tab = {
  variants: [
    {
      props: { variant: 'standard' },
      style: {
        color: `${dark.main}`,
        textTransform: 'none',
        fontSize: '15px',
        fontWeight: 700,

        '&.Mui-selected': {
          color: `${dark.main} !important`
        }
      }
    },
    {
      props: { variant: 'scrollable' },
      style: {
        color: `${dark.main}`,
        textTransform: 'none',
        fontSize: '15px',
        fontWeight: 700,

        '&.Mui-selected': {
          color: `${dark.main} !important`
        }
      }
    },
    {
      props: { variant: 'buttonTabs' },
      style: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: '1 1 auto',
        textAlign: 'center',
        maxWidth: 'unset !important',
        minWidth: 'unset !important',
        minHeight: 'unset !important',
        fontSize: size.sm,
        fontWeight: fontWeightBold,
        textTransform: 'none',
        lineHeight: 'inherit',
        padding: `${pxToRem(10)} ${pxToRem(24)}`,
        borderRadius,
        color: `${white.main} !important`,
        opacity: '1 !important',

        '&.Mui-selected': {
          color: `${dark.main} !important`
        },

        '& .material-icons, .material-icons-round': {
          marginBottom: '0 !important',
          marginRight: pxToRem(6)
        },

        '& svg': {
          marginBottom: '0 !important',
          marginRight: pxToRem(6)
        },
        labelIcon: {
          paddingTop: pxToRem(4)
        }
      }
    }
  ]
};

export default tab;
