import React from 'react';

export default () => {
  return (
    <svg width='41' height='40' viewBox='0 0 41 40' fill='black' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='M10.5 11.6665C5.62831 11.6665 1.73237 15.8469 2.2057 20.8169C2.61904 25.1502 6.50471 28.3332 10.858 28.3332H15.5C16.42 28.3332 17.1666 27.5865 17.1666 26.6665C17.1666 25.7465 16.42 24.9998 15.5 24.9998H10.7832C8.07651 24.9998 5.64974 22.9718 5.50974 20.2668C5.35807 17.3834 7.64997 14.9998 10.5 14.9998H15.5C16.42 14.9998 17.1666 14.2532 17.1666 13.3332C17.1666 12.4132 16.42 11.6665 15.5 11.6665H10.5ZM25.5 11.6665C24.58 11.6665 23.8333 12.4132 23.8333 13.3332C23.8333 14.2532 24.58 14.9998 25.5 14.9998H30.2168C32.9234 14.9998 35.3502 17.0279 35.4902 19.7329C35.6419 22.6162 33.35 24.9998 30.5 24.9998H25.5C24.58 24.9998 23.8333 25.7465 23.8333 26.6665C23.8333 27.5865 24.58 28.3332 25.5 28.3332H30.5C35.3716 28.3332 39.2676 24.1528 38.7942 19.1828C38.3809 14.8494 34.4952 11.6665 30.1419 11.6665H25.5ZM13.8333 18.3332C12.9133 18.3332 12.1666 19.0798 12.1666 19.9998C12.1666 20.9198 12.9133 21.6665 13.8333 21.6665H27.1666C28.0866 21.6665 28.8333 20.9198 28.8333 19.9998C28.8333 19.0798 28.0866 18.3332 27.1666 18.3332H13.8333Z' />
      </g>
    </svg>
  );
};
