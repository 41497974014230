import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const MyOffersListSkeleton = ({ count = 3 }) => {
  return (
    <div className='my-offers-list'>
      {[...Array(count)].map((_, index) => (
        <div key={index} className='my-offers-item'>
          <SkeletonTheme color='#E4EEFA' highlightColor='#fff'>
            <Skeleton count={1} height={401} width='100%' />
          </SkeletonTheme>
        </div>
      ))}
    </div>
  );
};
