import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import AssociationEnquiryIcon from '@packs/images/svg/ui/associationEnquiryIcon.svg';
import AssociationMemberIcon from '@packs/images/svg/ui/associationMemberIcon.svg';
import useContainerStyles from '@packs/styles/Global/container';

import { AssociationEnquiry } from './components/AssociationEnquiry';
import { AssociationMember } from './components/AssociationMember';
import useStyles from './styles';

import { Helmet } from 'react-helmet';

const tabsValues = {
  member: 'member',
  enquiry: 'enquiry'
};

const associationsTabs = [
  {
    value: tabsValues.member,
    label: 'Free Access',
    icon: AssociationMemberIcon
  },
  {
    value: tabsValues.enquiry,
    label: 'Premium Access',
    icon: AssociationEnquiryIcon
  }
];

const Associations = () => {
  const styles = useStyles();
  const stylesContainer = useContainerStyles();

  const [tabValue, setTabValue] = useState(associationsTabs[0].value);

  const onSetTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>My Associations</title>
      </Helmet>
      <PageTitleWrapper homeIsLanding title='Associations' currentPage='Associations' />
      <Box sx={stylesContainer.container}>
        <Box sx={styles.wrapper}>
          <Tabs value={tabValue} onChange={onSetTabValue} sx={{ width: '352px' }}>
            {associationsTabs.map(({ value, label, icon }) => (
              <Tab key={value} value={value} label={label} />
            ))}
          </Tabs>
          {tabValue === tabsValues.member && <AssociationMember />}
          {tabValue === tabsValues.enquiry && <AssociationEnquiry />}
        </Box>
      </Box>
    </>
  );
};

export default Associations;
