import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { SignUpModalLink } from '@packs/components';
import { InfoBox } from '@packs/components/MaterialUi/InfoBox/InfoBox';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import HowItWorksIcon from '@packs/images/svg/ui/how-it-works.webp';
import HowItWorks1Icon from '@packs/images/svg/ui/how-it-works-1.svg';
import HowItWorks2Icon from '@packs/images/svg/ui/how-it-works-2.svg';
import HowItWorks3Icon from '@packs/images/svg/ui/how-it-works-3.svg';

const { white, secondary } = colors;
const benefits = [
  {
    id: '1',
    icon: HowItWorks1Icon,
    title: 'Sign Up',
    description: 'Sign up and manage all of your bills from one place!'
  },
  {
    id: '2',
    icon: HowItWorks2Icon,
    title: 'Get Matched!',
    description: 'Personalize your results so that you can get matched with the best businesses to quote you!'
  },
  {
    id: '3',
    icon: HowItWorks3Icon,
    title: 'Request Quotes!',
    description: "Request quotes from your chosen businesses! Don't forget to vote for your winning business!"
  }
];

export const HowItWorks = () => {
  const { currentUser } = useUserSelectors();

  return (
    <Box pb={15} sx={{ backgroundColor: white.main }}>
      <InfoBox
        title='How it works?'
        description='Bill Winner is a trusted, independent and transparent platform powered by our community!'
        infoIcon={HowItWorksIcon}
        infoIconWidth='684px'
        position='right'
      >
        <Box
          display='flex'
          gap={3}
          justifyContent='center'
          alignItems='center'
          position='relative'
          zIndex='3'
          sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: { xs: '-165px', md: '-127px' } }}
        >
          {benefits.map(({ id, icon, title, description }) => (
            <Box
              key={id}
              borderRadius='24px'
              pt={4}
              px={3}
              sx={{
                maxWidth: {
                  xs: '360px',
                  md: '316px'
                },
                minHeight: {
                  xs: '225px',
                  md: '276px'
                },
                border: '1px solid rgba(0, 0, 0, 0.16)',
                pb: {
                  xs: 4,
                  md: 0
                },
                backgroundColor: white.main
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                width={80}
                height={80}
                mb={3}
                mx='auto'
                borderRadius='16px'
                sx={{ backgroundColor: secondary.transparent }}
              >
                <img src={icon} alt='benefit' />
              </Box>
              <Typography variant='h6' mb={1} textAlign='center'>
                {title}
              </Typography>
              <Typography variant='body1' textAlign='center'>
                {description}
              </Typography>
            </Box>
          ))}
        </Box>
      </InfoBox>
      {!currentUser.id && (
        <Box display='flex' justifyContent='center' mt={4}>
          <SignUpModalLink
            variant='contained'
            size='xl'
            color='primary'
            sx={{ minWidth: { xs: '256px', md: '280px' } }}
          >
            Start Now!
          </SignUpModalLink>
        </Box>
      )}
    </Box>
  );
};
