import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/company';
import colors from '@packs/assets/theme/base/colors';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { Avatar } from '@packs/components/UI';
import { cantRemember } from '@packs/models/company/constants';

import debounce from 'lodash-es/debounce';

const { dark, white } = colors;

type CompaniesAutocompleteProps = {
  value: any;
  label: string;
  onSelect: (data: any) => void;
  noOptionsMessage?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  categoryId?: number | null;
};
export const CompaniesAutocomplete: FC<CompaniesAutocompleteProps> = ({
  value,
  label,
  onSelect,
  noOptionsMessage = <>No options</>,
  placeholder = 'Search',
  disabled,
  error = '',
  categoryId = null
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const promiseOptions = inputValue => {
    setLoading(true);
    return api
      .companiesAutocompleateListSelect({
        categoryId,
        keyWord: inputValue,
        orderColumn: 'most_popular',
        perPage: 50
      })
      .then(res => {
        setOptions(
          res?.companies.map(({ id, name, avatarUrl, companyType }) => ({
            value: id,
            label: name,
            avatarUrl,
            companyType
          }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    debounce(keyWord => {
      promiseOptions(keyWord);
    }, 1000),
    []
  );

  const onFilterChange = event => {
    fetchData(event);
  };

  useEffect(() => {
    if (open) {
      fetchData(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    fetchData(inputValue);
  }, [inputValue]);

  return (
    <AutocompleteMUI
      fullWidth
      size='medium'
      variant='outlined'
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      filterPlaceholder='Search current provider'
      value={value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onFilter={onFilterChange}
      options={[cantRemember, ...options]}
      loading={loading}
      mobileSelect
      filter
      error={error}
      PaperComponent={({ children }) => (
        <Box
          mt={1}
          sx={{
            backgroundColor: white.main,
            borderRadius: '4px',
            border: `1px solid ${dark.transparent}}`,
            px: 1
          }}
        >
          {children}
        </Box>
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              p: 1,
              borderRadius: '8px',
              cursor: 'pointer',
              '&:hover': { backgroundColor: dark.transparent }
            }}
          >
            {option?.avatarUrl && (
              <div>
                <Avatar size={24} avatarUrl={option?.avatarUrl} />
              </div>
            )}
            <Typography variant='body2'>{option?.label}</Typography>
          </Stack>
        </li>
      )}
      onChange={onSelect}
      noOptionsText={noOptionsMessage}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          event.preventDefault();
        }
      }}
    />
  );
};
