import colors from '@packs/assets/theme/base/colors';

const { grey } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 4,
    borderRadius: '16px',
    p: {
      xs: 2,
      sm: 3
    },
    backgroundColor: grey[200]
  }
});
