import React, { useMemo } from 'react';
import { isNull } from 'lodash-es';
import cx from 'classnames';

import BadSmileIcon from '@packs/images/svg/ui/recommended-bad.svg';
import GoodSmileIcon from '@packs/images/svg/ui/recommended-good.svg';
import NormalSmileIcon from '@packs/images/svg/ui/recommended-normal.svg';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Chart } from './Chart';

const icons = {
  0: BadSmileIcon,
  1: NormalSmileIcon,
  2: GoodSmileIcon
};

export const RecommendedChart = ({ company, allowAnimation }) => {
  const { isMobile } = useWindowSize();

  const data = [
    { label: 'Negative', value: company?.additionalData?.recommendation?.detractors || 0 },
    { label: 'Neutral', value: company?.additionalData?.recommendation?.passives || 0 },
    { label: 'Positive', value: company?.additionalData?.recommendation?.promoters || 0 }
  ];

  const isEmptyNps =
    !company?.additionalData?.recommendation ||
    (company?.additionalData?.recommendation?.detractors === 0 &&
      company?.additionalData?.recommendation?.passives === 0 &&
      company?.additionalData?.recommendation?.promoters === 0);

  const recommendationScore = +company?.additionalData?.recommendation?.score;

  const status = useMemo(() => {
    if (recommendationScore < 0) {
      return 'Poor Score!';
    }

    if (recommendationScore >= 0 && recommendationScore <= 19) {
      return 'Good Score!';
    }

    if (recommendationScore >= 20 && recommendationScore <= 49) {
      return 'Good Score!';
    }

    if (recommendationScore >= 50) {
      return 'Excellent Score!';
    }

    return null;
  }, [company?.additionalData?.recommendation?.score]);

  return (
    <>
      <div className='recommended-legend'>
        <div className='recommended-legend-list percent-container d-flex align-items-center justify-content-between'>
          {data.map((item, index) => (
            <div key={item.label} className='recommended-legend-item percent'>
              <img src={icons[index]} alt='Smile' />
              {item.value}%
            </div>
          ))}
        </div>

        <div className='recommended-legend-list d-flex align-items-center justify-content-between'>
          {data.map(item => (
            <div className='recommended-legend-item label' key={item.label}>
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className='recommendation-chart'>
          <div className='chart-label'>
            {!isNull(recommendationScore) ? (recommendationScore || 0).toFixed(0) : !isMobile ? 0 : ''}
          </div>
          <Chart {...{ data, isEmptyNps, allowAnimation }} />
        </div>
        {!isNull(recommendationScore) && (
          <div className='d-flex justify-content-center'>
            <div className={cx('recommendation-chart-status', { poor: recommendationScore < 0 })}>{status}</div>
          </div>
        )}
      </div>
    </>
  );
};
