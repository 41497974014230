const tooltip = {
  styleOverrides: {
    tooltip: {
      fontSize: '14px',
      lineHeight: '1.5',
      textAlign: 'center'
    }
  }
};

export default tooltip;
