import Box from '@mui/material/Box';

export const PreviewBadges = ({ badges }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 2, md: 1 },
        rowGap: 2,
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        justifyContent: { xs: 'space-around', md: 'flex-start' }
      }}
    >
      {badges.map(badge => (
        <Box key={badge.id} sx={{ maxWidth: { xs: '160px', md: '147px' } }}>
          <img src={badge.url} alt='Badge' />
        </Box>
      ))}
    </Box>
  );
};
