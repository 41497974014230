import React, { FC } from 'react';
import _ from 'lodash-es';

import { TypographyProps } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { IVote } from '@packs/interfaces/rootInterfaces';
import { usePath } from '@packs/layouts/constants';
import { divideStarRating } from '@packs/lib/helpers';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { stringToColor } from '@shared/helpers/additionalHelpers';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const { white, text, dark } = colors;

type Props = {
  vote: IVote;
};
export const Vote: FC<Props> = ({ vote }) => {
  const { BUSINESS_PATH_FN } = usePath();
  const comment = vote?.anyCompanyRateComment || _.get(vote?.feedback, 'insightsComment') || '';
  const company = vote?.anyCompany;
  const rating = divideStarRating(vote.rating);

  return (
    <Box
      sx={{
        minHeight: '230px',
        p: { xs: 2, md: 3 },
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.16)',
        backgroundColor: white.main
      }}
    >
      <Stack direction='row' justifyContent='space-between' spacing={1} alignItems='flex-start' mb={{ xs: 1, md: 3 }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          sx={{ alignItems: { xs: 'flex-start', md: 'center' } }}
        >
          <Avatar
            sx={{ width: 48, height: 48, backgroundColor: stringToColor(vote?.user?.displayName) }}
            variant='circular'
            src={vote?.user?.avatarUrl}
          />
          <Stack direction='column' spacing={0.5}>
            <Typography variant='body2'>{shortSecondName(vote?.user?.displayName)}</Typography>
            <Stack direction='row' flexWrap='wrap' spacing={0.5}>
              <RatingMUI value={rating} size='small' />
              <Stack direction='row' spacing={0.5} sx={{ alignItems: { xs: 'flex-start', md: 'center' } }}>
                <Typography variant={'body3' as TypographyProps['variant']} sx={{ color: text.secondary }}>
                  reviewed
                </Typography>
                <Link to={BUSINESS_PATH_FN(company)}>
                  <Typography
                    variant={'body4' as TypographyProps['variant']}
                    color={dark.main}
                    sx={{
                      display: 'block',
                      maxWidth: '110px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {company?.name}
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Typography
          variant={'body3' as TypographyProps['variant']}
          sx={{ display: 'inline', color: text.secondary, whiteSpace: 'nowrap' }}
        >
          {dayjs(vote.createdAt).format('MMM DD, YYYY')}
        </Typography>
      </Stack>
      <Box sx={{ display: 'flex' }} mb={1}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={0.5}
          sx={{
            borderRadius: '4px',
            p: 0.5,
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          }}
        >
          {SUB_ICONS({
            category: vote.category,
            size: '16px',
            fill: dark.main
          })}
          <Typography variant='caption'>{vote.category.name}</Typography>
        </Stack>
      </Box>
      <Typography
        variant='body1'
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '3',
          WebkitBoxOrient: 'vertical'
        }}
      >
        {comment}
      </Typography>
    </Box>
  );
};
