import React from 'react';

import { MixedPosts } from '@packs/components/UI';
import { ProfileFilters } from '@packs/layouts/ProfileLayout/ProfileFilters';
import { useWindowSize } from '@shared/hooks/useWindowSize';

const AllContributions = () => {
  const { isDesktop } = useWindowSize();

  return (
    <div className='profile-wrapper'>
      <div className='profile-page-content'>
        <MixedPosts />
      </div>
      <div className='profile-filters'>
        <ProfileFilters />
      </div>
    </div>
  );
};

export default AllContributions;
