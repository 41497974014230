import React from 'react';

import { I18n } from '@packs/components/I18n';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import FacebookImage from '@packs/images/svg/facebook.svg';
import GoogleImage from '@packs/images/svg/google.svg';
import LinkedinImage from '@packs/images/svg/linkedin.svg';
import TwitterImage from '@packs/images/svg/twitt.svg';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { Form } from './components';
import { oauthSigninUrls } from './constants';

import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const { userStore } = useStore();
  const { ROOT_PATH_FN } = usePath();
  const { defaultMessages } = useTranslationMessages();
  const navigate = useNavigate();

  const submitHandler = ({ email, password }) => {
    userStore
      .signIn({ email, password })
      .then(({ country }) => {
        navigate(ROOT_PATH_FN(country.isoA2Code));
        toastr.success(<I18n text={defaultMessages.deviseSessionsSignedIn} />);
      })
      .catch(errors => {
        toastr.error(errors[0]);
      });
  };

  return (
    <section className='auth-container'>
      <div className='auth-block login-block'>
        <h3 className='auth-title'>
          <I18n text={defaultMessages.login} />
        </h3>
        <Form onSubmit={submitHandler} />
        <div className='sign-up-text'>
          <I18n text={defaultMessages.dontHaveAnAccount} />
          <SignUpModalLink>
            <I18n text={defaultMessages.signUp} />
          </SignUpModalLink>
        </div>
        <div className='or-block'>
          <div className='dash' />
          <span>
            <I18n text={defaultMessages.or} />
          </span>
          <div className='dash' />
        </div>
        <div className='social-block'>
          <a href={oauthSigninUrls.GOOGLE} className='btn btn-circle google-background'>
            <img src={GoogleImage} alt='Google' className='google-img' />
          </a>
          <a href={oauthSigninUrls.FACEBOOK} className='btn btn-circle facebook-background'>
            <img src={FacebookImage} alt='Facebook' className='facebook-img' />
          </a>
          <a href={oauthSigninUrls.TWITTER} className='btn btn-circle twitter-background'>
            <img src={TwitterImage} alt='Twitter' className='twitt-img' />
          </a>
          <a href={oauthSigninUrls.LINKEDIN} className='btn btn-circle linkedin-background'>
            <img src={LinkedinImage} alt='Linkedin' className='linkedin-img' />
          </a>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
