import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export const ConfirmModal = ({
  isOpen,
  toggle,
  modalProps: { title = '', description = '', content = null, submitBtnText = '', onSubmit }
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (onSubmit) {
      setIsLoading(true);

      const res = await onSubmit();

      setIsLoading(false);

      if (res?.status === 'success' || res?.errors?.length === 0) {
        toggle();
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => toggle()} fullWidth maxWidth='xs' scroll='paper'>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>{title}</div>
        <IconButton onClick={toggle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {description && <Typography variant='body1'>{description}</Typography>}
        {content && content}
      </DialogContent>

      <DialogActions>
        <Button variant='text' color='dark' size='smallToMedium' onClick={toggle}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' size='smallToMedium' disabled={isLoading} onClick={handleSubmit}>
          {submitBtnText ? submitBtnText : 'ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
