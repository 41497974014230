import React, { useMemo, useState } from 'react';

import RadioButtonGroup from '@packs/components/RadioButtonGroup';
import { Questions } from '@packs/components/UI';
import { ProfileFilters } from '@packs/layouts/ProfileLayout/ProfileFilters';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { allTypes, postTypes } from '@shared/lib/constants';

const QA = () => {
  const { isDesktop } = useWindowSize();
  const { currentCategory } = useStore();

  const postTypesOptions = useMemo(() => {
    return [allTypes, ...postTypes];
  }, [postTypes]);

  const [postType, setPostType] = useState(postTypesOptions[0].value);

  const onChangePostType = data => {
    setPostType(data);
  };

  return (
    <div className='profile-wrapper'>
      <div className='profile-page-content'>
        <Questions
          category={currentCategory}
          postType={postType}
          emptyTitle='You have not created any posts yet!'
          emptyDescription='Your posts will appear here…'
          myProfile
          withOutCategoryMode
        />
      </div>
      <div className='profile-filters'>
        <ProfileFilters />
        <div className='fs-11 fw-700 tt-uc mb-16 mt-24'>Posts type:</div>
        <RadioButtonGroup
          name='feedbackType'
          className='radio-filters-wrapper align-items-start flex-wrap'
          options={postTypesOptions}
          value={postType}
          onChange={onChangePostType}
        />
      </div>
    </div>
  );
};

export default QA;
