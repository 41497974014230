import React, { useCallback } from 'react';

import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';

export const AbstractModal = observer(() => {
  const { modalStore, currentCategory } = useStore();

  const close = useCallback(() => {
    modalStore.close();
  }, [modalStore]);

  const closeSub = useCallback(() => {
    modalStore.closeSub();
  }, [modalStore]);

  const ModalComponent = modalStore.type;
  const SubModalComponent = modalStore.subType;

  return (
    <>
      {ModalComponent ? (
        <ModalComponent category={currentCategory} close={() => close()} {...modalStore.props} />
      ) : null}

      {SubModalComponent ? (
        <SubModalComponent category={currentCategory} close={() => closeSub()} {...modalStore.subProps} />
      ) : null}
    </>
  );
});
