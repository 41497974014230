import React from 'react';

import Box from '@mui/material/Box';
import { GetStarted } from '@packs/components/MaterialUi/Landing/GetStarted/GetStarted';
import { MembersLove } from '@packs/components/MaterialUi/Landing/MembersLove/MembersLove';
import { LandingOffers } from '@packs/components/MaterialUi/Landing/Offers/Offers';
import { RecentVotes } from '@packs/components/MaterialUi/RecentVotes/RecentVotes';
import GetStartedIcon from '@packs/images/ui/get-started.webp';
import { HEAD_TITLE } from '@packs/lib/constants';
import membersData from '@packs/screens/RootCategoryLanding/membersSection/membersData';

import { BoostSales } from './components/BoostSales/BoostSales';
import { Hero } from './components/Hero/Hero';
import { HowItWorks } from './components/HowItWorks/HowItWorks';

import { Helmet } from 'react-helmet';

export const Landing = () => {
  return (
    <>
      <Helmet>
        <title>{HEAD_TITLE}</title>
      </Helmet>
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <Hero />
        <RecentVotes />
        <MembersLove
          members={membersData['general']}
          title='Why our members like Bill Winner!'
          sx={{ pt: { xs: 6, lg: 15 } }}
        />
        <HowItWorks />
        <LandingOffers />
        <BoostSales />
        <GetStarted icon={GetStartedIcon} />
      </Box>
    </>
  );
};
