import React from 'react';
import cx from 'classnames';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import CheckIcon from '@packs/images/icons/UI/CheckIcon';
import PlusIcon from '@packs/images/icons/UI/PlusIcon';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

export const CategoryCheckbox = ({
  id,
  label,
  theme,
  name,
  code,
  enName = '',
  checked = false,
  onChange = () => {},
  root
}) => {
  const { isDesktop } = useWindowSize();

  if (!isDesktop) {
    return (
      <Box
        component='label'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          width: '100%',
          px: 2,
          py: 2,
          cursor: 'pointer',
          borderTop: `1px solid ${colors.dark.transparent}`,
          '&:first-child': {
            borderTop: 'none'
          }
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: 0,
            height: 0,
            opacity: 0
          }}
        >
          <input
            type='checkbox'
            className='category-checkbox'
            name={enName}
            checked={checked}
            value={id}
            onChange={onChange}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CategoryIconBox
            category={{ code, root }}
            backgroundColor='transparent'
            color={colors.secondary.main}
            containerSize='24px'
            iconSize='24px'
          />
          <Typography variant='body2' sx={{ color: colors.dark.main }}>
            {label}
          </Typography>
        </Box>
        {checked ? (
          <CheckCircleIcon sx={{ fontSize: '24px', color: colors.secondary.main }} />
        ) : (
          <AddCircleOutlineRoundedIcon sx={{ fontSize: '24px' }} />
        )}
      </Box>
    );
  }

  return (
    <Box
      component='label'
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        borderRadius: '90px',
        border: `1px solid ${colors.borderColor.secondary}`,
        px: 2,
        py: '9px',
        backgroundColor: checked ? colors.secondary.main : colors.white.main,
        cursor: 'pointer',
        transition: 'all .2s',
        ...(checked
          ? {}
          : {
              '&:hover': {
                backgroundColor: 'transparent !important',
                borderColor: colors.secondary.main
              }
            })
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 0,
          height: 0,
          opacity: 0
        }}
      >
        <input
          type='checkbox'
          className='category-checkbox'
          name={enName}
          checked={checked}
          value={id}
          onChange={onChange}
        />
      </Box>
      {checked ? (
        <CheckCircleIcon sx={{ fontSize: '24px', color: colors.white.main }} />
      ) : (
        <CategoryIconBox
          category={{ code, root }}
          backgroundColor='transparent'
          color={colors.secondary.main}
          containerSize='24px'
          iconSize='24px'
        />
      )}
      <Typography variant='body4' sx={{ color: checked ? colors.white.main : colors.dark.main }}>
        {label}
      </Typography>
    </Box>
  );
};
