import React from 'react';

import Box from '@mui/material/Box';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

export const SmartReminder = observer(() => {
  const { currentCategory } = useStore();

  // TODO toggleDesktopFilterDispatch if const refreshAfterDetail = location.pathname.match('/leaderboard');

  if (!currentCategory?.root?.id) {
    return null;
  }

  return (
    <Box>
      <Outlet />
    </Box>
  );
});
