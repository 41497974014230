import { FormPassword } from './FormPassword';

import { reduxForm } from 'redux-form';

const validate = ({ password, passwordConfirmation }) => {
  const errors = {};

  if (!password) {
    errors.password = 'Password is required';
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = 'Confirm Password is required';
  }

  if (password && password.length < 6) {
    errors.password = 'Password is too short (minimum is 6 characters)';
  }

  if (passwordConfirmation && passwordConfirmation.length < 6) {
    errors.passwordConfirmation = 'Confirm Password is too short (minimum is 6 characters)';
  }

  if (password && passwordConfirmation && password !== passwordConfirmation) {
    errors.passwordConfirmation = 'Passwords do not match';
  }

  return errors;
};

const createReduxForm = reduxForm({ form: 'userPasswordUpdate', validate });

export default createReduxForm(FormPassword);
