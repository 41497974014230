import React, { useMemo } from 'react';
import cx from 'classnames';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Avatar, BusinessSidebarBox } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import ContactIcon from '@packs/images/svg/ui/contact.svg';
import CopyIcon from '@packs/images/svg/ui/copy.svg';
import RatingsIcon from '@packs/images/svg/ui/ratings-performance.svg';
import RedirectIcon from '@packs/images/svg/ui/redirect-icon.svg';
import { pathGen, usePath } from '@packs/layouts/constants';
import { divideStarRating, toastr } from '@packs/lib/helpers';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import { getCountryFlag } from '@shared/helpers/flagIconHelper';
import { addHttps, replaceHttps } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import map from 'lodash-es/map';
import { observer } from 'mobx-react';
import { Link, NavLink } from 'react-router-dom';

const ratingList = [
  { label: 'Review ratings', key: 'reviewRating' },
  { label: 'Purchase ratings', key: 'purchaseRating' }
];

export const OfferSidebar = observer(({ offer }) => {
  const { countryCode, companiesStore, categoryStore } = useStore();
  const { leaderboardStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { flattenCategories } = useCategories();
  const { DASHBOARD_MY_RESULTS_PATH_FN, ADD_DETAILS_PATH_FN } = usePath();
  const { isMobile } = useWindowSize();

  const { category, company, code, companyContact, offerType, providerCompany } = offer || {};
  const currentCategory = flattenCategories.find(item => item.id === category.id);
  const havePolicy = categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser);

  const offerLink = offer?.customWebLink || '';
  const affinityLink = company?.affinityLink?.url || '';
  const websiteLink = offerLink || affinityLink || company.url;
  const rating = divideStarRating(company?.additionalData?.rating) || 0;
  const postalCodePlaceholder = useMemo(() => {
    switch (currentUser?.country?.isoA2Code) {
      case 'en-ie':
        return 'Eircode ';
      case 'en-us':
        return 'Zip Code';
      default:
        return 'Post Code';
    }
  }, [currentUser?.country?.isoA2Code]);

  const onClickRequestQuotes = () => {
    categoryStore.setCurrentCategory(currentCategory);
    leaderboardStore.addRequestBusiness(company);
  };

  const handleClickWebsiteLink = () => {
    companiesStore.companyUpdateClicks({ companyId: +company.id, categoryId: currentCategory?.id });
  };

  const copyToClipboard = () => {
    if (code) {
      navigator.clipboard.writeText(code).then(() => {
        toastr.success(`Code copied: ${code}`);
      });
    }
  };

  const handleRedirect = () => {
    categoryStore.setCurrentCategory(currentCategory);
    companiesStore.clearFilters();
  };

  const onVisitCompanyPage = () => {
    handleVisitCompany(company, currentCategory);
  };

  return (
    <div className='offer-sidebar'>
      <div className='offer-company mb-16 pb-24'>
        <div className={cx('offer-company-bg category-bg-image', category?.root?.code)}></div>
        <div className='offer-company-info d-flex flex-column align-items-center mb-24'>
          <div className='mb-16'>
            <Avatar
              avatarUrl={company?.avatarUrl}
              placeholder={company?.name}
              size={isMobile ? '60px' : '120px'}
              square
              fullSize
              withBorder
            />
          </div>
          <Link
            className='fs-13 secondary-text mb-4'
            to={pathGen('PRODUCT', {
              countryCode,
              category,
              company
            })}
            onClick={onVisitCompanyPage}
          >
            See Insights
          </Link>
          <Link
            className='fs-23 fw-700 lh-7 mb-8 main-text'
            to={pathGen('PRODUCT', {
              countryCode,
              category,
              company
            })}
            onClick={onVisitCompanyPage}
          >
            {company.name}
          </Link>
          <div className='d-flex align-items-center rate-wrapper'>
            <RatingMUI value={rating} />
            <span className='fs-13 secondary-text ml-8'>{`${rating}/${5}`}</span>
          </div>
        </div>
        {providerCompany?.id && (
          <div className='offer-code-container d-flex align-items-center gap-8 p-16 mb-16'>
            <Avatar avatarUrl={providerCompany.avatarUrl} size={48} fullSize square />
            <Link
              className='fs-19 fw-700 lh-5 main-text wb-bw'
              to={pathGen('BUSINESS', {
                countryCode,
                company
              })}
              onClick={() => handleVisitCompany(company)}
            >
              {providerCompany.name}
            </Link>
            {providerCompany.url && (
              <a
                href={websiteLink}
                target='_blank'
                rel='noopener noreferrer'
                className='redirect ml-auto'
                onClick={handleClickWebsiteLink}
              >
                <img src={RedirectIcon} alt='Redirect' />
              </a>
            )}
          </div>
        )}
        {offerType === 'discount_code' && (
          <div className='offer-code-container d-flex align-items-center gap-16 mb-16' onClick={copyToClipboard}>
            <div className='description fs-13 secondary-text lh-5'>
              {code ? (
                <>
                  Use a unique code to get a discount on the company`s website.
                  <div className='fs-16 main-text mt-4 wb-bw'>{code}</div>
                </>
              ) : (
                <>Unfortunately, the company does not yet use a unique discount code.</>
              )}
            </div>
            {code && (
              <div className='redirect d-flex align-items-center justify-content-center cursor-pointer'>
                <img src={CopyIcon} alt='link' />
              </div>
            )}
          </div>
        )}

        <div className='d-flex justify-content-center gap-8'>
          {company?.quoteAgent ? (
            <>
              {havePolicy ? (
                <Link
                  className='btn full white'
                  to={pathGen('REQUEST_QUOTES', { countryCode, category })}
                  onClick={onClickRequestQuotes}
                >
                  <span>Request quotes</span>
                </Link>
              ) : (
                <Link className='btn full white' to={ADD_DETAILS_PATH_FN(category)} onClick={onClickRequestQuotes}>
                  <span>Request quotes</span>
                </Link>
              )}

              <a
                href={websiteLink}
                target='_blank'
                rel='noopener noreferrer'
                className='btn empty black gap-8'
                onClick={handleClickWebsiteLink}
              >
                <div className='direct-text'>Go direct</div>
                <div className='direct-arrow'>
                  <ChevronRightIcon />
                </div>
              </a>
            </>
          ) : (
            <a
              href={websiteLink}
              target='_blank'
              rel='noopener noreferrer'
              className='btn empty black gap-8'
              onClick={handleClickWebsiteLink}
            >
              <div className='direct-text'>Go direct</div>
              <div className='direct-arrow'>
                <ChevronRightIcon />
              </div>
            </a>
          )}
        </div>
      </div>
      <BusinessSidebarBox title='Ratings Performance' icon={RatingsIcon}>
        {map(ratingList, item => {
          const rating = divideStarRating(
            offer?.company?.ratingsPerformanceData && offer?.company?.ratingsPerformanceData[item.key]
          );

          return (
            <div key={item.key} className='company-item d-flex align-items-center'>
              <span className='business-name'>{item.label}</span>
              <div className='d-flex align-items-center ml-a'>
                <span className='rating-count fs-14 fw-400 ml-10'>{rating ? rating.toFixed(1) : '0.0'}</span>
                <RatingMUI value={1} max={1} />
              </div>
            </div>
          );
        })}
      </BusinessSidebarBox>
      <BusinessSidebarBox title='CONTACT' icon={ContactIcon}>
        <div className='company-contact-item'>
          <div className='contact-title'>Country:</div>
          <div className='contact-decription d-flex align-items-center gap-8'>
            <img src={getCountryFlag(countryCode)} alt={countryCode} width='22px' />
            Ireland
          </div>
        </div>
        {companyContact?.region && (
          <div className='company-contact-item'>
            <div className='contact-title'>County:</div>
            <div className='contact-decription'>{companyContact.region}</div>
          </div>
        )}
        {companyContact?.address && (
          <div className='company-contact-item'>
            <div className='contact-title'>Address:</div>
            <div className='contact-decription'>{companyContact.address}</div>
          </div>
        )}
        {companyContact?.postalCode && (
          <div className='company-contact-item'>
            <div className='contact-title'>{postalCodePlaceholder}:</div>
            <div className='contact-decription'>{companyContact.postalCode}</div>
          </div>
        )}
        {companyContact?.email && (
          <div className='company-contact-item'>
            <div className='contact-title'>Email:</div>
            <div className='contact-decription'>{companyContact.email}</div>
          </div>
        )}
        {companyContact?.phoneNumber && (
          <div className='company-contact-item'>
            <div className='contact-title'>Mobile phone:</div>
            <div className='contact-decription'>{companyContact.phoneNumber}</div>
          </div>
        )}
        {companyContact?.websiteUrl && (
          <div className='company-contact-item'>
            <div className='contact-title'>Website:</div>
            <a
              href={addHttps(companyContact.websiteUrl)}
              target='_blank'
              rel='nofollow noreferrer'
              className='contact-decription main-text fw-600'
            >
              {replaceHttps(companyContact.websiteUrl)}
            </a>
          </div>
        )}
      </BusinessSidebarBox>
      <div className='d-flex justify-content-center mt-30'>
        <NavLink to={DASHBOARD_MY_RESULTS_PATH_FN(currentCategory)} className='btn full white' onClick={handleRedirect}>
          <span>GO TO THE LEADERBOARD</span>
        </NavLink>
      </div>
    </div>
  );
});
