import colors from '@packs/assets/theme/base/colors';

const { grey, text } = colors;

export default () => ({
  wrapper: {
    backgroundColor: grey[100]
  },
  container: {
    maxWidth: '1162px',
    mx: 'auto',
    px: 2,
    py: {
      xs: 6,
      lg: 15
    }
  },
  description: {
    maxWidth: '855px',
    width: '100%',
    mb: 8,
    mx: 'auto',
    textAlign: 'center',
    color: text.secondary
  },
  content: {
    flexGrow: 1,
    // pr: { xs: 0, lg: 10 },
    order: { xs: 2, lg: 1 },
    alignSelf: { xs: 'center', lg: 'flex-start' }
  },
  contentList: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr'
    },
    gap: 5
  },
  imgWrapper: {
    width: '100%',
    height: {
      xs: '360px',
      md: '560px'
    },
    order: { xs: 1, lg: 2 },
    alignSelf: { xs: 'center', lg: 'flex-start' },
    borderRadius: '20px',
    mb: 5,
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  }
});
