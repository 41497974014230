export default () => ({
  offersList: openFilters => ({
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      sm: 'repeat(2, 1fr)',
      md: 'repeat(3, 1fr)',
      lg: `repeat(${openFilters ? 2 : 3}, 1fr)`
    },
    '@media (min-width: 1200px)': {
      gridTemplateColumns: `repeat(${openFilters ? 3 : 4}, 1fr)`
    },
    gap: {
      xs: 4,
      sm: 2,
      md: 3
    },
    mb: 2
  })
});
