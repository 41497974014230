import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

const appReducer = () => {
  return combineReducers({
    form: formReducer
  });
};

export default appReducer;
