import React, { useCallback, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/company';
import colors from '@packs/assets/theme/base/colors';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { Avatar } from '@packs/components/UI/Avatar';
import { useStore } from '@packs/stores';

import debounce from 'lodash-es/debounce';

const { white, dark, secondary } = colors;

export const SearchBusinesses = ({ onSelect, noOptionsMessage = () => <>No options</>, placeholder = 'Select' }) => {
  const { countryCode } = useStore();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const promiseOptions = inputValue => {
    setLoading(true);
    return api
      .fetchCompaniesByCountry({
        countryCode,
        keyWord: inputValue,
        autocompleate: 'true'
      })
      .then(res => {
        setOptions(
          res?.companies.map(({ id, urlId, name, avatarUrl }) => ({
            id,
            value: urlId,
            label: name,
            avatarUrl
          }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    debounce(keyWord => {
      promiseOptions(keyWord);
    }, 1000),
    []
  );

  useEffect(() => {
    if (open) {
      fetchData(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    fetchData(inputValue);
  }, []);

  return (
    <Box
      sx={{
        '& .MuiInputBase-root': {
          minHeight: '64px',
          borderRadius: '8px',
          border: '1px solid transparent',
          pt: '0 !important',
          backgroundColor: white.main,
          fontSize: '16px',
          fontWeight: '500',
          color: dark.main,
          '&:after, &:before': {
            content: 'none'
          },
          '&.Mui-focused, &:hover': {
            borderColor: 'rgba(0, 0, 0, 0.08)',
            backgroundColor: white.main
          }
        }
      }}
    >
      <AutocompleteMUI
        fullWidth
        size='medium'
        freeSolo
        variant='filled'
        label=''
        placeholder={placeholder}
        value={null}
        open={open}
        onFilter={fetchData}
        onChange={data => onSelect(data)}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        loading={loading}
        PaperComponent={({ children }) => (
          <Box
            sx={{
              mt: 1,
              borderRadius: '4px',
              border: `1px solid ${dark.transparent}`,
              px: 1,
              backgroundColor: white.main
            }}
          >
            {children}
          </Box>
        )}
        renderOption={(props, option) => (
          <Stack
            direction='row'
            spacing={2}
            sx={{
              p: 1,
              borderRadius: '8px',
              cursor: 'pointer',
              '&:hover': { backgroundColor: secondary.transparent }
            }}
            onClick={() => onSelect(option)}
          >
            {option?.avatarUrl && (
              <div>
                <Avatar size={24} avatarUrl={option?.avatarUrl} square fullSize />
              </div>
            )}
            <Typography variant='body2'>{option?.label}</Typography>
          </Stack>
        )}
        noOptionsText={noOptionsMessage}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        InputProps={{
          startAdornment: <SearchIcon sx={{ fontSize: '32px' }} />,
          endAdornment: <>{loading ? <CircularProgress color='inherit' size={20} /> : null}</>
        }}
      />
    </Box>
  );
};
