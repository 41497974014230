import React from 'react';
import { configure } from 'mobx';
import _ from 'lodash-es';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReduxStoreProvider } from '@packs/layouts/ReduxStoreProvider';
import { categoryFromLocation } from '@packs/models/category/helpers';
import { StoreProvider as MobxProvider } from '@packs/stores/base';

import configureReduxStore from './core/store';
import { Application } from './application';
import { initializeStore } from './stores';

import '@packs/lib/utils/defineProperty';

import camelcaseKeys from 'camelcase-keys';
import { addLocaleData } from 'react-intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import sg from 'react-intl/locale-data/sg';

// Initizalize all locales for react-intl.
addLocaleData([...en, ...de, ...sg]);

configure({
  enforceActions: 'observed'
});

export const ServerRendering = (railsProps, railsContext) => {
  const { i18nLocale } = railsContext;
  const jsProps = camelcaseKeys(railsProps, { deep: true });
  const locale = i18nLocale;
  const messages = _.clone(railsProps.translations[locale]);
  const defaultTranslations = _.clone(railsProps.default_translations);

  const campaignPage = jsProps.campaignPage;

  const reduxStore = configureReduxStore();
  const mobxStore = initializeStore();

  // set country from BE
  mobxStore.setLaunchCountries(jsProps.launchCountries || []);
  mobxStore.setCurrentCountry(jsProps.currentCountry);
  if (jsProps.currentUser) {
    mobxStore.userStore.setCurrentUser(jsProps.currentUser);
    mobxStore.userStore.setUserProfileStats(jsProps.userProfileStats);
  }

  mobxStore.categoryStore.setCategories(jsProps.countryCategories);
  mobxStore.categoryStore.setCurrentCategory(
    categoryFromLocation(mobxStore.categoryStore.categories) || mobxStore.categoryStore.categories[0].leafChildren[0]
  );

  // Allows components to add properties to the object to store
  // information about the render.
  const context = {};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
      <MobxProvider rootStore={mobxStore}>
        <ReduxStoreProvider store={reduxStore}>
          <Application
            toastrData={jsProps.toastrData}
            {...{ locale, messages, campaignPage, defaultTranslations, context }}
          />
        </ReduxStoreProvider>
      </MobxProvider>
    </LocalizationProvider>
  );
};
