import colors from '@packs/assets/theme/base/colors';

const { grey, text } = colors;
export default () => ({
  wrapper: {
    py: {
      xs: 8,
      md: 10,
      lg: 20
    }
  },
  container: {
    display: 'flex',
    alignItems: {
      xs: 'center',
      md: 'flex-start'
    },
    gap: 4,
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    width: '100%',
    maxWidth: '1112px',
    mx: 'auto',
    px: 2
  },
  content: {
    flex: 1,
    order: {
      xs: 2,
      md: 1
    }
  },
  subtitle: {
    color: text.secondary
  },
  imgContainer: {
    order: {
      xs: 1,
      md: 2
    },
    width: '450px',
    maxWidth: '100%'
  },
  confirmDescription: {
    maxWidth: '500px',
    mt: 5,
    p: 2,
    borderRadius: '16px',
    backgroundColor: grey[100]
  },
  confirmDescriptionSubtitle: {
    color: text.secondary
  },
  buttonContainer: {
    mt: 3
  }
});
