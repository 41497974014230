import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { IconBox } from '@shared/components/IconBox/IconBox';

import useStyles from './styles';

const sidebarList = [
  {
    title: 'Add your details!',
    description:
      'Complete your profile by adding your details and bills for each category to receive personalized recommendations from our community and quotes from our partners. '
  },
  {
    title: 'Benchmark your bills!',
    description: 'Add your costs to compare and assess your spend with members like you.'
  },
  {
    title: 'Access Community Tips!',
    description:
      'Benefit from the collective wisdom of members like you and business experts to improve your financial savviness.'
  },
  {
    title: 'Unlock Exclusive Offers!',
    description:
      'Access all the best deals in one place and enjoy personalized offers from our trusted partners, specially selected for you.'
  },
  {
    title: 'Obtain Partner Quotes!',
    description: 'Obtain great quotes from our partner businesses.'
  }
];

const { blue, secondary, text } = colors;

export const EmptyBills = ({ handleCategoryModal }) => {
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.selectCategoriesWrapper}>
        <IconBox
          containerSize='64px'
          icon={<AddRoundedIcon sx={{ fontSize: '40px' }} />}
          color={secondary.main}
          backgroundColor={blue.main}
          borderRadius='8px'
          onClick={handleCategoryModal}
        />
        <Typography variant='h6' sx={{ maxWidth: '377px' }}>
          You have not selected any categories to display yet!
        </Typography>
        <Typography variant='body3' sx={{ color: text.secondary }}>
          Let's start by adding your list of bill categories here
        </Typography>
        <Button variant='contained' color='primary' size='smallToMedium' onClick={handleCategoryModal}>
          Add Bill Categories{' '}
        </Button>
      </Box>
      <Box sx={styles.emptySidebarWrapper}>
        <Typography variant='h6'>Start saving today!</Typography>
        {sidebarList.map(({ title, description }, index) => (
          <Box key={title} sx={styles.emptySidebarItem}>
            <Typography variant='h5' sx={styles.emptySidebarIndex}>
              0{index + 1}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='body4'>{title}</Typography>
              <Typography variant='body5' sx={{ color: text.secondary }}>
                {description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
