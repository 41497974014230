import colors from '@packs/assets/theme/base/colors';

const { white, grey, dark } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    mt: {
      xs: 2,
      lg: 5
    }
  },
  container: {
    maxWidth: '934px',
    width: '100%',
    borderRadius: '16px',
    color: dark.main,
    overflow: 'hidden'
  },
  categoriesWrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 2,
    px: 2,
    pt: 2,
    pb: 1,
    backgroundColor: white.main
  },
  socialWrapper: {
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      md: 'center'
    },
    justifyContent: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 1,
    px: 4,
    py: 1.5,
    backgroundColor: grey[200]
  }
});
