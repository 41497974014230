import colors from '@packs/assets/theme/base/colors';

const { primary, error, grey, dark } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 0.5,
    width: '100%',
    borderRadius: '8px',
    p: 2,
    backgroundColor: primary.tonal
  },
  difference: {
    display: 'inline-block',
    px: 1,
    borderRadius: '2px',
    backgroundColor: '#C9E7CA',
    color: '#2E7D32',
    lineHeight: '24px'
  },
  differenceNegative: {
    backgroundColor: error.transparent,
    color: error.main
  },
  equal: {
    backgroundColor: grey[400],
    color: dark.main
  }
});
