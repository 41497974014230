import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
// Components
import { toastr } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';
// Images
import { useStore } from '@packs/stores';

import { Form } from './components';

import { observer } from 'mobx-react';

export const AssociationEnquiry = observer(() => {
  const [inProcess, setInProcess] = useState(false);
  const { additionalStore } = useStore();
  const { currentCategory } = useCategories();

  const submitHandler = variables => {
    const callback = () => {
      toastr.success('Thanks for contacting with our partner team!');
      setInProcess(false);
    };
    const errorCallback = () => {
      setInProcess(false);
    };

    if (!inProcess) {
      setInProcess(true);
      additionalStore.associationSendMail(variables).then(callback).catch(errorCallback);
    }
  };

  return (
    <div className='partner-form-container second-box'>
      <Typography variant='subtitle1' sx={{ textAlign: 'center' }}>
        If you are interested in obtaining premium access for your association (which includes privacy and premium
        functionality for your members please contact us here)
      </Typography>
      <Form
        initialValues={{
          name: '',
          associationName: '',
          email: '',
          body: ''
        }}
        onSubmit={submitHandler}
        category={currentCategory}
        {...{ inProcess }}
      />
    </div>
  );
});
