import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { usePath } from '@packs/layouts/constants';
import { saveState } from '@packs/lib/utils/localStorage';
import { ContentWrapper } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/ContentWrapper';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/styles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Link } from 'react-router-dom';

export const QuotesPartners = ({ item, data, isLoading, setCurrentCategory, category, renewalDetail }) => {
  const { REQUEST_QUOTES_PATH_FN } = usePath();
  const { isDesktop } = useWindowSize();
  const styles = useStyles();
  const totalCount = data?.totalCount || 0;
  const isEmpty = totalCount === 0;

  const handleRedirectToLeaderboard = () => {
    setCurrentCategory();
    saveState('openDashboardItemId', item.id);
  };

  return (
    <ContentWrapper
      wrapperSx={styles.quotesSectionItemWrapper}
      title={isEmpty ? 'No Quotes from Partners Yet!' : 'Get Quotes from Partners!'}
      counter={`${totalCount} Quote${totalCount === 1 ? '' : 's'}`}
      sectionLabelIcon={<SavingsRoundedIcon />}
      isLoading={isLoading}
      component={isEmpty ? Box : Link}
      to={REQUEST_QUOTES_PATH_FN(category, renewalDetail)}
      onClick={isEmpty ? null : handleRedirectToLeaderboard}
      sectionLabel={
        totalCount ? (
          <Box sx={styles.companiesList}>
            {data?.companies.map(item => (
              <Box key={item.id} sx={styles.companyItemWrapper} className='companyItemWrapper'>
                <img src={item?.avatarUrl} alt='Company avatar' />
              </Box>
            ))}
          </Box>
        ) : null
      }
      isEmpty={isEmpty}
      emptyText='Save up to 50% with quotes from our partners'
      bottomButton={
        isEmpty ? null : (
          <Button
            size={isDesktop ? 'xs' : 'small'}
            variant='contained'
            fullWidth
            sx={styles.sectionButton}
            className='sectionButton'
          >
            See All Quotes
          </Button>
        )
      }
    />
  );
};
