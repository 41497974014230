import colors from '@packs/assets/theme/base/colors';
import PageNavigationBGIcon from '@packs/images/svg/ui/page-navigation-bg.svg';

const { secondary, primary, dark, white } = colors;

export default () => ({
  wrapper: {
    backgroundImage: secondary.gradient
  },
  innerWrapper: {
    px: { xs: 0, md: 5 },
    backgroundImage: {
      xs: 'none',
      lg: `url(${PageNavigationBGIcon})`
    },
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '260px'
  },
  container: {
    maxWidth: '1128px',
    mx: 'auto',
    pt: { xs: 2, md: 3 }
  },
  breadcrumbs: {
    mb: { xs: 3, md: 0 },
    px: { xs: 2, lg: 0 },
    color: dark.main,
    '& a:hover': {
      color: dark.main
    }
  },
  innerSection: {
    display: 'flex',
    alignItems: { xs: 'flex-start', md: 'center' },
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    mb: { xs: 5, lg: 0 },
    px: { xs: 2, md: 0 }
  },
  categoryWrapper: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: { xs: 'flex-start', md: 'center' },
    gap: 3,
    width: { xs: '100%', md: 'auto' },
    mb: {
      xs: 3,
      md: 0
    }
  },
  categoryName: {
    mb: 1,
    color: white.main
  },
  categoryArrow: {
    color: white.main
  },
  title: {
    color: white.main
  },
  btnContainer: {
    ml: 'auto',
    mr: { xs: 'auto', md: 0 },
    width: { xs: '100%', md: 'auto' }
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
    // mt: 'auto'
  },
  navigationWrapper: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    // mt: 5,
    pl: { xs: 2, md: 0 }
  },
  navLink: {
    position: 'relative',
    pt: 1,
    pb: '12px',
    px: 2,
    color: white.main,
    '&:hover': {
      color: white.main
    },
    '&.active .active-label': {
      display: 'block'
    }
  },
  navLinkDisabled: {
    position: 'relative',
    pt: 1,
    pb: '12px',
    px: 2,
    color: white.tonal
  },
  activeLabel: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '4px',
    display: 'none',
    backgroundColor: primary.main,
    borderRadius: '8px 8px 0 0'
  },
  obsoleteWrapper: {
    borderRadius: '4px',
    px: 1.5,
    color: '#D22D2F',
    backgroundColor: '#FECDD2'
  },
  updateDetailsTitleWrapper: {
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      md: 'center'
    },
    gap: 2,
    color: '#fff'
  }
});
