import React, { useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { renewalDetailApi } from '@packs/apis';
import colors from '@packs/assets/theme/base/colors';
import { Vote } from '@packs/components/MaterialUi/Votes/Vote';
import { IVote } from '@packs/interfaces/rootInterfaces';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import slice from 'lodash-es/slice';
import Slider from 'react-slick';

const { grey, background, primary } = colors;

export const RecentVotes = () => {
  const { windowSize } = useWindowSize();
  const { isDesktop, isMobile } = useWindowSize();
  const [votesList, setVoteList] = useState<IVote[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const slidesCount = windowSize / (520 + (isDesktop ? 32 : 16));

  const fetchData = async () => {
    setIsLoading(true);
    const resp = (await renewalDetailApi.fetchRenewalDetailsList({ page: 1, perPage: 5 })) as { list: IVote[] };
    setVoteList(slice(resp?.list || [], 0, 5));
    setIsLoading(false);
  };

  const arrowStyles = {
    position: 'absolute',
    top: 'calc(100% + 23px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '4px',
    cursor: 'pointer',
    zIndex: 2,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .04)'
    }
  };

  const SampleNextArrow = props => {
    const { onClick } = props;
    return (
      <Box sx={{ ...arrowStyles, right: `calc(50% - ${(votesList.length / 2) * 35 + 35}px)` }} onClick={onClick}>
        <ArrowForwardIcon />
      </Box>
    );
  };

  const SamplePrevArrow = props => {
    const { onClick } = props;
    return (
      <Box sx={{ ...arrowStyles, left: `calc(50% - ${(votesList.length / 2) * 35 + 35}px)` }} onClick={onClick}>
        <ArrowBackIcon />
      </Box>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: !isMobile,
    slidesToShow: isMobile ? 1 : Math.floor(slidesCount) || 1,
    slidesToScroll: 1,
    arrows: !isMobile,
    dots: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box py={{ xs: 6, lg: 15 }} sx={{ backgroundColor: background.default }}>
      <Typography variant='h2' textAlign='center' mb={{ xs: 3, md: 5 }}>
        Recent Reviews
      </Typography>
      <Box
        pb={6}
        sx={{
          '& .slick-dots': {
            left: '50%',
            bottom: '-56px',
            width: 'auto',
            transform: 'translateX(-50%)',
            '& li': {
              '& button': {
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: grey[500],
                '&:before': {
                  content: 'none'
                }
              },
              '&:not(.slick-active) button': {
                transform: 'scale(0.8)'
              },
              '&.slick-active button': {
                backgroundColor: primary.main
              }
            }
          }
        }}
      >
        {isLoading ? (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Slider {...settings}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {votesList.map(item => (
              <Box key={item.id} px={{ xs: 1, lg: 2 }}>
                <Vote vote={item} />
              </Box>
            ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
};
