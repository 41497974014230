import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash-es';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LeaderboardList } from '@packs/components/MaterialUi/Leaderboard/LeaderboardList/LeaderboardList';
import { LeaderboardListCompare } from '@packs/components/MaterialUi/Leaderboard/LeaderboardListCompare/LeaderboardListCompare';
import { LeaderboardReminder } from '@packs/components/MaterialUi/Leaderboard/LeaderboardReminder/LeaderboardReminder';
import { PersonalizedOffers } from '@packs/components/MaterialUi/Offers/PersonalizedOffers/PersonalizedOffers';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { pathGen, paths, usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { sortOptions } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { isWikiTableCategory } from '@packs/models/category/functions';
import { getCategoryCriteria } from '@packs/models/category/helpers';
import {
  COMPANY_TYPE_EACH_TYPE,
  COMPANY_TYPE_OPTIONS,
  INSURANCE_ORDER_OPTIONS,
  mostPopularDefaultCategories,
  ORDER_OPTIONS
} from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { MyResultsActionPanel } from '@packs/screens/MaterialUi/MyResults/components/MyResultsActionPanel/MyResultsActionPanel';
import { MyResultsFilters } from '@packs/screens/MaterialUi/MyResults/components/MyResultsFilters';
import { useStore } from '@packs/stores';
import { INIT_FILTERS } from '@packs/stores/CompaniesStore';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import {
  categoriesWithoutPersonalizeFlow,
  myResultsFiltersByCategory,
  myResultsFiltersBySubCategory,
  subCategoriesWithoutPersonalizeFlow
} from '@shared/constants/categories';
import { websitePageUrl } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { getWindow } from '@shared/lib/utils';

import { MyResultsTopBar } from './components/MyResultsTopBar/MyResultsTopBar';
import { RequestQuotesBar } from './components/RequestQuotesBar/RequestQuotesBar';

import omit from 'lodash-es/omit';
import size from 'lodash-es/size';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';

const fields = ['companyType', 'lastBuy', 'city', 'criteriaQuestions'];

export const MyResults = observer(() => {
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  const { isDesktop } = useWindowSize();
  const stylesLeaderboard = useLeaderboardStyles();
  const wrapperRef = useRef(null);
  const personalizedOffersSection = useRef(null);
  const { currentCategory, companiesStore, countryCode, leaderboardStore, offersStore, categoryStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { isDashboardLoading, isCurrentCategoryLoaded } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPolicy } = useDetails();
  const { toggleWinnerModal, toggleSignInModal } = useModalsContext();
  const query = queryString.parse(location.search);

  const [isCompaniesFirstLoaded, setCompaniesFirstLoaded] = useState({
    [currentCategory?.id]: isCurrentCategoryLoaded
  });
  const [showFilters, setShowFilters] = useState(false);

  const isWikiTable = useMemo(() => {
    return isWikiTableCategory(currentCategory);
  }, [currentCategory?.id]);
  const isFirstCLoaded = isCompaniesFirstLoaded[currentCategory?.id];
  const list = isWikiTable ? [...COMPANY_TYPE_OPTIONS, COMPANY_TYPE_EACH_TYPE] : COMPANY_TYPE_OPTIONS;

  const isPersonalized = hasPolicyCriteria(currentPolicy);
  const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(currentCategory?.root?.code);
  const showPersonalizedOffers = currentPolicy?.id && !isCategoriesWithoutPersonalizeFlow;

  const isSubCategoriesWithoutPersonalizeFlow = subCategoriesWithoutPersonalizeFlow.includes(currentCategory.code);

  const showBlockedLeaderboard =
    !currentPolicy?.id &&
    !categoryStore.categoriesWithPolicy[currentCategory?.id] &&
    !isCategoriesWithoutPersonalizeFlow &&
    !isSubCategoriesWithoutPersonalizeFlow;

  const isInsuranceCategory =
    ['insurance'].includes(currentCategory.root?.enName) || currentCategory.code?.includes('insurance');
  const sortList = sortOptions({ isInsuranceCategory, isPersonalized, currentUser });

  const sortValue = sortList.find(item => item.value === companiesStore?.filters?.orderColumn) || sortList[0];
  const title = `${!currentUser.id ? 'Sign Up To See The Best' : `The ${sortValue.label}`} Businesses For ${
    currentCategory.name
  } for Members Like You!`;

  const handleScrollToBegin = () => {
    window.scrollTo({
      top: wrapperRef?.current?.offsetTop - 98,
      behavior: 'smooth'
    });
  };

  const handleCancel = () => {
    leaderboardStore.resetRequestBusinesses();
  };

  const submitButton = ({ sx }) => (
    <Button
      component={Link}
      to={pathGen('REQUEST_QUOTES', { countryCode, category: currentCategory }).concat('?initialStep=2')}
      onClick={() => {
        leaderboardStore?.cleanCategoryAgents();
      }}
      variant='contained'
      color='primary'
      size='medium'
      sx={sx}
    >
      Request quotes
    </Button>
  );

  const toggleShowFilters = () => {
    setShowFilters(prevState => !prevState);
  };

  const onLoadCompanies = (filters = null, noCriteria = false) => {
    if (filters) {
      companiesStore.load(filters);
    } else {
      companiesStore.load({ noCriteria });
    }
  };

  const disabledResetFilters = useMemo(() => {
    return _.isEqual(_.pick(INIT_FILTERS, fields), _.pick(companiesStore.filters, fields));
  }, [companiesStore.filters]);

  const handleResetCompanyListFilters = () => {
    if (!disabledResetFilters) {
      companiesStore.clearFilters();
      onLoadCompanies(null, true);

      if (showPersonalizedOffers) {
        offersStore.loadPersonalizedOffers(currentCategory.id, {});
      }
    }
  };

  useEffect(() => {
    if (currentCategory.id && isCurrentCategoryLoaded) {
      companiesStore.resetInfinityList();

      const orderList = isWikiTable ? ORDER_OPTIONS : INSURANCE_ORDER_OPTIONS;
      const city = countryCode === 'us' ? getWindow().localStorage.getItem('region') : null;
      let orderColumn = orderList[isWikiTable ? 0 : 1].value;
      if (mostPopularDefaultCategories.some(item => item === currentCategory?.code)) {
        orderColumn = 'most_popular';
      }
      const filters = {
        categoryId: currentCategory.id,
        orderColumn,
        companyType: list[0].value
      };

      const criteriaQuestions = getCategoryCriteria(currentCategory?.id);

      if (query?.city) {
        filters.city = query?.city;
      } else if (city || criteriaQuestions?.location) {
        filters.city = city || criteriaQuestions?.location;
      }

      if (query?.sourceType) {
        filters.companyType = query.sourceType;
      }

      if (query?.orderColumn) {
        filters.orderColumn = query.orderColumn;
      }

      if (size(criteriaQuestions) > 0 && !currentPolicy?.id) {
        filters.criteriaQuestions = omit(criteriaQuestions, ['location']);
      }

      companiesStore.load({ filters, prefillCriteria: true }).then(() => {
        setCompaniesFirstLoaded({ [currentCategory.id]: true });
      });

      if (currentPolicy?.id) {
        const offersFilters = {
          ...companiesStore.filters.criteriaQuestions,
          location: filters.city
        };

        offersStore.loadPersonalizedOffers(currentCategory.id, offersFilters).then(res => {
          if (res?.list?.length) {
            if (query?.scrollTo === 'personalizedOffersSection') {
              window.scrollTo({
                top: personalizedOffersSection?.current?.offsetTop - 120,
                behavior: 'smooth'
              });
            }
          }
        });
      }
    }
  }, [currentCategory.id, isCurrentCategoryLoaded]);

  useEffect(() => {
    if (currentUser?.id && !currentUser?.isConfirmed && currentPolicy?.id) {
      navigate(paths.CONFIRM_EMAIL.replace(':country', countryCode));
    }
  }, [currentUser?.id, currentUser?.isConfirmed, currentPolicy?.id]);

  useEffect(() => {
    const { pickWinner } = queryString.parse(location.search);

    if (pickWinner === 'true') {
      if (!currentUser?.id) {
        toggleSignInModal({});
      } else {
        toggleWinnerModal({ modalProps: {} });
      }
    }
  }, []);

  useEffect(() => {
    handleScrollToBegin();
  }, [companiesStore.filters.page]);

  useEffect(() => {
    const filtersByCategory = myResultsFiltersByCategory.includes(currentCategory?.root?.code);
    const filtersBySubCategory = myResultsFiltersBySubCategory.includes(currentCategory?.code);

    if (filtersByCategory || filtersBySubCategory) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
    // currentCategory?.id, currentPolicy?.id
  }, [currentCategory]);

  if (!currentCategory?.id) {
    return null;
  }

  return (
    <>
      <Helmet>
        <link rel='canonical' href={websitePageUrl() + DASHBOARD_MY_RESULTS_PATH.replace('/', '')} />
      </Helmet>
      <Box width='100%'>
        <RequestQuotesBar onCancel={handleCancel} submitButton={submitButton} />
        <Box width='100%' maxWidth='1160px' mx='auto' px={2} py={{ xs: 4, md: 6 }} ref={wrapperRef}>
          {currentPolicy?.policyType === 'simple' && !currentPolicy?.smartReminder?.id && <LeaderboardReminder />}
          <MyResultsTopBar {...{ disabledResetFilters, handleResetCompanyListFilters }} />
          <Box
            sx={{
              ...stylesLeaderboard.container,
              ...(!showFilters || showBlockedLeaderboard ? stylesLeaderboard.containerWithOutFilters : {})
            }}
          >
            {isDesktop && (
              <Box
                sx={{
                  ...stylesLeaderboard.filterContainer,
                  ...(!showFilters || showBlockedLeaderboard ? stylesLeaderboard.filterContainerHide : {})
                }}
              >
                <Box sx={stylesLeaderboard.filterContainerInner}>
                  <Box sx={stylesLeaderboard.filterTitle}>
                    <Typography variant='body4'>Filter by:</Typography>
                    <Button
                      variant='text'
                      size='xs'
                      color='dark'
                      sx={{ minWidth: '44px' }}
                      disabled={disabledResetFilters}
                      onClick={handleResetCompanyListFilters}
                    >
                      Reset
                    </Button>
                  </Box>
                  <MyResultsFilters sidebarFilter />
                </Box>
              </Box>
            )}
            <Box
              sx={{
                ...stylesLeaderboard.content,
                maxWidth: { xs: '100%', lg: !showFilters || showBlockedLeaderboard ? '100%' : 'calc(100% - 258px)' }
              }}
            >
              <MyResultsActionPanel {...{ showFilters, toggleShowFilters }} />
              {showPersonalizedOffers && (
                <Box ref={personalizedOffersSection}>
                  <PersonalizedOffers
                    categoryId={currentCategory?.id}
                    showFilters={showFilters}
                    criteriaQuestions={{
                      ...companiesStore?.filters?.criteriaQuestions,
                      location: companiesStore?.filters?.city || null
                    }}
                  />
                </Box>
              )}

              <Typography variant='h6' sx={{ mb: 2 }}>
                {title}
              </Typography>
              {companiesStore.filters.companyType === COMPANY_TYPE_EACH_TYPE.value ? (
                <LeaderboardListCompare
                  allTypesList={companiesStore.allTypesList}
                  isLoading={isDashboardLoading || !isFirstCLoaded}
                />
              ) : (
                <LeaderboardList isLoading={isDashboardLoading || !isFirstCLoaded} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});
