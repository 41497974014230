export default () => ({
  formFooterFlag: {
    display: 'inline',
    mx: 0.5,
    img: {
      width: '24px',
      borderRadius: 0.5
    }
  }
});
