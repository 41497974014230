export default () => ({
  wrapper: {
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      md: 'center'
    },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 1
  },
  imagesList: {
    width: '128px',
    minWidth: '128px',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '34px'
    },
    '& img + img': {
      ml: '-13px'
    }
  }
});
