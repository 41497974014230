import React from 'react';

// Components
import { CategoryCheckbox } from '@packs/components/UI';

export const LeafCategoryElement = ({ category: { id, code, name, enName, selected, root }, handleUpdateCategory }) => {
  return (
    <CategoryCheckbox
      name={enName}
      root={root}
      label={name}
      checked={selected}
      value={id}
      code={code}
      theme
      onChange={() => handleUpdateCategory({ id, selected: !selected })}
    />
  );
};
