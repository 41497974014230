import React from 'react';
import { lowerCase } from 'lodash-es';
import cx from 'classnames';

// Utils
import { rateCostList } from '@packs/lib/constants';
import { numberToString } from '@packs/lib/helpers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

// Components
import Chart from 'react-apexcharts';
import Select from 'react-select';

const colors = ['#FFCC52', '#00D3B0'];

export const UnitPriceBarChart = ({
  userData,
  taxFilter,
  setTaxFilter,
  unitFilters,
  membersData,
  getTaxesData,
  toggleUnitPriceModal,
  companyListTimeFilter,
  unitPricesCompanies
}) => {
  const { isMobile } = useWindowSize();

  const userPrice = userData[1];
  const membersPrice = membersData[1];

  const data = [
    {
      value: userPrice,
      label: `Your unit ${lowerCase(unitFilters?.costType?.label)}`
    },
    {
      value: membersPrice,
      label: `Average unit ${lowerCase(unitFilters?.costType?.label)}`
    }
  ];

  const biggerData = (a, b) => {
    const bigger = a > b ? a : b;

    return bigger + (bigger / 100) * 10;
  };

  const chartData = {
    series: [
      {
        name: '',
        data: data.map(item => item.value)
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        animations: {
          enabled: true,
          speed: 600
        },
        toolbar: { show: false }
      },
      colors,
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.9
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'darken',
            value: 0.9
          }
        },
        onItemClick: { toggleDataSeries: true }
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
          horizontal: false,
          distributed: true,
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
          stroke: { width: 2 }
        }
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontWeight: 500,
        itemMargin: {
          horizontal: 20,
          vertical: 0
        },
        markers: {
          width: 14,
          height: 14,
          radius: 0
        }
      },
      dataLabels: { enabled: false },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const timePeriod = () => {
            if (dataPointIndex === 0) {
              return '';
            }

            if (
              companyListTimeFilter?.value === null ||
              companyListTimeFilter?.value === 'year_or_more' ||
              companyListTimeFilter?.value === 'today'
            ) {
              return `For ${lowerCase(companyListTimeFilter?.label)}`;
            }

            return `In the ${lowerCase(companyListTimeFilter?.label)}`;
          };

          const title = () => {
            if (dataPointIndex === 0) {
              return 'You bought here';
            }

            return 'Most people have bought here:';
          };

          const companyName = () => {
            if (dataPointIndex === 0) {
              return unitPricesCompanies?.userWinner?.name || '';
            }

            return unitPricesCompanies?.membersWinner?.name || '';
          };

          const companyAvatar = () => {
            if (dataPointIndex === 0) {
              return unitPricesCompanies?.userWinner?.avatar_url || '';
            }

            return unitPricesCompanies?.membersWinner?.avatar_url || '';
          };

          return `<div class="arrow_box">
                <div class="title">${title()} <br /> ${timePeriod()}</div>
                <div class="company d-flex align-items-center">
                    <div class="company-avatar">
                      <img src="${companyAvatar()}" alt="Avatar">
                    </div>
                    <div class="company-name">${companyName()}</div>
                </div>
                <div class="body-text">See how you compare with <br /> members like you!</div>
                <div class="chart-data d-flex align-items-center">
                    <div class="point" style="background-color: ${colors[dataPointIndex]}"></div>
                    <div class="text">${series[0][dataPointIndex]}</div>
                </div>
            </div>`;
        },
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0
        },
        theme: 'dark',
        onDatasetHover: { highlightDataSeries: false }
      },
      stroke: { width: 2 },
      xaxis: {
        labels: { rotate: -45 },
        categories: data.map(item => item.label)
      },
      yaxis: {
        min: 0,
        max: biggerData(userPrice, membersPrice)
      },
      responsive: [
        {
          breakpoint: 768,
          options: { chart: { width: '100%', height: '450px' } }
        }
      ]
    }
  };

  return (
    <div className='price-wrapper'>
      <div className={cx('energy-chart-filters d-flex', isMobile && 'justify-content-between')}>
        <div className={cx('contract-length-label', isMobile && 'w-100')}>
          <div className={cx('d-flex align-items-center', isMobile && 'justify-content-between')}>
            {!isMobile && (
              <>
                <div className='fs-12 fw-500'>Unit rate</div>
                <div className='ml-15 mr-15'>
                  <Select
                    value={rateCostList.find(({ value }) => value === taxFilter)}
                    className='select-ui small'
                    classNamePrefix='Select'
                    isSearchable={false}
                    options={rateCostList}
                    onChange={({ value }) => setTaxFilter(value)}
                  />
                </div>
              </>
            )}

            {getTaxesData() && (
              <div className='taxes-data d-flex align-items-center'>
                <span>{numberToString((getTaxesData() && getTaxesData()[unitFilters.costType.value]) || 0)}</span>
                <span>cent/ kWh</span>
              </div>
            )}

            <div
              className={cx('btn theme empty small no-uppercase', !isMobile && 'ml-15')}
              onClick={toggleUnitPriceModal}
            >
              {getTaxesData() && getTaxesData()[unitFilters.costType.value]
                ? 'Edit your unit prices'
                : 'Add your unit prices'}
            </div>
          </div>
        </div>
      </div>
      <div className='chart-wrapper'>
        <Chart series={chartData.series} options={chartData.options} type='bar' />
      </div>
    </div>
  );
};
