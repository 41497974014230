import React, { useEffect, useState } from 'react';
import _ from 'lodash-es';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { usePath } from '@packs/layouts/constants';
import { loadState } from '@packs/lib/utils/localStorage';
import { CategoriesPolicy } from '@packs/screens/MyHome/components/CategoriesPolicy';
import { ActionsDropDown } from '@packs/screens/MyHome/components/MyRenewals/components/ActionsDropDown/ActionsDropDown';
import { AdditionalSection } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/AdditionalSection';
import { LeaderboardCTA } from '@packs/screens/MyHome/components/MyRenewals/components/LeaderboardCTA';
import { RenderRenewalPrice } from '@packs/screens/MyHome/components/MyRenewals/components/RenderRenewalPrice';
import { RenewalAutoQuote } from '@packs/screens/MyHome/components/MyRenewals/components/RenewalAutoQuote';
import { RenewalSmartReminder } from '@packs/screens/MyHome/components/MyRenewals/components/RenewalSmartReminder';
import useWrapperStyles from '@packs/screens/MyHome/components/MyRenewals/styles';
import { useStore } from '@packs/stores';

import AnimateHeight from 'react-animate-height';
import { NavLink } from 'react-router-dom';

const { secondary, dark } = colors;

export const DesktopItem = ({
  item,
  notLinkedCategory,
  category,
  options,
  renewalDetail,
  onClickDetails,
  categoryItem,
  toggleMyBill,
  disabledCategory,
  selectedPolicy,
  setSelectedPolicy,
  selectedCost,
  currency,
  onClickEditReminder,
  toggleReminderFormModal,
  handleCreateReminderCallback,
  autoQuoteEnabled,
  toggleIsAutoQuote,
  userNotConfirmed,
  categoryRedirect,
  filterCategory,
  showPotentialSavings,
  setShowPotentialSavings
}) => {
  const wrapperStyles = useWrapperStyles();
  const { categoryStore, homeStore } = useStore();
  const { DASHBOARD_MY_RESULTS_PATH_FN } = usePath();
  const defaultAdditionalOpen = loadState('openDashboardItemId');
  const [showAdditional, setShowAdditional] = useState(defaultAdditionalOpen === item.id);

  const toggleAdditional = () => {
    setShowAdditional(prev => !prev);
  };

  useEffect(() => {
    if (renewalDetail?.id) {
      homeStore.fetchUserCategoryByPolicy({ renewalDetailId: renewalDetail?.id }, item.id);
    }
  }, [renewalDetail?.id, item.id]);

  if (item?.hide) {
    return null;
  }

  return (
    <Box sx={wrapperStyles.desktopItemWrapper(showAdditional)}>
      <Box sx={wrapperStyles.desktopTopInfoWrapper}>
        <Box sx={wrapperStyles.categoryCell}>
          <CategoryIconBox
            category={item}
            backgroundColor={secondary.transparent}
            color={secondary.main}
            containerSize='48px'
            iconSize='40px'
            borderRadius='8px'
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {!notLinkedCategory ? (
              <NavLink
                to={DASHBOARD_MY_RESULTS_PATH_FN(category)}
                onClick={() => {
                  categoryStore.setCurrentCategory(category);
                }}
              >
                <Typography variant='body4' sx={{ color: dark.main }}>
                  {item.name || '-'}
                </Typography>
              </NavLink>
            ) : (
              <Typography variant='body4'>{item.name || '-'}</Typography>
            )}
          </Box>
        </Box>
        <Box sx={wrapperStyles.policyCell}>
          <CategoriesPolicy
            {...{
              category,
              item,
              options,
              renewalDetail,
              onClickDetails,
              categoryItem,
              toggleMyBill
            }}
            disabled={disabledCategory(item?.root?.code)}
            onSelect={val => {
              selectedPolicy[item.id] = val.value;
              setSelectedPolicy(_.clone(selectedPolicy));
              if (showPotentialSavings) {
                setShowPotentialSavings(false);
              }
            }}
          />
        </Box>
        <Box sx={wrapperStyles.costCell}>
          <RenderRenewalPrice
            {...{
              item,
              selectedCost,
              selectedPolicy,
              toggleMyBill,
              currency,
              category
            }}
          />
        </Box>
        <Box sx={[wrapperStyles.reminderCell, { pt: renewalDetail?.smartReminder?.id ? 0 : 1.5 }]}>
          <RenewalSmartReminder
            {...{
              item,
              category,
              renewalDetail,
              onClickEditReminder,
              toggleReminderFormModal,
              handleCreateReminderCallback
            }}
          />
          <RenewalAutoQuote
            {...{
              item,
              autoQuoteEnabled,
              renewalDetail,
              disabledCategory,
              toggleReminderFormModal,
              category,
              handleCreateReminderCallback,
              toggleIsAutoQuote,
              onClickEditReminder
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', ml: 'auto', pt: 1 }}>
          <LeaderboardCTA
            {...{
              item,
              userNotConfirmed,
              disabledCategory,
              selectedPolicy,
              categoryRedirect,
              category
            }}
          />
          <ActionsDropDown
            category={category}
            item={item}
            renewalDetail={renewalDetail}
            toggleMyBill={toggleMyBill}
            onClickEditReminder={onClickEditReminder}
            toggleReminderFormModal={toggleReminderFormModal}
            handleCreateReminderCallback={handleCreateReminderCallback}
            disabledCategory={disabledCategory}
            toggleIsAutoQuote={toggleIsAutoQuote}
            filterCategory={filterCategory}
          />
        </Box>
      </Box>
      {renewalDetail?.id && !disabledCategory(item?.root?.code) && (
        <>
          <AnimateHeight duration={250} height={showAdditional ? 'auto' : 0}>
            {showAdditional && (
              <AdditionalSection
                key={renewalDetail?.id}
                item={item}
                category={category}
                renewalDetail={renewalDetail}
                selectedCost={selectedCost}
                currency={currency}
              />
            )}
          </AnimateHeight>
          {!showAdditional && <Divider sx={{ mt: 1 }} />}
          <Box sx={{ mt: 2 }}>
            {showAdditional ? (
              <Button
                key='hide'
                size='small'
                color='dark'
                fullWidth
                onClick={toggleAdditional}
                endIcon={showAdditional ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
              >
                Hide ways to save money!
              </Button>
            ) : (
              <Button
                key='show'
                size='small'
                variant='filledTonal'
                color='secondary'
                fullWidth
                onClick={toggleAdditional}
                endIcon={showAdditional ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
              >
                Explore ways to save money!
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
