export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    py: { xs: 2, lg: 0 }
  },
  contentWrapper: {
    width: '100%',
    maxWidth: '645px'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    mt: 4
  },
  button: {
    width: {
      xs: '100%',
      sm: 'auto'
    }
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 2
  }
});
