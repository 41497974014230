import Member1Icon from '@packs/screens/RootCategoryLanding/membersSection/generalImages/member-1.webp';
import Member2Icon from '@packs/screens/RootCategoryLanding/membersSection/generalImages/member-2.webp';
import Member3Icon from '@packs/screens/RootCategoryLanding/membersSection/generalImages/member-3.webp';
import Member4Icon from '@packs/screens/RootCategoryLanding/membersSection/generalImages/member-4.webp';
import Member5Icon from '@packs/screens/RootCategoryLanding/membersSection/generalImages/member-5.webp';
import Member6Icon from '@packs/screens/RootCategoryLanding/membersSection/generalImages/member-6.webp';
import Member7Icon from '@packs/screens/RootCategoryLanding/membersSection/generalImages/member-7.webp';

const general = [
  {
    label: 'Why our members use Bill Winner to shop for their Bills!',
    title: 'Why our members use Bill Winner to shop for their Bills!',
    description: '',
    list: [
      { title: 'We compare the best providers, brokers, and price comparison sites in one place!', desc: '' },
      { title: 'See the most popular businesses on our leaderboards!', desc: '' },
      { title: 'Get customer insights and ratings for businesses!', desc: '' },
      { title: 'Get support from our members and business experts!', desc: '' },
      { title: 'Get quotes from multiple businesses within one quote request!', desc: '' },
      { title: 'See all the best offers in one place!', desc: '' }
    ],
    imgSrc: Member1Icon
  },
  {
    label: 'Manage all your bills in one place!',
    title: 'Manage all your bills in one place!',
    description:
      'Bill Winner enables Irish households to manage all their bills decisions and documents in one place! Make your life easier with Bill Winner!',
    imgSrc: Member2Icon
  },
  {
    label: 'Get green, amber, and red renewal notifications!',
    title: 'Get green, amber, and red renewal notifications!',
    description:
      'Everyone forgets renewal dates. They just pounce on top of you out of the blue. Bill Winner takes care of that with three simple reminders to our leaderboards!',
    imgSrc: Member3Icon
  },
  {
    label: 'Get quotes from the top voted businesses!',
    title: 'Get quotes from the top voted businesses to quote you!',
    description:
      'Consumers are ditching generic search engines for more personalized decision engines! Bill Winner matches you with the best businesses based on recommendations from members just like you!',
    imgSrc: Member4Icon
  },
  {
    label: 'Get expert support & insights!',
    title: 'Get expert support & insights!',
    description:
      "Bill Winner has designed one of the world's leading platforms to match you with experts from the best businesses and insights from members just like you! Our community helps members make smarter decisions with their money!",
    imgSrc: Member5Icon
  },
  {
    label: 'Schedule quotes for any of your bills!',
    title: 'Schedule quotes for any of your bills!',
    description:
      'Our quote scheduler enables the best matched businesses to contact you prior to your renewal dates! This feature is perfect for people who have busy lives and;',
    list: [
      { title: "Don't have time to shop around for the best deals!", desc: '' },
      { title: 'Find it difficult to keep on top of their renewal reminders!', desc: '' },
      { title: 'Want to save time and money! ', desc: '' }
    ],
    description2:
      "Sound familiar? That's ok, most people experience this problem and its getting worse in a digital age! This world first feature is free to use and enables you to save time and money on all of your bills for the rest of your life! Set up your quote scheduler today and start an easier and smarter life!",
    imgSrc: Member6Icon
  },
  {
    label: 'Get all the best offers in one place!',
    title: 'Get all the best offers in one place!',
    description:
      'Bill Winner brings together all the best offers in one place from providers, brokers and price comparison websites!',
    imgSrc: Member7Icon
  }
];

export default general;
