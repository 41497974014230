import React from 'react';

import { Box, Dialog, DialogContent } from '@mui/material';
import { VerificationForm } from '@packs/components/UI';

export const VerificationModal = ({ isOpen, toggle, modalProps: { handleSubmitVerification, phoneNumber } }) => {
  return (
    <Dialog open={isOpen} onClose={toggle} fullScreen>
      <DialogContent>
        <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
          <VerificationForm
            handleSubmitVerification={handleSubmitVerification}
            closeVerification={toggle}
            phoneNumber={phoneNumber || null}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
