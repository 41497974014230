const dialog = {
  styleOverrides: {
    paper: {
      marginLeft: '16px',
      marginRight: '16px',
      width: 'calc(100% - 16px)'
    }
  }
};

export default dialog;
