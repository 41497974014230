import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/components/MobileItem/styles';

export const MobileSkeleton = () => {
  const styles = useStyles();

  return (
    <Box sx={styles.mobileItemWrapper()}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={styles.categoryBox}>
          <Skeleton variant='rounded' width={40} height={40} />
          <Skeleton variant='rounded' width={130} height={21} />
        </Box>
        <Skeleton variant='rounded' width={24} height={24} />
      </Box>
      <Divider sx={{ mb: 1, mt: 0.5 }} />
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Skeleton variant='rounded' width={130} height={21} />
          <Skeleton variant='rounded' width={155} height={18} />
          <Skeleton variant='rounded' width={148} height={18} />
        </Box>
        <Skeleton variant='rounded' width={24} height={24} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mt: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Skeleton variant='rounded' width={130} height={21} />
          <Skeleton variant='rounded' width={155} height={18} />
          <Skeleton variant='rounded' width={148} height={18} />
        </Box>
        <Skeleton variant='rounded' width={24} height={24} />
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width={164} height={18} />
      </Box>
    </Box>
  );
};
