import React from 'react';

import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/styles';

export const ContentWrapper = ({
  wrapperSx,
  sectionLabel = null,
  sectionLabelIcon,
  title,
  counter,
  isLoading,
  isEmpty,
  emptyText,
  bottomButton = null,
  component,
  to,
  onClick
}) => {
  const styles = useStyles();

  if (isLoading) {
    return (
      <Box sx={{ ...styles.sectionItemWrapper(), ...wrapperSx }}>
        <Box sx={styles.sectionTopInfo}>
          <Skeleton variant='rounded' width={40} height={40} />
          <Skeleton variant='rounded' width={33} height={18} sx={{ borderRadius: '4px' }} />
        </Box>
        <Box sx={styles.sectionBottomInfo}>
          <Skeleton variant='rectangular' width='50%' height={18} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      component={component}
      to={to}
      onClick={onClick && onClick}
      sx={{
        ...styles.sectionItemWrapper(isEmpty),
        ...(bottomButton ? styles.sectionItemWrapperHover : {}),
        ...wrapperSx
      }}
    >
      <Box sx={styles.sectionTopInfo}>
        {sectionLabel ? (
          sectionLabel
        ) : (
          <Box sx={styles.sectionLabel(isEmpty)} className='sectionLabel'>
            {sectionLabelIcon}
          </Box>
        )}
        {isEmpty ? (
          <Typography variant='body5'>{emptyText}</Typography>
        ) : (
          <Chip label={counter} variant='outlined' size='small' color='secondary' />
        )}
      </Box>
      <Box sx={styles.sectionBottomInfo}>
        <Typography variant='body4' className='sectionTitle'>
          {title}
        </Typography>
        {bottomButton && bottomButton}
      </Box>
    </Box>
  );
};
