import React, { useEffect } from 'react';

import { FieldWithErrors } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';

// Images
import { observer } from 'mobx-react';
import { Field } from 'redux-form';

export const FormPassword = observer(({ handleSubmit, change }) => {
  const { currentUser } = useUserSelectors();

  useEffect(() => {
    change('displayName', currentUser.displayName);
  }, []);

  return (
    <form encType='multipart/form-data' className='form user-settings-form' onSubmit={handleSubmit}>
      <div className='input-field'>
        <Field
          type='password'
          component={FieldWithErrors}
          name='password'
          placeholder='Enter new password'
          className='form-input'
          variant='outlined'
          label='Password'
          material
        />
      </div>
      <div className='input-field'>
        <Field
          type='password'
          component={FieldWithErrors}
          name='passwordConfirmation'
          placeholder='Confirm password'
          className='form-input'
          variant='outlined'
          label='Confirm password'
          material
        />
      </div>
      <div className='input-field align-items-center'>
        <button type='submit' className='btn full black'>
          <span>Save</span>
        </button>
      </div>
    </form>
  );
});
