import { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import userApi from '@packs/apis/currentUser';
import { isWikiTableCategory } from '@packs/models/category/functions';
import { CommunityTips } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/CommunityTips';
import { PersonalizedOffers } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/PersonalizedOffers';
import { QuotesPartners } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/QuotesPartners';
import { TopBusiness } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/TopBusiness';
import { useStore } from '@packs/stores';

import useStyles from './styles';

export const AdditionalSection = ({ item, category, renewalDetail, selectedCost, currency }) => {
  const { categoryStore } = useStore();
  const styles = useStyles();

  const [additionalData, setAdditionalData] = useState({ quotes: {}, offers: {}, posts: {} });
  const [isLoading, setIsLoading] = useState(false);

  const isWikiTable = useMemo(() => {
    return isWikiTableCategory(category);
  }, [category?.id]);

  const setCurrentCategory = () => {
    categoryStore.setCurrentCategory(category);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const res = await userApi.fetchAdditionalData({
      quotes: {
        categoryId: category?.id,
        page: 1,
        perPage: 3,
        isWiki: isWikiTable,
        policyId: renewalDetail?.id || null
      },
      offers: {
        categoryId: category?.id,
        criteriaQuestions: renewalDetail?.criteriaQuestions || {},
        page: 1,
        perPage: 1
      },
      posts: {
        categoryId: category?.id,
        postType: ['tip'],
        page: 1,
        perPage: 1
      }
    });
    setAdditionalData(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [renewalDetail.id]);

  return (
    <Box sx={styles.wrapper}>
      <TopBusiness
        item={item}
        category={category}
        renewalDetail={renewalDetail}
        isWikiTable={isWikiTable}
        selectedCost={selectedCost}
        currency={currency}
        setCurrentCategory={setCurrentCategory}
      />
      <CommunityTips
        item={item}
        data={additionalData.posts}
        setCurrentCategory={setCurrentCategory}
        category={category}
        isLoading={isLoading}
        renewalDetail={renewalDetail}
      />
      <PersonalizedOffers
        item={item}
        data={additionalData.offers}
        setCurrentCategory={setCurrentCategory}
        category={category}
        isLoading={isLoading}
      />
      <QuotesPartners
        item={item}
        data={additionalData.quotes}
        setCurrentCategory={setCurrentCategory}
        category={category}
        renewalDetail={renewalDetail}
        isLoading={isLoading}
      />
    </Box>
  );
};
