import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { SideCropImage } from '@packs/components/MaterialUi/SideCropImage/SideCropImage';

import useStyles from './styles';

const { green, text } = colors;

export const Guide = ({ sectionImage, options = [], title, description }) => {
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Typography variant='h2' mb={2} textAlign='center'>
          {title}
        </Typography>
        <Typography variant='subtitle1' sx={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        <Box>
          <Box sx={styles.imgWrapper}>
            <SideCropImage sx={{ width: '100%', height: '100%' }}>
              <img src={sectionImage} alt='Guide' />
            </SideCropImage>
          </Box>
          <Box sx={styles.content}>
            <Stack sx={styles.contentList}>
              {options.map((option, index) => (
                <Box key={option.title + index} direction='column' alignItems='flex-start' spacing={{ xs: 1, lg: 2 }}>
                  <Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 2 }}>
                    <CheckRoundedIcon sx={{ color: green[500] }} />
                    <Typography variant='h6'>{option.title}</Typography>
                  </Stack>
                  <Typography
                    variant='body1'
                    color={text.secondary}
                    dangerouslySetInnerHTML={{ __html: option.description }}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
