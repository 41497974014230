import colors from '@packs/assets/theme/base/colors';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { dark } = colors;

const baseProperties = {
  fontFamily: '"Quicksand", -apple-system, BlinkMacSystemFont',
  fontFamilySecond: '"Poppins", -apple-system, BlinkMacSystemFont',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
  fontSize2XL: pxToRem(24),
  fontSize3XL: pxToRem(30)
};

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamilySecond,
  color: dark.main,
  fontWeight: baseProperties.fontWeightBold,
  lineHeight: 1.25
};

const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightLight,
  lineHeight: 1.2
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    fontSize: pxToRem(76),
    ...baseHeadingProperties,
    '@media (max-width: 1200px)': {
      fontSize: pxToRem(57)
    },
    '@media (max-width: 576px)': {
      fontSize: pxToRem(36)
    }
  },

  h2: {
    fontSize: pxToRem(61),
    ...baseHeadingProperties,
    '@media (max-width: 1200px)': {
      fontSize: pxToRem(47)
    },
    '@media (max-width: 576px)': {
      fontSize: pxToRem(32)
    }
  },

  h3: {
    fontSize: pxToRem(48),
    ...baseHeadingProperties,
    '@media (max-width: 1200px)': {
      fontSize: pxToRem(39)
    },
    '@media (max-width: 576px)': {
      fontSize: pxToRem(28)
    }
  },

  h4: {
    fontSize: pxToRem(39),
    ...baseHeadingProperties,
    '@media (max-width: 1200px)': {
      fontSize: pxToRem(33)
    },
    '@media (max-width: 576px)': {
      fontSize: pxToRem(25)
    }
  },

  h5: {
    fontSize: pxToRem(31),
    ...baseHeadingProperties,
    '@media (max-width: 1200px)': {
      fontSize: pxToRem(27)
    },
    '@media (max-width: 576px)': {
      fontSize: pxToRem(22)
    }
  },

  h6: {
    fontSize: pxToRem(22),
    ...baseHeadingProperties,
    '@media (max-width: 1200px)': {
      fontSize: pxToRem(22)
    },
    '@media (max-width: 576px)': {
      fontSize: pxToRem(20)
    }
  },

  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(20),
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.5,
    '@media (max-width: 1200px)': {
      fontSize: pxToRem(19)
    }
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(18),
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.5
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.5
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: pxToRem(24)
  },

  body3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: pxToRem(22)
  },

  body4: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: pxToRem(22)
  },

  body5: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: '1.5'
  },

  body6: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: '1.5'
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.5
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.5
  },

  overline: {
    fontSize: baseProperties.fontSizeXXS,
    fontFamily: baseProperties.fontFamily,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: pxToRem(15)
  },

  d1: {
    fontSize: pxToRem(80),
    ...baseDisplayProperties
  },

  d2: {
    fontSize: pxToRem(72),
    ...baseDisplayProperties
  },

  d3: {
    fontSize: pxToRem(64),
    ...baseDisplayProperties
  },

  d4: {
    fontSize: pxToRem(56),
    ...baseDisplayProperties
  },

  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties
  },

  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    '2xl': baseProperties.fontSize2XL,
    '3xl': baseProperties.fontSize3XL
  },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2
  }
};

export default typography;
