import pxToRem from '@shared/assets/theme/functions/pxToRem';

const slider = {
  styleOverrides: {
    thumb: {
      backgroundColor: 'currentColor',
      border: '3px solid #fff',
      '&::before': {
        display: 'none'
      }
    },
    markLabel: {
      fontSize: pxToRem(12),
      fontWeight: 500,
      '&[style="left: 0%;"]': {
        transform: 'translateX(0%)'
      },
      '&[style="left: 100%;"]': {
        transform: 'translateX(-100%)'
      }
    }
  }
};

export default slider;
