import React from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Box from '@mui/material/Box';
import { FiltersDrawer } from '@packs/components/MaterialUi/FiltersDrawer/FiltersDrawer';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const MyStatsTopBar = observer(({ myStatsFilter, disabledResetFilters, handleResetCompanyListFilters }) => {
  const { isDesktop } = useWindowSize();
  const styles = useStyles();
  const { companiesStore } = useStore();

  if (isDesktop) {
    return null;
  }

  return (
    <Box sx={styles.searchFilterWrapper}>
      <Box display='flex' alignItems='center' gap={2} sx={{ width: { xs: '100%', lg: 'auto' } }}>
        <Box sx={styles.actionSection}>
          <FiltersDrawer
            buttonProps={{
              variant: 'filledTonal',
              color: 'primary',
              startIcon: <FilterAltIcon />,
              sx: { width: { xs: '100%', lg: 'auto' } }
            }}
            handleResetFilters={handleResetCompanyListFilters}
            disabledResetFilters={disabledResetFilters}
            showResultText={`Show ${companiesStore?.total} business`}
          >
            {myStatsFilter && myStatsFilter()}
          </FiltersDrawer>
        </Box>
      </Box>
    </Box>
  );
});
