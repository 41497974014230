import React, { useState } from 'react';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AppliedFilters } from '@packs/components/MaterialUi/Leaderboard/AppliedFilters/AppliedFilters';
import { OffersSearch } from '@packs/components/MaterialUi/Offers/MyOffersFilters/components/OffersSearch/OffersSearch';
import { appliedFiltersLabels, offerTypes } from '@packs/models/company/constants';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const MyOffersActionPanel = observer(({ showFilters, defaultCategory }) => {
  const styles = useStyles();
  const { offersStore } = useStore();
  const { isDesktop } = useWindowSize();

  const [showSearch, setShowSearch] = useState(false);

  const toggleShowSearch = () => {
    setShowSearch(prevState => !prevState);
  };

  return (
    <Box sx={styles.actionPanelWrapper}>
      <Box sx={styles.container}>
        <Box sx={styles.searchWrapper}>
          {isDesktop &&
            (!showSearch ? (
              <Box sx={styles.searchButton} onClick={toggleShowSearch}>
                <SearchRoundedIcon />
              </Box>
            ) : (
              <OffersSearch {...{ defaultCategory }} />
            ))}
          <Typography variant='body3' sx={styles.resultsTitle}>
            {`${offersStore?.totalCount} offers found`}
          </Typography>
        </Box>
      </Box>
      <AppliedFilters
        filters={appliedFiltersLabels({ categoryId: defaultCategory, ...offersStore.filters }, showFilters, null, [
          ...offerTypes
        ])}
      />
    </Box>
  );
});
