export default () => ({
  categoryWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gpa: 1,
    borderRadius: '8px',
    p: 1,
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'all .2s',
    '&:hover': {
      backgroundColor: 'rgba(0, 85, 217, 0.08)'
    }
  },
  categorySection: {
    display: 'flex',
    alignItems: 'center'
  }
});
