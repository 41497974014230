import React from 'react';
import cx from 'classnames';

import PlusCircleIcon from '@packs/images/icons/UI/PlusCircleIcon';
import BronzeWinnerIcon from '@packs/images/svg/bronze-winner.svg';
// Images
import GoldWinnerIcon from '@packs/images/svg/gold-winner.svg';
import SilverWinnerIcon from '@packs/images/svg/silver-winner.svg';
import AddedCompanyIcon from '@packs/images/svg/ui/addedCompanyIcon.svg';
import { useStore } from '@packs/stores';

// Components
import Chart from 'react-apexcharts';

const colorsList = ['#FF4572', '#FFA24D', '#00D3B0', '#2EABFF', '#FFCC52', '#ACBCCD'];

const secondaryColorsList = ['#FD899E', '#FFDB89', '#A5D0D1', '#70B8F8', '#FFDB89', '#97ABC1'];

export const PieChart = ({ pieData, category, labelsData, renewalDetail }) => {
  const { shoppingCartStore, countryCode } = useStore();
  const shoppingCart = shoppingCartStore.shoppingCart;
  const shoppingCartItemsList = shoppingCart?.cartItems || [];

  const placesHelper = ['GOLDEN WINNER', 'SILVER WINNER', 'BRONZE WINNER'];
  const labels = labelsData.map((item, index) => `${item.name} - ${pieData[index]}%`);

  const addToShoppingCart = company => {
    const opts = {
      categoryId: +category?.id,
      companyIds: [+company?.id],
      policyId: renewalDetail.id ? +renewalDetail.id : null
    };

    shoppingCartStore.createShoppingCartItems(opts).catch(() => {});
  };

  const options = {
    chart: {
      type: 'donut',
      animations: { speed: 300 },
      toolbar: { show: false }
    },
    labels,
    colors: colorsList,
    fill: { opacity: 1 },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.9
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'darken',
          value: 0.9
        }
      }
    },
    legend: {
      show: false,
      width: 240,
      height: 'auto',
      position: 'left',
      fontSize: '12px',
      offsetY: 0,
      fontWeight: 400,
      markers: {
        width: 14,
        height: 14,
        fillColors: secondaryColorsList,
        strokeWidth: 2,
        strokeColor: '#fff',
        radius: 0,
        offsetX: 0,
        offsetY: 0
      },
      itemMargin: {
        horizontal: 0,
        vertical: 12
      },
      onItemHover: { highlightDataSeries: false }
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        donut: {
          size: '50%',
          background: 'transparent'
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter(val) {
        return val + '%';
      },
      style: {
        fontSize: '14px',
        fontWeight: 'bold'
      },
      dropShadow: {
        enabled: true,
        left: 1,
        top: 1,
        opacity: 0.25
      },
      offset: 0,
      minAngleToShowLabel: 10
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      custom({ series, seriesIndex }) {
        const notOtherSection = labelsData[seriesIndex].name !== 'Other';

        let imgSrc = '';
        switch (seriesIndex) {
          case 0:
            imgSrc = GoldWinnerIcon;
            break;
          case 1:
            imgSrc = SilverWinnerIcon;
            break;
          case 2:
            imgSrc = BronzeWinnerIcon;
            break;
          default:
            imgSrc = '';
        }

        let renderImg = '';
        if (notOtherSection && imgSrc) {
          renderImg = `
            <div class="tooltip-img">
                <img src="${imgSrc}" alt="Place" />
            </div>
        `;
        }

        return `
            <div class="custom-chart-tooltip">
              ${renderImg}
              <div class="right-part">
                <div class="tooltip-title">${notOtherSection ? placesHelper[seriesIndex] || '' : ''}</div>
                <div class="tooltip-label">
                  ${labelsData[seriesIndex].name} - ${series[seriesIndex]}%
                </div>
              </div>
            </div>
          `;
      }
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: { width: '100%', height: '340px' },
          legend: {
            position: 'top',
            width: '100%',
            itemMargin: {
              horizontal: 10,
              vertical: 10
            }
          }
        }
      }
    ]
  };

  return (
    <>
      <div className='pie-container'>
        <div className='pie-wrapper position-relative'>
          <div className='donut-legend'>
            {labelsData.map((company, index) => {
              const offerLink = company?.partnerProvider?.customWebLink || '';
              const affinityLink = company?.affinityLink?.url || '';
              const websiteLink = affinityLink || offerLink || company.url || null;
              const companyInShoppingCart = (shoppingCartItemsList || []).some(
                item => item?.company?.id === company.id
              );

              return (
                <div key={company.id} className='d-flex align-items-center donut-legend-item'>
                  {company.name !== 'Other' && (
                    <div
                      className={cx('img-container mr-10', !companyInShoppingCart && 'cursor-pointer')}
                      onClick={() => !companyInShoppingCart && addToShoppingCart(company)}
                    >
                      {companyInShoppingCart ? (
                        <img src={AddedCompanyIcon} alt='added' />
                      ) : (
                        <PlusCircleIcon fill={secondaryColorsList[index]} />
                      )}
                    </div>
                  )}
                  {websiteLink ? (
                    <a
                      href={websiteLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='d-flex align-items-center company-wrapper'
                      onClick={() => addToShoppingCart(company)}
                    >
                      <div className='marker' style={{ backgroundColor: secondaryColorsList[index] }} />
                      <div className='name'>{`${company.name} - ${pieData[index]}%`}</div>
                    </a>
                  ) : (
                    <span className='d-flex align-items-center company-wrapper'>
                      <div className='marker' style={{ backgroundColor: secondaryColorsList[index] }} />
                      <div className='name'>{`${company.name} - ${pieData[index]}%`}</div>
                    </span>
                  )}
                </div>
              );
            })}
          </div>
          <Chart series={pieData} options={options} type='donut' width='100%' height='auto' />
        </div>
      </div>
    </>
  );
};
