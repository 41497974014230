import React, { useMemo } from 'react';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { CategoryMobileSelect } from '@packs/components/MaterialUi/Leaderboard/CategoryMobileSelect/CategoryMobileSelect';
import { PersonalizationQuoteButton } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import {
  categoriesWithoutPersonalizeFlow,
  rootCategoriesLanding,
  subCategoriesWithoutPersonalizeFlow
} from '@shared/constants/categories';
import { websitePageUrl } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import dayjs from 'dayjs';
import isNumber from 'lodash-es/isNumber';
import startCase from 'lodash-es/startCase';
import { observer } from 'mobx-react';
import { Link, NavLink } from 'react-router-dom';

const { white } = colors;

export const calculateTimeAgo = dateString => {
  const currentDate = dayjs();
  const givenDate = dayjs(dateString);

  const diffInMonths = currentDate.diff(givenDate, 'month');
  const diffInYears = currentDate.diff(givenDate, 'year');

  if (diffInMonths < 12) {
    return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
  } else {
    return `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
  }
};

export const LeaderboardNavigation = observer(({ isLoading }) => {
  const { currentUser } = useUserSelectors();
  const { isDesktop } = useWindowSize();
  const styles = useStyles();
  const { currentPolicy } = useDetails();
  const { toggleShareSocialModal } = useModalsContext();
  const { currentCategory, leaderboardStore, currentCountry, companiesStore } = useStore();
  const {
    DASHBOARD_MY_RESULTS_PATH,
    DASHBOARD_MY_STATS_PATH,
    DASHBOARD_MY_OFFERS_PATH,
    MY_NOTES_DASHBOARD_PATH,
    DASHBOARD_MY_QUOTE_REQUESTS_PATH,
    DASHBOARD_QA_GENERAL,
    DASHBOARD_LANDING_PATH_FN,
    DASHBOARD_ROOT_LANDING_PATH_FN,
    MY_DETAILS_PATH_FN
  } = usePath();
  const { isMobile } = useWindowSize();

  const links = [
    {
      label: 'Results',
      url: DASHBOARD_MY_RESULTS_PATH,
      type: 'MY_RESULTS'
    },
    {
      label: 'Stats',
      url: DASHBOARD_MY_STATS_PATH,
      type: 'MY_STATS'
    },
    {
      label: 'Offers',
      url: DASHBOARD_MY_OFFERS_PATH,
      type: 'MY_OFFERS',
      count: leaderboardStore.leaderboardCounter?.offersCount
    },
    {
      label: 'Community',
      url: DASHBOARD_QA_GENERAL,
      type: 'QA_GENERAL',
      count: leaderboardStore.leaderboardCounter?.communityCount
    },
    {
      label: 'Docs',
      url: MY_NOTES_DASHBOARD_PATH,
      type: 'MY_NOTES',
      count: leaderboardStore.leaderboardCounter?.docsCount
    },
    {
      label: 'Quote Requests',
      url: DASHBOARD_MY_QUOTE_REQUESTS_PATH,
      type: 'QUOTE_REQUESTS',
      count: leaderboardStore.leaderboardCounter?.quoteRequestsCount
    }
  ];
  const title = ['broadband', 'energy', 'mobile'].includes(currentCategory.root.code)
    ? `Powered by the ${startCase(currentCategory.root.code)} community of ${currentCountry.name}!`
    : `Powered by the ${currentCategory.name} community of ${currentCountry.name}!`;
  const categoryIsRootLanding = rootCategoriesLanding.some(item => item === currentCategory?.root?.code);
  const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(currentCategory?.root?.code);
  const isSubCategoriesWithoutPersonalizeFlow = subCategoriesWithoutPersonalizeFlow.includes(currentCategory.code);
  const progress = useMemo(() => {
    if (currentPolicy?.policyType === 'simple') {
      return 34;
    }

    if (
      currentPolicy?.pricesQuestions?.price?.value &&
      !hasPolicyCriteria(currentPolicy) &&
      currentPolicy?.smartReminder?.id
    ) {
      return 15;
    }

    if (!hasPolicyCriteria(currentPolicy)) {
      if (currentPolicy?.pricesQuestions?.price?.value) {
        return 5;
      }

      if (currentPolicy?.smartReminder?.id) {
        return 10;
      }
    }

    return 100;
  }, [
    currentPolicy?.criteriaQuestions,
    currentPolicy?.policyType,
    currentPolicy?.pricesQuestions?.price?.value,
    currentPolicy?.smartReminder?.id,
    currentPolicy?.criteriaQuestions
  ]);

  const categoryUrl = () => {
    let url = DASHBOARD_LANDING_PATH_FN(currentCategory);
    if (categoryIsRootLanding) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(currentCategory);
    }

    return url;
  };

  const progressBar = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, '& ul': { listStyleType: 'disc !important' } }}>
        <Box sx={{ width: '64px', height: '6px', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.67)' }}>
          <Box
            sx={{
              width: `${progress}%`,
              height: '100%',
              backgroundColor: white.main,
              borderRadius: '8px'
            }}
          />
        </Box>
        <Typography variant='subtitle1'>{`${progress}% completed!`}</Typography>
      </Box>
    );
  };

  const UpdateDetailsTitle = () => {
    const updatedAt = currentPolicy?.updatedAt;
    const lastUpdate = `Last update: ${
      dayjs().diff(updatedAt, 'month') >= 1
        ? calculateTimeAgo(currentPolicy?.updatedAt)
        : dayjs(currentPolicy?.updatedAt).format('DD/MM/YYYY')
    }`;

    return (
      <Box sx={styles.updateDetailsTitleWrapper}>
        {!isMobile && (
          <Typography
            variant='subtitle1'
            component={Link}
            to={MY_DETAILS_PATH_FN()}
            sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#fff', textDecoration: 'underline' }}
          >
            Update details!
            <EditOutlinedIcon />
          </Typography>
        )}
        {currentPolicy?.policyType === 'simple' ? (
          progressBar()
        ) : dayjs().diff(updatedAt, 'month') >= 5 ? (
          <TooltipMUI title={lastUpdate} arrow placement={isDesktop ? 'right' : 'top'}>
            <Typography variant='subtitle1' sx={styles.obsoleteWrapper}>
              Obsolete information
            </Typography>
          </TooltipMUI>
        ) : (
          <Box
            sx={{
              ...(isDesktop
                ? {
                    '&:hover': {
                      '.lastUpdateTitle': { display: 'block' },
                      '.progressBar': { display: 'none' }
                    }
                  }
                : {})
            }}
          >
            <Box className='progressBar'>{progressBar()}</Box>
            <Typography
              variant={isMobile ? 'body5' : 'subtitle1'}
              className='lastUpdateTitle'
              sx={{ display: { xs: 'block', lg: 'none' } }}
            >
              {lastUpdate}
            </Typography>
          </Box>
        )}
        <TooltipMUI
          title={
            <Box sx={{ width: '300px', maxWidth: 'calc(100% - 16px)', textAlign: 'left' }}>
              <Typography variant='caption'>Benefits:</Typography>
              <Box component='ul' sx={{ listStyleType: 'disc !important', pl: 2.5 }}>
                <li>
                  <Typography variant='caption'>See best matched offers! </Typography>
                </li>
                <li>
                  <Typography variant='caption'>All your details will be in one place! </Typography>
                </li>
                <li>
                  <Typography variant='caption'>Share your full details with businesses to request quotes!</Typography>
                </li>
                <li>
                  <Typography variant='caption'>Get fully matched with members like you! </Typography>
                </li>
                <li>
                  <Typography variant='caption'>
                    Set up Auto. quote to request automatic quotes at renewal time!
                  </Typography>
                </li>
              </Box>
            </Box>
          }
          arrow
          placement='top'
        >
          <InfoOutlinedIcon fontSize='medium' />
        </TooltipMUI>
      </Box>
    );
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.innerWrapper}>
          <Box sx={{ ...styles.container, ...styles.contentWrapper }}>
            <Box sx={styles.breadcrumbs}>
              <BreadcrumbsMUI
                routes={
                  currentPolicy?.id ? [] : [{ to: DASHBOARD_LANDING_PATH_FN(currentCategory), label: 'Personalize' }]
                }
                currentPage={currentCategory.name}
                color={white.main}
              />
            </Box>
            <Box sx={styles.innerSection}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: {
                    xs: 'flex-start',
                    md: 'center'
                  },
                  gap: 2,
                  width: { xs: '100%', md: 'auto' },
                  mb: { xs: 3, md: 0 }
                }}
              >
                <CategoryIconBox
                  containerSize={isMobile ? '48px' : '88px'}
                  borderRadius={isMobile ? '8px' : '16px'}
                  iconSize={isMobile ? '32px' : '56px'}
                  category={currentCategory}
                  backgroundColor={white.tonal}
                  color={white.main}
                />
                <Box width={{ xs: '100%', md: 'auto' }}>
                  {!isMobile ? (
                    <Typography variant='h5' sx={styles.categoryName}>
                      {currentCategory?.name}
                    </Typography>
                  ) : (
                    <CategoryMobileSelect>
                      <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant='h4' sx={styles.categoryName}>
                          {currentCategory?.name}
                        </Typography>
                        <ChevronRightRoundedIcon sx={styles.categoryArrow} />
                      </Box>
                    </CategoryMobileSelect>
                  )}
                  {currentPolicy?.id &&
                  !isCategoriesWithoutPersonalizeFlow &&
                  !isSubCategoriesWithoutPersonalizeFlow ? (
                    <UpdateDetailsTitle />
                  ) : (
                    <Typography variant='subtitle1' sx={styles.title}>
                      {title}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: { xs: '100%', md: 'auto' } }}>
                {isMobile && (
                  <Button
                    variant='filledTonal'
                    color='white'
                    size='large'
                    fullWidth
                    component={Link}
                    to={MY_DETAILS_PATH_FN()}
                    endIcon={<EditOutlinedIcon />}
                  >
                    Update details!
                  </Button>
                )}
                {!isLoading && (
                  <Box sx={styles.btnContainer}>
                    <PersonalizationQuoteButton />
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={styles.tabsWrapper}>
              <Box sx={styles.navigationWrapper}>
                {links.map(({ label, url, type, count }) => {
                  const labelWithCount = `${label} ${isNumber(count) ? `(${count})` : ''}`;
                  if (
                    (type === 'QUOTE_REQUESTS' && !currentUser?.id) ||
                    (type === 'QUOTE_REQUESTS' && companiesStore?.globalRequestQuotesCount === 0)
                  ) {
                    return null;
                  }

                  if (
                    isCategoriesWithoutPersonalizeFlow ||
                    isSubCategoriesWithoutPersonalizeFlow ||
                    currentPolicy?.id ||
                    type === 'MY_RESULTS'
                  ) {
                    return (
                      <Box component={NavLink} key={type} to={url} sx={styles.navLink}>
                        <Typography variant='body2' whiteSpace='nowrap' sx={{ fontSize: '18px' }}>
                          {`${labelWithCount}`}
                        </Typography>
                        <Box className='active-label' sx={styles.activeLabel} />
                      </Box>
                    );
                  }

                  return (
                    <Box key={type} sx={styles.navLinkDisabled}>
                      <Typography variant='body2' whiteSpace='nowrap'>
                        {labelWithCount}
                      </Typography>
                      <Box className='active-label' sx={styles.activeLabel} />
                    </Box>
                  );
                })}
              </Box>
              {isDesktop && (
                <Button
                  variant='text'
                  color='white'
                  size='smallToMedium'
                  startIcon={<ShareRoundedIcon />}
                  sx={{ mb: 0.5 }}
                  onClick={() =>
                    toggleShareSocialModal({
                      modalProps: {
                        category: currentCategory,
                        shareLink: websitePageUrl().replace(/\/$/, '') + categoryUrl()
                      }
                    })
                  }
                >
                  Invite friends
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});
