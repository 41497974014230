import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { COMPANIES_PER_PAGE } from '@packs/models/company/constants';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const LeaderboardListSkeleton = ({ count = COMPANIES_PER_PAGE }) => {
  const { isMobile } = useWindowSize();
  const styles = useStyles();

  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div key={index}>
          <SkeletonTheme color='#E4EEFA' highlightColor='#fff'>
            <Box sx={styles.companyWrapper}>
              <Box sx={styles.mainSection}>
                <Box sx={styles.companyInfoSection}>
                  <Box sx={styles.companyTopInfo}>
                    <Box>
                      <Skeleton
                        count={1}
                        height={isMobile ? 80 : 120}
                        width={isMobile ? 80 : 120}
                        className='avatar-skeleton'
                      />
                    </Box>
                    <Box>
                      <Skeleton count={1} height={39} width={92} />
                      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 2 }}>
                        <Skeleton count={1} height={32} width={90} />
                        <Skeleton count={1} height={32} width={111} />
                      </Box>
                    </Box>
                    {!isMobile && (
                      <Box sx={{ ml: 'auto' }}>
                        <Skeleton count={1} height={39} width={178} />
                      </Box>
                    )}
                  </Box>
                  <Box sx={styles.additionalInfoWrapper}>
                    <Box>
                      <Skeleton count={1} height={63} width='100%' />
                    </Box>
                    <Box sx={styles.additionalItems}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={styles.additionalItem}>
                          <Box>
                            <Skeleton count={1} height={21} width={100} />
                          </Box>
                        </Box>
                        <Skeleton count={1} height={21} width={37} />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={styles.additionalItem}>
                          <Box>
                            <Skeleton count={1} height={21} width={100} />
                          </Box>
                        </Box>
                        <Skeleton count={1} height={21} width={37} />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={styles.additionalItem}>
                          <Box>
                            <Skeleton count={1} height={21} width={100} />
                          </Box>
                        </Box>
                        <Skeleton count={1} height={21} width={37} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {!isMobile && (
                  <Box sx={styles.ratingInfoSection}>
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Skeleton count={1} height={21} width={120} />
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Skeleton count={1} height={21} width={38} />
                          <Skeleton count={1} height={33} width={220} />
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Box sx={styles.ratingItem}>
                          <Skeleton count={1} height={21} width={90} />
                          <Skeleton count={1} height={21} width={130} />
                        </Box>
                        <Box sx={styles.ratingItem}>
                          <Skeleton count={1} height={21} width={90} />
                          <Skeleton count={1} height={21} width={130} />
                        </Box>
                        <Box sx={styles.ratingItem}>
                          <Skeleton count={1} height={21} width={90} />
                          <Skeleton count={1} height={21} width={130} />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Skeleton count={1} height={48} width='100%' />
                    </Box>
                  </Box>
                )}
              </Box>
              {isMobile && (
                <Box sx={styles.mobileBottomSection}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}></Box>
                </Box>
              )}
            </Box>
          </SkeletonTheme>
        </div>
      ))}
    </>
  );
};
