import Form from './form';

import { reduxForm } from 'redux-form';

const validate = ({ password, passwordConfirmation }) => {
  const errors = {};
  if (!password) errors.password = 'Required';
  if (!passwordConfirmation) errors.passwordConfirmation = 'Required';
  if (passwordConfirmation !== password) errors.passwordConfirmation = "Password doesn't match";
  return errors;
};

const createReduxForm = reduxForm({ form: 'forgotPasswordUpdate', validate });

export default createReduxForm(Form);
