import React from 'react';
import moment from 'moment';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tabs from '@packs/components/MaterialUi/Tabs/Tabs';
import { MONTH_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';

import useStyles from './styles';

export const CalendarHeader = ({
  events,
  currentDate,
  setCurrentDate,
  FullCalendarApi,
  changeCalendarView,
  calendarTypeView
}) => {
  const styles = useStyles();
  return (
    <Box sx={styles.toolbarWrapper}>
      <Box>
        <IconButton
          onClick={() => {
            FullCalendarApi().prev();
            setCurrentDate(FullCalendarApi().getDate());
          }}
        >
          <ChevronLeftRoundedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            FullCalendarApi().next();
            setCurrentDate(FullCalendarApi().getDate());
          }}
        >
          <ChevronRightRoundedIcon />
        </IconButton>

        <Button
          type='button'
          disabled={currentDate.toDateString() === new Date().toDateString()}
          onClick={() => {
            FullCalendarApi().today();
            setCurrentDate(FullCalendarApi().getDate());
          }}
          color='dark'
          size='small'
        >
          Today
        </Button>
        <Typography variant='body4'>
          {calendarTypeView === 'dayGridMonth'
            ? moment(currentDate).format(MONTH_FORMAT)
            : moment(currentDate).format('[Year] YYYY')}
        </Typography>
      </Box>

      <Box sx={{ pr: { xs: 0, sm: 2 } }}>
        <Tabs
          options={[
            { label: 'Year', value: 'customGridDayYear' },
            { label: 'Month', value: 'dayGridMonth' }
          ]}
          activeTab={calendarTypeView}
          onChange={value => {
            FullCalendarApi().changeView(value);
            changeCalendarView(value);
          }}
        />
      </Box>
    </Box>
  );
};
