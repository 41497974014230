import React from 'react';

const HomeIconFilled = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 10.625C9.25832 10.625 8.5333 10.4051 7.91661 9.99301C7.29993 9.58095 6.81928 8.99528 6.53545 8.31006C6.25162 7.62484 6.17736 6.87084 6.32206 6.14341C6.46675 5.41598 6.8239 4.7478 7.34835 4.22335C7.8728 3.6989 8.54098 3.34175 9.26841 3.19706C9.99584 3.05236 10.7498 3.12662 11.4351 3.41045C12.1203 3.69428 12.706 4.17493 13.118 4.79161C13.5301 5.4083 13.75 6.13332 13.75 6.875C13.75 7.86956 13.3549 8.82339 12.6517 9.52665C11.9484 10.2299 10.9946 10.625 10 10.625ZM10 4.375C9.50555 4.375 9.0222 4.52162 8.61108 4.79633C8.19995 5.07103 7.87952 5.46148 7.6903 5.91829C7.50108 6.37511 7.45158 6.87777 7.54804 7.36273C7.6445 7.84768 7.8826 8.29314 8.23223 8.64277C8.58187 8.9924 9.02732 9.2305 9.51228 9.32696C9.99723 9.42343 10.4999 9.37392 10.9567 9.1847C11.4135 8.99548 11.804 8.67505 12.0787 8.26392C12.3534 7.8528 12.5 7.36945 12.5 6.875C12.5 6.21196 12.2366 5.57607 11.7678 5.10723C11.2989 4.63839 10.663 4.375 10 4.375Z'
        fill='#06132F'
      />
      <path
        d='M9.99997 19.375C8.61399 19.3741 7.24546 19.0659 5.9929 18.4726C4.74035 17.8792 3.63492 17.0156 2.75621 15.9438L2.43121 15.5438L2.75621 15.15C3.63566 14.0796 4.74141 13.2174 5.99393 12.6254C7.24645 12.0334 8.6146 11.7264 9.99997 11.7264C11.3853 11.7264 12.7535 12.0334 14.006 12.6254C15.2585 13.2174 16.3643 14.0796 17.2437 15.15L17.5687 15.5438L17.2437 15.9438C16.365 17.0156 15.2596 17.8792 14.007 18.4726C12.7545 19.0659 11.3859 19.3741 9.99997 19.375ZM4.06871 15.55C4.82886 16.3638 5.74817 17.0126 6.76957 17.4562C7.79097 17.8998 8.89265 18.1287 10.0062 18.1287C11.1198 18.1287 12.2215 17.8998 13.2429 17.4562C14.2643 17.0126 15.1836 16.3638 15.9437 15.55C15.1836 14.7363 14.2643 14.0874 13.2429 13.6438C12.2215 13.2002 11.1198 12.9713 10.0062 12.9713C8.89265 12.9713 7.79097 13.2002 6.76957 13.6438C5.74817 14.0874 4.82886 14.7363 4.06871 15.55Z'
        fill='#06132F'
      />
      <path
        d='M9.99997 19.375C7.87044 19.3764 5.80387 18.6528 4.1404 17.3232C2.47693 15.9936 1.31569 14.1373 0.847806 12.0598C0.379919 9.98232 0.633268 7.80743 1.56615 5.8931C2.49904 3.97877 4.05587 2.43909 5.98041 1.52747C7.90496 0.615841 10.0825 0.386598 12.1547 0.877468C14.2269 1.36834 16.0702 2.55007 17.3813 4.22816C18.6924 5.90625 19.3931 7.98071 19.3681 10.1101C19.3431 12.2395 18.5938 14.2969 17.2437 15.9438C16.365 17.0156 15.2596 17.8792 14.007 18.4725C12.7545 19.0659 11.3859 19.3741 9.99997 19.375ZM9.99997 1.87501C8.393 1.87501 6.82211 2.35153 5.48596 3.24432C4.14981 4.1371 3.10841 5.40605 2.49345 6.8907C1.87849 8.37535 1.71759 10.009 2.03109 11.5851C2.3446 13.1612 3.11843 14.6089 4.25473 15.7452C5.39103 16.8815 6.83877 17.6554 8.41486 17.9689C9.99096 18.2824 11.6246 18.1215 13.1093 17.5065C14.5939 16.8916 15.8629 15.8502 16.7557 14.514C17.6484 13.1779 18.125 11.607 18.125 10C18.125 7.84512 17.2689 5.7785 15.7452 4.25476C14.2215 2.73103 12.1549 1.87501 9.99997 1.87501Z'
        fill='#06132F'
      />
      <path
        d='M3.23749 15.55C3.23749 15.55 9.53124 22.5812 15.9375 16.25L16.7625 15.55C16.7625 15.55 11.4125 10 5.98124 13.3312L3.23749 15.55Z'
        fill='#06132F'
      />
      <path
        d='M10 10C11.7259 10 13.125 8.60089 13.125 6.875C13.125 5.14911 11.7259 3.75 10 3.75C8.27411 3.75 6.875 5.14911 6.875 6.875C6.875 8.60089 8.27411 10 10 10Z'
        fill='#06132F'
      />
    </svg>
  );
};

export default HomeIconFilled;
