import React from 'react';

import CheckIcon from '@packs/images/svg/ui/check-circle-filed-primary.svg';

const CompanyPaymentComplete = () => {
  return (
    <div className='checkout-container'>
      <div className='payment-wrapper'>
        <div className='success-info d-flex flex-column align-items-center w-100 justify-content-center'>
          <div className='success-icon'>
            <img src={CheckIcon} alt='success' />
          </div>
          <div className='success-charged'>Transaction Completed!</div>
        </div>
      </div>
    </div>
  );
};

export default CompanyPaymentComplete;
