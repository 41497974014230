import React, { useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';

import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/company';
import userApi from '@packs/apis/currentUser';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { ICompany } from '@packs/interfaces/rootInterfaces';
import { pathGen, usePath } from '@packs/layouts/constants';
import { pickPersonalizedCriteriaQuestionsFromOptions } from '@packs/lib/helpers/renewalDetails';
import { saveState } from '@packs/lib/utils/localStorage';
import { ASC, DESC, INSURANCE_ORDER_OPTIONS, ORDER_OPTIONS } from '@packs/models/company/constants';
import { AveragePrice } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/AveragePrice/AveragePrice';
import { Price } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/Price/Price';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/styles';
import { calcSaveMoney, categoriesWithCountryFilter, defaultOrderColumn } from '@packs/screens/MyHome/constants';
import { useStore } from '@packs/stores';
import {
  categoriesWithoutPersonalizeFlow,
  rootCategoriesLanding,
  subCategoriesWithoutPersonalizeFlow
} from '@shared/constants/categories';
import { getTotalRating } from '@shared/helpers/companyHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Link } from 'react-router-dom';

const sources = [
  { value: 'provider', label: 'Provider' },
  { value: 'broker', label: 'Broker' }
];

export const TopBusiness = ({
  item,
  category,
  renewalDetail,
  isWikiTable,
  selectedCost,
  currency,
  setCurrentCategory
}) => {
  const { isDesktop } = useWindowSize();
  const styles = useStyles();
  const { DASHBOARD_MY_RESULTS_PATH_FN, DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();
  const { leaderboardStore, countryCode } = useStore();

  const isInsuranceCategory = ['insurance'].includes(category.root?.enName) || category.code?.includes('insurance');
  const sortList = useMemo(() => {
    return isInsuranceCategory ? INSURANCE_ORDER_OPTIONS : ORDER_OPTIONS;
  }, [isInsuranceCategory]);

  const [company, setCompany] = useState<ICompany>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderColumn, setOrderColumn] = useState(defaultOrderColumn(category, sortList));
  const [sourceType, setSourceType] = useState(sources[0]);
  const [leaderboardFilters, setLeaderboardFilters] = useState({});
  const [loading, setLoading] = useState(true);

  const currentPrice = +renewalDetail[selectedCost.value] || 0;
  const isEmptyPrice = !renewalDetail[selectedCost.value];
  const period = selectedCost.value === 'renewalCostMonthly' ? 'p/m' : 'p/y';
  const city = categoriesWithCountryFilter.some(item => item === category.code || item === category.root.code)
    ? 'all'
    : renewalDetail?.criteriaQuestions?.location;

  const inRegion = useMemo(() => {
    const filterCity = city || null;

    return !filterCity || filterCity === 'all' || toJS(company?.quoteAgent?.regions)?.includes(filterCity);
  }, [company]);

  const saveMoney = calcSaveMoney({ currentPrice, company, selectedCost, category }).toFixed(2);
  const isPopularRootCategory = rootCategoriesLanding.some(popular => popular === category?.root?.code);
  const categoryUrl = useMemo(() => {
    const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(category?.root?.code);
    const isSubCategoriesWithoutPersonalizeFlow = subCategoriesWithoutPersonalizeFlow.includes(category.code);

    const enabledPersonalizeFlow = !isCategoriesWithoutPersonalizeFlow && !isSubCategoriesWithoutPersonalizeFlow;

    let url = DASHBOARD_LANDING_PATH_FN(category);

    if (enabledPersonalizeFlow || renewalDetail?.id) {
      url = DASHBOARD_MY_RESULTS_PATH_FN(category).concat(
        `?policyId=${renewalDetail?.id}&orderColumn=${orderColumn.value}&sourceType=${sourceType.value}&city=${city}`
      );
    } else if (isPopularRootCategory) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(category).concat(category.code ? `?initialCategory=${category.code}` : '');
    }

    return url;
  }, [category.id, orderColumn.value, sourceType.value, city, renewalDetail?.id, isPopularRootCategory, category]);

  const onChangeSort = (_, data) => {
    setOrderColumn(data);
  };

  const onChangeSourceType = (_, data) => {
    setSourceType(data);
  };

  const handleAddRequestBusiness = () => {
    setCurrentCategory();
    leaderboardStore.addRequestBusiness(company);
  };

  const handleRedirectToLeaderboard = () => {
    setCurrentCategory();
    saveState('openDashboardItemId', item.id);
  };

  const getLeaderboardFilters = async () => {
    if (category?.id) {
      setLoading(true);
      const res = await userApi.fetchLeaderboardFilters({ categoryId: category?.id });
      if (res?.leaderboardFilters) {
        setLeaderboardFilters(res.leaderboardFilters);
      }

      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (!loading) {
      const criteriaQuestions = pickPersonalizedCriteriaQuestionsFromOptions(
        renewalDetail?.criteriaQuestions,
        leaderboardFilters
      );

      const apiCall = isWikiTable ? api.fetchWikiPricesCompanies : api.fetchCompaniesList;
      setIsLoading(true);
      const res = await apiCall({
        categoryId: category?.id,
        policyId: renewalDetail?.id,
        companyType: sourceType?.value || null,
        criteriaQuestions,
        page: 1,
        perPage: 1,
        orderDirection: orderColumn.value === 'wiki_price' || orderColumn.value === 'best_avg_prices' ? ASC : DESC,
        city: city === 'all' || !city ? null : city,
        orderColumn: orderColumn.value
      });

      if (res?.companies?.length > 0) {
        setCompany(res.companies[0]);
      } else {
        setCompany(null);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [orderColumn.value, city, sourceType.value, renewalDetail.id, loading]);

  useEffect(() => {
    getLeaderboardFilters();
  }, [category?.id]);

  if (isLoading || loading) {
    return (
      <Box sx={{ ...styles.sectionItemWrapper() }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, height: '100%' }}>
          <Skeleton variant='rounded' width={184} height={21} />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Skeleton variant='rounded' width={144} height={40} />
            <Skeleton variant='rounded' width={100} height={40} />
          </Box>
          <Skeleton variant='rounded' width={200} height={21} />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.sectionItemWrapper(!company?.id), ...(company?.id ? styles.sectionItemWrapperHover : {}) }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 1,
            '& .MuiInput-root': { mt: 0 },
            '& > div': { width: 'calc(50% - 8px)' }
          }}
        >
          <SelectMUI
            value={sortList.find(item => item.value === orderColumn.value) || sortList[0]}
            options={sortList}
            onChange={onChangeSort}
            size='small'
            variant='standard'
            sx={{ flexGrow: 1 }}
          />
          <SelectMUI
            value={sourceType}
            options={sources}
            onChange={onChangeSourceType}
            size='small'
            variant='standard'
            sx={{ flexGrow: 1 }}
          />
        </Box>
        {company?.id ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar sx={{ width: 40, height: 40 }} variant='rounded' src={company?.avatarUrl} />
              <Box>
                <Typography variant='body4'>{company?.name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StarRoundedIcon sx={{ fontSize: '18px' }} />
                  <Typography variant='body5'>{getTotalRating(company).totalRating.toFixed(1)}</Typography>
                  <Divider orientation='vertical' flexItem sx={{ ml: 1, mr: 1 }} className='divider' />
                  <Typography variant='caption' sx={{ fontWeight: 700 }}>
                    {getTotalRating(company).totalReviews} Reviews
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              {isWikiTable ? (
                <Price company={company} category={category} selectedCost={selectedCost} extended />
              ) : (
                <AveragePrice company={company} category={category} selectedCost={selectedCost} extended />
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={styles.sectionLabel(true)} className='sectionLabel'>
              <PublicRoundedIcon />
            </Box>
            <Typography variant='body5'>
              There are no results that match your criteria. <br /> Please consider adjusting the filter settings.
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: 'auto' }}>
          <Box className={company?.id ? 'topBusinessTitle' : ''} sx={{ mt: { xs: 1, lg: 0 } }}>
            <Typography variant='body4'>Community Recommendations!</Typography>
          </Box>
          {company?.id && (
            <>
              {company?.quoteAgent?.id && inRegion && renewalDetail.id ? (
                <Button
                  component={Link}
                  variant='contained'
                  color='primary'
                  size={isDesktop ? 'xs' : 'small'}
                  fullWidth
                  className='topBusinessButton'
                  sx={{ display: 'none', mt: isDesktop ? 'auto' : 1 }}
                  to={pathGen('REQUEST_QUOTES', { countryCode, category }).concat(
                    `?initialStep=2&renewalDetailId=${renewalDetail?.id}`
                  )}
                  onClick={handleAddRequestBusiness}
                >
                  {saveMoney > 0 && !isEmptyPrice ? `Save up to ${currency + saveMoney} ${period}` : 'Request quote'}
                </Button>
              ) : (
                <Button
                  component={Link}
                  variant='contained'
                  color='primary'
                  size={isDesktop ? 'xs' : 'small'}
                  fullWidth
                  className='topBusinessButton'
                  sx={{ display: 'none', mt: isDesktop ? 'auto' : 1 }}
                  to={categoryUrl}
                  onClick={handleRedirectToLeaderboard}
                >
                  {saveMoney > 0 && !isEmptyPrice ? `Save up to ${currency + saveMoney} ${period}` : 'See Leaderboard'}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
