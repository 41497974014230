import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { SidebarTitle } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SidebarTitle/SidebarTitle';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import otherIcon from '@shared/images/company/other.svg';

import useStyles from './styles';

import { NavLink } from 'react-router-dom';

export const OtherCategories = ({ company }) => {
  const styles = useStyles();
  const { companiesStore, categoryStore, countryCode } = useStore();

  const list = (company && company?.suggestedCategories) || [];

  const handleRedirect = subCategory => {
    return e => {
      handleVisitCompany(company, subCategory);
      categoryStore.setCurrentCategory(subCategory);
      companiesStore.clearFilters();
    };
  };

  if (list.length === 0) {
    return null;
  }

  return (
    <SidebarTitle icon={<img src={otherIcon} alt='Other' />} title='Other company categories'>
      {list.map(category => {
        return (
          <Box
            component={NavLink}
            key={category.id}
            to={pathGen('PRODUCT', {
              countryCode,
              category,
              company
            })}
            sx={styles.categoryItem}
            onClick={handleRedirect(category)}
          >
            <CategoryIconBox
              containerSize='32px'
              borderRadius='8px'
              iconSize='24px'
              category={category}
              backgroundColor={colors?.secondary.transparent}
              color={colors?.secondary.main}
            />
            <Typography variant='body1'>{category.name}</Typography>
          </Box>
        );
      })}
    </SidebarTitle>
  );
};
