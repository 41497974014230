import colors from '@packs/assets/theme/base/colors';

const { white, text, secondary, socialMediaColors, grey, dark } = colors;

export default () => ({
  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'stretch'
  },
  infoSection: {
    flex: 1,
    width: '580px',
    minWidth: '580px',
    backgroundImage: 'linear-gradient(180deg, #3273F5 0%, #06C 100%)',
    color: white.main
  },
  infoSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  infoSectionContent: {
    width: '100%',
    maxWidth: '484px',
    mx: 'auto',
    mb: 4,
    pt: 7,
    px: 2
  },
  title: {
    mb: 4,
    color: white.main
  },
  subTitle: {
    mb: 1,
    color: white.main
  },
  benefitsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    '& ul': {
      pl: 3
    },
    '& li': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 1
    }
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    // textAlign: 'center',
    flex: 1,
    pb: 3,
    px: 3
  },
  formFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '480px',
    height: '100%',
    mx: 'auto'
  },
  authSidebarImgWrapper: {
    maxWidth: '450px',
    mx: 'auto'
  },
  formTitle: {
    mb: 1,
    textAlign: 'center'
  },
  formSubTitle: {
    mb: 3,
    color: text.secondary,
    textAlign: 'center'
  },
  fieldsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    width: '100%'
  },
  forgotWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  forgotLink: {
    fontSize: '16px',
    fontWeight: '700',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  btnContainer: {
    width: '100%',
    mt: 3
  },
  termsPolicy: {
    mb: 3,
    textAlign: 'center'
  },
  termsPolicyLink: {
    color: secondary.main,
    cursor: 'pointer'
  },
  salesCode: {
    backgroundColor: grey[100],
    borderRadius: 1,
    px: 2,
    py: 1
  },
  socialButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
    maxWidth: '430px',
    mx: 'auto',
    '& .social-link': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
      cursor: 'pointer',
      height: '56px',
      width: '100%',
      borderRadius: '4px',
      border: 'none',
      fontSize: '18px',
      fontWeight: '700',
      '&:hover': {
        opacity: '0.8'
      },
      '& svg, & img': {
        width: '20px'
      },
      '&.facebook': {
        backgroundColor: socialMediaColors.facebook.main,
        color: white.main
      },
      '&.twitter': {
        backgroundColor: socialMediaColors.twitter.main,
        color: white.main
      },
      '&.linkedin': {
        backgroundColor: socialMediaColors.linkedin.main,
        color: white.main
      },
      '&.whatsApp': {
        backgroundColor: socialMediaColors.whatsApp.main,
        color: white.main
      },
      '&.email': {
        backgroundColor: grey[200],
        color: dark.main
      }
    }
  }
});
