import Member1Icon from '@packs/screens/RootCategoryLanding/membersSection/energyImages/member-1.webp';
import Member2Icon from '@packs/screens/RootCategoryLanding/membersSection/energyImages/member-2.webp';
import Member3Icon from '@packs/screens/RootCategoryLanding/membersSection/energyImages/member-3.webp';
import Member4Icon from '@packs/screens/RootCategoryLanding/membersSection/energyImages/member-4.webp';
import Member5Icon from '@packs/screens/RootCategoryLanding/membersSection/energyImages/member-5.webp';

const energy = [
  {
    label: "Here's why our members use Bill Winner to shop for their energy Bills!",
    title: "Here's why our members use Bill Winner to shop for their energy Bills!",
    description: '',
    numerate: false,
    list: [
      { title: 'We compare the best providers, brokers, and price comparison sites in one place!', desc: '' },
      { title: 'We match our members to the best businesses on the leaderboard!', desc: '' },
      { title: 'Our members get to see the best ratings and insights!', desc: '' },
      { title: 'Our members get support from other members and the top voted business experts!', desc: '' },
      { title: 'Our members can request quotes from multiple businesses with one quote request!', desc: '' }
    ],
    imgSrc: Member1Icon
  },
  {
    label: 'What are the main types of energy plans and tariffs in Ireland?',
    title: 'What are the main types of energy plans and tariffs in Ireland?',
    description:
      'In Ireland, there are various types of energy plans and tariffs offered by energy providers to residential and business customers. These plans and tariffs can vary depending on the energy provider, the type of energy (electricity, gas, or dual fuel), and the region. Here are some common types of energy plans and tariffs:',
    numerate: true,
    list: [
      {
        title: 'Standard Tariff:',
        desc: "A standard tariff is the default energy plan that customers are placed on when they sign up with an energy provider. This plan typically has variable rates, and prices can change over time. It's a flexible option but may not offer the best value for customers."
      },
      {
        title: 'Fixed-Rate Tariff:',
        desc: "With a fixed-rate tariff, customers lock in their energy prices for a specific contract term, which can range from 1 to 3 years. This provides price stability and protection against energy price increases during the contract period. However, if energy prices decrease, customers won't benefit from the lower rates."
      },
      {
        title: 'Variable-Rate Tariff:',
        desc: 'Variable-rate tariffs have rates that can change periodically in response to market conditions. Customers on variable-rate tariffs may experience fluctuations in their energy costs, which can be influenced by factors like wholesale energy prices.'
      },
      {
        title: 'Discounted Tariff:',
        desc: 'Some energy providers offer discounted tariffs, which provide reduced rates for a specific period or under certain conditions, such as setting up direct debit payments or bundling multiple services (e.g., electricity and gas).'
      },
      {
        title: 'Green Energy Tariff:',
        desc: 'Green energy tariffs allow customers to support renewable energy sources. Providers often source a portion of the energy from renewable sources like wind, solar, or hydroelectric power. These tariffs may be more expensive than standard tariffs but appeal to environmentally conscious customers.'
      },
      {
        title: 'Economy 7 and Economy 24 Tariffs:',
        desc: 'These tariffs offer different pricing for electricity during off-peak hours. Economy 7 typically provides seven hours of lower rates at night, while Economy 24 spreads the lower rates more evenly throughout the day.'
      },
      {
        title: 'Pay-As-You-Go Tariff:',
        desc: 'Pay-as-you-go tariffs are designed for customers who prefer to pay for their energy in advance rather than receiving a monthly bill. Prepayment meters or smart meters with prepayment functionality are often used with these plans.'
      },
      {
        title: 'Dual Fuel Tariff:',
        desc: 'A dual fuel tariff bundles both electricity and gas services from the same provider. These tariffs can simplify billing and may offer discounts for customers who use both energy types from the same provider.'
      },
      {
        title: 'Time of Use (TOU) Tariff:',
        desc: 'TOU tariffs vary energy rates depending on the time of day. These plans encourage customers to use electricity during off-peak hours when rates are lower.'
      },
      {
        title: 'Business Energy Tariffs:',
        desc: 'Energy providers offer specific tariffs tailored to the needs of business customers, which may include options like commercial electricity, gas, or tailored energy solutions for different business sizes and types.'
      }
    ],
    description2:
      "It's essential to carefully review the details of each plan and tariff, including contract terms, rates, exit fees, and any additional services or benefits offered. Choose the plan that best suits your energy consumption patterns, preferences, and budget. Keep in mind that the availability of specific plans and tariffs can vary depending on your location and the energy providers serving your area.",
    imgSrc: Member2Icon
  },
  {
    label: 'What are the key steps to evaluating the best energy deals?',
    title: 'What are the key steps to evaluating the best energy deals?',
    description:
      'Evaluating the best energy deals involves considering several factors to ensure you choose a plan that aligns with your energy consumption and budget. Here are the key steps to help you evaluate energy deals:',
    numerate: true,
    list: [
      {
        title: 'Assess Your Energy Consumption:',
        desc: 'Determine your typical energy consumption by reviewing your past energy bills. This will help you understand your energy needs and consumption patterns.'
      },
      {
        title: 'Check Your Meter Type:',
        desc: 'Identify whether you have a standard (credit) or prepayment meter, as some energy deals may be specific to one meter type.'
      },
      {
        title: 'Review Your Current Plan:',
        desc: "Examine your current energy plan, including the rates you're paying, contract terms, and any exit fees. Note your current supplier and any additional services, such as gas or electricity."
      },
      {
        title: 'Compare Providers:',
        desc: 'Research and compare energy providers in your area to identify the available options. Check their tariffs, pricing structures, and any special offers or discounts.'
      },
      {
        title: 'Consider the Type of Plan:',
        desc: 'Choose between fixed-rate and variable-rate plans. Fixed-rate plans offer price stability over a set period, while variable-rate plans can change with market fluctuations. Decide which type suits your preference.'
      },
      {
        title: 'Review Unit Rates and Standing Charges:',
        desc: 'Look at the unit rates (price per kWh or unit of energy) and standing charges (daily or monthly fees) of different plans. Calculate the potential cost based on your estimated consumption.'
      },
      {
        title: 'Check for Green Energy Options:',
        desc: 'If environmental concerns are important to you, consider providers that offer green energy plans, such as electricity generated from renewable sources.'
      },
      {
        title: 'Consider Contract Length:',
        desc: 'Evaluate the contract length of each plan. Some deals may require a longer commitment, while others offer more flexibility with shorter terms.'
      },
      {
        title: 'Examine Additional Services:',
        desc: "If you're interested in services like home heating, boiler servicing, or insulation, inquire whether providers offer bundled deals or discounts for these services."
      },
      {
        title: 'Check for Discounts and Incentives:',
        desc: 'Be aware of any discounts, cashback offers, or incentives that providers may offer. These can significantly reduce your energy costs.'
      },
      {
        title: 'Understand Payment Options:',
        desc: 'Different providers may offer various payment methods, such as monthly direct debit, pay-as-you-go, or traditional billing. Choose the payment method that suits your preferences and budget.'
      },
      {
        title: 'Read Customer Reviews:',
        desc: 'Research customer reviews and the reputation of energy providers. Consider feedback regarding customer service, billing accuracy, and overall satisfaction.'
      },
      {
        title: 'Contact Customer Support:',
        desc: "Reach out to the energy providers' customer support to clarify any questions or concerns you may have regarding their plans, terms, or services."
      },
      {
        title: 'Switching Process:',
        desc: 'Understand the process of switching energy providers, including any required notice period to terminate your current contract. Ensure there are no early termination fees.'
      },
      {
        title: 'Calculate the Total Cost:',
        desc: 'Calculate the total annual cost of the energy plan, taking into account unit rates, standing charges, and any discounts or incentives.'
      },
      {
        title: 'Check for Exit Fees:',
        desc: 'Be aware of any exit fees from your current provider if you decide to switch. Weigh these fees against the potential savings from switching.'
      },
      {
        title: 'Read and Compare Terms and Conditions:',
        desc: 'Carefully read and compare the terms and conditions of the plans you are considering. Pay attention to any terms that may affect your decision.'
      }
    ],
    description2:
      'By following these steps and taking the time to evaluate your energy options, you can make an informed decision and select the best energy deal that suits your needs and budget. Remember to review your energy plan periodically to ensure it continues to be the most cost-effective option for you.',
    imgSrc: Member3Icon
  },
  {
    label: 'What information do I need to switch my energy provider?',
    title: 'What information do I need to switch my energy provider?',
    description:
      "To switch your energy provider in Ireland, you'll need to gather some key information and take several steps. Here's a list of the information you'll need and the steps to follow when switching your energy provider:",
    numerate: true,
    list: [
      {
        title: 'Current Energy Account Details:',
        desc: 'Gather your existing energy account information, including the name of your current energy provider, your MPRN number, and any customer reference numbers. You can find this information on your energy bills.'
      },
      {
        title: 'Meter Reading:',
        desc: 'Take a meter reading on the day you plan to switch. This helps ensure an accurate final bill from your current provider and an accurate starting point for your new provider.'
      },
      {
        title: 'Bank Details:',
        desc: "You'll need your bank account details if you plan to set up a direct debit payment method with your new provider."
      }
    ],
    imgSrc: Member4Icon
  },
  {
    label: 'What are the key steps to switching your energy service?',
    title: 'What are the key steps to switching your energy service?',
    description:
      "Switching your energy service, whether it's electricity or gas, requires careful planning and following a set of key steps to ensure a smooth transition. Here are the key steps to switching your energy service:",
    numerate: true,
    list: [
      {
        title: 'Review Your Current Energy Plan:',
        desc: "Begin by examining your current energy plan, including the rates, contract terms, and any additional services you're receiving. Understand your current energy usage and billing history."
      },
      {
        title: 'Compare Energy Providers and Plans:',
        desc: 'Research and compare different energy providers and the plans they offer. Consider factors like pricing, contract length, renewable energy options, and customer reviews.'
      },
      {
        title: 'Check Contract End Date and Exit Fees:',
        desc: "Determine the end date of your current contract and any potential exit fees for early termination. It's essential to time your switch to minimize or avoid these charges."
      },
      {
        title: 'Calculate Your Energy Usage:',
        desc: 'Estimate your energy usage based on your past bills and current needs. This helps you select the right plan that matches your consumption.'
      },
      {
        title: 'Select a New Energy Provider:',
        desc: 'Choose the new energy provider and plan that best suits your needs. Contact the chosen provider to initiate the switch.'
      },
      {
        title: 'Provide Personal and Account Information:',
        desc: "When signing up with the new provider, you'll need to provide your personal information, such as your name, address, contact details, and energy account number."
      },
      {
        title: 'Specify the Switching Date:',
        desc: 'Coordinate with your new provider to set the switching date. Ensure it aligns with the end of your current contract or any notice period required by your current provider.'
      },
      {
        title: 'Review and Sign the Contract:',
        desc: 'Carefully review the terms and conditions of the new contract. Ensure you understand the pricing structure, billing, and any other important details before signing the contract.'
      },
      {
        title: 'Provide Meter Readings:',
        desc: 'On the switching date, take meter readings for both gas and electricity if applicable. This ensures an accurate final bill from your old provider and an accurate starting point for your new provider.'
      },
      {
        title: 'Set Up Payment Method:',
        desc: 'Choose a payment method, such as direct debit, and provide the necessary banking details to set up automatic payments with your new provider.'
      },
      {
        title: 'Monitor the Cooling-Off Period:',
        desc: 'You typically have a "cooling-off" period (often around 14 days) during which you can change your mind and cancel the switch without penalties.'
      },
      {
        title: 'Receive Confirmation and Documentation:',
        desc: 'Your new provider will send you confirmation of your switch, including details of your new plan, payment information, and the expected start date. Keep this documentation for your records.'
      },
      {
        title: 'Cancel Your Current Contract:',
        desc: 'Your new provider should cancel your contract with your previous supplier. Please confirm with your new supplier that they will cancel your previous contract on your behalf. Check to see if you are out of contract before cancelling with your previous supplier.'
      },
      {
        title: 'Monitor the Switch:',
        desc: 'On the switching date, keep an eye on your energy supply to ensure it transitions smoothly from your old provider to the new one.'
      },
      {
        title: 'Check Your Bills:',
        desc: 'Review your bills from both providers to ensure accuracy. Your final bill from your old provider should reflect any remaining charges or credits.'
      },
      {
        title: 'Update Your Information:',
        desc: 'Inform your new provider of any changes in your contact details or payment methods to ensure a seamless customer experience.'
      }
    ],
    description2:
      'Switching your energy service can usually happen quickly if you have all the correct information to hand. By following these steps, you can make the process as straightforward as possible and potentially save money on your energy costs.',
    imgSrc: Member5Icon
  }
];

export default energy;
