import React from 'react';

import Button from '@mui/material/Button';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';

import { Field } from 'redux-form';

export const Form = ({ handleSubmit }) => {
  const { defaultMessages, formatMessage } = useTranslationMessages();

  return (
    <form onSubmit={handleSubmit}>
      <div className='input-field ml-a mr-a'>
        <Field
          type='text'
          name='email'
          component='input'
          autoComplete='email'
          className='form-input with-border transparent'
          placeholder={formatMessage(defaultMessages.resendConfirmationPageEmailPlaceholder)}
          autoFocus
        />
      </div>
      <div className='d-flex align-items-center justify-content-center mt-30 pb-20'>
        <Button type='submit' variant='contained'>
          <span>{formatMessage(defaultMessages.resendConfirmationPageSend)}</span>
        </Button>
      </div>
    </form>
  );
};
