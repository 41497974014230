import React from 'react';
import cx from 'classnames';

import { Avatar } from '@packs/components/UI';
import ArrowBack from '@packs/images/icons/ArrowBack';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { pathGen } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';

import { WikiTableGroups } from './components/WikiTableGroups';

import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const WikiTableModal = ({ isOpen, toggle, productsModalOpen, modalProps: { company, backButton } }) => {
  const { toggleProductsModalOpen } = useModalsContext();
  const { currentCategory, countryCode } = useStore();
  const providerCompany = company?.providerCompany || company;

  const onShowProducts = () => {
    if (!productsModalOpen) {
      toggleProductsModalOpen({ modalProps: { company } });
    }
    toggle();
  };

  const onVisitCompanyPage = () => {
    handleVisitCompany(company, currentCategory);
    toggle();
  };

  return (
    <Modal {...{ isOpen, toggle }} className={cx('wiki-table-modal', currentCategory?.root?.code)}>
      <ModalHeader {...{ toggle }} charCode='x' />
      <ModalBody>
        {backButton && (
          <div className='wiki-back d-flex align-items-center justify-content-center' onClick={onShowProducts}>
            <ArrowBack />
          </div>
        )}
        <div className='company-section d-flex flex-column align-items-center'>
          <div className='mb-16'>
            <Avatar avatarUrl={providerCompany.avatarUrl} size={96} withBoxShadow square fullSize />
          </div>
          <Link
            className='fs-11 lh-9 secondary-text'
            to={pathGen('PRODUCT', {
              countryCode,
              category: currentCategory,
              company: providerCompany
            })}
            onClick={onVisitCompanyPage}
          >
            <>See Insights</>
          </Link>
          <div className='fw-700 lh-9 fs-28 mb-8'>{providerCompany?.name}</div>
        </div>
        <WikiTableGroups {...{ category: currentCategory, company }} />
      </ModalBody>
    </Modal>
  );
};
