import colors from '@packs/assets/theme/base/colors';

const { grey, blue } = colors;

export default () => ({
  wrapper: {
    borderRadius: '16px',
    py: 3,
    px: {
      xs: 2,
      sm: 3
    },
    backgroundColor: grey[200]
  },
  list: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      md: 'repeat(3, 1fr)'
    },
    gap: {
      xs: 2,
      md: 5
    }
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    left: 'calc(100% + 8px)',
    display: {
      xs: 'none',
      md: 'block'
    },
    transform: 'translateY(-50%)'
  }
});
