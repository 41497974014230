import { useEffect, useState } from 'react';

import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { businessTabs, feedbackTypesList, satisfactionOptions } from '@packs/lib/constants';
import { useStore } from '@packs/stores';

import camelcaseKeys from 'camelcase-keys';
import queryString from 'query-string';
import { useNavigate, useParams } from 'react-router-dom';

const sortOptions = [
  { value: 'most_recent', label: 'Most Recent' },
  { value: 'most_helpful', label: 'Most Helpful' },
  { value: 'most_commented', label: 'Most commented' }
];

export const useBusiness = ({ category = null, filter, IS_BUSINESS_PAGE }) => {
  const { insightsCompanyStore } = useStore();
  const { toggleThankModal, toggleBusinessVoteModal } = useModalsContext();
  const navigate = useNavigate();
  const { urlId, slag } = useParams();
  const { initialTab } = queryString.parse(location.search);

  const defaultTab = businessTabs.find(item => item.value === initialTab) || businessTabs[0];
  const [businessTab, setBusinessTab] = useState(defaultTab);
  const [orderColumn, setOrderColumn] = useState(sortOptions[0].value);
  const [feedbackType, setFeedbackType] = useState(feedbackTypesList[0].value);
  const [satisfaction, setSatisfaction] = useState(satisfactionOptions.map(({ value }) => value));
  const { token, voteModalIsOpen } = camelcaseKeys(queryString.parse(location.search));

  const company = insightsCompanyStore.object;
  const openVoteModal = token || voteModalIsOpen;

  const fetchInsights = () => {
    insightsCompanyStore.load({
      urlId,
      categoryId: IS_BUSINESS_PAGE ? null : category.id
    });
  };

  const handleVoteNowClick = () => {
    toggleBusinessVoteModal(
      {
        company,
        category: IS_BUSINESS_PAGE ? null : category,
        IS_BUSINESS_PAGE,
        fetchDetailsAndInsights
      },
      voteModalIsOpen
    );
  };

  const changeRateFilter = (value, single) => {
    let newSatisfaction;

    if (value === 'all_reviews') {
      newSatisfaction = [];
    } else if (single) {
      newSatisfaction = [value];
    } else {
      newSatisfaction = [...satisfaction];

      if (satisfaction.includes(value)) {
        newSatisfaction = newSatisfaction.filter(item => item !== value);
      } else {
        newSatisfaction.push(value);
      }
    }

    setSatisfaction(newSatisfaction);
  };

  const handleInsightsSwitcher = option => {
    setFeedbackType(option.value);
  };

  const fetchDetailsAndInsights = () => {
    fetchInsights();
  };

  useEffect(() => {
    fetchInsights();
    if ((location.search || '').match('vote-success=1')) {
      toggleThankModal();
      navigate(location.pathname);
    }
  }, [IS_BUSINESS_PAGE, urlId, slag]);

  useEffect(() => {
    if (openVoteModal && company?.id && !insightsCompanyStore.companyIsLoading) {
      handleVoteNowClick();
    }
  }, [company?.id]);

  return {
    satisfaction,
    businessTab,
    setBusinessTab,
    orderColumn,
    feedbackType,
    setOrderColumn,
    setFeedbackType,
    changeRateFilter,
    handleInsightsSwitcher,
    fetchDetailsAndInsights
  };
};
