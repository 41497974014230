import React, { FC } from 'react';
import { Field } from 'formik';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tab from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import colors from '@packs/assets/theme/base/colors';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { CompaniesAutocomplete } from '@packs/components/MaterialUi/CompaniesAutocomplete/CompaniesAutocomplete';
import { Switcher } from '@packs/components/Switcher';
import { IOption } from '@packs/interfaces/rootInterfaces';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';

import AnimateHeight from 'react-animate-height';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [['bold', 'italic', 'underline'], [{ align: [] }], ['link']]
};

const { dark, grey } = colors;

type Props = {
  field: { label: string; name: string; type: string; placeholder: string };
  setFieldValue: (name: string, value: any) => void;
  value: any;
  options: IOption[];
  onChange?: (name: string, value: any) => void;
  disabled?: boolean;
  disableClearable?: boolean;
  mobileSelect?: boolean;
  filter?: boolean;
  error?: string;
};

const FormField: FC<Props> = ({
  field,
  setFieldValue,
  value,
  options = [],
  onChange,
  disabled = false,
  disableClearable = false,
  error,
  ...rest
}) => {
  const { label, name, type, placeholder } = field;

  const Component = () => {
    if (type === 'hidden') {
      return (
        <Box sx={{ display: 'none' }}>
          <input type='hidden' {...rest} {...{ name, label, placeholder, value, options }} />
        </Box>
      );
    }

    if (type === 'select') {
      return (
        <AutocompleteMUI
          {...rest}
          {...{ name, label, placeholder, value, options, disableClearable }}
          disableCloseOnSelect={false}
          renderInput={params => <TextField {...params} label={label} />}
          onChange={value => {
            setFieldValue(name, value);
            onChange && onChange(name, value);
          }}
          mobileSelect
          filter
        />
      );
    }

    if (type === 'company') {
      return (
        <CompaniesAutocomplete
          {...rest}
          label={label}
          value={value}
          onSelect={data => !!data && setFieldValue(name, data)}
        />
      );
    }

    if (type === 'textEditor') {
      return (
        <ReactQuill
          width='100%'
          value={value}
          modules={modules}
          onChange={data => {
            setFieldValue(name, data);
          }}
          className='QuillWrapper'
        />
      );
    }

    if (type === 'radio') {
      return (
        <Box>
          {label && (
            <Typography variant='body2' color={dark.main}>
              {label}
            </Typography>
          )}
          <RadioGroup row aria-labelledby='buttons-group-label' name={name}>
            {options.map(item => {
              return (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio checked={item?.value === value?.value} />}
                  label={item.label}
                  sx={{
                    color: dark.main
                  }}
                  onChange={() => setFieldValue(name, item)}
                />
              );
            })}
          </RadioGroup>
        </Box>
      );
    }

    if (type === 'datePicker') {
      return (
        <Box>
          {label && (
            <Typography variant='body2' color={dark.main}>
              {label}
            </Typography>
          )}
          <DatePicker
            format='DD MMMM YYYY'
            label={label}
            value={value}
            onChange={value => setFieldValue(name, value)}
            sx={{ width: '100%' }}
          />
        </Box>
      );
    }

    if (type === 'switcher') {
      return (
        <Box>
          {label && (
            <Typography variant='body2' color={dark.main}>
              {label}
            </Typography>
          )}
          <Switcher name={name} value={value} onChange={() => setFieldValue(name, !value)} />
        </Box>
      );
    }

    if (type === 'rating') {
      return (
        <Box>
          {label && (
            <Typography variant='body2' color={dark.main}>
              {label}
            </Typography>
          )}
          <RatingMUI value={value} max={5} readOnly={false} onChange={data => setFieldValue(name, data)} {...rest} />
        </Box>
      );
    }

    if (type === 'tabs') {
      return (
        <Box
          sx={{
            '& .MuiTabs-root': {
              height: '56px',
              backgroundColor: grey[200]
            },
            '& .MuiButtonBase-root': {
              color: `${dark.main} !important`,
              cursor: disabled ? 'default' : 'pointer'
            }
          }}
        >
          {label && (
            <Typography variant='body2' color={dark.main}>
              {label}
            </Typography>
          )}
          <Tabs
            {...rest}
            value={value}
            onChange={(_, data) => !disabled && setFieldValue(name, data)}
            variant={'buttonTabs' as TabsProps['variant']}
          >
            {options.map(item => (
              <Tab key={item.value} value={item.value} label={item.label} />
            ))}
          </Tabs>
        </Box>
      );
    }

    return (
      <Field
        {...rest}
        {...{ name, label, placeholder, type, value }}
        as={TextField}
        fullWidth
        multiline={type === 'textArea'}
        maxRows={6}
      />
    );
  };

  return (
    <Box>
      {Component()}
      <AnimateHeight duration={250} height={error ? 'auto' : 0}>
        <Box mt={0.75}>
          <Typography component='div' variant='caption' color='error' fontWeight='regular' sx={{ textAlign: 'left' }}>
            {error}
          </Typography>
        </Box>
      </AnimateHeight>
    </Box>
  );
};

export default FormField;
