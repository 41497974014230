import React from 'react';

const TrashIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path d='M12.7587 7.24602C12.5 7.24602 12.2903 7.45569 12.2903 7.7144V16.5669C12.2903 16.8254 12.5 17.0352 12.7587 17.0352C13.0174 17.0352 13.227 16.8254 13.227 16.5669V7.7144C13.227 7.45569 13.0174 7.24602 12.7587 7.24602Z' />
        <path d='M7.23181 7.24602C6.9731 7.24602 6.76343 7.45569 6.76343 7.7144V16.5669C6.76343 16.8254 6.9731 17.0352 7.23181 17.0352C7.49052 17.0352 7.7002 16.8254 7.7002 16.5669V7.7144C7.7002 7.45569 7.49052 7.24602 7.23181 7.24602Z' />
        <path d='M3.20357 5.95413V17.4941C3.20357 18.1762 3.45368 18.8167 3.8906 19.2763C4.3255 19.7372 4.93073 19.9988 5.56415 19.9999H14.4261C15.0597 19.9988 15.6649 19.7372 16.0996 19.2763C16.5366 18.8167 16.7867 18.1762 16.7867 17.4941V5.95413C17.6552 5.7236 18.218 4.88453 18.1018 3.99333C17.9854 3.1023 17.2263 2.43577 16.3276 2.43559H13.9295V1.85011C13.9323 1.35776 13.7376 0.884987 13.3891 0.537177C13.0405 0.189549 12.567 -0.00402466 12.0747 5.06472e-07H7.91557C7.42322 -0.00402466 6.94972 0.189549 6.60118 0.537177C6.25263 0.884987 6.05796 1.35776 6.06071 1.85011V2.43559H3.66263C2.76392 2.43577 2.00481 3.1023 1.88844 3.99333C1.77226 4.88453 2.33505 5.7236 3.20357 5.95413ZM14.4261 19.0632H5.56415C4.76332 19.0632 4.14034 18.3752 4.14034 17.4941V5.9953H15.8499V17.4941C15.8499 18.3752 15.2269 19.0632 14.4261 19.0632ZM6.99747 1.85011C6.99436 1.60622 7.09023 1.37148 7.26332 1.19932C7.43621 1.02715 7.6715 0.932557 7.91557 0.936766H12.0747C12.3187 0.932557 12.554 1.02715 12.7269 1.19932C12.9 1.3713 12.9959 1.60622 12.9928 1.85011V2.43559H6.99747V1.85011ZM3.66263 3.37235H16.3276C16.7933 3.37235 17.1707 3.74981 17.1707 4.21544C17.1707 4.68108 16.7933 5.05853 16.3276 5.05853H3.66263C3.19699 5.05853 2.81954 4.68108 2.81954 4.21544C2.81954 3.74981 3.19699 3.37235 3.66263 3.37235Z' />
        <path d='M9.99524 7.24602C9.73653 7.24602 9.52686 7.45569 9.52686 7.7144V16.5669C9.52686 16.8254 9.73653 17.0352 9.99524 17.0352C10.2539 17.0352 10.4636 16.8254 10.4636 16.5669V7.7144C10.4636 7.45569 10.2539 7.24602 9.99524 7.24602Z' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashIcon;
