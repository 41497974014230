import { MINIMUM_PASSWORD_CHARACTERS } from '@packs/lib/constants';

import Form from './form';

import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const validate = ({ email, website, password, country, state, displayName, businessName, registerBusiness }) => {
  const errors = {};
  if (!email) errors.email = 'Required';
  if (!password) {
    errors.password = 'Required';
  } else if (password.length < MINIMUM_PASSWORD_CHARACTERS) {
    errors.password = `Should me more or equal than ${MINIMUM_PASSWORD_CHARACTERS}`;
  }
  if (!displayName) errors.displayName = 'Required';
  if (!country) errors.country = 'Required';
  if (!state) errors.state = 'Required';

  return errors;
};

const createReduxForm = reduxForm({ form: 'invitationAccept', validate });
const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

export default createReduxForm(connect(mapStateToProps)(Form));
