import React from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Box from '@mui/material/Box';
import { FiltersDrawer } from '@packs/components/MaterialUi/FiltersDrawer/FiltersDrawer';
import { SearchCompanies } from '@packs/components/UI';
import { useDetails } from '@packs/models/policy/useDetails';
import { MyResultsFilters } from '@packs/screens/MaterialUi/MyResults/components/MyResultsFilters';
import { useStore } from '@packs/stores';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

export const MyResultsTopBar = observer(({ disabledResetFilters, handleResetCompanyListFilters }) => {
  const { isDesktop } = useWindowSize();
  const styles = useStyles();
  const { companiesStore } = useStore();
  const { currentPolicy } = useDetails();

  return (
    <Box>
      {currentPolicy?.id && (
        <Box sx={styles.searchFilterWrapper}>
          {!isDesktop && (
            <Box sx={styles.actionSection}>
              <SearchCompanies width='100%' />
            </Box>
          )}
          {!isDesktop && (
            <Box sx={styles.actionSectionWrapper}>
              <Box sx={styles.actionSection}>
                <FiltersDrawer
                  buttonProps={{
                    variant: 'filledTonal',
                    color: 'primary',
                    startIcon: <FilterAltIcon />,
                    sx: { width: { xs: '100%', lg: 'auto' } }
                  }}
                  handleResetFilters={handleResetCompanyListFilters}
                  disabledResetFilters={disabledResetFilters}
                  showResultText={`Show ${companiesStore?.total} business`}
                >
                  <MyResultsFilters />
                </FiltersDrawer>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});
