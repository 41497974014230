import { grey } from '@mui/material/colors';
import colors from '@packs/assets/theme/base/colors';
import typography from '@packs/assets/theme/base/typography';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { white, text, primary, secondary, orange, dark } = colors;
const { size } = typography;

const contained = {
  base: {
    backgroundColor: white.main,
    minHeight: pxToRem(40),
    padding: `${pxToRem(10)} ${pxToRem(24)}`,
    boxShadow: 'none !important',

    '&:active, &:active:focus, &:active:hover': {
      opacity: 1
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  xs: {
    minHeight: pxToRem(24),
    padding: `${pxToRem(4)}`,
    fontSize: `${pxToRem(13)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(13)} !important`
    }
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: `${pxToRem(13)}`,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  smallToMedium: {
    minHeight: pxToRem(40),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`
    }
  },

  medium: {
    minHeight: pxToRem(48),
    padding: `${pxToRem(12)} ${pxToRem(22)}`,
    fontSize: pxToRem(16),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  large: {
    minHeight: pxToRem(53),
    padding: `${pxToRem(14)} ${pxToRem(24)}`,
    fontSize: pxToRem(18),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  xl: {
    minHeight: pxToRem(64),
    padding: `${pxToRem(16)} ${pxToRem(28)}`,
    fontSize: pxToRem(18),

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`
    }
  },

  primary: {
    backgroundColor: primary.main,

    '&:hover': {
      color: white.main
    }
  },

  secondary: {
    backgroundColor: secondary.main,

    '&:hover': {
      color: white.main
    }
  },

  orange: {
    backgroundColor: orange.main,

    '&:hover': {
      color: white.main
    }
  },

  dark: {
    backgroundColor: grey[100],
    color: white.main
  },

  white: {
    color: dark.main
  }
};

export default contained;
