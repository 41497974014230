import React from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { getCurrency, numberWithCommas } from '@packs/lib/constants';
import { isCompanyPerMonth } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

const { text } = colors;

const calcPrice = (isPerCategoryPerMonth, isPerMonth, price) => {
  if (isPerCategoryPerMonth) {
    return isPerMonth ? price || 0 : (price || 0) * 12;
  }

  return isPerMonth ? price / 12 : price || 0;
};

export const Price = ({ company, category, extended = false, selectedCost }) => {
  const { isMobile } = useWindowSize();
  const { countryCode } = useStore();
  const isPerCategoryPerMonth = isCompanyPerMonth(category);
  const isPerMonth = selectedCost?.value === 'renewalCostMonthly';
  const isEnergyCategory = category?.root?.code === 'energy';
  const wikiTable = company?.wikiTable;
  const currency = getCurrency(countryCode);
  const isDynamicPrice = ['loans', 'mortgages'].includes(category.code);

  if (!wikiTable) {
    return null;
  }

  const RateRender = ({ value, kind }) => {
    return (
      <div className={cx('price-data', isMobile && 'd-flex flex-column align-items-center')}>
        {extended && <div className='fs-11 fw-500 lh-5 secondary-text mb-4 tt-uc'>Typical {kind}:</div>}
        <div className='fs-23 fw-700 lh-9'>{value}%</div>
      </div>
    );
  };

  const PlanPriceRender = ({ value }) => {
    if (!value) {
      return null;
    }

    return (
      <Box sx={{ textAlign: { md: 'right' } }}>
        <Typography variant='body3' sx={{ textAlign: { md: 'right' } }}>
          {`${currency + value} ${
            isDynamicPrice ? (isPerMonth ? 'p/m' : 'p/y') : wikiTable ? (isPerMonth ? 'p/m' : 'p/y') : ''
          }`}
        </Typography>
        <>
          <Box>
            <Typography variant='body5' sx={{ mt: 1, color: text.secondary, textAlign: { md: 'right' } }}>
              {isDynamicPrice
                ? 'Repayment Amount'
                : isEnergyCategory
                ? `Estimated ${isPerMonth ? 'monthly' : 'annual'} bill`
                : ''}
            </Typography>
          </Box>
        </>
      </Box>
    );
  };

  if (isDynamicPrice) {
    return (
      <PlanPriceRender
        value={
          wikiTable?.variablePrice
            ? numberWithCommas(calcPrice(isPerCategoryPerMonth, isPerMonth, wikiTable?.variablePrice).toFixed(2))
            : null
        }
      />
    );
  } else if (wikiTable?.aerRate || wikiTable?.aprRate) {
    const kind = wikiTable?.aerRate ? 'AER' : 'APR';
    const value = wikiTable?.aerRate || wikiTable?.aprRate || null;

    return (
      <RateRender
        kind={kind}
        value={value ? numberWithCommas(calcPrice(isPerCategoryPerMonth, isPerMonth, value).toFixed(2)) : null}
      />
    );
  }

  return (
    <PlanPriceRender
      value={
        wikiTable?.planPrice
          ? numberWithCommas(calcPrice(isPerCategoryPerMonth, isPerMonth, wikiTable?.planPrice).toFixed(2))
          : null
      }
    />
  );
};
