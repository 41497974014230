import React from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CopyLink from '@packs/images/icons/CopyLink';
import Facebook from '@packs/images/icons/Facebook/FaceBook';
import LinkedIn from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';
import WhatsApp from '@packs/images/icons/WhatsApp';
import { toastr } from '@packs/lib/helpers';
import shareUseStyles from '@packs/styles/ShareSocial/shareSocial';

import useStyles from './styles';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

export const ShareSocialModal = ({ isOpen, toggle, modalProps: { category, shareLink } }) => {
  const styles = useStyles();
  const stylesShare = shareUseStyles();

  const inviteMessage = `Invite your family & friends to the ${category?.name} community on Bill Winner! Together we are stronger!`;
  const href = shareLink ? shareLink : window?.location?.href;

  const handleClose = () => {
    toggle();
  };

  const onCopyLink = () => {
    navigator.clipboard
      .writeText(href)
      .then(() => {
        toastr.success('Successfully copied');
      })
      .catch(err => {
        // Unable to copy text to the clipboard
        console.error('Error copying text to clipboard:', err);
      });
  };

  return (
    <Dialog open={!!isOpen} onClose={handleClose}>
      <DialogTitle sx={styles.modalTitle}>
        Share with friends
        <IconButton edge='end' color='inherit' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1' sx={styles.content}>
          {inviteMessage}
        </Typography>
      </DialogContent>
      <Divider />
      <DialogContent>
        <Box sx={stylesShare.buttonsContainer}>
          <FacebookShareButton url={href} quote={inviteMessage} className='social-link'>
            <Box sx={stylesShare.socialIcon} className='facebook'>
              <Facebook />
            </Box>
            <Box sx={stylesShare.socialName}>Facebook</Box>
          </FacebookShareButton>
          <TwitterShareButton url={href} title={inviteMessage} className='social-link'>
            <Box sx={stylesShare.socialIcon} className='twitter'>
              <Twitter />
            </Box>
            <Box sx={stylesShare.socialName}>X</Box>
          </TwitterShareButton>
          <LinkedinShareButton url={href} title={inviteMessage} className='social-link'>
            <Box sx={stylesShare.socialIcon} className='linkedin'>
              <LinkedIn />
            </Box>
            <Box sx={stylesShare.socialName}>Linkedin</Box>
          </LinkedinShareButton>
          <WhatsappShareButton url={href} title={inviteMessage} className='social-link'>
            <Box sx={stylesShare.socialIcon} className='whatsApp'>
              <WhatsApp />
            </Box>
            <Box sx={stylesShare.socialName}>Whatsapp</Box>
          </WhatsappShareButton>
          <Box onClick={onCopyLink} className='social-link'>
            <Box sx={stylesShare.socialIcon} className='copyLink'>
              <CopyLink />
            </Box>
            <Box sx={stylesShare.socialName}>Copy link</Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
