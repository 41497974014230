import React from 'react';
import cx from 'classnames';

import { useUserSelectors } from '@packs/hooks/useUserSelectors';
// Images
import EmailIcon from '@packs/images/icons/EmailIcon';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { useWindowSize } from '@shared/hooks/useWindowSize';

export const ConfirmEmail = () => {
  const { currentUser } = useUserSelectors();
  const { toggleResendConfirmationModal } = useModalsContext();
  const { isMobile } = useWindowSize();

  if (currentUser.id && !currentUser.isConfirmed) {
    return (
      <div
        className={cx(
          'confirm-email-wrapper d-flex align-items-center justify-content-between mb-15',
          isMobile && 'flex-column text-center'
        )}
      >
        <div className={cx('d-flex align-items-center', isMobile && 'flex-column mb-30')}>
          <div
            className={cx('email-icon-holder d-flex align-items-center justify-content-center', isMobile && 'mb-20')}
          >
            <EmailIcon />
          </div>
          <div className='fs-17 fw-600 ml-25'>Please confirm your email to activate your account!</div>
        </div>

        <div className={cx('d-flex flex-column', !isMobile ? 'align-items-end text-right' : 'align-items-center')}>
          <div className='fs-14'>We have sent you an email notification!</div>
          <div className='resend-btn fs-12 fw-600' onClick={toggleResendConfirmationModal}>
            Resend a mail
          </div>
        </div>
      </div>
    );
  }

  return false;
};
