import React from 'react';

import { Box, Button } from '@mui/material';

import useStyles from './styles';

const Tabs = ({ options, activeTab, onChange, stretch = false }) => {
  const styles = useStyles();

  return (
    <Box sx={styles.typeSwitcherWrapper}>
      {options.map(({ label, value }, index) => (
        <Button
          key={index}
          type='button'
          variant='text'
          size='smallToMedium'
          color='dark'
          onClick={() => onChange(value)}
          fullWidth={stretch}
          sx={{ ...(value === activeTab ? styles.activeTypeSwitcher : {}) }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

export default Tabs;
