import colors from '@packs/assets/theme/base/colors';

const { grey, statuses } = colors;

export default () => ({
  statusWrapper: status => ({
    width: '114px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    px: 2,
    backgroundColor: status === 'viewed' ? statuses.active : grey[200],
    color: status === 'viewed' ? '#2E7D32' : 'rgba(0, 0, 0, 0.38)',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '700'
  })
});
