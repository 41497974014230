import React from 'react';

import Box from '@mui/material/Box';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const OfferItemSkeleton = () => {
  return (
    <SkeletonTheme color='#E4EEFA' highlightColor='#fff'>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '350px' }}>
        <Box sx={{ mb: 2 }}>
          <Skeleton count={1} height={125} width='100%' />
        </Box>
        <Box sx={{ mb: 1 }}>
          <Skeleton count={1} height={36} width={110} />
        </Box>
        <Box sx={{ mb: 1 }}>
          <Skeleton count={1} height={40} width='100%' />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Skeleton count={1} height={40} width='100%' />
        </Box>
        <Box>
          <Skeleton count={1} height={36} width='100%' />
        </Box>
      </Box>
    </SkeletonTheme>
  );
};
