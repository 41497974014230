import Box from '@mui/material/Box';

import useStyles from './styles';

const statuses = {
  viewed: 'Viewed',
  not_viewed: 'Not viewed'
};

export const QuoteRequestStatus = ({ status = 'not_viewed' }) => {
  const styles = useStyles();

  return <Box sx={styles.statusWrapper(status)}>{statuses[status]}</Box>;
};
