const tableCell = {
  styleOverrides: {
    columnSeparator: {
      display: 'none'
    },
    cell: {
      '&:focus': {
        outline: 'none'
      },
      '&:focus-within': {
        outline: 'none'
      }
    },
    columnHeader: {
      '&:focus': {
        outline: 'none'
      },
      '&:focus-within': {
        outline: 'none'
      }
    }
  }
};

export default tableCell;
