import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import { useModalsContext } from '../../../../layouts/AppLayer/context';
import { useDetails } from '../../../../models/policy/useDetails';

import useStyles from './styles';

export const LeaderboardVotedSection = () => {
  const { toggleWinnerModal } = useModalsContext();

  const onPickWinner = () => {
    toggleWinnerModal({ modalProps: {} });
  };

  const styles = useStyles();

  return (
    <Zoom timeout={500} in={true} style={{ transitionDelay: `${6.5 * 200}ms` }}>
      <Box sx={styles.wrapper}>
        <Box>
          <Typography variant='h6' sx={{ mb: 1 }}>
            Don't forget to vote for your winning business!
          </Typography>
          <Typography variant='body3'>
            Vote for your chosen business to keep your details up to date, to help members like you and to win rewards!
          </Typography>
        </Box>
        <Box>
          <Button variant='contained' color='secondary' sx={{ whiteSpace: 'nowrap' }} onClick={onPickWinner}>
            Pick your winner!
          </Button>
        </Box>
      </Box>
    </Zoom>
  );
};
