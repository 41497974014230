import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useStore } from '@packs/stores/base';

import useStyles from './styles';

import Flag from 'react-world-flags';

export const PoweredBlock = ({ currentCategory, variant = 'subtitle1' }) => {
  const styles = useStyles();
  const { currentCountry } = useStore();
  const countryCode = currentCountry?.isoA2Code;

  return (
    <Typography variant={variant}>
      {`Powered by the ${currentCategory?.name?.toLowerCase()} community in`}
      <Box sx={styles.formFooterFlag}>
        <Flag code={countryCode === 'uk' ? 'gb' : countryCode} />
      </Box>
      {currentCountry?.name}
    </Typography>
  );
};
