import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import OptionIcon from '@packs/images/svg/ui/purchase-option-icon.svg';
import OptionIcon2 from '@packs/images/svg/ui/purchase-option-icon-2.svg';
import OptionIcon3 from '@packs/images/svg/ui/purchase-option-icon-3.svg';
import PurchaseIcon from '@packs/images/ui/landing/purchase.webp';
import { IconBox } from '@shared/components/IconBox/IconBox';

import useStyles from './styles';

import size from 'lodash-es/size';

const { dark } = colors;

export const Purchase = ({ options = [], title = '', description = '', categoryName = '' }) => {
  const styles = useStyles();

  const sectionTitle = title || `Join our community to make smarter decisions on your ${categoryName}!`;
  const sectionOptions = size(options)
    ? options
    : [
        {
          icon: OptionIcon,
          title: `Our ${categoryName} leaderboard is powered by people in Ireland!`,
          description: `Our ${categoryName} leaderboard enables our members to make more informed & trusted decisions through the votes and insights from other drivers!`
        },
        {
          icon: OptionIcon2,
          title: `Get support and insights from our ${categoryName} community!`,
          description:
            'Our members can seek support and insights from other members and also from top voted experts from our business community!'
        },
        {
          icon: OptionIcon3,
          title: `Get all the best offers for ${categoryName} in one place!`,
          description: `Bill Winner brings all the best offers for ${categoryName} in one place so that our members can easily compare the best deals!`
        }
      ];

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Typography variant='h2' textAlign='center' sx={styles.title}>
          {sectionTitle}
        </Typography>
        <Box sx={styles.content}>
          <Box flexGrow={1} sx={styles.leftContent}>
            {description ? (
              <Typography variant='body1' mb={4} dangerouslySetInnerHTML={{ __html: description }} />
            ) : (
              <Box sx={{ mb: 4 }}>
                <Typography variant='h5' sx={{ mb: 2 }}>
                  The benefits for our members!
                </Typography>
                <Typography variant='body1'>
                  Bill Winner is a trusted transparent platform powered by our community! Some of the benefits for
                  members include;
                </Typography>
              </Box>
            )}

            <Stack direction='column' spacing={5}>
              {sectionOptions.map((option, index) => (
                <Stack key={option.title + index} direction='row' alignItems='flex-start' spacing={2}>
                  <IconBox
                    containerSize='56px'
                    iconSize='32px'
                    icon={<img src={option.icon || OptionIcon} alt='Option' />}
                    backgroundColor={dark.transparent}
                    borderRadius='8px'
                  />
                  <Box>
                    <Typography variant='h6' mb={1}>
                      {option.title}
                    </Typography>
                    <Typography variant='body1' dangerouslySetInnerHTML={{ __html: option?.description }} />
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Box>
          <Box sx={styles.rightContent}>
            <img src={PurchaseIcon} alt='Purchase' />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
