import { NoteForm as NoteFormC } from './NoteForm';

import { reduxForm } from 'redux-form';

const validate = ({ title, categoryId }) => {
  const errors = {};

  if (!title) {
    errors.title = 'Title is required';
  }

  if (!categoryId) {
    errors.categoryId = 'Category is required';
  }

  return errors;
};

const createReduxForm = reduxForm({ form: 'createDoc', validate });

export const NoteForm = createReduxForm(NoteFormC);
