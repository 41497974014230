import React from 'react';
import { size } from 'lodash-es';

import Box from '@mui/material/Box';
import { OfferItem } from '@packs/components/MaterialUi/Offers/OfferItem/OfferItem';
import { OfferItemSkeleton } from '@packs/components/MaterialUi/Offers/OfferItemSkeleton/OfferItemSkeleton';
import { OFFERS_PER_PAGE } from '@packs/models/offer/constants';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { Pagination } from '@shared/components/Pagination/Pagination';

import useStyles from './styles';

import { observer } from 'mobx-react';

export const OffersList = observer(({ openFilters, withOutCategory }) => {
  const { offersStore } = useStore();
  const styles = useStyles();

  const offers = offersStore?.offers;
  const totalCount = offersStore?.totalCount;
  const loader = offersStore?.loader;
  const filters = offersStore?.filters;
  const count = Math.ceil(totalCount / filters.perPage);

  const handlePagination = page => {
    offersStore.load({ page }, false);
  };

  if (!loader && size(offers) === 0) {
    return <EmptyPlaceholder description='Special offers are coming soon!' />;
  }

  if (loader) {
    return (
      <Box sx={styles.offersList(openFilters)}>
        {[...Array(OFFERS_PER_PAGE)].map(item => (
          <OfferItemSkeleton key={item} />
        ))}
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.offersList(openFilters)}>
        {offers.map((offer, index) => (
          <OfferItem
            key={offer.id}
            index={index}
            offer={offer}
            darkMode
            isSliderType={false}
            withOutCategory={withOutCategory}
          />
        ))}
      </Box>
      <Pagination count={count} page={filters.page} onChange={handlePagination} />
    </>
  );
});
