import React from 'react';

import Box from '@mui/material/Box';
import { Questions } from '@packs/components/UI';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { websitePageUrl } from '@shared/helpers/urlHelper';

import useStyles from './styles';

import { Helmet } from 'react-helmet';

const QAGeneral = () => {
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  const { currentCategory } = useStore();
  const styles = useStyles();

  return (
    <>
      <Helmet>
        <link rel='canonical' href={websitePageUrl() + DASHBOARD_MY_RESULTS_PATH.replace('/', '')} />
      </Helmet>
      <Box sx={styles.wrapper}>
        <Questions category={currentCategory} showAllTypes withSideBar withOutCreate isSingleButton />
      </Box>
    </>
  );
};

export default QAGeneral;
