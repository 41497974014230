import React from 'react';

// Components
import Chart from 'react-apexcharts';

const colors = ['#FFCC52', '#00D3B0'];

export const BarChart = ({ category, userData, membersData }) => {
  const userPrice = userData[1];
  const membersPrice = membersData[1];

  const data = [
    {
      value: userPrice,
      label: 'Your price'
    },
    {
      value: membersPrice,
      label: `Average price for members for ${category?.name}`
    }
  ];

  const biggerData = (a, b) => {
    if (a === 0 && b === 0) {
      return 100;
    }

    const bigger = a > b ? a : b;

    return bigger + (bigger / 100) * 10;
  };

  const annotation = {
    text:
      userPrice < membersPrice
        ? 'You are paying above bellow average compared to other members!'
        : 'You are paying  average compared to other members!',
    color: userPrice < membersPrice ? '#3FB0A7' : '#E53E4D',
    background: userPrice < membersPrice ? '#F5FBFB' : '#FEF5F6'
  };

  const chartData = {
    series: [
      {
        name: '',
        data: data.map(item => item.value)
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        animations: {
          enabled: true,
          speed: 600
        },
        toolbar: { show: false }
      },
      annotations: {
        points: [
          {
            x: data[0].label,
            seriesIndex: 0,
            marker: { size: 0 },
            label: {
              offsetY: -10,
              borderWidth: 0,
              borderRadius: 8,
              style: {
                color: annotation.color,
                background: annotation.background,
                cssClass: 'apexcharts-point-annotation-label',
                padding: {
                  left: 15,
                  right: 15,
                  top: 12,
                  bottom: 12,
                  lineHeight: 20,
                  fontSize: '12px',
                  fontWeight: 500
                }
              },
              text: annotation.text
            }
          }
        ]
      },
      colors,
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.9
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'darken',
            value: 0.9
          }
        },
        onItemClick: { toggleDataSeries: true }
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
          horizontal: false,
          distributed: true,
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
          stroke: { width: 2 }
        }
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontWeight: 500,
        itemMargin: {
          horizontal: 20,
          vertical: 0
        },
        markers: {
          width: 14,
          height: 14,
          radius: 0
        }
      },
      dataLabels: { enabled: false },
      tooltip: {
        enabled: true,
        theme: 'dark',
        onDatasetHover: { highlightDataSeries: false }
      },
      stroke: { width: 2 },
      xaxis: {
        labels: { rotate: -45 },
        categories: data.map(item => item.label)
      },
      yaxis: {
        min: 0,
        max: biggerData(userPrice, membersPrice)
      },
      responsive: [
        {
          breakpoint: 768,
          options: { chart: { width: '100%', height: '450px' } }
        }
      ]
    }
  };

  return (
    <div className='price-wrapper'>
      <Chart series={chartData.series} options={chartData.options} type='bar' />
    </div>
  );
};
