import React from 'react';

const UserPlusIcon = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='11.9998'
        cy='8.7486'
        r='4.25177'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.99609 20.5036C3.99609 18.0175 6.01193 16.0017 8.49797 16.0017H11.082'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5014 21.0041V16.0013'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15 18.5027H20.0027' stroke='#6E7F95' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default UserPlusIcon;
