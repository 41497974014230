import React from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';

import useStyles from '../../../../styles';

import { Field } from 'redux-form';

const Form = ({ handleSubmit, inProcess }) => {
  const styles = useStyles();

  return (
    <Box component='form' onSubmit={handleSubmit} sx={styles.partnerForm}>
      <Field type='text' component={FieldWithErrors} name='name' placeholder='Name' material variant='outlined' />
      <Field
        type='text'
        component={FieldWithErrors}
        name='associationName'
        placeholder='Association name'
        material
        variant='outlined'
      />
      <Field type='text' component={FieldWithErrors} name='email' placeholder='Email' material variant='outlined' />
      <Field
        component={FieldWithErrors}
        componentType='textarea'
        name='body'
        placeholder='Your message…'
        material
        variant='outlined'
      />
      <div className='btn-container d-flex align-items-center justify-content-center'>
        <Button type='submit' variant='contained' disabled={inProcess}>
          Submit
        </Button>
      </div>
    </Box>
  );
};

export default Form;
