import React, { useState } from 'react';

import { Box, Button } from '@mui/material';
import { FieldWithErrors } from '@packs/components';
import useStyles from '@packs/layouts/AppLayer/components/AuthModal/styles';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { useStore } from '@packs/stores';

import { Field, reduxForm } from 'redux-form';

const validate = ({ email }) => {
  const errors = {
    email: ''
  };
  if (!email) {
    errors.email = 'Required';
  }
  return errors;
};

const createReduxForm = reduxForm({ form: 'forgotPassword', validate });

const Form = createReduxForm(({ inProcess, handleSubmit }) => {
  const styles = useStyles();
  const { defaultMessages, formatMessage } = useTranslationMessages();

  return (
    <form className='d-flex flex-column align-items-center' onSubmit={handleSubmit}>
      <Box sx={styles.fieldsList}>
        <Field
          name='email'
          component={FieldWithErrors}
          type='text'
          label='Email'
          disableFocusReset
          material
          variant='outlined'
        />
      </Box>
      <Box sx={styles.btnContainer}>
        <Button type='submit' variant='contained' size='large' fullWidth disabled={inProcess}>
          {formatMessage(defaultMessages.forgotPasswordPageSend)}
        </Button>
      </Box>
    </form>
  );
});
const ForgotPasswordForm = ({ handleSubmit }) => {
  const [inProcess, setInProcess] = useState(false);
  const { userStore } = useStore();

  const onSubmit = data => {
    const { email } = data;
    const callback = () => {
      setInProcess(false);
      handleSubmit();
    };

    const errorCallback = () => {
      setInProcess(false);
    };
    setInProcess(true);
    userStore.usersResetPassword({
      email,
      callback,
      errorCallback
    });
  };

  return <Form onSubmit={onSubmit} inProcess={inProcess} />;
};

export default ForgotPasswordForm;
