export default () => ({
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },
  ratingItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gpa: 1
  },
  ratingSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  }
});
