import { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';

const { grey } = colors;
export const LearnMore = ({ title, description }) => {
  const blockRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [isLongText, setIsLongText] = useState(false);

  useEffect(() => {
    const blockHeight = blockRef.current.clientHeight;
    setIsLongText(blockHeight >= 388);
  }, []);

  const handleShowFullText = () => {
    setShowMore(!showMore);
  };

  return (
    <Box sx={{ backgroundColor: grey[100] }}>
      <Box
        width='100%'
        maxWidth='966px'
        mx='auto'
        px={2}
        sx={{
          pt: {
            xs: 6,
            lg: 10
          },
          pb: {
            xs: 6,
            lg: '196px'
          }
        }}
      >
        <Typography variant='h3' mb={{ xs: 3, lg: 5 }} textAlign='center'>
          {title}
        </Typography>
        <Typography
          ref={blockRef}
          variant='body1'
          mb={{ xs: 0, lg: 5 }}
          sx={{
            position: 'relative',
            maxHeight: showMore ? 'none' : '388px',
            overflow: 'hidden',
            '&:after': {
              content: isLongText && !showMore ? '""' : 'none',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '91px',
              backgroundImage: 'linear-gradient(180deg, rgba(245, 245, 245, 0.00) 0%, #F5F5F5 100%)',
              zIndex: 3
            }
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {isLongText && !showMore && (
          <Button variant='text' color='primary' onClick={handleShowFullText}>
            Read Completely
          </Button>
        )}
      </Box>
    </Box>
  );
};
