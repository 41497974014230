import React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import { ReminderList } from '@packs/components/Calendar/components/ReminderList';
import ArrowDownIcon from '@packs/images/svg/ui/arrow-down-gray.svg';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import AnimateHeight from 'react-animate-height';

export const TopRemindersList = ({
  list,
  showReminders,
  handleHideAll,
  handleShowMore,
  eventsLimit,
  eventsLimitInitial,
  toggleShowReminders,
  onCalendarDeleteReminder,
  onCalendarEditReminder
}) => {
  const allEventsVisible = eventsLimit >= list.length;
  const { isMobile } = useWindowSize();

  if (!_.size(list)) {
    return null;
  }

  return (
    <div className='reminders-list-wrapper'>
      <div
        className='profile-title lh-5'
        onClick={() => {
          if (isMobile) {
            toggleShowReminders();
          }
        }}
      >
        My Pending reminders {_.size(list)}
        {isMobile && (
          <div className={cx('arrow', { active: showReminders })}>
            <img src={ArrowDownIcon} alt='arrow' />
          </div>
        )}
      </div>
      <AnimateHeight duration={250} height={showReminders || !isMobile ? 'auto' : 0}>
        <ReminderList reminders={list} {...{ onCalendarDeleteReminder, onCalendarEditReminder }} />

        {_.size(list) > eventsLimitInitial && (
          <div className='more-reminders-container d-flex'>
            <span onClick={() => (allEventsVisible ? handleHideAll() : handleShowMore())}>
              {allEventsVisible ? 'Hide all Reminders' : 'More Reminders'}
            </span>
          </div>
        )}
      </AnimateHeight>
    </div>
  );
};
