import React from 'react';
import cx from 'classnames';

import { useCategories } from '@packs/models/category/useCategories';
import PersonalizeResults from '@packs/screens/PersonalizeResults/RenewalDetailsFormWithHooks';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Modal, ModalBody } from 'reactstrap';

export const PersonalizationFlowModal = ({ toggle, isOpen }) => {
  const { policiesListStore, companiesStore } = useStore();
  const { currentCategory } = useCategories();
  const { isDesktop } = useWindowSize();

  const successSubmitCallback = () => {
    toggle();
    policiesListStore
      .loadPolicyData({
        categoryId: currentCategory?.id
      })
      .then(() => {
        if (!isDesktop) {
          companiesStore.resetInfinityList();
        }
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={cx('personalization-flow-modal', currentCategory.root.enName)}>
      <ModalBody>
        <PersonalizeResults {...{ successSubmitCallback }} handleCloseModal={toggle} simpleForm inModalFrame />
      </ModalBody>
    </Modal>
  );
};
