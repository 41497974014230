import colors from '@packs/assets/theme/base/colors';

const { text, borderColor } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mb: 3
  },
  requestItem: {
    borderRadius: '16px',
    border: `2px solid ${borderColor.main}`,
    p: 2,
    backgroundColor: '#fff'
  },
  topSectionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1
  },
  companyWrapper: {
    display: 'flex',
    gap: 2
  },
  nameStatusWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5
  },
  policyWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1
  }
});
