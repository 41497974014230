import Form from './form';

import { reduxForm } from 'redux-form';

const validate = ({ email }) => {
  const errors = {};
  if (!email) errors.email = 'Required';
  return errors;
};

const createReduxForm = reduxForm({ form: 'resendUnlock', validate });

export default createReduxForm(Form);
