import { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import companyApi from '@packs/apis/company';
import { BadgesEmpty } from '@packs/components/MaterialUi/Badges/BadgesGroupList/components/BadgesEmpty/BadgesEmpty';
import { BadgesSkeleton } from '@packs/components/MaterialUi/Badges/BadgesGroupList/components/BadgesSkeleton/BadgesSkeleton';
import { BadgesSummary } from '@packs/components/MaterialUi/Badges/BadgesGroupList/components/BadgesSummary/BadgesSummary';
import { BadgesList } from '@packs/components/MaterialUi/Badges/BadgesGroupList/components/DadgesList/DadgesList';
import { IBadge } from '@packs/interfaces/rootInterfaces';

import map from 'lodash-es/map';

interface YearData {
  categories: number[];
  seasons: Record<string, IBadge[]>;
}

export const BadgesGroupList = ({ companyId, categoryId, company }) => {
  const [badges, setBadges] = useState<IBadge[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await companyApi.fetchAllBadges({ companyId, categoryId });
    if (res) {
      setBadges(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (companyId) {
      fetchData();
    }
  }, [companyId, categoryId]);

  const groupedBadges = useMemo(() => {
    return badges.reduce<Record<number, YearData>>((acc, item) => {
      const { year, categoryId, season } = item;
      const seasonKey = season ?? 'year';

      if (!acc[year]) {
        acc[year] = { categories: [], seasons: {} };
      }

      if (!acc[year].categories.includes(categoryId)) {
        acc[year].categories.push(categoryId);
      }

      if (!acc[year].seasons[seasonKey]) {
        acc[year].seasons[seasonKey] = [];
      }

      acc[year].seasons[seasonKey].push(item);

      return acc;
    }, {});
  }, [badges]);

  if (isLoading) {
    return <BadgesSkeleton />;
  }

  if (!isLoading && badges.length === 0) {
    return <BadgesEmpty />;
  }

  return (
    <Box>
      <BadgesSummary badges={badges} company={company} />
      {map(groupedBadges, (item, key) => {
        return <BadgesList key={key} seasons={item.seasons} categoriesIds={item.categories} year={key} />;
      })}
    </Box>
  );
};
