// Components
import { MyBillDetailsModal as MyBillDetailsModalC } from './MyBillDetailsModal';

import dayjs from 'dayjs';
// Selectors
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

const currentYear = dayjs().year();
const validate = ({ dataMonthPrices, addSmartReminder, smartReminder } = {}) => {
  const errors = {};
  if (dataMonthPrices && !dataMonthPrices[currentYear]?.price?.value) {
    errors.dataMonthPrices = { [currentYear]: { price: { value: 'Please set the price' } } };
  }
  if (dataMonthPrices && !dataMonthPrices[currentYear]?.price?.period) {
    errors.dataMonthPrices = { [currentYear]: { period: { value: 'Please set the period' } } };
  }
  if (addSmartReminder && smartReminder?.triggeredAt && dayjs(smartReminder.triggeredAt).diff(dayjs(), 'hours') < 0) {
    errors.smartReminder = { triggeredAt: 'Reminder date must be in the future' };
  }

  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({ form: 'myBillingDetails', validate });

export const MyBillDetailsModal = createReduxForm(connect(mapStateToProps)(MyBillDetailsModalC));
