import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PaymentPlans } from '@packs/components/UI';
import ArrowBack from '@packs/images/icons/ArrowBack';
import { Form } from '@packs/screens/BecomeAPartner/components/Form/form';

export const BusinessPartners = ({
  inProcess,
  onCheckAdds,
  selectedAdds,
  submitHandler,
  selectedPlan,
  onSelectPlan
}) => {
  const handleBack = () => {
    onSelectPlan(null);
  };

  if (!selectedPlan) {
    return (
      <PaymentPlans
        {...{
          selectedAdds,
          onCheckAdds,
          onSelectPlan
        }}
      />
    );
  }

  return (
    <Box sx={{ width: '700px', mx: 'auto', mt: 5 }}>
      <div className='payment-back cursor-pointer' onClick={handleBack}>
        <ArrowBack />
        <span className='ml-10 fs-14'>Back</span>
      </div>
      <Typography variant='h4' sx={{ mb: 3, textAlign: 'center' }}>
        Business Partners
      </Typography>
      <Typography variant='subtitle2' sx={{ mb: 5, textAlign: 'center' }}>
        At Bill Winner we love partnering with great businesses. Our application ensures that the best businesses are
        rewarded for their devotion to their customers. Please enter your details below and a member of our customer
        service team will contact you shortly. Thank you!
      </Typography>
      <div className='partner-form-container second-box'>
        <Form handleSubmit={submitHandler} inProcess={inProcess} />
      </div>
    </Box>
  );
};
