import React, { useState } from 'react';
import cx from 'classnames';

import { NoteForm } from '@packs/components/UI/MyNotes/components/NoteForm';
import LockIcon from '@packs/images/svg/ui/lock.svg';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const CreateNoteModal = observer(
  ({ toggle = () => {}, isOpen = false, hideModal, modalProps: { category, note = {}, general = false } }) => {
    const { isMobile } = useWindowSize();
    const { notesStore } = useStore();
    const { categoriesOptions } = useCategories();

    const [loading, setLoading] = useState(false);

    const initialValues = {
      title: note?.title || '',
      body: note?.body || '',
      categoryId:
        categoriesOptions
          .find(cat => cat?.options.find(subCat => subCat.value === (note?.category?.id || category?.id)))
          ?.options.find(cat => cat.value === (note?.category?.id || category?.id)) ||
        categoriesOptions[0].options[0] ||
        null
    };

    const isEditMode = note?.id || false;

    const [files, setFiles] = useState([]);
    const [deleteFilesIds, setDeleteFilesIds] = useState([]);

    const additionalFiles = isEditMode ? note?.files.filter(additional => !deleteFilesIds.includes(additional.id)) : [];

    const onFileAdd = ev => {
      const newFiles = [...files].concat(Array.from(ev.target.files)).sort((a, b) => (a.type.match(/image/) ? -1 : 1));

      setFiles(newFiles);
    };

    const removeFile = index => {
      let newFiles = [...files];
      delete newFiles[index];
      newFiles = newFiles.filter(f => f);
      setFiles(newFiles);
    };

    const onRemoveAdditionalFile = id => {
      const newData = [...deleteFilesIds, id];

      setDeleteFilesIds(newData);
    };

    const callback = () => {
      setLoading(false);
      toggle();
    };

    const errorCallback = () => {
      setLoading(false);
    };

    const submitHandler = attrs => {
      setLoading(true);
      const newData = { ...attrs };
      newData.categoryId = attrs?.categoryId?.value || attrs?.categoryId;
      if (isEditMode) {
        notesStore
          .update({
            ...newData,
            id: note.id,
            files,
            deleteFilesIds
          })
          .then(callback)
          .catch(errorCallback);
      } else {
        notesStore
          .create({ ...newData, files })
          .then(callback)
          .catch(errorCallback);
      }
    };

    return (
      <Modal className={cx('modal-dialog create-note-modal', { hideModal })} {...{ isOpen, toggle }}>
        <ModalHeader toggle={() => toggle()} />
        <ModalBody>
          <div className='title'>Add new doc or note</div>
          <div className='sub-title'>Choose a category and add your note.</div>
          <NoteForm
            onSubmit={submitHandler}
            {...{
              general,
              loading,
              files,
              onFileAdd,
              removeFile,
              toggle,
              isMobile,
              category,
              initialValues,
              additionalFiles,
              onRemoveAdditionalFile
            }}
          />
          {isMobile && (
            <div className='private d-flex align-items-center justify-content-center'>
              <img src={LockIcon} alt='lock' />
              <span>Notes&Docs are private to you.</span>
            </div>
          )}
        </ModalBody>
        {!isMobile && (
          <div className='private d-flex align-items-center justify-content-center'>
            <img src={LockIcon} alt='lock' />
            <span>Notes&Docs are private to you.</span>
          </div>
        )}
      </Modal>
    );
  }
);
