import React, { Fragment } from 'react';

import {
  BusinessVoteModal,
  ChangePasswordModal,
  ConfirmModal,
  CreateQuestionModal,
  MyAssociationsModal,
  NewAssociationModal,
  NewProviderModal,
  PersonalizationFlowModal,
  PersonalizeConfirmModal,
  PersonalizeUnAuthorizedModal,
  PrivacyModal,
  ReminderFormModal,
  RequestQuoteModal,
  TermsModal,
  ThankModal,
  ThankVoteModal,
  WinnerModal
} from '@packs/components';
import {
  AcceptInviteModal,
  ConnectSocialModal,
  MobileFilter,
  ShareSocialModal,
  VerificationModal,
  WikiTableModal
} from '@packs/components/UI';
import { CreateNoteModal } from '@packs/components/UI/MyNotes/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import {
  AuthModal,
  DeleteConfirmModal,
  InviteFriendsModal,
  MyBillDetailsModal,
  ResendConfirmationModal,
  SettingsModal
} from '@packs/layouts/AppLayer/components';
import { useModalsContext, useTranslationMessages } from '@packs/layouts/AppLayer/context';

import { observer } from 'mobx-react';

export const AllModals = observer(({ category, modalState }) => {
  const {
    toggleReminderFormModal,
    toggleResendConfirmationModal,
    toggleTermsModal,
    togglePrivacyModal,
    toggleChangePasswordModal,
    toggleRequestQuoteModal,
    toggleSignInModal,
    toggleSignUpModal,
    toggleNewCompanyModal,
    toggleNewAssociationModal,
    toggleSettingsModal,
    toggleDeleteConfirmModal,
    toggleThankModal,
    onSignUpSuccess,
    toggleMyBillDetailsModal,
    togglePersonalizationFlowModal,
    togglePersonalizeUnAuthorizedModal,
    togglePersonalizeConfirmModal,
    toggleBusinessVoteModal,
    toggleThankVoteModal,
    toggleCreateQuestionModal,
    toggleMyAssociationsModalOpen,
    toggleConfirmModalOpen,
    toggleNoteModalOpen,
    toggleConnectSocialModalOpen,
    toggleWikiTableModalOpen,
    toggleAcceptInviteModalOpen,
    toggleMobileFilterOpen,
    toggleWinnerModal,
    toggleVerificationModalOpen,
    toggleInviteFriendsModal,
    toggleShareSocialModal
  } = useModalsContext();

  const { currentUser } = useUserSelectors();
  const { formatMessage } = useTranslationMessages();

  return (
    <Fragment>
      {modalState.isOpenDoubleModal && (
        <AuthModal
          isOpen={modalState.isOpenDoubleModal}
          {...{
            currentUser,
            onSignUpSuccess,
            toggleSignUpModal,
            toggleSignInModal
          }}
          modalAuthProps={modalState.modalAuthProps}
        />
      )}

      <ResendConfirmationModal
        isOpen={modalState.isOpenResendConfirmationModal}
        toggle={toggleResendConfirmationModal}
      />

      <TermsModal
        isOpen={modalState.isOpenTermsModal || false}
        toggle={toggleTermsModal}
        className={category.root && category.root.enName}
      />

      <PrivacyModal
        isOpen={modalState.isOpenPrivacyModal || false}
        toggle={togglePrivacyModal}
        className={category.root && category.root.enName}
      />

      <ChangePasswordModal isOpen={modalState.isChangePasswordModalOpen || false} toggle={toggleChangePasswordModal} />
      {modalState.isOpenRequestQuoteModal && (
        <RequestQuoteModal
          isOpen={modalState.isOpenRequestQuoteModal || false}
          toggle={toggleRequestQuoteModal}
          hideModal={modalState?.isOpenDoubleModal}
          {...modalState.requestQuoteModalProps}
        />
      )}

      {modalState.isOpenReminderFormModal && (
        <ReminderFormModal
          isOpen={modalState.isOpenReminderFormModal || false}
          currentUser={currentUser}
          {...modalState.reminderFormModalProps}
          selectedCategory={modalState.reminderFormModalProps?.category}
          toggle={() => toggleReminderFormModal()}
        />
      )}

      <NewProviderModal
        isNewCompanyModalOpen={modalState.isNewCompanyModalOpen || false}
        toggle={() => toggleNewCompanyModal()}
        {...modalState.newCompanyModalProps}
      />

      <NewAssociationModal
        isNewAssociationModalOpen={modalState.isNewAssociationModalOpen || false}
        toggle={() => toggleNewAssociationModal()}
        {...modalState.newAssociationModalProps}
      />

      {/* AUTH modals */}

      {modalState.isOpenSettingsModal && (
        <SettingsModal
          isOpen={modalState.isOpenSettingsModal || false}
          toggle={() => toggleSettingsModal()}
          toggleDeleteConfirmModal={toggleDeleteConfirmModal}
        />
      )}

      <DeleteConfirmModal
        isOpen={modalState.isOpenDeleteConfirmModal || false}
        toggle={() => toggleDeleteConfirmModal()}
      />

      {category.id && (
        <>
          <ThankModal
            isThankModalOpen={modalState.isThankModalOpen || false}
            toggle={() => toggleThankModal()}
            formatMessage={formatMessage}
            category={category}
          />
        </>
      )}
      {modalState.isOpenMyBillDetails && (
        <MyBillDetailsModal
          isOpen={modalState.isOpenMyBillDetails}
          toggle={() => toggleMyBillDetailsModal()}
          modalProps={modalState.modalProps}
        />
      )}
      {modalState.isPersonalizationFlowModalOpen && (
        <PersonalizationFlowModal
          isOpen={modalState.isPersonalizationFlowModalOpen}
          toggle={togglePersonalizationFlowModal}
          modalProps={modalState.modalProps}
        />
      )}
      {modalState.isPersonalizeUnAuthorizedModalOpen && (
        <PersonalizeUnAuthorizedModal
          isOpen={modalState.isPersonalizeUnAuthorizedModalOpen}
          toggle={() => togglePersonalizeUnAuthorizedModal(!modalState.isPersonalizeUnAuthorizedModalOpen)}
          modalProps={modalState.modalProps}
        />
      )}
      {modalState.isPersonalizeConfirmModalOpen && (
        <PersonalizeConfirmModal
          isOpen={modalState.isPersonalizeConfirmModalOpen}
          toggle={() => togglePersonalizeConfirmModal(!modalState.isPersonalizeConfirmModalOpen)}
          modalProps={modalState.modalProps}
        />
      )}
      {modalState.isBusinessVoteModalOpen && (
        <BusinessVoteModal
          isOpen={modalState.isBusinessVoteModalOpen}
          toggle={() => toggleBusinessVoteModal(!modalState.isBusinessVoteModalOpen)}
          modalProps={modalState.modalPropsVote}
          hideModal={modalState?.isOpenDoubleModal}
        />
      )}
      {modalState.isCreateQuestionOpen && (
        <CreateQuestionModal
          isOpen={modalState.isCreateQuestionOpen}
          toggle={() => toggleCreateQuestionModal(!modalState.isCreateQuestionOpen)}
          modalProps={modalState.modalProps}
          hideModal={modalState?.isOpenDoubleModal || modalState.connectSocialModalOpen}
        />
      )}
      {modalState.isThankVoteModalOpen && (
        <ThankVoteModal
          isOpen={modalState.isThankVoteModalOpen}
          modalProps={modalState.modalProps}
          toggle={() => toggleThankVoteModal(!modalState.isThankVoteModalOpen)}
        />
      )}
      {modalState.myAssociationsModalOpen && (
        <MyAssociationsModal
          isOpen={modalState.myAssociationsModalOpen}
          modalProps={modalState.modalProps}
          toggle={() => toggleMyAssociationsModalOpen(!modalState.myAssociationsModalOpen)}
        />
      )}
      {modalState.connectSocialModalOpen && (
        <ConnectSocialModal
          isOpen={modalState.connectSocialModalOpen}
          modalProps={modalState.connectModalProps}
          toggle={() => toggleConnectSocialModalOpen(!modalState.connectSocialModalOpen)}
        />
      )}
      {modalState.confirmModalOpen && (
        <ConfirmModal
          isOpen={modalState.confirmModalOpen}
          modalProps={modalState.modalProps}
          toggle={() => toggleConfirmModalOpen(!modalState.confirmModalOpen)}
        />
      )}
      {modalState.noteModalIsOpen && (
        <CreateNoteModal
          isOpen={modalState.noteModalIsOpen}
          hideModal={modalState?.isOpenDoubleModal}
          toggle={() => toggleNoteModalOpen(!modalState.noteModalIsOpen)}
          modalProps={modalState.modalProps}
        />
      )}
      {modalState.isOpenWikiTableModal && (
        <WikiTableModal
          isOpen={modalState.isOpenWikiTableModal}
          toggle={() => toggleWikiTableModalOpen({ productsProps: {} })}
          productsModalOpen={modalState.isOpenProductsModal}
          modalProps={modalState.productsProps}
        />
      )}
      {modalState.isOpenAcceptInviteModal && (
        <AcceptInviteModal
          isOpen={modalState.isOpenAcceptInviteModal}
          toggle={() => toggleAcceptInviteModalOpen(!modalState.isOpenAcceptInviteModal)}
          modalProps={modalState.modalProps}
        />
      )}
      {modalState.isOpenVerificationModal && (
        <VerificationModal
          isOpen={modalState.isOpenVerificationModal}
          toggle={() => toggleVerificationModalOpen(!modalState.isOpenVerificationModal)}
          modalProps={modalState.modalProps}
        />
      )}
      <MobileFilter
        isOpen={modalState.isOpenMobileFilter}
        toggle={close => toggleMobileFilterOpen(!modalState.isOpenMobileFilter, close)}
        drawerProps={modalState.drawerProps}
      />
      {modalState.winnerModalIsOpen && (
        <WinnerModal
          toggle={() => toggleWinnerModal({ modalProps: {} })}
          isModalOpen={modalState.winnerModalIsOpen}
          hideModal={modalState?.isOpenDoubleModal}
        />
      )}
      {modalState.shareSocialModalIsOpen && (
        <ShareSocialModal
          toggle={() => toggleShareSocialModal({ modalProps: {} })}
          isOpen={modalState.shareSocialModalIsOpen}
          modalProps={modalState.modalProps}
        />
      )}

      <InviteFriendsModal
        isOpen={modalState.isOpenInviteFriends || false}
        modalProps={modalState.modalProps}
        toggle={toggleInviteFriendsModal}
      />
    </Fragment>
  );
});
