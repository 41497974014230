import React from 'react';

import { Box, Chip, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { Hero } from '@packs/components/MaterialUi/Landing/Hero/Hero';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import useStyles from '@packs/screens/SubCategoryLanding/styles';

const { primary } = colors;

export const ComingSoon = ({ landingData, currentCategory, heroTitle }) => {
  const styles = useStyles();
  const { currentUser } = useUserSelectors();

  return (
    <Hero breadcrumbLabel={currentCategory.name} heroBackgroundImg={landingData?.blockHeroImgUrl} comingSoon>
      <Box sx={styles.wrapper}>
        <Box sx={styles.contentWrapper}>
          <Chip label='Coming soon' sx={{ mb: 2, backgroundColor: primary.focus }} />
          <Typography variant='h2' color='inherit' mb={2}>
            {heroTitle}
          </Typography>
          <Typography variant='subtitle1'>We will be soon launching our service in this category.</Typography>
          {!currentUser?.id && <Typography variant='subtitle1'>Sign up to Bill Winner to be notified!</Typography>}
        </Box>
      </Box>
    </Hero>
  );
};
