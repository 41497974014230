import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { getCurrency, numberWithCommas } from '@packs/lib/constants';
import { useStore } from '@packs/stores';

const { text } = colors;

export const AveragePrice = ({ company, selectedCost }) => {
  const { countryCode } = useStore();

  const isPerMonth = selectedCost?.value === 'renewalCostMonthly';

  const averagePrice = useMemo(() => {
    if (isPerMonth) {
      return company?.additionalData.averagePriceData?.averageMonthly
        ? numberWithCommas(company?.additionalData.averagePriceData.averageMonthly)
        : null;
    }

    return company?.additionalData.averagePriceData?.averageYearly
      ? numberWithCommas(company?.additionalData.averagePriceData.averageYearly)
      : null;
  }, [company?.additionalData.averagePriceData, selectedCost.value]);

  const currency = getCurrency(countryCode);

  if (!averagePrice) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant='body5' sx={{ color: text.secondary, textAlign: { md: 'right' } }} className='averageTitle'>
        Average premium for members
      </Typography>
      <Typography variant='body3' sx={{ textAlign: { md: 'right' } }}>
        {`${currency + averagePrice} p/${isPerMonth ? 'm' : 'y'}`}
      </Typography>
    </Box>
  );
};
