export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      lg: 'row'
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    py: { xs: 2, lg: 0 }
  },
  contentWrapper: {
    width: '100%',
    maxWidth: '645px'
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      lg: 'flex-start'
    },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 2
  },
  footerTitle: {
    display: 'flex',
    alignItems: 'center'
  }
});
