import colors from '@packs/assets/theme/base/colors';

const { dark } = colors;

export default () => ({
  wrapper: {
    pt: { xs: 2, md: 4 }
  },
  container: {
    maxWidth: '1128px',
    mx: 'auto',
    pb: {
      xs: 3,
      lg: 5
    }
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 2,
    mb: { xs: 3, sm: 4, lg: 8 },
    px: { xs: 2, lg: 0 },
    color: dark.main,
    '& a:hover': {
      color: dark.main
    }
  },
  innerSection: {
    display: 'flex',
    alignItems: { xs: 'flex-start', md: 'center' },
    flexDirection: { xs: 'column', md: 'row' },
    px: { xs: 2, md: 0 }
  },
  categoryWrapper: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: { xs: 'flex-start', md: 'center' },
    gap: { xs: 1, lg: 3 },
    width: { xs: '100%', md: 'auto' },
    mb: 0
  },
  categoryName: {
    mb: 1,
    color: dark.main
  },
  title: {
    color: dark.main
  },
  personalizeWrapper: {
    pb: {
      xs: 4,
      lg: 6
    }
  }
});
