import React, { useEffect, useMemo, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { renewalDetailApi } from '@packs/apis';
import { categoryApi } from '@packs/apis/category';
import { GetStarted } from '@packs/components/MaterialUi/Landing/GetStarted/GetStarted';
import { CommunityBlock } from '@packs/components/MaterialUi/Landing/Hero/components/CommunityBlock/CommunityBlock';
import { PoweredBlock } from '@packs/components/MaterialUi/Landing/Hero/components/PoweredBlock/PoweredBlock';
import { Hero } from '@packs/components/MaterialUi/Landing/Hero/Hero';
import { HowItWorks } from '@packs/components/MaterialUi/Landing/HowItWorks/HowItWorks';
import { LandingCriteria } from '@packs/components/MaterialUi/Landing/LandingCriteria/LandingCriteria';
import { MembersLove } from '@packs/components/MaterialUi/Landing/MembersLove/MembersLove';
import { LandingOffers } from '@packs/components/MaterialUi/Landing/Offers/Offers';
import { Purchase } from '@packs/components/MaterialUi/Landing/Purchase/Purchase';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import GetStartedIcon from '@packs/images/ui/category-landing-get-started.webp';
import RootHowItWorksIcons from '@packs/images/ui/landing-how-it-works-root.webp';
import { usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { setCategoryCriteria } from '@packs/models/category/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import membersData from '@packs/screens/RootCategoryLanding/membersSection/membersData';
import { useStore } from '@packs/stores/base';
import { makeSelectOptions } from '@shared/helpers/select';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { LandingSubCategories } from './components/LandingSubCategories';
import { getCategoryData } from './constants/helper';
import useStyles from './styles';

import startCase from 'lodash-es/startCase';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

const RootCategoryLanding = observer(() => {
  const styles = useStyles();
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();
  const { currentCountry, categoryStore, companiesStore } = useStore();
  const params = useParams();
  const { categories, flattenCategories } = useCategories();
  const { DASHBOARD_MY_RESULTS_PATH_FN } = usePath();
  const { initialCategory, initialLocation } = queryString.parse(location.search);
  const { currentUser } = useUserSelectors();

  const [landingData, setLandingData] = useState(null);

  const { blockHero, blockHeroImgUrl, blockPurchase } = landingData || {};
  const currentCategory = useMemo(() => {
    return categories.find(rootCat => rootCat?.code === params?.root_code);
  }, [params?.root_code]);
  const initialSubCategory = useMemo(() => {
    return (
      currentCategory?.leafChildren.find(rootCat => rootCat?.code === initialCategory) ||
      currentCategory?.leafChildren[0]
    );
  }, [currentCategory, initialCategory]);

  const [simpleCriteria, setSimpleCriteria] = useState([]);
  const [loading, setLoading] = useState(true);

  const regions = makeSelectOptions([{ value: 'all', label: currentCountry.name }, ...(currentCountry?.regions || [])]);
  const blockHeroInitial = getCategoryData({ currentCategory, regions, initialSubCategory, blockHero });
  const initialCity = useMemo(() => {
    return regions.find(item => item.label === initialLocation) || null;
  }, [regions, initialLocation]);
  const mateDescription = `Compare the top ${initialSubCategory.name} providers and choose the best rates for your next contract`;

  const fields = [
    {
      name: 'category',
      type: 'select',
      label: 'Select category',
      placeholder: 'Select category',
      options: makeSelectOptions(currentCategory.leafChildren)
    },
    {
      name: 'city',
      type: 'select',
      label: 'Location',
      placeholder: 'Location',
      options: regions
    },
    ...(simpleCriteria || [])
      .sort((a, b) => a.index - b.index)
      .map(item => ({
        ...item,
        name: `criteriaQuestions.${item.name}`
      }))
  ];

  const fetchData = async () => {
    if (currentCategory?.id) {
      const res = await categoryApi.fetchCategoryRootPage({ categoryId: currentCategory.id });
      setLandingData(res);
    }
  };

  const onSubmitCriteria = values => {
    const formValues = { ...values };
    const currentCategory = flattenCategories.find(item => item.id === formValues?.category?.value);
    categoryStore.setCurrentCategory(currentCategory);
    companiesStore.setFilters({
      city: formValues?.city?.label
    });

    const criteria = values?.criteriaQuestions || {};
    const extractedValues = {};
    Object.keys(criteria).forEach(key => {
      if (typeof criteria[key] === 'object') {
        extractedValues[key] = criteria[key].value;
      } else {
        extractedValues[key] = criteria[key];
      }
    });

    const criteriaForPolicy = {
      ...extractedValues,
      location: values?.city?.label || ''
    };
    setCategoryCriteria(currentCategory.id, criteriaForPolicy);

    navigate(DASHBOARD_MY_RESULTS_PATH_FN(currentCategory));
  };

  const fetchCriteria = async category => {
    setLoading(true);
    const data = await renewalDetailApi.fetchCriteriaQuestionList({ categoryId: +category.id, formType: 'simple' });
    if (!data.errors) {
      setSimpleCriteria(data);
    }
    setLoading(false);
  };

  const onChangeSelectedCategory = data => {
    const cat = currentCategory?.leafChildren.find(rootCat => rootCat?.id === data.value);
    if (cat?.id) {
      navigate({ search: `?initialCategory=${cat.code}` });
    }

    fetchCriteria({ id: data.value });
  };

  const initialValues = () => {
    const init = {
      criteriaQuestions: {}
    };

    fields.forEach(field => {
      const fieldPath = field.name.split('.');

      if (fieldPath[0] === 'city') {
        init.city = initialCity || regions[0] || null;
      } else if (fieldPath[0] === 'category') {
        init.category = makeSelectOptions([initialSubCategory])[0] || null;
      } else if (field.type === 'select' || field.type === 'radio') {
        const fieldValue = field?.options.find(item => item.value === field?.defaultValue) || null;

        if (fieldPath.length === 1) {
          init[field.name] = fieldValue;
        } else {
          if (!init[fieldPath[0]]) {
            init[fieldPath[0]] = {};
          }
          init[fieldPath[0]][fieldPath[1]] = fieldValue;
        }
      } else {
        if (fieldPath.length === 1) {
          init[field.name] = field?.defaultValue || '';
        } else {
          if (!init[fieldPath[0]]) {
            init[fieldPath[0]] = {};
          }
          init[fieldPath[0]][fieldPath[1]] = field?.defaultValue || '';
        }
      }
    });

    return init;
  };

  useEffect(() => {
    if (currentCategory.id) {
      fetchData();
    }
  }, [currentCategory.id]);

  useEffect(() => {
    if (initialSubCategory?.id) {
      fetchCriteria(initialSubCategory);
    }
  }, [initialSubCategory?.id]);

  useEffect(() => {
    if (categoryHavePolicy(initialSubCategory, categoryStore.categoriesWithPolicy, currentUser)) {
      navigate(DASHBOARD_MY_RESULTS_PATH_FN(initialSubCategory));
    }
  }, [initialSubCategory, currentUser.id]);

  const blockHeroOptions = blockHero?.options?.length
    ? blockHero?.options
    : [
        { title: 'See where members like you are finding their best value!' },
        { title: 'See the best matched offers!' },
        { title: 'Get tips and insights from our community!' },
        { title: 'Set up Auto, quote to request automatic quotes at renewal time!' },
        { title: 'Manage all your policy details in one place!' }
      ];

  return (
    <>
      <Helmet>
        <title>{initialSubCategory.name}</title>
        <meta name='description' content={mateDescription} />
        <meta name='og:description' content={mateDescription} />
      </Helmet>
      <Box sx={{ overflow: 'hidden' }}>
        <Hero breadcrumbLabel={startCase(currentCategory.enName)} heroBackgroundImg={blockHeroImgUrl}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.contentWrapper}>
              <Typography variant='h2' color='inherit' mb={{ xs: 0, lg: 2 }}>
                {blockHeroInitial?.title ||
                  `Save on your ${currentCategory?.name.toLowerCase()} bills with BillWinner!`}
              </Typography>
              <Stack spacing={1} mt={3} mb={{ xs: 0, md: 3 }}>
                {blockHeroOptions.map(el => (
                  <Stack key={el.title} direction='row' spacing={1}>
                    <CheckIcon />
                    <Typography variant='body1'>{el.title}</Typography>
                  </Stack>
                ))}
              </Stack>
              {isDesktop ? (
                <>
                  {blockHeroInitial.communityBlock ? (
                    <>
                      <CommunityBlock currentCategoryId={currentCategory.id}>
                        <Typography
                          variant='body1'
                          dangerouslySetInnerHTML={{ __html: blockHeroInitial.description }}
                        />
                      </CommunityBlock>
                      <PoweredBlock {...{ currentCategory }} variant='body1' />
                    </>
                  ) : (
                    <Typography variant='body1' dangerouslySetInnerHTML={{ __html: blockHeroInitial.description }} />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant='body1'
                    sx={{ mt: 2 }}
                    dangerouslySetInnerHTML={{
                      __html: blockHero?.description || "Let's make smarter decisions together!"
                    }}
                  />
                </>
              )}
            </Box>
            {blockHeroInitial?.formType === 'criteria' && !loading && (
              <LandingCriteria
                key={params?.root_code + initialCategory}
                fields={fields}
                initialValues={initialValues()}
                handleSubmit={onSubmitCriteria}
                onChangeSelectedCategory={onChangeSelectedCategory}
                isRootLandingPage
              />
            )}
          </Box>
          {blockHeroInitial?.formType === 'subCategories' && <LandingSubCategories {...{ currentCategory }} />}
        </Hero>
        <Purchase
          title={blockPurchase?.title}
          description={blockPurchase?.description}
          options={blockPurchase?.options}
          categoryName={currentCategory?.name?.toLowerCase()}
        />
        <Box sx={{ pt: 15 }}>
          <MembersLove
            title={`Join the Bill Winner Community to save on your ${currentCategory?.name.toLowerCase()} bills!`}
            members={membersData[currentCategory?.name.toLowerCase()] || membersData['energy']}
          />
        </Box>
        <HowItWorks {...{ currentCategory }} withoutTitle position='right' infoIcon={RootHowItWorksIcons} />
        {currentCategory?.code && <LandingOffers rootCategoryCode={currentCategory?.code} withBottomGap />}
        <GetStarted
          icon={GetStartedIcon}
          iconWidth='579px'
          title='Join our Community!'
          description='Join our community to manage and make smarter decisions on your bills!'
        />
      </Box>
    </>
  );
});

export default RootCategoryLanding;
