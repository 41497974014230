import React from 'react';

import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { I18n } from '@packs/components/I18n';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';

import { Field } from 'redux-form';

const Form = ({ handleSubmit }) => {
  const { defaultMessages, formatMessage } = useTranslationMessages();

  return (
    <form onSubmit={handleSubmit}>
      <div className='input-field'>
        <Field
          name='email'
          component={FieldWithErrors}
          type='text'
          placeholder={formatMessage(defaultMessages.email)}
        />
      </div>
      <button type='submit' className='btn auth-btn'>
        <I18n text={defaultMessages.resendUnlockInstructions} />
      </button>
    </form>
  );
};

export default Form;
