import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { VoteSearchBusiness } from '@packs/components/VoteModal/components/VoteSearchBusiness';
import Benefit1Icon from '@packs/images/svg/ui/vote-benefit-1.svg';
import Benefit2Icon from '@packs/images/svg/ui/vote-benefit-2.svg';
import Benefit3Icon from '@packs/images/svg/ui/vote-benefit-3.svg';
import Benefit4Icon from '@packs/images/svg/ui/vote-benefit-4.svg';
import { DiscoverAll } from '@packs/screens/Landing/components/Hero/components/DiscoverAll/DiscoverAll';
import useContainerStyles from '@packs/styles/Global/container';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const { white, secondary } = colors;

const benefits = [
  {
    icon: Benefit1Icon,
    title: 'Transparency',
    description:
      'Your vote helps maintain a transparent marketplace in your country and helps customers like you find the best businesses!'
  },
  {
    icon: Benefit2Icon,
    title: 'Business Rewards',
    description:
      'Your vote rewards your chosen business for doing a great job for their customers! The winning businesses will be awarded digital badges for their website.'
  },
  {
    icon: Benefit3Icon,
    title: 'Customer Rewards',
    description:
      'You are entered into a free 12 month draw to win bill bucks towards your bills! Proof of purchase is necessary if you win.'
  },
  {
    icon: Benefit4Icon,
    title: 'Reduce Bill Poverty',
    description:
      'Your vote can help reduce bill poverty in your country. Partner businesses on our Bill Winner Bill Poverty Programme have agreed to make a donation to the society of st vincent de paul for every customer that votes for them!'
  }
];

const Vote = observer(() => {
  const styles = useStyles();
  const stylesContainer = useContainerStyles();
  const { isMobile } = useWindowSize();

  return (
    <>
      <Helmet>
        <title>Vote</title>
      </Helmet>
      <Box>
        <Box sx={styles.heroWrapper}>
          <Box sx={{ ...stylesContainer.container, ...styles.heroContainer }}>
            <BreadcrumbsMUI currentPage='Write a review' color={white.main} />
            <Typography variant='h2' sx={styles.title}>
              Our Bill Winner Community Awards!
            </Typography>
            <VoteSearchBusiness />
          </Box>
          <Box sx={styles.votingCategoriesWrapper}>
            <Typography variant='h5' sx={styles.votingTitle}>
              Voting Categories
            </Typography>
            <DiscoverAll />
          </Box>
        </Box>
        <Box sx={{ ...stylesContainer.container, ...styles.benefitsContainer }}>
          <Typography variant='h2' sx={styles.benefitsTitle}>
            The Benefits of Voting!
          </Typography>
          <Typography variant='subtitle1'>
            Voting on Bill Winner has benefits for you, members like you and the winning businesses!
          </Typography>
          <Box sx={styles.benefitsList}>
            {benefits.map(({ icon, title, description }) => (
              <Box key={title} sx={styles.benefitItem}>
                <Box sx={styles.benefitIconWrapper}>
                  <IconBox
                    backgroundColor={secondary.transparent}
                    color={secondary.main}
                    containerSize={isMobile ? '64px' : '88px'}
                    iconSize={isMobile ? '48px' : '56px'}
                    icon={<img src={icon} alt={title} />}
                    borderRadius='16px'
                  />
                </Box>
                <Typography variant='h6' sx={styles.benefitItemTitle}>
                  {title}
                </Typography>
                <Typography variant='body1'>{description}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default Vote;
