import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import OfferPlaceholderIcon from '@packs/images/svg/ui/offer-placeholder-icon.svg';
import { usePath } from '@packs/layouts/constants';
import { VIEW_DATE_FORMAT } from '@packs/screens/PersonalizeResults/formHooks/defaults';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import { Link } from 'react-router-dom';

export const MyOffersItem = ({ offer }) => {
  const { MY_OFFERS_DETAILED_PATH_FN } = usePath();

  const { offerMessage, offerDetails, untilDate, category, bannerUrl } = offer;

  const OfferItemContent = () => {
    return (
      <>
        <div
          className={cx(
            'category-section d-flex align-items-center justify-content-center gap-4 fs-13 fw-500 theme-box',
            category?.root?.code
          )}
        >
          {SUB_ICONS({ category })}
          {category.name}
        </div>
        <div
          className={cx('item-img-container d-flex align-items-center justify-content-center', { filled: bannerUrl })}
        >
          <img src={bannerUrl || OfferPlaceholderIcon} alt='Offer image' />
        </div>
        <div className='item-bottom-part d-flex flex-column'>
          {untilDate && (
            <div className='date-section d-flex align-items-center justify-content-between fs-11'>
              <div className='secondary-text'>End date:</div>
              <div className='fw-500'>{moment(untilDate).format(VIEW_DATE_FORMAT)}</div>
            </div>
          )}
          <div className='content-section'>
            <div className='text fs-13 fw-700 lh-10 wb-bw'>{offerMessage}</div>
            <div className='text fs-13 lh-10 wb-bw'>{offerDetails}</div>
          </div>
          <div className='btn-section mt-auto'>
            <div className='btn full primary white small w-100'>
              <span>See offer</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Link to={MY_OFFERS_DETAILED_PATH_FN(offer.id)} className='my-offers-item theme-box'>
      <OfferItemContent />
    </Link>
  );
};
