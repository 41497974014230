import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash-es';

import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { FeaturedOffers } from '@packs/components/MaterialUi/Offers/FeaturedOffers/FeaturedOffers';
import { MyOffersActionPanel } from '@packs/components/MaterialUi/Offers/MyOffersActionPanel/MyOffersActionPanel';
import { MyOffersFilter } from '@packs/components/MaterialUi/Offers/MyOffersFilters/MyOffersFilter';
import { MyOffersTopBar } from '@packs/components/MaterialUi/Offers/MyOffersTopBar/MyOffersTopBar';
import { OffersList } from '@packs/components/MaterialUi/Offers/OffersList/OffersList';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { defaultCategory, INIT_FILTERS } from '@packs/stores/OffersStore';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const { white } = colors;

const showFilters = ['offerType', 'categoryId', 'keyWord', 'rootCategoryCode', 'customerType'];

const MyOffers = observer(() => {
  const { isDesktop } = useWindowSize();
  const wrapperRef = useRef(null);
  const { offersStore, currentCategory } = useStore();
  const { MY_HOME_PATH } = usePath();
  const styles = useStyles();
  const stylesLeaderboard = useLeaderboardStyles();

  const featuredOffers = offersStore?.featuredOffers;

  const handleScrollToBegin = () => {
    window.scrollTo({
      top: wrapperRef?.current?.offsetTop - 98,
      behavior: 'smooth'
    });
  };

  const defaultFilters = { categoryId: defaultCategory };

  const disabledRemove = useMemo(() => {
    return _.isEqual(
      _.pick({ ...INIT_FILTERS }, showFilters),
      _.pick({ categoryId: defaultCategory, ...offersStore.filters }, showFilters)
    );
  }, [offersStore.filters]);

  const onResetFilters = () => {
    if (!disabledRemove) {
      offersStore.load({ ...INIT_FILTERS, ...defaultFilters });
    }
  };

  useEffect(() => {
    offersStore.load({}, false);
    offersStore.loadFeatured();
  }, []);

  useEffect(() => {
    handleScrollToBegin();
  }, [offersStore.filters.page]);

  return (
    <>
      <Helmet>
        <title>My Offers</title>
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <PageTitleWrapper
          homeIsLanding
          title='My Offers: All the best offers in one place!'
          currentPage='My offers'
          subTitle='The best offers to save you money!'
          sx={{ mb: 0 }}
        />
        <Box sx={styles.featuredWrapper}>
          <Box sx={styles.featureContainer}>
            <FeaturedOffers categoryId={currentCategory?.id} containerStyles={styles.sliderWrapper} />
          </Box>
        </Box>

        <Box sx={styles.offersWrapper} ref={wrapperRef}>
          <MyOffersTopBar showFilters={showFilters} {...{ onResetFilters, disabledRemove, defaultFilters }} />
          <Box sx={stylesLeaderboard.container}>
            {isDesktop && (
              <Box sx={stylesLeaderboard.filterContainer}>
                <Box sx={stylesLeaderboard.filterContainerInner}>
                  <Box sx={stylesLeaderboard.filterTitle}>
                    <Typography variant='body4'>Filter by:</Typography>
                    <Button
                      variant='text'
                      size='xs'
                      color='dark'
                      sx={{ minWidth: '44px' }}
                      disabled={disabledRemove}
                      onClick={onResetFilters}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
                <MyOffersFilter {...{ defaultFilters, showFilters }} sidebarFilters />
              </Box>
            )}
            <Box sx={stylesLeaderboard.content}>
              <MyOffersActionPanel {...{ showFilters, onResetFilters, disabledRemove }} />
              <Typography variant='h6' sx={{ mb: 2 }}>
                All offers
              </Typography>
              <OffersList openFilters />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default MyOffers;
