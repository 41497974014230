import { useMemo } from 'react';

import Box from '@mui/material/Box';
import { useCategories } from '@packs/models/category/useCategories';
import { popularCategories } from '@shared/constants/categories';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import { CategoryItemPopular } from './components/CategoryItemPopular';

export const Popular = () => {
  const { categories, flattenCategories } = useCategories();

  const popularCategoriesList = useMemo(() => {
    const list = [];
    popularCategories.forEach(code => {
      const cat = flattenCategories.find(item => item.code === code);
      if (cat) {
        list.push(cat);
      }
    });
    return list;
  }, [categories]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: {
          xs: 2,
          md: 4
        },
        px: {
          xs: 2,
          md: 0
        },
        width: '100%',
        maxWidth: '1128px',
        mx: 'auto'
      }}
    >
      {popularCategoriesList.map(cat => (
        <CategoryItemPopular
          key={cat.id}
          categoryIcon={cat?.parentId ? SUB_ICONS({ category: cat }) : ICONS[cat?.enName]}
          category={cat}
        />
      ))}
    </Box>
  );
};
