import React from 'react';
import cx from 'classnames';

import Check from '@packs/images/icons/CheckboxIcon';
import Email from '@packs/images/icons/Email';
import Facebook from '@packs/images/icons/Facebook';
import LinkedIn from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';
import WhatsApp from '@packs/images/icons/WhatsApp';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { BASE_APP_URL } from '@packs/lib/constants';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ThankModal = ({ isThankModalOpen, toggle, category }) => {
  const { defaultMessages, formatMessage } = useTranslationMessages();
  const inviteMessage = formatMessage(defaultMessages.inviteMessage);

  return (
    <Modal
      isOpen={isThankModalOpen}
      toggle={() => toggle('isThankModalOpen')}
      className={cx('thank-modal', category.root.enName)}
    >
      <ModalHeader toggle={() => toggle('isThankModalOpen')} />
      <ModalBody>
        <div className='modal-top-box'>
          <div className='thank-title'>Thank you for your review!</div>
          <div className='thank-dialog' />
        </div>
        <div className='thank-content'>
          <div className='content-title'>Each review help`s us create</div>
          <ul className='thank-list'>
            <li>
              <span className='icon'>
                <Check />
              </span>
              <span>Recommendations for people just like you</span>
            </li>
            <li>
              <span className='icon'>
                <Check />
              </span>
              <span>More transparent and competitive markets</span>
            </li>
          </ul>
          <div className='leaderboard-social-list'>
            <EmailShareButton
              className='social-link email'
              url={BASE_APP_URL}
              subject={formatMessage(defaultMessages.inviteMailSubject)}
              body={`${inviteMessage} ${BASE_APP_URL}`}
              onClick={() => {
                toggle('isInviteEmailModalOpen');
              }}
            >
              <span className='icon'>
                <Email />
              </span>
            </EmailShareButton>
            <FacebookShareButton url={BASE_APP_URL} quote={inviteMessage} className='social-link facebook'>
              <span className='icon'>
                <Facebook />
              </span>
            </FacebookShareButton>
            <TwitterShareButton url={BASE_APP_URL} title={inviteMessage} className='social-link twitter'>
              <span className='icon'>
                <Twitter />
              </span>
            </TwitterShareButton>
            <LinkedinShareButton url={BASE_APP_URL} title={inviteMessage} className='social-link linkedin'>
              <span className='icon'>
                <LinkedIn />
              </span>
            </LinkedinShareButton>
            <WhatsappShareButton url={BASE_APP_URL} title={inviteMessage} className='social-link whatsApp'>
              <span className='icon'>
                <WhatsApp />
              </span>
            </WhatsappShareButton>
          </div>
          <div className='invite-text'>Invite friends to Bill Winner</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className='btn reminder-btn'
          onClick={() => {
            toggle('isThankModalOpen');
          }}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
