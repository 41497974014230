import React, { useState } from 'react';
import cx from 'classnames';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { OfferItem } from '@packs/components/MaterialUi/Offers/OfferItem/OfferItem';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import AnimateHeight from 'react-animate-height';
import Slider from 'react-slick';

const { grey, primary, secondary, dark } = colors;

export const PersonalizedOffers = observer(
  ({
    containerStyles = {},
    darkMode = true,
    featuredTitleStyles = {},
    featuredArrowsStyles = {},
    showFilters = false
  }) => {
    const { isMobile, isDesktop } = useWindowSize();
    const { offersStore } = useStore();

    const styles = useStyles();
    const [activeSlide, setActiveSlide] = useState(0);

    const offers = offersStore?.personalizedOffers;

    const SampleNextArrow = props => {
      const { className, onClick } = props;
      return (
        <Box
          className={cx(className, 'arrow')}
          sx={{ ...styles.sliderArrow, ...styles.nextArrow, ...featuredArrowsStyles }}
          onClick={onClick}
        >
          <ChevronRightRoundedIcon />
        </Box>
      );
    };

    const SamplePrevArrow = props => {
      const { className, onClick } = props;
      return (
        <Box
          className={cx(className, 'arrow')}
          sx={{ ...styles.sliderArrow, ...styles.prevArrow, ...featuredArrowsStyles }}
          onClick={onClick}
        >
          <KeyboardArrowLeftRoundedIcon />
        </Box>
      );
    };

    const slidesToShow = isMobile ? 1 : isDesktop ? (showFilters ? 3 : 4) : 3;
    const showSlider = offers.length >= slidesToShow;
    const wideItem = !isMobile && offers.length === 1;

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow,
      dots: !offersStore?.personalizedLoader && isMobile,
      arrows: !offersStore?.personalizedLoader && !isMobile,
      beforeChange: (current, next) => setActiveSlide(next),
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };

    if (!offersStore?.personalizedLoader && offers.length === 0) {
      return null;
    }

    return (
      <AnimateHeight duration={250} height={offers?.length > 0 ? 'auto' : 0}>
        <Box sx={{ ...styles.container, ...containerStyles }}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.titleWrapper}>
              <Typography variant='h6' sx={featuredTitleStyles}>
                Your personalized offers!
              </Typography>
              {offers.length > 1 && !isMobile && offers.length !== slidesToShow && (
                <Typography variant='body4'>{`${activeSlide + 1}/${offers.length}`}</Typography>
              )}
            </Box>
            {offersStore?.personalizedLoader ? (
              <Box display='flex' justifyContent='center' alignItems='center' minHeight='200px'>
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  '& .slick-dots': {
                    left: '50%',
                    bottom: '-40px',
                    width: '100%',
                    transform: 'translateX(-50%)',
                    '& li': {
                      '& button': {
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        backgroundColor: grey[500],
                        '&:before': {
                          content: 'none'
                        }
                      },
                      '&.slick-active button': {
                        backgroundColor: primary.main
                      }
                    }
                  }
                }}
              >
                {showSlider ? (
                  <Slider {...settings}>
                    {offers.map((offer, index) => (
                      <Box key={offer.id} justifyContent='center' px={2} sx={{ display: 'flex !important' }}>
                        <OfferItem offer={offer} isSliderType index={index} darkMode={darkMode} withOutCategory />
                      </Box>
                    ))}
                  </Slider>
                ) : (
                  <Box sx={{ display: 'flex' }}>
                    {offers.map((offer, index) => (
                      <Box
                        key={offer.id}
                        justifyContent='center'
                        px={2}
                        sx={{ display: 'flex !important', width: wideItem ? '100%' : 'auto' }}
                      >
                        <OfferItem
                          offer={offer}
                          isSliderType
                          index={index}
                          darkMode={darkMode}
                          wideItem={wideItem}
                          withOutCategory
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </AnimateHeight>
    );
  }
);
