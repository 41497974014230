import React from 'react';
import cx from 'classnames';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ReminderConfirmModal = ({ onClose, item, handleDeleteReminder }) => {
  return (
    <Modal className='reminder-delete-modal' isOpen>
      <ModalHeader toggle={onClose}>Do you sure want to delete?</ModalHeader>
      <ModalBody>
        <div className={cx('status', item.status)} />
        <h3 className='reminder-name'>{item.name}</h3>
      </ModalBody>

      <ModalFooter>
        <div className='btn cancel' onClick={onClose}>
          Cancel
        </div>
        <Button
          className='btn full'
          onClick={() => {
            handleDeleteReminder(item);
            onClose();
          }}
        >
          <span>Delete</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
