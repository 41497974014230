import { useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import api from '@packs/apis/category';
import colors from '@packs/assets/theme/base/colors';
import { getCategoryCriteria } from '@packs/models/category/helpers';

import useStyles from './styles';

import toNumber from 'lodash-es/toNumber';

const { secondary } = colors;

export const CriteriaInfo = ({ categoryId, period, price }) => {
  const styles = useStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const criteriaQuestions = getCategoryCriteria(categoryId);

  const periodLabel = period === 'per_year' ? 'per year' : 'per month';
  const crowdSourcePrice = data?.crowdSource?.price;
  const markedPrice = data?.market?.price;

  const marketPriceCalculated = useMemo(() => {
    const markedPeriod = data?.market?.period;

    if (period === markedPeriod) {
      return markedPrice;
    } else if (period === 'per_year' && markedPeriod === 'per_month') {
      return (markedPrice * 12).toFixed(0);
    } else if (period === 'per_month' && markedPeriod === 'per_year') {
      return (markedPrice / 12).toFixed(0);
    }
  }, [markedPrice, period]);
  const crowdSourcePriceCalculated = useMemo(() => {
    if (period === 'per_year') {
      return crowdSourcePrice;
    } else {
      return (crowdSourcePrice / 12).toFixed(0);
    }
  }, [crowdSourcePrice, period]);
  const differenceCalculated = price - crowdSourcePriceCalculated;

  const fetchData = async () => {
    setLoading(true);
    const resp = await api.fetchSavingsInformation({ categoryId, criteriaQuestions });
    setData(resp);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading || (!crowdSourcePrice && !markedPrice)) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      {!!data?.crowdSource?.price && (
        <>
          <Typography variant='body2'>
            On average members like you are paying{' '}
            <Box component='span' sx={{ color: secondary.main }}>
              €{toNumber(crowdSourcePriceCalculated).toFixed(0)} {periodLabel}!
            </Box>
          </Typography>
          {price &&
            crowdSourcePriceCalculated &&
            (differenceCalculated === 0 ? (
              <Typography variant='body2' sx={{ ...styles.difference, ...styles.equal }}>
                You are paying the average!
              </Typography>
            ) : (
              <Typography
                variant='body2'
                sx={{ ...styles.difference, ...(differenceCalculated > 0 ? styles.differenceNegative : {}) }}
              >
                You are paying {differenceCalculated > 0 ? 'above' : 'below'} average by €
                {toNumber(Math.abs(differenceCalculated)).toFixed(0)}
              </Typography>
            ))}
        </>
      )}
      {!!data?.market?.price && (
        <Typography variant='body2' sx={{ mt: 0.5 }}>
          Members like you could save up to{' '}
          <Box component='span' sx={{ color: secondary.main }}>
            €{toNumber(marketPriceCalculated).toFixed(0)}* {periodLabel}
          </Box>{' '}
          by signing up today.
          <Typography variant='body1'>*Based on your contract been with the most expensive provider today!</Typography>
        </Typography>
      )}
    </Box>
  );
};
