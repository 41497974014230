import React, { useRef } from 'react';
import cx from 'classnames';

import { CustomReactSelect } from '@packs/components';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import UploadFile from '@packs/images/icons/UploadFile';
import { runFormSubmit } from '@packs/lib/helpers';
import { useCategories } from '@packs/models/category/useCategories';

import AttachedDocument from '../attachedDocument';

import { observer } from 'mobx-react';
import { Field } from 'redux-form';

export const NoteForm = observer(
  ({
    files,
    general,
    loading,
    onFileAdd,
    removeFile,
    handleSubmit,
    openLightbox,
    additionalFiles,
    onRemoveAdditionalFile
  }) => {
    const { currentUser } = useUserSelectors();
    const { categoriesOptions } = useCategories();
    const formRef = useRef();

    return (
      <form ref={formRef} className='create-note-form' onSubmit={handleSubmit}>
        {general && (
          <div className='category-field'>
            <Field
              component={CustomReactSelect}
              name='categoryId'
              options={categoriesOptions}
              classNamePrefix='Select'
              className='gray-bg big'
              placeholder='Select category'
            />
          </div>
        )}
        <div className='form-field'>
          <Field
            name='title'
            type='text'
            component={FieldWithErrors}
            autoComplete='off'
            variant='outlined'
            label='Enter your Title'
            material
          />
        </div>

        <div className='form-field'>
          <Field
            name='body'
            componentType='textarea'
            component={FieldWithErrors}
            type='textarea'
            autoComplete='off'
            variant='outlined'
            label='Enter your Note'
            maxRows={15}
            minRows={15}
            material
            multiline
          />
        </div>

        <div className='form-footer'>
          <div className='d-flex align-items-start'>
            <div className='files-box d-flex align-items-center mr-14'>
              <label className='file-uploader'>
                <UploadFile />
                <input className='hidden' type='file' name='atach' id='atach' multiple onChange={onFileAdd} />
              </label>
            </div>
            <div className='attached-files'>
              {additionalFiles.map((file, index) => (
                <AttachedDocument
                  key={index}
                  removeFile={onRemoveAdditionalFile}
                  hideName
                  additional
                  {...{
                    index,
                    file,
                    openLightbox
                  }}
                />
              ))}
              {files.map((file, index) => (
                <AttachedDocument
                  key={index}
                  isNew
                  hideName
                  {...{
                    index,
                    file,
                    removeFile,
                    openLightbox
                  }}
                />
              ))}
            </div>
          </div>

          <div className='footer-actions d-flex justify-content-center'>
            {currentUser.id ? (
              <button className={cx('btn full black big submit', { disabled: loading })} type='submit'>
                <span>Save</span>
              </button>
            ) : (
              <SignUpModalLink
                onSignUpSuccess={() => {
                  runFormSubmit(formRef.current);
                }}
                skipRedirect={true}
              >
                Save
              </SignUpModalLink>
            )}
          </div>
        </div>
      </form>
    );
  }
);
