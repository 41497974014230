import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import _ from 'lodash-es';
import cx from 'classnames';

import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { renewalDetailApi } from '@packs/apis';
import { RadioButtonField, Switcher } from '@packs/components';
import { PriceField } from '@packs/components/PriceField';
import { getPeriodsList } from '@packs/components/WinnerModal/constants/constants';
// Images
import PaginationArrow from '@packs/images/icons/PaginationArrow';
import ArrowDownIcon from '@packs/images/svg/ui/arrow-down-gray.svg';
import { monthsList, numberWithCommas, perMonthCategories } from '@packs/lib/constants';
import { toastr } from '@packs/lib/helpers';
import { getIsoCode } from '@packs/lib/utils';
import { useStore } from '@packs/stores/base';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';

import { PersonalizeTab } from './components/Personalize';

import dayjs from 'dayjs';
import { getParamByISO } from 'iso-country-currency';
import AnimateHeight from 'react-animate-height';
// Components
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// Utils
import { Field } from 'redux-form';

const currentYear = dayjs().year();
const currentYearOption = { value: currentYear, label: currentYear };
const yearsList = _.map([dayjs().subtract(1, 'years'), dayjs()], dayValue => {
  const year = dayValue.year();

  return {
    value: year,
    label: year
  };
});

const makeDataMonthPrices = val => {
  return yearsList.reduce((memo, opt) => {
    memo[opt.value] = {
      months: monthsList.reduce((mm, option) => {
        mm[option.value] = val;
        return mm;
      }, {}),
      price: {
        period: 'per_year',
        value: val * 12
      }
    };
  });
};

const tabs = [
  {
    value: 'details',
    label: 'My Bill Details'
  },
  {
    value: 'personalize',
    label: 'Personalize'
  }
];

export const MyBillDetailsModal = ({ isOpen, toggle, change, formAttributes, handleSubmit, modalProps, reset }) => {
  const { currentCountry, currentCategory } = useStore();
  const { renewalDetail, successCallback, disabledCategory } = modalProps;
  const [activeYear, setActiveYear] = useState(currentYearOption);
  const [advancesIsOpen, setAdvancesIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const categoryName = currentCategory?.root.enName;
  const currencySymbol = getParamByISO(getIsoCode(currentCountry), 'symbol');
  const addSmartReminder = formAttributes?.addSmartReminder || false;
  const savedSmartReminder = renewalDetail?.smartReminder || {};

  const currentYearIndex = yearsList.findIndex(({ value }) => value === activeYear.value);
  const disabledPrevYear = currentYearIndex === 0;
  const disabledNextYear = currentYearIndex === yearsList.length - 1;
  const defaultPeriod =
    perMonthCategories.includes(currentCategory?.code) || perMonthCategories.includes(currentCategory?.root?.code)
      ? getPeriodsList[0].value
      : getPeriodsList[1].value;

  const perPeriod = formAttributes?.dataMonthPrices[activeYear.value]?.price?.period === 'per_month' ? 'p/m' : 'p/y';

  // const onDateFieldChange = date => {
  //   console.log(date);
  //   const dateSelect = dayjs(date).add(1, 'hour');
  //
  //   change('smartReminder.triggeredAt', dateSelect);
  // };

  const handleFormSubmit = () => {
    const newFormData = {
      renewalDetailId: renewalDetail?.id || null,
      categoryId: currentCategory.id,
      ...formAttributes
    };

    if (!addSmartReminder) {
      delete newFormData.smartReminder;
    } else {
      newFormData.smartReminder.triggeredAt = dayjs(newFormData.smartReminder.triggeredAt).toDate();
    }

    if (formAttributes?.dataMonthPrices) {
      newFormData.dataMonthPrices = yearsList.reduce((result, opt) => {
        if (_.get(formAttributes, `dataMonthPrices.${opt.value}.months`)) {
          result[opt.value] = {
            months: newFormData.dataMonthPrices[opt.value].months,
            price: newFormData.dataMonthPrices[opt.value].price
          };
        }
        return result;
      }, {});
    }
    newFormData.pricesQuestions = { price: newFormData.dataMonthPrices[currentYear]?.price };
    delete newFormData.addSmartReminder;

    renewalDetailApi.updateOrCreateBillDetails(newFormData).then(res => {
      if (res?.errors.length === 0) {
        if (successCallback) {
          successCallback(
            formAttributes?.dataMonthPrices[activeYear.value].price.period,
            currentCategory.id,
            res.renewalDetail
          );
          toggle();
          reset();
        }
      } else {
        toastr.error(res.errors[0].message);
      }
    });
  };

  const toggleAdvanced = () => {
    setAdvancesIsOpen(!advancesIsOpen);
  };

  const handlePrevYear = () => {
    if (!disabledPrevYear) {
      setActiveYear(yearsList[currentYearIndex - 1]);
    }
  };

  const handleNextYear = () => {
    if (!disabledNextYear) {
      setActiveYear(yearsList[currentYearIndex + 1]);
    }
  };

  const calcPriceForEachMonth = (data = 0, period) => {
    const pricePerMonth =
      (period || _.get(formAttributes, `dataMonthPrices.${activeYear.value}.price.period`) || defaultPeriod) ===
      'per_year'
        ? data / 12 || 0
        : data;

    const months = {};
    monthsList.forEach(({ value }) => {
      months[value] = +pricePerMonth || 0;
    });

    change(`dataMonthPrices.${activeYear.value}.months`, months);
  };

  const handleTotalPriceChange = (data, period) => {
    calcPriceForEachMonth(data, period);
  };

  const handleActiveTab = data => {
    setActiveTab(data);
  };

  const handleMonthPriceChange = (data, name) => {
    const perMonth = formAttributes?.dataMonthPrices[activeYear.value].price.period === 'per_month';
    const dataMonthPrices = { ...formAttributes?.dataMonthPrices } || {};
    const currentMonthKey = name.split('.')[3];
    const months = dataMonthPrices[activeYear?.value].months || {};
    const value = Object.keys(months).reduce((accumulator, key) => {
      const currentPrice = key === currentMonthKey ? data : months[key];
      return accumulator + +(currentPrice || '');
    }, 0);

    change(`dataMonthPrices.${activeYear.value}.price.value`, perMonth ? +(value / 12) : +value);
  };

  const handleChangePeriod = data => {
    const price = formAttributes?.dataMonthPrices[activeYear.value]?.price?.value;

    calcPriceForEachMonth(price, data);
  };

  const toggleStatus = () => {
    change('addSmartReminder', !formAttributes?.addSmartReminder);
  };

  useEffect(() => {
    const nextYearTotalPrice = formAttributes?.dataMonthPrices[currentYear + 1]?.price?.value;

    if (!nextYearTotalPrice) {
      change(`dataMonthPrices.${activeYear.value}`, formAttributes?.dataMonthPrices[currentYear]);
    }
  }, [activeYear]);

  useEffect(() => {
    if (renewalDetail?.pricesQuestions) {
      change('pricesQuestions', toJS(renewalDetail.pricesQuestions));
    }
    if (renewalDetail?.dataMonthPrices) {
      change('dataMonthPrices', toJS(renewalDetail.dataMonthPrices));
    } else {
      change('dataMonthPrices', makeDataMonthPrices(0));
    }
  }, [renewalDetail?.id]);

  useEffect(() => {
    if (savedSmartReminder.id) {
      change('smartReminder.id', savedSmartReminder.id);
      change(
        'smartReminder.triggeredAt',
        (savedSmartReminder.triggeredAt && dayjs(savedSmartReminder.triggeredAt)) || dayjs().add(1, 'day')
      );
      change('addSmartReminder', true);
    }
  }, [savedSmartReminder]);

  useEffect(() => {
    if (isOpen) {
      if (!(renewalDetail?.dataMonthPrices && renewalDetail?.dataMonthPrices[activeYear.value])) {
        const renewalDetailPeriod = renewalDetail?.pricesQuestions?.price?.period || defaultPeriod;
        const renewalDetailPrice = renewalDetail?.pricesQuestions?.price?.value || '';

        change(`dataMonthPrices.${activeYear.value}.price.period`, renewalDetailPeriod);
        change(`dataMonthPrices.${activeYear.value}.price.value`, renewalDetailPrice);
        handleTotalPriceChange(renewalDetailPrice ? renewalDetailPrice : 0);
      }
      if (renewalDetail?.smartReminder?.triggeredAt) {
        change('smartReminder.triggeredAt', dayjs(renewalDetail?.smartReminder?.triggeredAt));
      } else {
        change('smartReminder.triggeredAt', dayjs().add(1, 'year').add(1, 'day'));
      }
      if (!savedSmartReminder.id) {
        change('addSmartReminder', false);
      }
    } else {
      reset();
      setAdvancesIsOpen(false);
      handleActiveTab(tabs[0].value);
    }
  }, [isOpen]);

  return (
    <Modal className={cx('modal-dialog winner-modal my-bill-details-modal', categoryName)} {...{ isOpen, toggle }}>
      <ModalHeader toggle={toggle}>
        <div className='d-flex align-items-center modal-title'>
          <div className='icon-container theme-bg d-flex align-items-center justify-content-center'>
            {SUB_ICONS({ category: currentCategory })}
          </div>

          <div>{currentCategory.name}</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <form className='form' onSubmit={handleSubmit(handleFormSubmit)}>
          <div className='modal-content-inner'>
            <div className='d-flex'>
              <div className='tabs-ui'>
                {tabs.map(({ value, label }) => (
                  <div
                    key={value}
                    className={cx('tab-item-ui', value === activeTab && 'active')}
                    onClick={() => handleActiveTab(value)}
                  >
                    {label}
                  </div>
                ))}
              </div>
            </div>

            <div className={cx('tab-content', activeTab === 'details' && 'active')}>
              <div className='form-container'>
                <div className='label-title d-flex align-items-center justify-content-between'>
                  My Cost
                  <div className='d-flex align-items-center'>
                    <span
                      className={cx('pagination-arrow left', disabledPrevYear && 'disabled')}
                      onClick={handlePrevYear}
                    >
                      <PaginationArrow />
                    </span>
                    <span className='ml-10 mr-10'>{activeYear.label}</span>
                    <span
                      className={cx('pagination-arrow right', disabledNextYear && 'disabled')}
                      onClick={handleNextYear}
                    >
                      <PaginationArrow />
                    </span>
                  </div>
                </div>
                <div className='form-row-list align-items-center'>
                  <div className='input-field price-input-field'>
                    <Field
                      name={`dataMonthPrices.${activeYear.value}.price.value`}
                      currencySymbol={currencySymbol}
                      component={PriceField}
                      placeholder='Price...'
                      className='circle with-border'
                      handleChange={value => handleTotalPriceChange(value)}
                      customNumeric
                    />
                  </div>
                  <div className='form-row-list row-2 input-field period-input-field flex-row'>
                    <Field
                      name={`dataMonthPrices.${activeYear.value}.price.period`}
                      options={getPeriodsList}
                      component={RadioButtonField}
                      handleChange={handleChangePeriod}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='d-flex align-items-center fs-14 fw-500 mb-20 cursor-pointer' onClick={toggleAdvanced}>
                    <span>Advanced cost</span>{' '}
                    <img src={ArrowDownIcon} alt='arrow' className={cx('advanced-arrow', { open: advancesIsOpen })} />
                  </div>
                </div>
                <AnimateHeight duration={250} height={advancesIsOpen ? 'auto' : 0}>
                  <div className='advanced-cost'>
                    <div className='advanced-list-month d-flex'>
                      {monthsList.map(({ value, label }) => (
                        <div key={value} className='month-item'>
                          <label className='label'>{label}</label>
                          <Field
                            name={`dataMonthPrices.${activeYear.value}.months.${value}`}
                            component={PriceField}
                            placeholder={label}
                            className='circle with-border small'
                            handleChange={handleMonthPriceChange}
                            customNumeric
                          />
                        </div>
                      ))}
                    </div>
                    <div className='total-cost'>
                      Total cost:
                      <span className='total-cost-price'>
                        {formAttributes?.dataMonthPrices[activeYear.value]?.price?.value
                          ? numberWithCommas(formAttributes?.dataMonthPrices[activeYear.value].price.value.toFixed(2)) +
                            currencySymbol
                          : 0}
                      </span>
                      <span className='total-cost-period'>{perPeriod}</span>
                    </div>
                  </div>
                </AnimateHeight>
                <div className='label-title d-flex align-items-center'>
                  <span className='mr-15'>Set Reminder</span>
                  <Switcher
                    textOff='Off'
                    textOn='On'
                    value={formAttributes?.addSmartReminder}
                    onChange={toggleStatus}
                  />
                </div>
                <div className='form-row-list'>
                  <div className='input-field'>
                    <div className='reminder-wrapper d-flex'>
                      <Field
                        name='smartReminder.triggeredAt'
                        disabled={!addSmartReminder}
                        component={({ input, disabled, meta: { error } }) => (
                          <FormControl fullWidth error={!!error}>
                            <DatePicker
                              label='Renewal date'
                              value={dayjs(input.value)}
                              onChange={input.onChange}
                              disabled={disabled}
                            />
                            <FormHelperText error>{error}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={cx('tab-content', activeTab === 'personalize' && 'active')}>
              <PersonalizeTab
                category={currentCategory}
                {...{
                  renewalDetail,
                  change,
                  formAttributes,
                  toggle,
                  disabledCategory
                }}
              />
            </div>
          </div>

          <div className='btn-container'>
            <div className='btn cancel' onClick={toggle}>
              Cancel
            </div>
            <button type='submit' className={cx('btn empty black submit')}>
              Save
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
