import React from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import { EmailInvite } from '@packs/components/UI';
import Facebook from '@packs/images/icons/Facebook';
import LinkedIn from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';
import CopyIcon from '@packs/images/icons/UI/CopyIcon';
import WhatsApp from '@packs/images/icons/WhatsApp';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import Clipboard from 'react-clipboard.js';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const InviteFriendsModal = ({ isOpen, toggle, modalProps }) => {
  const { currentCategory } = useStore();
  const customUrl = modalProps?.customUrl || null;
  const categoryName = currentCategory && currentCategory.root && currentCategory.root.enName;
  const currentUrl = customUrl || location.origin + location.pathname;
  const inviteMessage =
    'Hey, I would like to invite you to Bill Winner, the world’s first crowd-sourced service for renewals!';
  const onSuccess = () => {
    toastr.success('Successfully copied');
  };

  return (
    <Modal className={cx('modal-dialog small invite-friends-modal', categoryName)} {...{ isOpen, toggle }}>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <div className='invite-section'>
          <div className='title'>Invite Friends and Earn Points</div>
          <p className='fs-16'>
            You`ll receive <span>+ 1 point</span> when the person you invite signs up for a Bill Winner account.
            <br /> Win-Win!
          </p>
        </div>
        <Box className='invite-section' sx={{ maxWidth: '400px' }}>
          <div className='title'>Invite a friend</div>
          <div className='email-invite d-flex align-items-center'>
            <EmailInvite />
          </div>
        </Box>
        <div className='invite-section bottom-section'>
          <div className='title'>Share on Social Media</div>
          <div className='invite-socials-btn-list d-flex gap-16'>
            <FacebookShareButton url={currentUrl} quote={inviteMessage} className='invite-social-btn facebook'>
              <Facebook />
            </FacebookShareButton>
            <LinkedinShareButton url={currentUrl} title={inviteMessage} className='invite-social-btn linkedin'>
              <LinkedIn />
            </LinkedinShareButton>
            <TwitterShareButton url={currentUrl} title={inviteMessage} className='invite-social-btn twitter'>
              <Twitter />
            </TwitterShareButton>
            <WhatsappShareButton url={currentUrl} title={inviteMessage} className='invite-social-btn whatsApp'>
              <WhatsApp />
            </WhatsappShareButton>
          </div>
        </div>
        <div className='invite-section bottom-section'>
          <div className='title'>Share this link</div>
          <Clipboard
            component='a'
            data-clipboard-text={customUrl ? customUrl : 'https://www.billwinner.com/'}
            onSuccess={onSuccess}
          >
            <span className='cursor-pointer primary-text d-flex align-items-center gap-4'>
              Copy invite link
              <CopyIcon />
            </span>
          </Clipboard>
          {/* <CopyText text={ customUrl ? customUrl : 'https://www.billwinner.com/' }  />*/}
        </div>
      </ModalBody>
    </Modal>
  );
};
