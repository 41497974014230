import React, { useEffect, useState } from 'react';
import _, { set, size } from 'lodash-es';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Loader } from '@packs/components';
import { useSetUserAttrsToStore } from '@packs/hooks/useSetUserAttrsToStore';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import AuthIcon from '@packs/images/ui/sign-up-sidebar.webp';
import { IUserFormAttributes } from '@packs/layouts/AppLayer/components/AuthModal/AuthModal';
import useStyles from '@packs/layouts/AppLayer/components/AuthModal/styles';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { serializeCountryName } from '@packs/models/country/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { AcceptInviteForm } from './components/Form';

import { MacScrollbar } from 'mac-scrollbar';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

const benefits = [
  'Get personalized results and insights!',
  'Get competitor offers!',
  'All your bill data is kept securely in one place!',
  'Get member rewards!',
  'Get reminder notifications prior to your next renewal!',
  'Create a more competitive and transparent marketplace for bills!'
];

export const AcceptInviteModal = ({ toggle, isOpen }) => {
  const styles = useStyles();
  const { isDesktop } = useWindowSize();
  const { invitationToken, email = '', socialInvitationToken } = queryString.parse(location?.search);
  const { userStore, currentCountry, countryCode, categoryStore } = useStore();
  const { currentUser, getSignUpFormInitialValues } = useUserSelectors();
  const { INVITE_FRIENDS_PATH } = usePath();
  const navigate = useNavigate();
  const initialValues = getSignUpFormInitialValues(countryCode, currentUser, {});
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const setUserAttrsToStore = useSetUserAttrsToStore();

  const countryOption = {
    ...currentCountry,
    label: serializeCountryName(currentCountry.enName),
    value: currentCountry.isoA2Code,
    countryCode: currentCountry.isoA2Code
  };

  if (countryOption) set(initialValues, 'country', countryOption);

  const [loading, setLoading] = useState(false);
  const [socialUser, setSocialUser] = useState({});

  const handleBackSignUp = () => {
    setSocialUser({});
    setShowSignUpForm(false);
  };

  const acceptInviteHandler = (userAttributes = {} as IUserFormAttributes) => {
    const variables = {
      ...userAttributes,
      registerEvent: 'trust_network'
    } as IUserFormAttributes;
    if (invitationToken) variables.invitationToken = invitationToken as string;
    if (socialInvitationToken) variables.socialInvitationToken = socialInvitationToken as string;

    delete variables?.socialName;
    delete variables?.secret;
    delete variables?.accessToken;
    delete variables?.showSalesCode;

    setUserAttrsToStore(variables);
    setLoading(true);

    const callback = () => {
      setLoading(false);

      navigate(INVITE_FRIENDS_PATH);

      if (userAttributes?.accessToken && userAttributes?.secret) {
        userStore.connectSocial({
          type: userAttributes?.socialName,
          secret: userAttributes?.secret,
          accessToken: userAttributes?.accessToken
        });
      }
      toggle();
      categoryStore.loadCategories(countryCode);
    };
    const errorCallback = (errors = []) => {
      setLoading(false);
      toastr.error(errors[0]);
    };

    (socialInvitationToken ? userStore.userAcceptInvitationBySocial : userStore.userAcceptInvitationByEmail)({
      callback,
      errorCallback
    });
  };

  useEffect(() => {
    if (currentUser.id && isOpen) {
      toggle();
    }
  }, [currentUser.id, isOpen]);

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={toggle}
      PaperProps={{
        style: {
          maxWidth: '1440px',
          width: '100%',
          margin: '24px 16px',
          borderRadius: '24px',
          overflow: 'hidden'
        }
      }}
    >
      {loading && <Loader />}
      <Box sx={styles.wrapper}>
        {isDesktop && (
          <Box sx={styles.infoSection}>
            <MacScrollbar
              style={{ flex: 1, maxHeight: 'calc(100vh - 82px)' }}
              trackStyle={horizontal => ({
                [horizontal ? 'height' : 'width']: 0,
                right: 2,
                border: 0
              })}
              thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
              suppressAutoHide
            >
              <Box sx={styles.infoSectionContainer}>
                <Box sx={styles.infoSectionContent}>
                  <Typography variant='h4' sx={styles.title}>
                    Become a member of the Bill Winner community!
                  </Typography>
                  <Typography variant='h6' sx={styles.subTitle}>
                    Bill Winner Member Benefits:
                  </Typography>
                  <Box component='ul' sx={styles.benefitsWrapper}>
                    {benefits.map(item => (
                      <li key={item}>
                        <CheckRoundedIcon />
                        <Typography variant='body1'>{item}</Typography>
                      </li>
                    ))}
                  </Box>
                </Box>
                <Box sx={styles.authSidebarImgWrapper}>
                  <img src={AuthIcon} alt='Auth' />
                </Box>
              </Box>
            </MacScrollbar>
          </Box>
        )}
        <Box sx={styles.formWrapper}>
          <DialogTitle sx={styles.modalTitle}>
            {showSignUpForm ? (
              <IconButton edge='end' color='inherit' onClick={handleBackSignUp}>
                <ArrowBackRoundedIcon />
              </IconButton>
            ) : (
              <Box />
            )}
            <IconButton edge='end' color='inherit' onClick={toggle}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <MacScrollbar
            style={{ flex: 1, maxHeight: 'calc(100vh - 145px)' }}
            trackStyle={horizontal => ({
              [horizontal ? 'height' : 'width']: 0,
              right: 2,
              border: 0
            })}
            thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
            suppressAutoHide
          >
            <Box sx={styles.formContainer}>
              <AcceptInviteForm
                initialValues={{
                  ...initialValues,
                  email
                }}
                {...{ showSignUpForm, setShowSignUpForm, setSocialUser, socialUser }}
                onSubmit={acceptInviteHandler}
              />
            </Box>
          </MacScrollbar>
        </Box>
      </Box>
    </Dialog>
  );
};
