import Box from '@mui/material/Box';
import colors from '@packs/assets/theme/base/colors';

import { Link } from 'react-router-dom';

const { white, primary } = colors;

export const FooterLink = ({ to, label, onClick, ...res }) => {
  return (
    <Box mb={2}>
      <Box
        {...res}
        component={Link}
        to={to}
        onClick={onClick}
        pl={2}
        sx={{
          position: 'relative',
          fontSize: '14px',
          fontWeight: '500',
          color: white.main,
          '&:hover': {
            color: white.main
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: '50%',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: primary.main,
            transform: 'translateY(-50%)'
          }
        }}
      >
        {label}
      </Box>
    </Box>
  );
};
