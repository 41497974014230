export const plans = {
  free: 'free',
  small: 'small',
  enterprise: 'enterprise'
};

export const countryPlanPrices = {
  ie: {
    currency: '€',
    [plans.small]: '99',
    [plans.enterprise]: '299'
  },
  uk: {
    currency: '£',
    [plans.small]: '99',
    [plans.enterprise]: '299'
  },
  us: {
    currency: '$',
    [plans.small]: '99',
    [plans.enterprise]: '299'
  },
  ca: {
    currency: 'Can $',
    [plans.small]: '125',
    [plans.enterprise]: '375'
  },
  in: {
    currency: 'INR',
    [plans.small]: '7,000',
    [plans.enterprise]: '23,000'
  },
  au: {
    currency: 'Aus $',
    [plans.small]: '130',
    [plans.enterprise]: '380'
  },
  sg: {
    currency: 'S $',
    [plans.small]: '135',
    [plans.enterprise]: '400'
  },
  za: {
    currency: 'Rand',
    [plans.small]: '1,485',
    [plans.enterprise]: '4,400'
  },
  nz: {
    currency: 'NZ $',
    [plans.small]: '130',
    [plans.enterprise]: '380'
  },
  de: {
    currency: '€',
    [plans.small]: '99',
    [plans.enterprise]: '299'
  },
  at: {
    currency: '€',
    [plans.small]: '99',
    [plans.enterprise]: '299'
  },
  ch: {
    currency: 'Franc',
    [plans.small]: '99',
    [plans.enterprise]: '299'
  }
};
