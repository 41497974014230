import React from 'react';
import cx from 'classnames';

// Images
import CheckboxIcon from '@packs/images/icons/CheckboxIcon';

export const CustomCheckBox = ({
  value,
  checked,
  label,
  onChange,
  theme,
  name,
  rightSideCheck,
  disabled,
  primary,
  onLabelClick
}) => {
  return (
    <label
      className={cx('custom-checkbox', {
        theme,
        'right-side-check': rightSideCheck,
        disabled,
        primary
      })}
    >
      <input
        type='checkbox'
        {...{
          name,
          value,
          checked
        }}
        onChange={({ target: { checked } }) => {
          onChange(checked);
        }}
      />
      <span className='checkmark radio-mark'>{checked && <CheckboxIcon />}</span>
      <span className='checkbox-label' onClick={onLabelClick}>
        {label}
      </span>
    </label>
  );
};
