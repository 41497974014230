import React from 'react';
import cx from 'classnames';

import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import OfferPlaceholderIcon from '@packs/images/svg/ui/offer-placeholder-icon.svg';
import { pathGen, usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { OtherOffers } from '@packs/screens/MyOffersDetailed/components/OtherOffers';
import { useStore } from '@packs/stores';
import { generalIcon } from '@shared/helpers/offerGeneralIconsHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Link } from 'react-router-dom';

export const OfferContent = ({ offer }) => {
  const { isMobile } = useWindowSize();
  const { countryCode, leaderboardStore, categoryStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { ADD_DETAILS_PATH_FN } = usePath();

  const { bannerUrl, offerDetails, generalPointAttributes, terms } = offer;
  const { category, company } = offer || {};

  const havePolicy = categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser);

  const onClickRequestQuotes = () => {
    categoryStore.setCurrentCategory(category);
    leaderboardStore.addRequestBusiness(company);
  };

  return (
    <div className='offer-content'>
      <div
        className={cx(
          'img-container d-flex align-items-center justify-content-center mb-24',
          isMobile ? 'mb-24' : 'mb-8',
          { filled: bannerUrl }
        )}
      >
        <img src={bannerUrl || OfferPlaceholderIcon} alt='Offer image' />
      </div>
      {offerDetails && <div className={cx('lh-9 wb-bw', isMobile ? 'fs-13' : 'fs-16')}>{offerDetails}</div>}
      {isMobile && company?.quoteAgent && (
        <div className='d-flex justify-content-center mt-8'>
          {havePolicy ? (
            <Link
              className='btn full white'
              to={pathGen('REQUEST_QUOTES', { countryCode, category })}
              onClick={onClickRequestQuotes}
            >
              <span>Request quotes</span>
            </Link>
          ) : (
            <Link className='btn full white' to={ADD_DETAILS_PATH_FN(category)} onClick={onClickRequestQuotes}>
              <span>Request quotes</span>
            </Link>
          )}
        </div>
      )}
      {generalPointAttributes.length > 0 && (
        <div className='offer-info-section'>
          <div className={cx('fw-700 lh-7 mb-8', isMobile ? 'fs-16' : 'fs-23')}>General point for new customer.</div>
          {generalPointAttributes.map(({ text, icon }, index) => {
            const imgSrc = generalIcon(icon);
            return (
              <div key={icon} className='offer-general-point d-flex align-items-center gap-16'>
                {imgSrc && (
                  <div className='icon-container d-flex align-items-center justify-content-center'>
                    <img src={imgSrc} alt={icon} />
                  </div>
                )}
                <div
                  className={cx('lh-9 wb-bw', isMobile ? 'fs-13' : 'fs-16')}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
            );
          })}
        </div>
      )}
      {terms && (
        <div className='offer-info-section'>
          <div className={cx('fw-700 lh-7 mb-8', isMobile ? 'fs-16' : 'fs-23')}>Terms and conditions.</div>
          <div
            className={cx('lh-10 wb-bw', isMobile ? 'fs-11' : 'fs-13')}
            dangerouslySetInnerHTML={{ __html: terms }}
          />
        </div>
      )}
      {!isMobile && (
        <div className='offer-info-section'>
          <OtherOffers offer={offer} />
        </div>
      )}
    </div>
  );
};
