import colors from '@packs/assets/theme/base/colors';

const { white, grey, dark } = colors;

export default () => ({
  wrapper: (darkMode, wideItem = false) => ({
    display: 'flex',
    flexDirection: wideItem ? 'row' : 'column',
    gap: wideItem ? 2 : 0,
    width: {
      xs: wideItem ? '100%' : '200px',
      md: wideItem ? '100%' : '258px'
    },
    mx: {
      xs: 'auto',
      sm: 0
    },
    minWidth: {
      xs: wideItem ? '100%' : '200px',
      md: wideItem ? '100%' : '258px'
    },
    minHeight: wideItem ? '212px' : '345px',
    borderRadius: '8px',
    border: '1px solid transparent',
    p: 2,
    cursor: 'pointer',
    borderColor: darkMode ? dark.transparent : 'rgba(255, 255, 255, 0.16)',
    backgroundColor: darkMode ? white.main : 'rgba(255, 255, 255, 0.20)'
  }),
  imgContainer: (darkMode, bannerUrl, wideItem) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: wideItem ? '336px' : '100%',
    minWidth: wideItem ? '336px' : '100%',
    height: wideItem ? '180px' : '125px',
    mb: 2,
    borderRadius: '8px',
    backgroundColor: grey[100],
    border: `1px solid ${darkMode ? dark.tonal : 'transparent'}`,
    overflow: 'hidden',
    '& img': {
      ...(bannerUrl && {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      })
    }
  }),
  categoryWrapper: halfWidth => ({
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    maxWidth: halfWidth ? '50%' : '100%',
    borderRadius: '4px',
    px: 0.5,
    py: 0.5,
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    '& svg': {
      fill: `${white.main} !important`
    }
  }),
  categoryText: {
    color: white.main,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  typeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: '100%'
  },
  offerMessage: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical'
  },
  wideContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});
