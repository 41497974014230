import React, { useMemo } from 'react';
import _ from 'lodash-es';

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { categoryApi } from '@packs/apis/category';
import colors from '@packs/assets/theme/base/colors';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { IconBox } from '@shared/components/IconBox/IconBox';

import { useNavigate } from 'react-router-dom';

const { secondary, dark } = colors;

export const Actions = ({
  handleClose,
  renewalDetail,
  toggleMyBill,
  category,
  item,
  onClickEditReminder,
  toggleReminderFormModal,
  handleCreateReminderCallback,
  disabledCategory,
  paddingHorizontal = 1
}) => {
  const { toggleConfirmModalOpen } = useModalsContext();
  const { ADD_DETAILS_PATH_FN, MY_DETAILS_PATH_FN } = usePath();
  const navigate = useNavigate();
  const { homeStore, categoryStore } = useStore();
  const { smartReminder } = renewalDetail;
  const disabled = disabledCategory(item?.root?.code);

  const handleClick = () => {
    handleClose && handleClose();
  };

  const handleRemoveBill = () => {
    toggleConfirmModalOpen({
      modalProps: {
        title: 'Remove Bill',
        description: 'Are you sure you want to remove this bill?',
        submitBtnText: 'Remove',
        onSubmit: async () => {
          const res = await categoryApi.categorySubscribe({ categoryId: category.id, selected: false });
          if (res?.errors.length === 0) {
            categoryStore.markCategorySelect({ id: category.id, selected: false });
            homeStore.removeBill({ id: category.id });
          }

          return res;
        }
      }
    });
  };

  const list = useMemo(() => {
    return [
      {
        title: !(renewalDetail?.renewalCostMonthly || renewalDetail?.renewalCostYearly) ? 'Add Cost' : 'Update Cost',
        icon: <EditOutlinedIcon />,
        onClick: () => {
          toggleMyBill(category, renewalDetail, item);
        }
      },
      {
        type: 'pdf',
        title: 'Export Policy as PDF',
        icon: <PictureAsPdfOutlinedIcon />,
        additionalProps: {
          component: 'a',
          href: location?.origin + `/download-my-details/${renewalDetail?.id}`,
          rel: 'noreferrer'
        }
      },
      {
        title: smartReminder?.id ? 'Edit Reminder' : 'Add Reminder',
        icon: <EventRepeatRoundedIcon />,
        onClick: () => {
          if (smartReminder?.id) {
            onClickEditReminder(_.clone(smartReminder), renewalDetail, item, category);
          } else {
            toggleReminderFormModal({
              renewalDetail,
              category,
              disabledCategory: true,
              shouldShowDetails: true,
              onSuccess: data => handleCreateReminderCallback(data, category)
            });
          }
        }
      },
      {
        type: 'policy',
        title: renewalDetail.id ? 'Update Details' : 'Add Policy Details',
        icon: <TaskRoundedIcon />,
        onClick: () => {
          categoryStore.setCurrentCategory(category);
          navigate(renewalDetail.id ? MY_DETAILS_PATH_FN(renewalDetail, category) : ADD_DETAILS_PATH_FN(category));
        }
      },
      {
        type: 'policy',
        title: item?.isPinned ? 'Remove Pin' : 'Pin to the Top',
        icon: item?.isPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />,
        onClick: () => {
          if (item?.isPinned) {
            homeStore.dashboardUnPinCategory(item?.id);
          } else {
            homeStore.dashboardPinCategory(item?.id);
          }
        }
      },
      {
        type: 'divider'
      },
      {
        title: 'Remove Bill',
        icon: <DeleteRoundedIcon />,
        iconBG: dark.transparent,
        iconColor: 'rgba(0, 0, 0, 0.6)',
        onClick: handleRemoveBill
      }
    ].filter(
      item =>
        !(item.type === 'pdf' && !renewalDetail.id) &&
        !(item.type === 'policy' && disabled) &&
        !(item.type === 'autoQuote' && !renewalDetail?.id)
    );
  }, [renewalDetail, category, item]);

  return (
    <>
      {list.map((item, index) => {
        if (item?.type === 'divider') {
          return <Divider key={item?.type + index} />;
        }

        return (
          <MenuItem
            {...item.additionalProps}
            key={item.title}
            onClick={() => {
              handleClick();
              item.onClick && item.onClick();
            }}
            sx={{ gap: 1, px: paddingHorizontal }}
          >
            <IconBox
              containerSize='32px'
              backgroundColor={item?.iconBG || 'rgba(1, 103, 205, 0.08)'}
              color={item?.iconColor || secondary.main}
              iconSize='24px'
              icon={item.icon}
            />
            <Typography variant='body4'>{item.title}</Typography>
          </MenuItem>
        );
      })}
    </>
  );
};
