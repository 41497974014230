import React, { useEffect, useMemo, useState } from 'react';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { toastr } from '@packs/lib/helpers';
import { loadState, saveState } from '@packs/lib/utils/localStorage';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import useStyles from './styles';

import dayjs from 'dayjs';
import startCase from 'lodash-es/startCase';

export const LeaderboardReminder = () => {
  const { smartRemindersStore } = useStore();
  const { currentPolicy } = useDetails();
  const { currentCategory } = useCategories();
  const styles = useStyles();
  const closedCategories = loadState('closed_reminder_categories');

  const [page, setPage] = useState<number>(1);
  const [monthList, setMonthList] = useState<string[]>([]);
  const [hideReminder, setHideReminder] = useState<boolean>(true);

  const displayYear = useMemo(() => {
    const startYear = dayjs(monthList[0], 'YYYY').format('YYYY');
    const finishYear = dayjs(monthList[monthList.length - 1], 'YYYY').format('YYYY');

    if (startYear === finishYear) {
      return finishYear;
    }

    return `${startYear}-${finishYear}`;
  }, [page, monthList]);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handleCloseReminder = () => {
    if (closedCategories && closedCategories.length) {
      saveState('closed_reminder_categories', [...closedCategories, currentCategory.id]);
    } else {
      saveState('closed_reminder_categories', [currentCategory.id]);
    }
    setHideReminder(true);
  };

  const handleSelectDate = (triggeredAt: string) => () => {
    const payload = {
      reminderType: 'select',
      renewalDetailId: currentPolicy?.id,
      categoryId: currentCategory.id,
      triggeredAt: dayjs(triggeredAt).format('YYYY-DD-MM')
    };
    smartRemindersStore.create(
      payload,
      () => toastr.success('Your smart reminder has been added.'),
      err => err.triggeredAt && toastr.error(startCase(err.triggeredAt[0]))
    );
  };

  useEffect(() => {
    const generateMonthList = () => {
      const nextMonth = dayjs().add(1, 'month').startOf('month');
      const startMonth = nextMonth.add(page - 1, 'year');
      const months = [];
      for (let i = 0; i < 12; i++) {
        months.push(startMonth.add(i, 'month').format('DD MM YYYY'));
      }
      setMonthList(months);
    };

    generateMonthList();
  }, [page]);

  useEffect(() => {
    setTimeout(() => {
      setHideReminder(closedCategories && closedCategories.some(category => category === currentCategory.id));
    }, 500);
  }, [closedCategories]);

  if (hideReminder) {
    return null;
  }

  return (
    <Box sx={styles.wrapper}>
      <IconButton sx={styles.closeIcon} onClick={handleCloseReminder}>
        <CloseRoundedIcon size='large' color='dark' />
      </IconButton>
      <Typography variant='h5' gutterBottom sx={{ pr: 5 }}>
        Don't miss out on the best offers at renewal time!
      </Typography>
      <Typography variant='body1' gutterBottom>
        Please tell us the month when you renew so that we can notify you at renewal time! Thank you!
      </Typography>
      <Box sx={styles.pageSwitcherWrapper}>
        <IconButton onClick={handlePrevPage} disabled={page <= 1}>
          <ChevronLeftRoundedIcon />
        </IconButton>
        <Typography variant='body4'>{displayYear}</Typography>
        <IconButton onClick={handleNextPage}>
          <ChevronRightRoundedIcon />
        </IconButton>
      </Box>
      <Box sx={styles.monthList}>
        {monthList.map(month => {
          const date = dayjs(month, 'DD MM YYYY');
          const formattedDate = date.format('MMMM YYYY');

          return (
            <Box key={month} onClick={handleSelectDate(month)} sx={styles.monthItem}>
              {formattedDate}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
