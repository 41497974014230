import colors from '@packs/assets/theme/base/colors';
import pxToRem from '@shared/assets/theme/functions/pxToRem';

const { grey, white } = colors;

const borderRadius = '8px';

const tabs = {
  variants: [
    {
      props: { variant: 'buttonTabs' },
      style: {
        position: 'relative',
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
        borderRadius,
        minHeight: 'unset',
        padding: pxToRem(4),

        '& .MuiTabs-flexContainer': {
          height: '100%',
          position: 'relative',
          zIndex: 10
        },

        '&.MuiTabs-fixed': {
          overflow: 'unset !important',
          overflowX: 'unset !important'
        },

        '&.MuiTabs-vertical .MuiTabs-indicator': {
          width: '100%'
        },

        '& .MuiTabs-indicator': {
          height: '100%',
          borderRadius: '4px',
          backgroundColor: white.main,
          transition: 'all 500ms ease'
        }
      }
    }
  ]
};

export default tabs;
