import React from 'react';

export default () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.53399 2.99805C9.81539 2.99805 10.0665 3.17473 10.1616 3.43956L10.5004 4.38329C10.5542 4.53169 10.6586 4.65639 10.7952 4.73544L11.8003 5.31568C11.9368 5.39443 12.0967 5.42231 12.2518 5.39438L13.2389 5.2163C13.5161 5.1659 13.795 5.2951 13.9358 5.53911L14.4694 6.45949C14.6106 6.70283 14.5839 7.0086 14.4027 7.22381L13.7551 7.98946C13.6531 8.10985 13.5971 8.26251 13.597 8.4203V9.58079C13.5971 9.73858 13.6531 9.89124 13.7551 10.0116L14.4027 10.7773C14.5839 10.9925 14.6106 11.2983 14.4694 11.5416L13.9358 12.462C13.7951 12.7057 13.5165 12.8349 13.2395 12.7848L12.2525 12.6067C12.0974 12.5788 11.9374 12.6067 11.8009 12.6854L10.7959 13.2657C10.6592 13.3447 10.5548 13.4694 10.5011 13.6178L10.1623 14.5615C10.0671 14.8266 9.81563 15.0033 9.53399 15.003H8.46688C8.18549 15.003 7.9344 14.8264 7.83929 14.5615L7.50048 13.6178C7.44672 13.4696 7.34262 13.345 7.20636 13.2657L6.20061 12.6854C6.06412 12.6067 5.90416 12.5788 5.74908 12.6067L4.76201 12.7848C4.48483 12.8352 4.20587 12.706 4.06505 12.462L3.53149 11.5416C3.39029 11.2983 3.41698 10.9925 3.59819 10.7773L4.24579 10.0116C4.34779 9.89124 4.4038 9.73858 4.40386 9.58079V8.4203C4.4038 8.26251 4.34779 8.10985 4.24579 7.98946L3.60486 7.22381C3.42365 7.0086 3.39696 6.70283 3.53816 6.45949L4.07172 5.53911C4.21245 5.29537 4.49105 5.16621 4.76801 5.2163L5.75509 5.39438C5.91017 5.42231 6.07012 5.39443 6.20661 5.31568L7.21236 4.73544C7.34863 4.65613 7.45272 4.5315 7.50648 4.38329L7.84529 3.43956C7.93965 3.17679 8.1877 3.0006 8.46688 2.99805H9.53399Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='9.00011' cy='9.00011' r='1.8341' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
