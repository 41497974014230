import React from 'react';

import DonutChart from 'react-apexcharts';

const colorsList = ['#FF4572', '#2D9CDB', '#00D3B0'];

const colorsListEmpty = ['#E2E5EA'];

export const Chart = ({ data, isEmptyNps, allowAnimation }) => {
  const options = {
    chart: {
      type: 'donut',
      animations: { enabled: allowAnimation, speed: 300 },
      toolbar: { show: false }
    },
    labels: !isEmptyNps ? data.map(item => item.label) : ['100'],
    colors: !isEmptyNps ? colorsList : colorsListEmpty,
    fill: { opacity: 1 },
    legend: {
      show: false,
      width: 72,
      height: 'auto',
      position: 'left',
      fontSize: '12px',
      offsetY: 0,
      fontWeight: 400,
      markers: {
        width: 14,
        height: 14,
        strokeWidth: 2,
        strokeColor: '#fff',
        radius: 0,
        offsetX: 0,
        offsetY: 0
      },
      itemMargin: {
        horizontal: 0,
        vertical: 12
      },
      onItemHover: { highlightDataSeries: false }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    dataLabels: { enabled: false },
    tooltip: { enabled: false }
  };
  return (
    <DonutChart
      series={!isEmptyNps ? data.map(item => item.value) : ['100']}
      options={options}
      type='donut'
      width='100px'
      height='110px'
    />
  );
};
