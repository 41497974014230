import React, { useState } from 'react';

import Box from '@mui/material/Box';
import colors from '@packs/assets/theme/base/colors';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import PhotoIcon from '@packs/images/svg/ui/photo.svg';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

const { primary } = colors;

const sizeLimit = 6;

export const AvatarUploader = observer(() => {
  const { isDesktop } = useWindowSize();
  const { userStore } = useStore();
  const { currentUser } = useUserSelectors();
  const [avatarUrl, setAvatarUrl] = useState(currentUser.avatarUrl);

  const changeAvatarPreview = e => {
    const file = e.target.files[0];

    if (file?.size > sizeLimit * 1024 * 1024) {
      userStore.updateCurrentUser({ avatar: avatarUrl }).then(() => {
        toastr.error(`File size exceeds the maximum allowed size of ${sizeLimit} MB.`);
      });
    } else {
      setAvatarUrl(file);
      userStore.updateCurrentUser({ avatar: file }).then(() => {
        toastr.success('User avatar successfully updated');
      });
    }
  };

  return (
    <Box sx={{ position: 'relative', marginX: 'auto', width: '100%', maxWidth: isDesktop ? '120px' : '60px' }}>
      <Avatar
        placeholder={currentUser?.displayName}
        avatarUrl={currentUser.avatarUrl}
        size={isDesktop ? 120 : 60}
        withBorder
        fullSize
        withBG
      />
      <input type='file' accept='image/*' id='avatar-input' className='d-none' onChange={changeAvatarPreview} />
      <Box
        component='label'
        htmlFor='avatar-input'
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          height: '40px',
          backgroundColor: primary.main,
          borderRadius: '50%',
          cursor: 'pointer',
          '& img': {
            width: '60%'
          }
        }}
      >
        <img src={PhotoIcon} alt='edit' />
      </Box>
    </Box>
  );
});
