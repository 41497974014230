import React, { useEffect } from 'react';

import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import { useStore } from '@packs/stores/base';

import { observer } from 'mobx-react';

const defaultAssociation = { value: 'all', label: 'All' };

export const AssociationFilter = observer(props => {
  const { category, currentUser, associationFilter = defaultAssociation, handleSetAssociation, className } = props;
  const { associationStore, currentCountry } = useStore();

  if (!associationStore) return null;

  useEffect(() => {
    associationStore.setParam({
      countryCode: currentCountry.isoA2Code,
      userId: +currentUser.id,
      categoryId: category.id
    });

    if (currentUser.id) associationStore.fetchUserAssociations();
  }, [currentCountry.isoA2Code, currentUser.id, category.id]);

  const addAssociationSelect = value => {
    if (value) {
      handleSetAssociation(value);
    }
  };

  const onInputChange = (_, value) => {
    associationStore.fetchAssociations({ name: value });
  };

  const mapOptions = arr =>
    arr.map(ob => ({
      value: ob.id,
      label: ob.name,
      object: ob
    }));

  const options = [defaultAssociation, ...mapOptions(associationStore.associations)];

  useEffect(() => {
    associationStore.fetchAssociations({ name: '' });
  }, []);

  return (
    <AutocompleteMUI
      value={associationFilter || null}
      options={options}
      onChange={addAssociationSelect}
      onInputChange={onInputChange}
      label='Associations'
      placeholder='Select'
      size={props.withTitle ? 'small' : 'medium'}
      withTitle={props.withTitle}
      mobileSelect
      filter
    />
  );
});
