import React, { useState } from 'react';

import userApi from '@packs/apis/currentUser';
import EmailIcon from '@packs/images/ui/Email.png';
import EmailAgreeIcon from '@packs/images/ui/EmailAgree.png';
import EmailCancelIcon from '@packs/images/ui/EmailCancel.png';
import { pathGen } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Unsubscribe = () => {
  const { isDesktop } = useWindowSize();
  const { countryCode, currentCategory } = useStore();

  const { type, signature } = queryString.parse(location.search);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  const onUnsubscribe = async () => {
    const res = await userApi.userUnsubscribeEmail({ type, signature });
    if (res?.status === 'success') {
      setIsUnsubscribed(true);
    } else {
      toastr.error('Unsubscribe failed!');
    }
  };

  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>
      <div className='unsubscribe-wrapper'>
        <div className='unsubscribe-container d-flex align-items-center justify-content-between '>
          <div>
            <div className='title'>{isUnsubscribed ? 'Unsubscribe Successful!' : 'Do you wish to unsubscribe?'}</div>
            <div className='description'>
              {isUnsubscribed
                ? 'You have successfully unsubscribed from BillWinner’s email list.'
                : 'If you unsubscribe, you will stop receiving our newsletter.'}
            </div>
            <div className='btn-container d-flex align-items-center gap-16 mt-24'>
              {!isUnsubscribed && (
                <div className='btn full primary white' onClick={onUnsubscribe}>
                  <span>Unsubscribe</span>
                </div>
              )}
              <Link
                to={pathGen('ROOT', { countryCode, category: currentCategory })}
                className='btn empty primary white'
              >
                <span>{isUnsubscribed ? 'Close' : 'Cancel'}</span>
              </Link>
            </div>
          </div>
          {isDesktop && (
            <div className='img-container position-relative'>
              <img src={EmailIcon} alt='Email' />
              <div className='img-status'>
                <img src={isUnsubscribed ? EmailAgreeIcon : EmailCancelIcon} alt='Cancel' />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Unsubscribe;
