import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import ConfirmEmailIcon from '@packs/images/ui/confirm-email-icon.svg';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { pathGen } from '@packs/layouts/constants';
import { useStore } from '@packs/stores/base';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const ConfirmEmail = observer(() => {
  const styles = useStyles();
  const { currentCategory, countryCode } = useStore();
  const { currentUser } = useUserSelectors();
  const { toggleResendConfirmationModal } = useModalsContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser?.id) {
      navigate('/en-ie');
    } else if (currentUser?.isConfirmed) {
      navigate(pathGen('LEADERBOARD', { countryCode, category: currentCategory }));
    }
  }, []);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <Typography variant='h3' sx={{ mb: 1 }}>
            Please check your inbox and confirm your Email address!
          </Typography>
          <Typography variant='subtitle1' sx={styles.subtitle}>
            We’ve sent a confirmation email to{' '}
            <Typography variant='subtitle1' component='span' sx={{ fontWeight: 700 }}>
              {currentUser?.email}
            </Typography>
          </Typography>
          <Box sx={styles.confirmDescription}>
            <Typography variant='body2' sx={{ mb: 1 }}>
              Didn’t receive an Email?
            </Typography>
            <Typography variant='body1' sx={styles.confirmDescriptionSubtitle}>
              If you can’t find the Email in your inbox or spam folder, please click below and we will send you a new
              one.
            </Typography>
            <Box sx={styles.buttonContainer}>
              <Button variant='filledTonal' color='primary' onClick={toggleResendConfirmationModal}>
                Resend Email
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.imgContainer}>
          <img src={ConfirmEmailIcon} alt='Confirm' />
        </Box>
      </Box>
    </Box>
  );
});

export default ConfirmEmail;
