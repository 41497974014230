import React, { useEffect, useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import { renewalDetailApi } from '@packs/apis';
import companyApi from '@packs/apis/company';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import RightSideArrowIcon from '@packs/images/svg/ui/right-side-arrow.svg';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { categoryHavePolicy, isWikiTableCategory } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { rootCategoriesLanding } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const PersonalizeTab = observer(
  ({ change, category, toggle, formAttributes, disabledCategory, renewalDetail }) => {
    const { isMobile } = useWindowSize();
    const { categoryStore } = useStore();
    const { togglePersonalizationFlowModal } = useModalsContext();
    const [simpleCriterias, setSimpleCriterias] = useState([]);
    const [agents, setAgents] = useState([]);
    const {
      REQUEST_QUOTES_PATH,
      DASHBOARD_LANDING_PATH_FN,
      ADD_DETAILS_PATH_FN,
      MY_DETAILS_PATH_FN,
      DASHBOARD_MY_RESULTS_PATH_FN,
      DASHBOARD_ROOT_LANDING_PATH_FN
    } = usePath();
    const { currentUser } = useUserSelectors();

    const isVehicleCategories = ['car_insurance', 'motorbike_insurance', 'van_insurance'].some(
      item => item === category.code
    );
    const personalizeButtonText = isVehicleCategories ? 'Personalize!' : 'Personalize more!';

    const isPopularRootCategory = rootCategoriesLanding.some(popular => popular === category?.root?.code);

    const categoryUrl = () => {
      let url = DASHBOARD_LANDING_PATH_FN(category);

      if (categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)) {
        if (hasPolicyCriteria(renewalDetail)) {
          url = MY_DETAILS_PATH_FN(renewalDetail, category);
        } else {
          url = ADD_DETAILS_PATH_FN(category);
        }
      } else if (isPopularRootCategory) {
        url = DASHBOARD_ROOT_LANDING_PATH_FN(category).concat(category.code ? `?initialCategory=${category.code}` : '');
      }

      return url;
    };

    const handleDddDetails = () => {
      toggle();
    };

    const fetchCriteria = async () => {
      const data = await renewalDetailApi.fetchCriteriaQuestionList({ categoryId: +category.id, formType: 'simple' });

      if (!data.errors) {
        setSimpleCriterias(data);
      }
    };

    const fetchAgents = async () => {
      const wikiLogic = isWikiTableCategory(category);
      const apiCall = wikiLogic ? companyApi.fetchCategoryAgentsWiki : companyApi.fetchCategoryAgents;

      const res = await apiCall({ categoryId: category.id });
      setAgents(res.companies);
    };

    useEffect(() => {
      fetchCriteria();
      fetchAgents();
    }, []);

    return (
      <div className={cx('personalize-tab-container position-relative', { empty: !_.size(simpleCriterias) })}>
        {!!_.size(simpleCriterias) && (
          <div className='fs-14 lh-5 secondary-text mb-15'>
            Benchmark your costs with members like you & see where they have found their best value!
          </div>
        )}
        {!!_.size(simpleCriterias) && !disabledCategory && (
          <div className='bottom-link mb-30 d-flex align-items-center'>
            <Link to={categoryUrl()} className={cx('link fw-600', isMobile && 'fs-14')} onClick={handleDddDetails}>
              {personalizeButtonText}
            </Link>
            {_.size(agents) > 0 && (
              <>
                <span className='ml-10 mr-10 fs-16'>or</span>
                <Link to={REQUEST_QUOTES_PATH} className={cx('link fw-600', isMobile && 'fs-14')} onClick={toggle}>
                  Request Quotes
                  <img src={RightSideArrowIcon} alt='right' />
                </Link>
              </>
            )}
          </div>
        )}
        {!_.size(simpleCriterias) && <div className='empty-personalization'>No Personalization required!</div>}
      </div>
    );
  }
);
