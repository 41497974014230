import React from 'react';

const ArrowRightIcon = () => {
  return (
    <svg width='15' height='10' viewBox='0 0 15 10' fill='#fff' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.46053 5.69256L12.4938 5.69256L9.22064 8.78112C8.93388 9.05152 8.93388 9.49016 9.22064 9.76052C9.50739 10.0311 9.97228 10.0311 10.2586 9.76052L14.7849 5.48972C15.0717 5.21932 15.0717 4.78064 14.7849 4.51032L10.2586 0.23932C10.1153 0.104001 9.92743 0.0364404 9.73962 0.0364404C9.55182 0.0364404 9.36397 0.104001 9.22064 0.23932C8.93388 0.50972 8.93388 0.94836 9.22064 1.21868L12.4938 4.3074L1.46053 4.3074C1.05516 4.3074 0.726435 4.61752 0.726435 5C0.726435 5.38248 1.05512 5.69256 1.46053 5.69256Z' />
    </svg>
  );
};

export default ArrowRightIcon;
