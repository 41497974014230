import React, { createContext, useContext } from 'react';

import { Provider } from 'react-redux';

export const StoreContext = createContext({});

export const ReduxStoreProvider = ({ store, children }) => {
  return (
    <Provider store={store}>
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    </Provider>
  );
};

export const useReduxStore = () => useContext(StoreContext);
