import { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import colors from '@packs/assets/theme/base/colors';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { MembersDesktop } from './components/MembersDesktop';
import { MembersMobile } from './components/MembersMobile';

const { white } = colors;

interface IMembersLikeSlide {
  label: string;
  title: string;
  description: string;
  numerate?: boolean;
  list?: { title: string; desc: string }[];
  imgSrc: string;
}

type MembersLoveProps = {
  title?: string;
  members: IMembersLikeSlide[];
  sx?: SxProps;
};

export const MembersLove: FC<MembersLoveProps> = ({ members, title = '', sx = {} }) => {
  const { isDesktop } = useWindowSize();
  const [value, setValue] = useState(0);

  const handleChange = index => () => {
    setValue(index);
  };

  return (
    <Box sx={{ ...sx, backgroundColor: white.main }}>
      <Box sx={{ maxWidth: '1128px', margin: '0 auto' }}>
        <Typography variant='h2' mb={{ xs: 3, lg: 5 }} px={{ xs: 3 }} textAlign={{ xs: 'center', lg: 'left' }}>
          {title}
        </Typography>
        {isDesktop ? (
          <MembersDesktop {...{ value, members, handleChange }} />
        ) : (
          <MembersMobile {...{ value, members, handleChange }} />
        )}
      </Box>
    </Box>
  );
};
