import colors from '@packs/assets/theme/base/colors';

const { primary, white } = colors;

export default () => ({
  searchWrapper: {
    width: '100%',
    maxWidth: '741px',
    mx: 'auto',
    mt: 2
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5
  },
  subTitle: {
    mt: 2,
    textAlign: 'center'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 3
  },
  extraDescription: {
    borderRadius: '8px',
    p: 2,
    backgroundColor: white.tonal
  },
  content: {
    textAlign: 'center'
  },
  addBusiness: {
    color: primary.main,
    cursor: 'pointer'
  }
});
