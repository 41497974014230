import React, { useEffect } from 'react';
import cx from 'classnames';

import CloseIcon from '@packs/images/svg/ui/close-dark-blue.svg';
import FilterIcon from '@packs/images/svg/ui/filter-icon.svg';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Drawer from 'react-modern-drawer';

export const MobileFilter = ({
  isOpen,
  toggle,
  drawerProps: { btnText = 'Filter', size = '90vh', headerTitle = 'Filter', hasFilterIcon = false, content }
}) => {
  const body = document?.body;

  const toggleDrawer = () => {
    if (isOpen) {
      disableBodyScroll(body);
    } else {
      enableBodyScroll(body);
    }
    toggle(!isOpen);
  };

  useEffect(() => {
    return () => {
      enableBodyScroll(body);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(body);
    } else {
      enableBodyScroll(body);
    }
  }, [isOpen]);

  return (
    <Drawer open={isOpen} direction='bottom' size={isOpen ? size : 0} onClose={toggleDrawer}>
      {isOpen && (
        <>
          <div className='mobile-filter-header d-flex justify-content-between align-items-center'>
            <div onClick={toggleDrawer}>
              <img src={CloseIcon} alt='close' className='close-drawer' />
            </div>
            <div className='d-flex align-items-center'>
              {hasFilterIcon && <img src={FilterIcon} alt='Filter' className='header-icon' />}
              <span className={cx('fs-11 fw-600 tt-uc', { 'ml-8': hasFilterIcon })}>{headerTitle || btnText}</span>
            </div>
            <div />
          </div>
          {content}
        </>
      )}
    </Drawer>
  );
};
