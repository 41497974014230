import React from 'react';

import { MyNotes } from '@packs/components/UI';
import { usePath } from '@packs/layouts/constants';
import { websitePageUrl } from '@shared/helpers/urlHelper';

import { Helmet } from 'react-helmet';

const MyNotesDashboard = () => {
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  return (
    <>
      <Helmet>
        <link rel='canonical' href={websitePageUrl() + DASHBOARD_MY_RESULTS_PATH.replace('/', '')} />
      </Helmet>
      <MyNotes />
    </>
  );
};

export default MyNotesDashboard;
