import React, { FC, useState } from 'react';
import _ from 'lodash-es';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import { Loader } from '@packs/components';
import { I18n } from '@packs/components/I18n';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import AuthIcon from '@packs/images/ui/sign-up-sidebar.webp';
import { ICountry } from '@packs/interfaces/rootInterfaces';
import { IUserFormAttributes } from '@packs/layouts/AppLayer/components/AuthModal/AuthModal';
import ForgotPasswordForm from '@packs/layouts/AppLayer/components/AuthModal/components/ForgotPasswordForm';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { serializeCountryName } from '@packs/models/country/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { LoginForm } from './components/LoginForm';
import { RegisterForm } from './components/RegisterForm';
import useStyles from './styles';

import { MacScrollbar } from 'mac-scrollbar';
import { observer } from 'mobx-react';

const benefits = [
  'Get personalized results and insights!',
  'Get competitor offers!',
  'All your bill data is kept securely in one place!',
  'Get member rewards!',
  'Get reminder notifications prior to your next renewal!',
  'Create a more competitive and transparent marketplace for bills!'
];

interface IProps {
  toggle: () => void;
  loading: boolean;
  toggleModalType: (arg: 'login' | 'signup' | 'forgotPassword') => void;
  signUpHandler: (arg: IUserFormAttributes) => void;
  loginSubmitHandler: (arg: { email: string; password: string }) => void;
  forgotPasswordHandler: (arg: { email: string }) => void;
  modalType: 'login' | 'signup' | 'forgotPassword';
  signUpWithUniqCode?: boolean;
  campaignSignUp?: boolean;
}

export const SignInSignUpContainer: FC<IProps> = observer(
  ({
    toggle,
    loading,
    toggleModalType,
    signUpHandler,
    forgotPasswordHandler,
    loginSubmitHandler,
    modalType,
    signUpWithUniqCode,
    campaignSignUp
  }) => {
    const styles = useStyles();
    const { isDesktop } = useWindowSize();
    const { currentUser, getSignUpFormInitialValues } = useUserSelectors();
    const { defaultMessages } = useTranslationMessages();
    const { countryCode, rootStore, currentCountry } = useStore();
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [socialUser, setSocialUser] = useState({});

    const initialValues = getSignUpFormInitialValues(countryCode, currentUser, {
      signUpWithUniqCode: signUpWithUniqCode!
    });

    const countryOption: ICountry = {
      ...currentCountry,
      label: serializeCountryName(currentCountry.enName),
      value: currentCountry.isoA2Code,
      countryCode: currentCountry.isoA2Code
    };

    const detailsLocationRegion = _.get(rootStore.detailsFormStore.formValues, 'criteriaQuestions.location') || '';

    if (countryOption) {
      _.set(initialValues, 'country', countryOption);
    }
    if (detailsLocationRegion) {
      _.set(initialValues, 'state', {
        label: detailsLocationRegion,
        value: detailsLocationRegion
      });
    }

    const handleBackSignUp = () => {
      setSocialUser({});
      setShowSignUpForm(false);
    };

    const SignUpContent = () => (
      <>
        <Box>
          <MacScrollbar
            style={{ flex: 1, maxHeight: 'calc(100vh - 82px)' }}
            trackStyle={horizontal => ({
              [horizontal ? 'height' : 'width']: 0,
              right: 2,
              border: 0
            })}
            thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
            suppressAutoHide
          >
            <Box sx={styles.formContainer}>
              {loading && <Loader />}
              <RegisterForm
                initialValues={initialValues}
                dirty
                onSubmit={signUpHandler}
                campaignSignUp={campaignSignUp}
                setShowSignUpForm={setShowSignUpForm}
                showSignUpForm={showSignUpForm}
                setSocialUser={setSocialUser}
                socialUser={socialUser}
              />
            </Box>
          </MacScrollbar>
        </Box>
        {!showSignUpForm && (
          <Typography variant='body1' sx={{ mt: 'auto', textAlign: 'center', pb: 3 }}>
            Already have an account?
            <Typography
              variant='body2'
              component='span'
              onClick={() => {
                toggleModalType('login');
              }}
              sx={{ ml: 1, cursor: 'pointer', textDecoration: 'underline' }}
            >
              <I18n text={defaultMessages.login} />
            </Typography>
          </Typography>
        )}
      </>
    );

    const LoginContent = () => (
      <Box sx={styles.formContainer}>
        <Box sx={styles.formFieldsContainer}>
          <Typography variant='h5' sx={styles.formTitle}>
            Sign In
          </Typography>
          <Typography variant='subtitle1' sx={styles.formSubTitle}>
            To access your Bill Winner account please enter your personal information.
          </Typography>
          <LoginForm
            onSubmit={loginSubmitHandler}
            onForgotPassword={() => {
              toggleModalType('forgotPassword');
            }}
          />
          <Typography variant='body1' sx={{ mt: 'auto', textAlign: 'center' }}>
            Don’t have an account?
            <Typography
              variant='body2'
              component='span'
              onClick={() => {
                toggleModalType('signup');
              }}
              sx={{ ml: 1, cursor: 'pointer', textDecoration: 'underline' }}
            >
              <I18n text={defaultMessages.signUp} />
            </Typography>
          </Typography>
        </Box>
      </Box>
    );

    const ForgotPasswordContent = () => {
      return (
        <Box sx={styles.formContainer}>
          <Box sx={styles.formFieldsContainer}>
            <Typography variant='h5' sx={styles.formTitle}>
              Forgot password
            </Typography>
            <Typography variant='subtitle1' sx={styles.formSubTitle}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              To reset your password, please enter your email address. We'll send you a link to create a new password.
            </Typography>
            <ForgotPasswordForm handleSubmit={forgotPasswordHandler} />
          </Box>
        </Box>
      );
    };

    return (
      <Box sx={styles.wrapper}>
        {isDesktop && (
          <Box sx={styles.infoSection}>
            <MacScrollbar
              style={{ flex: 1, height: 'calc(100vh - 82px)' }}
              trackStyle={horizontal => ({
                [horizontal ? 'height' : 'width']: 0,
                right: 2,
                border: 0
              })}
              thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
              suppressAutoHide
            >
              <Box sx={styles.infoSectionContainer}>
                <Box sx={styles.infoSectionContent}>
                  <Typography variant='h4' sx={styles.title}>
                    {modalType === 'signup'
                      ? 'Become a member of the Bill Winner community!'
                      : 'Smarter savings together!'}
                  </Typography>
                  <Typography variant='h6' sx={styles.subTitle}>
                    Bill Winner Member Benefits:
                  </Typography>
                  <Box component='ul' sx={styles.benefitsWrapper}>
                    {benefits.map(item => (
                      <li key={item}>
                        <CheckRoundedIcon />
                        <Typography variant='body1'>{item}</Typography>
                      </li>
                    ))}
                  </Box>
                </Box>
                <Box sx={styles.authSidebarImgWrapper}>
                  <img src={AuthIcon} alt='Auth' />
                </Box>
              </Box>
            </MacScrollbar>
          </Box>
        )}
        <Box sx={styles.formWrapper}>
          <DialogTitle sx={styles.modalTitle}>
            {modalType === 'signup' && showSignUpForm ? (
              <IconButton edge='end' color='inherit' onClick={handleBackSignUp}>
                <ArrowBackRoundedIcon />
              </IconButton>
            ) : modalType === 'forgotPassword' ? (
              <IconButton edge='end' color='inherit' onClick={() => toggleModalType('login')}>
                <ArrowBackRoundedIcon />
              </IconButton>
            ) : (
              <Box />
            )}
            <IconButton edge='end' color='inherit' onClick={toggle}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          {modalType === 'signup' ? (
            <SignUpContent />
          ) : modalType === 'login' ? (
            <LoginContent />
          ) : (
            <ForgotPasswordContent />
          )}
        </Box>
      </Box>
    );
  }
);
