import React from 'react';

export default () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.41091 10.0167L10.0159 1.41172C10.3409 1.08672 10.8684 1.08672 11.1934 1.41172L12.5901 2.80839C12.9151 3.13339 12.9151 3.66089 12.5901 3.98589L3.98425 12.5901C3.82841 12.7467 3.61675 12.8342 3.39591 12.8342H1.16675V10.6051C1.16675 10.3842 1.25425 10.1726 1.41091 10.0167Z'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.4585 2.96875L11.0335 5.54375'
        stroke='#6E7F95'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
