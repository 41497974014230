import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import useStyles from '@packs/layouts/AppLayer/components/AuthModal/styles';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';

import { Field, reduxForm } from 'redux-form';

const validate = ({ email, password }) => {
  const errors = {};
  if (!email) errors.email = 'Required';
  if (!password) errors.password = 'Required';
  return errors;
};

const createReduxForm = reduxForm({ form: 'signIn', validate });

export const LoginForm = createReduxForm(({ handleSubmit, onForgotPassword }) => {
  const styles = useStyles();
  const { defaultMessages, formatMessage } = useTranslationMessages();

  return (
    <form className='d-flex flex-column align-items-center' onSubmit={handleSubmit}>
      <Box sx={styles.fieldsList}>
        <Field
          name='email'
          component={FieldWithErrors}
          type='text'
          label='Email'
          disableFocusReset
          material
          variant='outlined'
        />
        <Field
          name='password'
          autoComplete='current-password'
          component={FieldWithErrors}
          type='password'
          label='Password'
          disableFocusReset
          material
          variant='outlined'
        />
        <Box sx={styles.forgotWrapper}>
          <Box sx={styles.forgotLink} onClick={onForgotPassword}>
            {formatMessage(defaultMessages.forgotPassword)}
          </Box>
        </Box>
      </Box>
      <Box sx={styles.btnContainer}>
        <Button type='submit' variant='contained' size='large' fullWidth>
          {formatMessage(defaultMessages.login)}
        </Button>
      </Box>
    </form>
  );
});
