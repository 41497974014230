import colors from '@packs/assets/theme/base/colors';

const { borderColor, white, primary } = colors;

export default () => ({
  mobileItemWrapper: (active = false) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    width: '100%',
    maxWidth: '768px',
    borderRadius: '12px',
    border: `2px solid ${active ? primary.main : borderColor.main}`,
    p: 1,
    background: white.main
  }),
  categoryBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  }
});
