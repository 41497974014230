import colors from '@packs/assets/theme/base/colors';

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    mb: 3,
    borderRadius: '16px',
    border: `1px solid ${colors.borderColor.secondary}`,
    px: { xs: 2, lg: 3 },
    py: { xs: 3, lg: 4 }
  },
  summaryList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
    rowGap: 1
  },
  summaryItem: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    width: '100px',
    minWidth: '100px',
    '&:after': {
      content: "''",
      position: 'absolute',
      right: '-15px',
      top: '50%',
      width: '2px',
      height: '24px',
      borderRadius: '2px',
      backgroundColor: colors.dark.transparent,
      transform: 'translateY(-50%)'
    },
    '&:last-child': {
      '&:after': {
        content: 'none'
      }
    }
  }
});
