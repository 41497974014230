import React, { useMemo, useState } from 'react';
import { uniqBy } from 'lodash-es';
import cx from 'classnames';

import { InfoComponent } from '@packs/components';
import { RecommendedChart } from '@packs/components/UI';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { MacScrollbar } from 'mac-scrollbar';

export const WikiTableGroups = ({ company, category }) => {
  const { isMobile } = useWindowSize();

  const groups = useMemo(() => {
    return [
      ...uniqBy(
        company?.wikiTable?.informationalSections.map(item => item?.group),
        'id'
      ),
      {
        id: 'description',
        name: 'Description & Stats'
      }
    ];
  }, [company?.wikiTable?.informationalSections]);
  const [group, setGroup] = useState(groups[0]);

  const onClickGroup = group => {
    return () => {
      setGroup(group);
    };
  };

  const scrollContent = () => (
    <div className='tabs-content-scrollbar'>
      {group.id === 'description' ? (
        <>
          {company?.metaDescription && (
            <div className='wiki-item description'>
              <div className='title'>Description:</div>
              <div className='answer'>{company?.metaDescription}</div>
            </div>
          )}
          <div className='wiki-item flex-column'>
            <div className='title d-flex align-items-center'>
              NPS <span className='tt-none ml-4'>Score</span>:
              <div className='ml-4'>
                <InfoComponent popoverId='nps_modal' placement='bottom' dark circle>
                  <div>
                    <p>
                      A Net Promoter Score (NPS) is a measure used to gauge customer loyalty, satisfaction, and
                      enthusiasm with a company. In general, a net promoter score above 0 is good, above 20 is great and
                      above 50 is amazing! It is calculated by asking a customer on a scale of 1-10 on how likely they
                      are to recommend a business!
                    </p>
                  </div>
                </InfoComponent>
              </div>
            </div>
            <div className='wiki-table-modal-chart d-flex align-items-center justify-content-between w-100'>
              <RecommendedChart {...{ company }} />
            </div>
          </div>
        </>
      ) : (
        company?.wikiTable?.informationalSections.map(item => {
          if (item?.group?.id === group?.id) {
            const sanitizedTitle = `${item?.criteriaQuestion?.label}`.replace(':category_name', category.name);
            return (
              <div key={item?.id} className={cx('wiki-item', item?.criteriaQuestion?.name)}>
                <div className='title'>{sanitizedTitle}</div>
                <div className='answer'>{item?.answer || '-'}</div>
              </div>
            );
          }
        })
      )}
    </div>
  );

  return (
    <div className='mt-24'>
      <div className='d-flex justify-content-center tabs-ui-scroll'>
        <div className='tabs-ui with-scroll medium'>
          {groups.map(item => (
            <div
              className={cx('tab-item-ui', { active: group.id === item.id })}
              key={item.id}
              onClick={onClickGroup(item)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div className='tabs-content'>
        {isMobile ? (
          scrollContent()
        ) : (
          <MacScrollbar
            style={{ height: '330px' }}
            trackStyle={horizontal => ({
              [horizontal ? 'height' : 'width']: 0,
              right: 2,
              border: 0
            })}
            thumbStyle={horizontal => ({ [horizontal ? 'height' : 'width']: 4 })}
            suppressAutoHide
          >
            {scrollContent()}
          </MacScrollbar>
        )}
      </div>
    </div>
  );
};
