import React from 'react';

import Button from '@mui/material/Button';
import { usePath } from '@packs/layouts/constants';
import { saveState } from '@packs/lib/utils/localStorage';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { NavLink } from 'react-router-dom';

export const LeaderboardCTA = ({
  item,
  userNotConfirmed,
  disabledCategory,
  selectedPolicy,
  categoryRedirect,
  category
}) => {
  const { isDesktop } = useWindowSize();
  const { DASHBOARD_MY_RESULTS_PATH_FN } = usePath();

  if (disabledCategory(item?.root?.code)) {
    return (
      <Button
        variant='filledTonal'
        size={isDesktop ? 'small' : 'smallToMedium'}
        disabled
        sx={{ whiteSpace: 'nowrap' }}
        fullWidth={!isDesktop}
      >
        Coming Soon
      </Button>
    );
  }
  return (
    <Button
      component={NavLink}
      disabled={userNotConfirmed}
      to={DASHBOARD_MY_RESULTS_PATH_FN(item).concat(`?policyId=${selectedPolicy[item?.id]}`)}
      onClick={() => {
        if (!userNotConfirmed) {
          categoryRedirect(category);
          saveState('openDashboardItemId', item.id);
        }
      }}
      variant='filledTonal'
      size={isDesktop ? 'small' : 'smallToMedium'}
      sx={{ whiteSpace: 'nowrap' }}
      fullWidth={!isDesktop}
    >
      See Leaderboard
    </Button>
  );
};
