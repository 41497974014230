import React from 'react';

import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { I18n } from '@packs/components/I18n';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';

import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

const Form = ({ handleSubmit }) => {
  const { defaultMessages, formatMessage } = useTranslationMessages();

  const { USERS_SIGN_IN_PATH } = usePath();

  return (
    <form onSubmit={handleSubmit}>
      <div className='input-field'>
        <Field
          component={FieldWithErrors}
          type='password'
          name='password'
          autoComplete='off'
          placeholder={formatMessage(defaultMessages.forgotPasswordUpdatePageNewPassword)}
        />
      </div>
      <div className='input-field'>
        <Field
          component={FieldWithErrors}
          type='password'
          name='passwordConfirmation'
          autoComplete='off'
          placeholder={formatMessage(defaultMessages.forgotPasswordUpdatePageConfirmPassword)}
        />
      </div>
      <button type='submit' className='btn auth-btn'>
        <I18n text={defaultMessages.forgotPasswordUpdatePageButton} />
      </button>
      <div className='forgot-text'>
        <I18n text={defaultMessages.forgotPasswordUpdatePageRememberPassword} />
        &nbsp;
        <Link to={USERS_SIGN_IN_PATH}>
          <I18n text={defaultMessages.forgotPasswordUpdatePageLogin} />
        </Link>
      </div>
    </form>
  );
};
export default Form;
