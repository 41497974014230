import React, { useEffect, useMemo, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { categoryApi } from '@packs/apis/category';
import { Community } from '@packs/components/MaterialUi/Landing/Community/Community';
import { FAQ } from '@packs/components/MaterialUi/Landing/FAQ/FAQ';
import { GetStarted } from '@packs/components/MaterialUi/Landing/GetStarted/GetStarted';
import { Guide } from '@packs/components/MaterialUi/Landing/Guide/Guide';
import { CommunityBlock } from '@packs/components/MaterialUi/Landing/Hero/components/CommunityBlock/CommunityBlock';
import { PoweredBlock } from '@packs/components/MaterialUi/Landing/Hero/components/PoweredBlock/PoweredBlock';
import { Hero } from '@packs/components/MaterialUi/Landing/Hero/Hero';
import { HowItWorks } from '@packs/components/MaterialUi/Landing/HowItWorks/HowItWorks';
import { LandingCriteria } from '@packs/components/MaterialUi/Landing/LandingCriteria/LandingCriteria';
import { LearnMore } from '@packs/components/MaterialUi/Landing/LearnMore/LearnMore';
import { LandingOffers } from '@packs/components/MaterialUi/Landing/Offers/Offers';
import { Purchase } from '@packs/components/MaterialUi/Landing/Purchase/Purchase';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import GetStartedIcon from '@packs/images/ui/category-landing-get-started.webp';
import { usePath } from '@packs/layouts/constants';
import { COMING_SOON_CATEGORIES } from '@packs/models/category/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { setCategoryCriteria } from '@packs/models/category/helpers';
import { useCategories } from '@packs/models/category/useCategories';
import { ComingSoon } from '@packs/screens/SubCategoryLanding/components/ComingSoon/ComingSoon';
import { useStore } from '@packs/stores/base';
import {
  categoriesWithoutPersonalizeFlow,
  categoriesWithoutSimpleForm,
  rootCategoriesLanding,
  subCategoriesWithoutPersonalizeFlow
} from '@shared/constants/categories';
import { makeSelectOptions } from '@shared/helpers/select';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import moment from 'moment/moment';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';

const SubCategoryLanding = observer(() => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { currentCountry, categoryStore, companiesStore } = useStore();
  const params = useParams();
  const { categories } = useCategories();
  const { isDesktop } = useWindowSize();
  const { DASHBOARD_MY_RESULTS_PATH_FN, ADD_DETAILS_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();
  const { currentUser } = useUserSelectors();

  const [landingData, setLandingData] = useState(null);
  const [loading, setLoading] = useState(false);

  const currentCategory = useMemo(() => {
    const rootCat = categories.find(rootCat => rootCat?.code === params?.root_code);
    return rootCat?.leafChildren.find(cat => cat.slag === params?.slag);
  }, [categories, params?.root_code, params?.slag]);

  const { blockHero, blockPurchase, blockGuide, blockFaq, blockLearnMore } = landingData || {};
  const regions = makeSelectOptions(currentCountry?.regions);
  const categoryImage = '';
  const { location } = useParams();
  const countryCode = currentCountry?.isoA2Code;

  const isCategoriesWithoutSimpleForm = categoriesWithoutSimpleForm.includes(currentCategory.code);

  const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(params?.root_code);
  const isSubCategoriesWithoutPersonalizeFlow = subCategoriesWithoutPersonalizeFlow.includes(currentCategory.code);

  const enabledPersonalizeFlow = !isCategoriesWithoutPersonalizeFlow && !isSubCategoriesWithoutPersonalizeFlow;
  const enabledSimpleForm = !isCategoriesWithoutSimpleForm;

  const fields = [
    {
      name: 'city',
      type: 'select',
      label: 'Location',
      placeholder: 'Location',
      options: regions
    },
    ...(landingData?.criteriaQuestions || [])
      .sort((a, b) => a.index - b.index)
      .map(item => ({
        ...item,
        name: `criteriaQuestions.${item.name}`
      }))
  ];
  const heroTitle =
    blockHero?.title || `Best ${currentCategory.name} in ${location} For ${moment().format('MMMM YYYY')}!`;
  const mateDescription = `Compare the top ${currentCategory.name} providers and choose the best rates for your next contract`;

  const initialValues = () => {
    const init = {
      criteriaQuestions: {}
    };

    fields.forEach(field => {
      const fieldPath = field.name.split('.');

      if (fieldPath[0] === 'city') {
        init.city = regions.find(item => item.label === location) || null;
      } else if (field.type === 'select' || field.type === 'radio') {
        const fieldValue = field?.options.find(item => item.value === field?.defaultValue) || null;

        if (fieldPath.length === 1) {
          init[field.name] = fieldValue;
        } else {
          if (!init[fieldPath[0]]) {
            init[fieldPath[0]] = {};
          }
          init[fieldPath[0]][fieldPath[1]] = fieldValue;
        }
      } else {
        if (fieldPath.length === 1) {
          init[field.name] = field?.defaultValue || '';
        } else {
          if (!init[fieldPath[0]]) {
            init[fieldPath[0]] = {};
          }
          init[fieldPath[0]][fieldPath[1]] = field?.defaultValue || '';
        }
      }
    });

    return init;
  };

  const onSubmitCriteria = values => {
    const formValues = { ...values };

    companiesStore.setFilters({
      city: formValues?.city?.label
    });

    const criteria = values?.criteriaQuestions || {};
    const extractedValues = {};
    Object.keys(criteria).forEach(key => {
      if (typeof criteria[key] === 'object') {
        extractedValues[key] = criteria[key].value;
      } else {
        extractedValues[key] = criteria[key];
      }
    });

    const criteriaForPolicy = {
      ...extractedValues,
      location: values?.city?.label || ''
    };
    setCategoryCriteria(currentCategory.id, criteriaForPolicy);
    navigate(DASHBOARD_MY_RESULTS_PATH_FN(currentCategory));
  };

  const fetchData = async () => {
    setLoading(true);
    if (currentCategory?.id) {
      const res = await categoryApi.fetchCategoryLandingPage({ categoryId: currentCategory.id });
      setLandingData(res);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rootCategoriesLanding.includes(currentCategory?.root?.code)) {
      navigate(DASHBOARD_ROOT_LANDING_PATH_FN(currentCategory));
    } else {
      fetchData();
      categoryStore.setCurrentCategory(currentCategory);
    }
  }, [currentCategory.id]);

  useEffect(() => {
    if (categoryHavePolicy(currentCategory, categoryStore.categoriesWithPolicy, currentUser)) {
      navigate(DASHBOARD_MY_RESULTS_PATH_FN(currentCategory));
    }
  }, [currentCategory.id, currentUser.id, categoryStore.categoriesWithPolicy]);

  const blockHeroOptions = blockHero?.options?.length
    ? blockHero?.options
    : [
        { title: 'See where members like you are finding their best value!' },
        { title: 'See the best matched offers!' },
        { title: 'Get tips and insights from our community!' },
        { title: 'Set up Auto. quote to request automatic quotes at renewal time!' },
        { title: 'Manage all your policy details in one place!' }
      ];

  if (COMING_SOON_CATEGORIES.includes(currentCategory?.root?.code)) {
    return (
      <>
        <Helmet>
          <title>{currentCategory.name}</title>
          <meta name='description' content={mateDescription} />
          <meta name='og:description' content={mateDescription} />
        </Helmet>
        <ComingSoon {...{ landingData, currentCategory, categoryImage, heroTitle, blockHero, countryCode }} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{currentCategory.name}</title>
        <meta name='description' content={mateDescription} />
        <meta name='og:description' content={mateDescription} />
      </Helmet>
      <Box sx={{ overflow: 'hidden' }}>
        <Hero breadcrumbLabel={currentCategory.name} heroBackgroundImg={landingData?.blockHeroImgUrl}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.contentWrapper}>
              <Typography variant='h2' color='inherit' mb={2}>
                {heroTitle}
              </Typography>

              <Stack spacing={1} mb={{ xs: 0, lg: 3 }}>
                {blockHeroOptions.map(el => (
                  <Stack key={el.title} direction='row' spacing={1}>
                    <CheckIcon />
                    <Typography variant='body1'>{el.title}</Typography>
                  </Stack>
                ))}
              </Stack>

              {!isDesktop && enabledSimpleForm && enabledPersonalizeFlow ? (
                <>
                  <Typography
                    variant='body1'
                    sx={{ mt: 3 }}
                    dangerouslySetInnerHTML={{
                      __html: blockHero?.description || "Let's make smarter decisions together!"
                    }}
                  />
                </>
              ) : (
                <>
                  <CommunityBlock currentCategoryId={currentCategory.id}>
                    <Typography
                      variant='body1'
                      dangerouslySetInnerHTML={{
                        __html: blockHero?.description || "Let's make smarter decisions together!"
                      }}
                    />
                  </CommunityBlock>
                  <PoweredBlock {...{ currentCategory }} variant='body1' />
                </>
              )}

              {!enabledSimpleForm && enabledPersonalizeFlow && (
                <Box sx={styles.buttonContainer}>
                  <Button component={Link} to={ADD_DETAILS_PATH_FN(currentCategory)} variant='contained'>
                    Get Started!
                  </Button>
                </Box>
              )}
              {!enabledPersonalizeFlow && (
                <Box sx={styles.buttonContainer}>
                  <Button
                    component={Link}
                    to={DASHBOARD_MY_RESULTS_PATH_FN(currentCategory)}
                    variant='contained'
                    sx={styles.button}
                  >
                    Go to the Leaderboard!
                  </Button>
                </Box>
              )}
            </Box>
            {enabledSimpleForm && enabledPersonalizeFlow && !loading && (
              <LandingCriteria fields={fields} initialValues={initialValues()} handleSubmit={onSubmitCriteria} />
            )}
          </Box>
        </Hero>
        {!blockPurchase?.hidden && (
          <Purchase
            title={blockPurchase?.title}
            description={blockPurchase?.description}
            options={blockPurchase?.options}
            categoryName={currentCategory.name}
          />
        )}
        <HowItWorks {...{ currentCategory }} />
        {currentCategory?.id && <LandingOffers categoryId={currentCategory?.id} />}
        {!!blockGuide && !blockGuide.hidden && (
          <Guide
            sectionImage={landingData?.blockGuideImgUrl ?? ''}
            title={blockGuide?.title}
            description={blockGuide?.description}
            options={blockGuide?.options}
          />
        )}
        <Community currentCategoryId={currentCategory.id} />
        {!!blockFaq && !blockFaq.hidden && (
          <FAQ
            currentCategory={currentCategory}
            title={blockFaq?.title}
            description={blockFaq?.description}
            options={blockFaq?.options}
          />
        )}
        {!!blockLearnMore && !blockLearnMore.hidden && (
          <LearnMore title={blockLearnMore?.title} description={blockLearnMore?.description} />
        )}
        <GetStarted
          blocksAboveEmpty={(!blockFaq || blockFaq?.hidden) && (!blockLearnMore || blockLearnMore?.hidden)}
          icon={GetStartedIcon}
          iconWidth='579px'
          title='Join our Community!'
          description='Join our community to manage and make smarter decisions on your bills!'
        />
      </Box>
    </>
  );
});

export default SubCategoryLanding;
