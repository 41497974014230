import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useWrapperStyles from '@packs/screens/MyHome/components/MyRenewals/styles';

export const DesktopSkeleton = () => {
  const wrapperStyles = useWrapperStyles();
  return (
    <Box sx={wrapperStyles.desktopItemWrapper()}>
      <Box sx={wrapperStyles.desktopTopInfoWrapper}>
        <Box sx={wrapperStyles.categoryCell}>
          <Skeleton variant='rounded' width={48} height={48} />
          <Skeleton variant='rounded' width={130} height={21} />
        </Box>
        <Box sx={wrapperStyles.policyCell}>
          <Skeleton variant='rounded' width={130} height={18} />
          <Skeleton variant='rounded' width={150} height={18} sx={{ mt: 0.5 }} />
        </Box>
        <Box sx={wrapperStyles.costCell}>
          <Skeleton variant='rounded' width={105} height={16} />
          <Skeleton variant='rounded' width={130} height={16} sx={{ mt: 0.5 }} />
          <Skeleton variant='rounded' width={160} height={16} sx={{ mt: 0.5 }} />
        </Box>
        <Box sx={wrapperStyles.reminderCell}>
          <Box>
            <Skeleton variant='rounded' width={105} height={21} />
            <Skeleton variant='rounded' width={150} height={18} sx={{ mt: 0.5 }} />
          </Box>
          <Skeleton variant='rounded' width={116} height={24} />
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', ml: 'auto' }}>
          <Skeleton variant='rounded' width={141} height={32} />
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton variant='rounded' width={183} height={18} />
      </Box>
    </Box>
  );
};
