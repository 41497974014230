import React, { useState } from 'react';
import cx from 'classnames';

import Box from '@mui/material/Box';
import colors from '@packs/assets/theme/base/colors';
import { AvatarUploader } from '@packs/components/MaterialUi/AvatarUploader/AvatarUploader';
import { Avatar, SocialSync } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import CloseIcon from '@packs/images/icons/CloseIcon';
import TrashIcon from '@packs/images/icons/UI/TrashIcon';
import PhotoIcon from '@packs/images/svg/ui/photo.svg';
import { toastr } from '@packs/lib/helpers';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';

import FormPassword from './components/FormPassword';
import { Form } from './components';

import { observer } from 'mobx-react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from 'reactstrap';

const settingsTabs = [
  {
    value: 'personal_info',
    label: 'Personal Info'
  },
  {
    value: 'password',
    label: 'Password'
  }
];

export const SettingsModal = observer(({ toggle, isOpen, toggleDeleteConfirmModal }) => {
  const { userStore } = useStore();
  const { currentUser } = useUserSelectors();
  const [activeTab, setActiveTab] = useState(settingsTabs[0].value);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const initialValues = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName
  };

  const toggleDropDown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };

  const onSelectTab = tab => {
    return () => {
      setActiveTab(tab);
    };
  };

  const submitWithClose = data => {
    userStore.updateCurrentUser({ ...data }).then(() => {
      toastr.success('User successfully updated');
      toggle();
    });
  };

  return (
    <Modal {...{ isOpen, toggle }} className='settings-modal'>
      <ModalHeader
        close={
          <div className='close-icon white-close' onClick={toggle}>
            <CloseIcon />
          </div>
        }
      >
        <Dropdown className='dropdown-ui' isOpen={isOpenDropDown} toggle={toggleDropDown}>
          <DropdownToggle tag='div'>
            <div className='dot-toggle d-flex align-items-center justify-content-center cursor-pointer'>...</div>
          </DropdownToggle>
          <DropdownMenu className='dropdown-menu-ui' center>
            <DropdownItem className='d-flex align-items-center delete-account-item' onClick={toggleDeleteConfirmModal}>
              <TrashIcon />
              <span>Delete your Account</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </ModalHeader>
      <ModalBody>
        <AvatarUploader />
        <div className='d-flex flex-column align-items-center mt-24'>
          <div className='fs-32 fw-700 user-name'>{shortSecondName(currentUser.displayName)}</div>
          <div className='d-flex justify-content-center mb-24 mt-20 fs-14 ml-5 lh-5 text-center'>
            <div>
              <span>ID:</span>
              <span className='secondary-text ml-5'>{currentUser.uid}</span>
            </div>
          </div>
        </div>
        <div className='mb-24'>
          <SocialSync />
        </div>
        <div className='setting-forms-container'>
          <div className='tabs-ui medium mb-24'>
            {settingsTabs.map(({ value, label }) => (
              <div
                key={value}
                className={cx('tab-item-ui', { active: activeTab === value })}
                onClick={onSelectTab(value)}
              >
                {label}
              </div>
            ))}
          </div>
          <div className='tab-content'>
            {activeTab === 'personal_info' && (
              <Form {...{ initialValues }} enableReinitialize onSubmit={submitWithClose} />
            )}
            {activeTab === 'password' && <FormPassword enableReinitialize onSubmit={submitWithClose} />}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});
