import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import { AssociationMemberForm } from '@packs/components/UI';
import { pathGen } from '@packs/layouts/constants';
// Images
import { useStore } from '@packs/stores';

import { useNavigate } from 'react-router-dom';

export const AssociationMember = () => {
  const { associationStore, countryCode } = useStore();
  const [inProcess, setInProcess] = useState(false);
  const navigate = useNavigate();

  const onSuccess = associationId => {
    return () => {
      setInProcess(true);
      navigate(pathGen('MY_ASSOCIATIONS', { countryCode }).concat(`?associationId=${associationId}`));
    };
  };

  const submitHandler = variables => {
    setInProcess(true);

    associationStore.addUserAssociationsCategory({
      attr: {
        associationId: variables?.associationId,
        code: variables?.code
      },
      onSuccess: onSuccess(variables?.associationId),
      onError: () => setInProcess(false)
    });
  };

  return (
    <div className='partner-form-container second-box'>
      <Typography variant='subtitle1' sx={{ mb: 2, textAlign: 'center' }}>
        Are you a member of a professional or trade association, a resident association or a social group & wish to
        obtain great member offers for your bills?
      </Typography>
      <Typography variant='body1' sx={{ textAlign: 'center' }}>
        Great, please search your association or group here
      </Typography>

      <AssociationMemberForm
        initialValues={{ code: '' }}
        associationInUrl
        onSubmit={submitHandler}
        {...{ inProcess }}
      />
    </div>
  );
};
