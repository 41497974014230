export default () => ({
  categoryItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    p: 1,
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'all .2s',
    '&:hover': {
      backgroundColor: 'rgba(0, 85, 217, 0.08)'
    }
  }
});
