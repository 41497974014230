import React, { useEffect } from 'react';

import { FieldWithErrors } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';

import { observer } from 'mobx-react';
import { Field } from 'redux-form';

export const Form = observer(({ handleSubmit, change }) => {
  const { currentUser } = useUserSelectors();

  return (
    <form encType='multipart/form-data' className='form user-settings-form' onSubmit={handleSubmit}>
      <div className='input-field'>
        <Field
          component={FieldWithErrors}
          type='text'
          name='firstName'
          autoComplete='firstName'
          placeholder='First name'
          disableFocusReset
          variant='outlined'
          label='First name'
          material
        />
      </div>
      <div className='input-field'>
        <Field
          component={FieldWithErrors}
          type='text'
          name='lastName'
          autoComplete='lastName'
          placeholder='Last name'
          disableFocusReset
          variant='outlined'
          label='Last name'
          material
        />
      </div>

      {/* <div className='input-field'>*/}
      {/*  <Field*/}
      {/*    type='text'*/}
      {/*    autoComplete='off'*/}
      {/*    component={FieldWithErrors}*/}
      {/*    name='displayName'*/}
      {/*    variant='outlined'*/}
      {/*    label='Name'*/}
      {/*    material*/}
      {/*  />*/}
      {/* </div>*/}
      <div className='input-field align-items-center'>
        <button type='submit' className='btn full black'>
          <span>Save</span>
        </button>
      </div>
    </form>
  );
});
