import React, { useEffect, useState } from 'react';

import { CustomReactSelect } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useCategories } from '@packs/models/category/useCategories';

import { useStore } from '../../../stores';

import { observer } from 'mobx-react';

const MyActivity = observer(() => {
  const { homeStore, currentCategory, userStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { categoriesOptions } = useCategories();

  const selectOptions = [{ value: null, label: 'All categories' }, ...categoriesOptions].map(
    ({ code, label, value }) => {
      return {
        value: value || code,
        label
      };
    }
  );
  const [selectValue, setSelectValue] = useState(selectOptions[0]);

  useEffect(() => {
    if (currentUser.id) {
      homeStore.fetchUserProfileActivity();
    }

    if (!currentUser.id) userStore.setRegisterEvent('home');
  }, []);

  const categoryName = currentCategory?.root?.enName;

  const onChangeCategoryFilter = valueObj => {
    homeStore.fetchUserProfileActivity({ categoryCode: valueObj.value });
    setSelectValue(valueObj);
  };

  const myReminders = homeStore.activity.myReminders;
  const myDetails = homeStore.activity.myDetails;
  const myReviews = homeStore.activity.myReviews;
  const myTips = homeStore.activity.myTips;
  const myQAndA = homeStore.activity.myQAndA;

  return (
    <div className='home-section'>
      <div className='inner-section activities-section'>
        <div className='d-flex align-items-center action-wrapper'>
          <CustomReactSelect
            className='reminder-select'
            classNamePrefix='Select'
            options={selectOptions}
            placeholder={selectOptions[0]?.label}
            value={selectValue}
            categoryName={categoryName}
            mobileDrawer
            onChange={onChangeCategoryFilter}
          />
        </div>
        <div className='activities-list d-flex align-items-center'>
          <ActivityBox name='My reminders' item={myReminders} />
          <ActivityBox name='My Details' item={myDetails} />
          <ActivityBox name='My Reviews' item={myReviews} />
          <ActivityBox name='My Tips' item={myTips} />
          <ActivityBox name='My Q&A' item={myQAndA} />
        </div>
      </div>
    </div>
  );
});

const ActivityBox = ({ item, name }) => {
  return (
    <div className='activity-box'>
      <div className='name'>{name}</div>
      <div className='count'>{item.totalCount}</div>

      {item.data.length ? (
        <div className='categories-list'>
          {item.data.map(({ count, categoryCode, categoryName }, inx) => (
            <div className='ctg-item' key={inx}>
              <div className='ctg-name'>{categoryName}</div>
              <div className='ctg-count'>{count}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default MyActivity;
