import BusinessPageIcon from '@shared/images/company/business-page-bg.jpg';

export default ({ colors }) => ({
  wrapper: {
    pt: 4,
    pb: 8,
    px: {
      xs: 2,
      lg: 0
    }
  },
  container: {
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    px: {
      xs: 0,
      lg: 2
    }
  },
  coverImageSection: {
    backgroundImage: `url(${BusinessPageIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: {
      xs: '100px',
      md: '252px'
    },
    borderRadius: '16px',
    p: 2
  },
  shareWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  breadcrumbsWrapper: {
    pb: 2
  },
  companySection: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 4
  },
  companySectionInfo: withBadges => ({
    flexGrow: 1,
    mt: {
      xs: '-40px',
      md: withBadges ? '-89px' : '-60px'
    }
  }),
  companySectionRating: {
    width: '375px',
    minWidth: {
      xs: '100%',
      md: '375px'
    },
    maxWidth: {
      xs: '100%',
      md: '375px'
    },
    mt: {
      xs: 0,
      md: '-132px'
    },
    mx: {
      xs: 'auto',
      md: '0'
    }
  },
  companyAvatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    mb: {
      xs: 2,
      lg: 3
    },
    px: {
      xs: 2,
      lg: 0
    }
  },

  companyAvatar: {
    width: {
      xs: '80px',
      lg: '126px'
    },
    height: {
      xs: '80px',
      lg: '126px'
    },
    border: `4px solid ${colors.white.main}`,
    backgroundColor: colors.white.main
  },
  companyName: {
    mb: 1
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 1,
    mb: 2
  },
  requestBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    mt: {
      xs: 2,
      lg: 4
    }
  },
  tabsWrapper: {
    mt: {
      xs: 5,
      lg: 6
    },
    mb: {
      xs: 3,
      lg: 6
    }
  },
  tabsContainer: {
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      md: 'flex-end'
    },
    justifyContent: 'space-between',
    flexDirection: {
      xs: 'column',
      md: 'row'
    }
  },
  tabsContentWrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 6
  },
  tabsContent: {
    width: {
      xs: '100%',
      md: 'calc(100% - 387px)'
    },
    flexGrow: 1
  },
  sidebarWrapper: {
    width: '339px',
    minWidth: '339px'
  },
  claimWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5
  },
  categorySection: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    borderRadius: '4px',
    p: 0.5,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    color: 'rgba(0, 0, 0, 0.87)',
    '& svg': {
      width: '16px',
      height: '16px',
      fill: 'rgba(0, 0, 0, 0.87)'
    }
  }
});
