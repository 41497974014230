import colors from '@packs/assets/theme/base/colors';

const { grey, dark } = colors;

export default () => ({
  container: {
    mb: 3,
    borderRadius: '24px',
    px: 1,
    pt: 3,
    pb: { xs: 5, lg: 1 },
    backgroundColor: grey[200]
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '1160px',
    mx: 'auto',
    px: {
      xs: 2,
      md: 0
    },
    textAlign: { xs: 'center', md: 'left' },
    mb: 2
  },
  item: {
    px: {
      xs: 1,
      sm: 3
    },
    py: 1
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 2,
    pl: {
      xs: 1,
      sm: 2
    },
    pr: {
      xs: 1,
      sm: 7
    }
  },
  sliderArrow: {
    position: 'absolute',
    top: '-30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    color: `${dark.main} !important`,
    cursor: 'pointer',
    '&:before': {
      content: 'none'
    }
  },
  prevArrow: {
    left: {
      xs: 'calc(100% - 108px)',
      sm: 'calc(100% - 120px)'
    }
  },
  nextArrow: {
    right: {
      xs: '8px',
      sm: '16px'
    }
  }
});
