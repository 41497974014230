import React, { useEffect, useState } from 'react';

import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';

import { CategoriesModal } from './components/categoriesModal';
import { MyRenewals } from './components/MyRenewals/components';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const MyHome = observer(() => {
  const { userStore } = useStore();
  const { currentUser } = useUserSelectors();

  const { flattenCategories } = useCategories();
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [filterCategory, setFilterCategory] = useState({ label: 'All', value: 'all' });

  useEffect(() => {
    if (!currentUser.id) {
      userStore.setRegisterEvent('home');
    }
  }, []);

  const handleCategoryModal = () => {
    setCategoryModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <MyRenewals
        {...{
          flattenCategories,
          setCategoryModalOpen,
          handleCategoryModal,
          filterCategory,
          setFilterCategory
        }}
      />
      {categoryModalOpen && (
        <CategoriesModal
          {...{ filterCategory }}
          isOpen={categoryModalOpen}
          toggle={() => {
            setCategoryModalOpen(!categoryModalOpen);
          }}
        />
      )}
    </>
  );
});

export default MyHome;
