import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';

export const BadgesSkeleton = () => {
  const styles = useStyles();

  return (
    <Box>
      <Box sx={styles.summaryWrapper}>
        <Skeleton variant='rounded' width={286} height={40} />
        <Box sx={styles.summaryList}>
          <Box sx={styles.summaryItem}>
            <Skeleton variant='rounded' width='80%' height={36} />
            <Skeleton variant='rounded' width={28} height={28} />
          </Box>
          <Box sx={styles.summaryItem}>
            <Skeleton variant='rounded' width='80%' height={36} />
            <Skeleton variant='rounded' width={28} height={28} />
          </Box>
          <Box sx={styles.summaryItem}>
            <Skeleton variant='rounded' width='80%' height={36} />
            <Skeleton variant='rounded' width={28} height={28} />
          </Box>
          <Box sx={styles.summaryItem}>
            <Skeleton variant='rounded' width='80%' height={36} />
            <Skeleton variant='rounded' width={28} height={28} />
          </Box>
          <Box sx={styles.summaryItem}>
            <Skeleton variant='rounded' width='80%' height={36} />
            <Skeleton variant='rounded' width={28} height={28} />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.listWrapper}>
        <Box
          sx={{
            ...styles.container,
            ...{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: { xs: 3, lg: 4 } }
          }}
        >
          <Skeleton variant='rounded' width={55} height={28} />
          <Skeleton variant='rounded' width={200} height={40} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            px: 3,
            mb: 2,
            minHeight: '32px'
          }}
        >
          <Skeleton variant='rounded' width={142} height={18} />
          <Divider orientation='horizontal' flexItem sx={{ flexGrow: 1, alignSelf: 'center', height: '1px' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Skeleton variant='rounded' width={32} height={32} sx={{ borderRadius: '50%' }} />
            <Skeleton variant='rounded' width={32} height={32} sx={{ borderRadius: '50%' }} />
          </Box>
        </Box>
        <Box sx={{ pl: { xs: 1, lg: 3 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
            <Skeleton variant='rounded' width={160} height={168} />
            <Skeleton variant='rounded' width={160} height={168} />
            <Skeleton variant='rounded' width={160} height={168} />
            <Skeleton variant='rounded' width={160} height={168} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
