import { FC, useMemo } from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { ICategory } from '@packs/interfaces/rootInterfaces';
import pxToRem from '@shared/assets/theme/functions/pxToRem';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import CreateSimplePolicy from './components/CreateSimplePolicy/CreateSimplePolicy';

type BlockedLeaderboardProps = {
  currentCategory: ICategory;
};
export const BlockedLeaderboard: FC<BlockedLeaderboardProps> = ({ currentCategory }) => {
  const { isMobile } = useWindowSize();

  const layoutTexts = useMemo((): { title: string; onText: string; offText: string } => {
    switch (currentCategory.code) {
      case 'accounts':
        return {
          title:
            'Final step: Add your existing current account provider to reveal your personalised results and insights!',
          onText: 'I have an existing current account provider!',
          offText: 'I don’t have a current account provider!'
        };
      default:
        return {
          title: 'Final step: Add your current provider to reveal your personalised results and insights!',
          onText: 'I have a current provider!',
          offText: 'I don’t have a current provider!'
        };
    }
  }, [currentCategory]);

  return (
    <Box sx={{ p: isMobile ? 2 : 3, border: 4, borderRadius: 4, borderColor: colors.primary.main }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md='auto'>
          <Box
            sx={{
              width: isMobile ? 1 : 330,
              p: isMobile ? 3 : 4,
              borderRadius: 4,
              backgroundColor: colors.primary.light
            }}
          >
            <Stack spacing={{ xs: 1, md: 2 }}>
              {[...Array(3)].map((_, index) => (
                <Box key={index} sx={{ width: 1, p: 1, borderRadius: 4, backgroundColor: colors.primary.focus }}>
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <IconBox
                      containerSize='56px'
                      icon={<Typography variant='h5'>?</Typography>}
                      backgroundColor={colors.primary.light}
                      borderRadius='12px'
                    />
                    <Box>
                      {index === 0 && (
                        <Typography variant='overline' color={colors.dark.main} gutterBottom>
                          Top Voted!
                        </Typography>
                      )}
                      <Typography variant='h6' sx={{ fontSize: pxToRem(20) }}>
                        {`#${index + 1}`}{' '}
                        <Box component='span' sx={{ fontSize: pxToRem(16) }}>
                          Business
                        </Box>
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 1 }}>
            <Typography variant='h5' sx={{ mb: 2 }}>
              {layoutTexts.title}
            </Typography>
            <CreateSimplePolicy onText={layoutTexts.onText} offText={layoutTexts.offText} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
