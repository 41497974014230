import React, { useEffect, useState } from 'react';

import { Loader } from '@packs/components';
import { I18n } from '@packs/components/I18n';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { useStore } from '@packs/stores';

import { Form } from './components';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const ResendUnlock = observer(({ setFormErrorDispatch }) => {
  const { currentUser } = useUserSelectors();
  const { userStore } = useStore();
  const { ROOT_PATH, USERS_SIGN_IN_PATH } = usePath();
  const [inProcess, setInProcess] = useState(false);
  const navigate = useNavigate();

  const { defaultMessages } = useTranslationMessages();

  useEffect(() => {
    return () => {
      userStore.setCurrentUser({});
      userStore.setCurrentUserUnlockInstructionsErrors([]);
    };
  }, []);

  const submitHandler = ({ email }) => {
    if (
      userStore.resendUnlockInstructionsFormErrors &&
      userStore.resendUnlockInstructionsFormErrors.length &&
      currentUser.email === email
    ) {
      return setFormErrorDispatch(userStore.resendUnlockInstructionsFormErrors[0]);
    }

    const callback = () => {
      if (currentUser.id) {
        navigate(ROOT_PATH);
      } else {
        navigate(USERS_SIGN_IN_PATH);
      }
      toastr.success(<I18n text={defaultMessages.deviseUnlocksSendInstructions} />);
    };

    const errorCallback = () => {
      setInProcess(false);
    };

    setInProcess(true);
    userStore.resendCurrentUserUnlockInstructions({ email, callback, errorCallback });
  };

  return (
    <section className='auth-container'>
      {inProcess && <Loader />}
      <div className='auth-block resend-unlock'>
        <h3 className='auth-title'>
          <I18n text={defaultMessages.resendUnlockInstructions} />
        </h3>
        <Form initialValues={{ email: currentUser.email }} onSubmit={submitHandler} />
      </div>
    </section>
  );
});

export default ResendUnlock;
