import colors from '@packs/assets/theme/base/colors';

const { text, dark, white } = colors;
export default () => ({
  wrapper: {
    width: '456px',
    maxWidth: '100%',
    '& .MuiFilledInput-root': {
      minHeight: '40px',
      borderRadius: '4px',
      border: '1px solid transparent',
      backgroundColor: dark.transparent,
      fontSize: '14px',
      fontWeight: '500',
      color: text.secondary,
      '&:after, &:before': {
        content: 'none'
      },

      '&.Mui-focused': {
        borderColor: 'rgba(0, 0, 0, 0.08)',
        backgroundColor: white.main,
        color: dark.main
      }
    }
  }
});
