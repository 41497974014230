import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

export const companyTypes = [
  { value: 'provider', label: 'Provider' },
  { value: 'broker', label: 'Agency / Broker' },
  { value: 'comparison_site', label: 'Comparison Site' }
];

export const BadgesSummary = ({ badges, company }) => {
  const styles = useStyles();
  const companyType = companyTypes.find(item => item.value === company?.companyType);

  const summary = useMemo(() => {
    return badges.reduce(
      (accum, item) => {
        const sortValue = item.sort;
        if (sortValue) {
          accum[sortValue] = (accum[sortValue] || 0) + 1;
        }
        if (!item?.season) {
          accum.year = (accum.year || 0) + 1;
        }
        return accum;
      },
      {
        best_avg_prices: 0,
        most_popular: 0,
        customer_service: 0,
        staff_knowledge: 0,
        year: 0
      }
    );
  }, [badges]);

  return (
    <Box sx={styles.wrapper}>
      <Typography variant='h5'>Badges Summary</Typography>
      <Box sx={styles.summaryList}>
        <Box sx={styles.summaryItem}>
          <Typography variant='body6'>
            Best priced <br /> {companyType?.label}
          </Typography>
          <Typography variant='h6'>{summary.best_avg_prices}</Typography>
        </Box>
        <Box sx={styles.summaryItem}>
          <Typography variant='body6'>
            Most popular <br /> {companyType?.label}
          </Typography>
          <Typography variant='h6'>{summary.most_popular}</Typography>
        </Box>
        <Box sx={styles.summaryItem}>
          <Typography variant='body6'>
            Best Customer <br /> Service
          </Typography>
          <Typography variant='h6'>{summary.customer_service}</Typography>
        </Box>
        <Box sx={styles.summaryItem}>
          <Typography variant='body6'>
            Top <br /> Experts
          </Typography>
          <Typography variant='h6'>{summary.staff_knowledge}</Typography>
        </Box>
        <Box sx={styles.summaryItem}>
          <Typography variant='body6'>
            Year <br /> Awards
          </Typography>
          <Typography variant='h6'>{summary.year}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
