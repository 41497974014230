import { FC } from 'react';

import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { CommunityBlock } from '@packs/components/MaterialUi/Landing/Hero/components/CommunityBlock/CommunityBlock';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import useContainerStyles from '@packs/styles/Global/container';

import useStyles from './styles';

type CommunityProps = {
  currentCategoryId: number;
};

export const Community: FC<CommunityProps> = ({ currentCategoryId }) => {
  const styles = useStyles();
  const stylesContainer = useContainerStyles();
  const { toggleShareSocialModal } = useModalsContext();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={{ ...stylesContainer.container, ...styles.container }}>
        <Box>
          <Typography variant='h4' sx={{ mb: 1 }}>
            Help grow our community!
          </Typography>
          <Box sx={styles.sharedWrapper}>
            <CommunityBlock currentCategoryId={currentCategoryId} />
            <Typography variant='body1'>Help us create a smarter transparent marketplace for bills!</Typography>
          </Box>
        </Box>
        <Box>
          <Button
            variant='contained'
            color={'white' as ButtonProps['color']}
            startIcon={<ShareRoundedIcon />}
            onClick={toggleShareSocialModal}
          >
            Share with your friends!
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
