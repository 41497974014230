import colors from '@packs/assets/theme/base/colors';

const { primary, borderColor, grey, dark } = colors;

export default () => ({
  wrapper: {
    position: 'relative',
    mb: {
      xs: 3,
      md: 5
    },
    borderRadius: '16px',
    border: `4px solid ${primary.main}`,
    padding: {
      xs: 2,
      md: 4
    },
    background: '#fff',
    textAlign: {
      xs: 'center',
      md: 'left'
    }
  },
  closeIcon: {
    position: 'absolute',
    top: '16px',
    right: '16px'
  },
  monthList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 2
  },
  monthItem: {
    width: {
      xs: 'calc(50% - 16px)',
      md: '250px'
    },
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    border: `1px solid ${borderColor.secondary}`,
    backgroundColor: grey[100],
    color: dark.main,
    fontSize: '15px',
    fontWeight: 700,
    cursor: 'pointer',
    transaction: 'all .2s',
    '&:hover': {
      backgroundColor: primary.main
    }
  },
  pageSwitcherWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      md: 'flex-end'
    },
    mb: 2
  }
});
