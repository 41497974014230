import React from 'react';

export default () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.5312 12.627V27.8789C29.5312 28.9453 28.6699 29.8066 27.6035 29.8066H3.59766C2.53125 29.8066 1.66992 28.9453 1.66992 27.8789V12.627L4.24805 14.584L15.6035 23.2266L26.959 14.584L29.5312 12.627Z'
        fill='#FFD23C'
      />
      <path
        d='M28.2598 22.3535C28.1543 22.3535 28.0664 22.2656 28.0664 22.1602V21.6387C28.0664 21.5332 28.1543 21.4453 28.2598 21.4453C28.3652 21.4453 28.4531 21.5332 28.4531 21.6387V22.1602C28.4531 22.2715 28.3652 22.3535 28.2598 22.3535Z'
        fill='white'
      />
      <path
        d='M28.2598 24.5566C28.1543 24.5566 28.0664 24.4687 28.0664 24.3633V23.1563C28.0664 23.0508 28.1543 22.9629 28.2598 22.9629C28.3652 22.9629 28.4531 23.0508 28.4531 23.1563V24.3633C28.4531 24.4687 28.3652 24.5566 28.2598 24.5566Z'
        fill='white'
      />
      <path
        d='M29.5312 12.627L26.9531 14.584L15.6035 23.2266L4.24805 14.584L1.66992 12.627L4.24805 10.1953L11.0156 3.80273L14.2793 0.720703C15.0234 0.0175781 16.1836 0.0175781 16.9219 0.720703L20.1855 3.80273L26.959 10.1953L29.5312 12.627Z'
        fill='#F3B818'
      />
      <path
        d='M25.9863 6.36903V15.3222L24.8496 16.1835L15.6035 23.2265L5.22071 15.328V6.36903C5.20313 5.33192 6.11133 4.42957 7.14844 4.44715H24.0586C24.2051 4.44715 24.3457 4.46473 24.4805 4.49403C24.8027 4.56434 25.1074 4.72254 25.3535 4.9452C25.7461 5.29676 25.9863 5.80653 25.9863 6.36903Z'
        fill='white'
      />
      <path
        opacity='0.1'
        d='M25.9863 6.36921V15.3223L24.8496 16.1837V7.50593C24.8496 6.43952 23.9883 5.57819 22.9219 5.57819H6.01172C5.77734 5.57819 5.54883 5.61921 5.34375 5.70124C5.61328 4.96296 6.36328 4.43562 7.14844 4.44734H24.0586C24.2051 4.44734 24.3457 4.46491 24.4805 4.49421C24.8027 4.57038 25.1074 4.72273 25.3535 4.94538C25.7461 5.29694 25.9863 5.80671 25.9863 6.36921Z'
        fill='#231F20'
      />
      <path
        d='M0.46875 19.7931C0.363281 19.7931 0.275391 19.881 0.275391 19.9865V20.5079C0.275391 20.6134 0.363281 20.7013 0.46875 20.7013C0.574219 20.7013 0.662109 20.6134 0.662109 20.5079V19.9806C0.65625 19.8751 0.574219 19.7931 0.46875 19.7931Z'
        fill='#231F20'
      />
      <path
        d='M0.46875 21.2988C0.363281 21.2988 0.275391 21.3867 0.275391 21.4922V24.3398C0.275391 24.4453 0.363281 24.5332 0.46875 24.5332C0.574219 24.5332 0.662109 24.4453 0.662109 24.3398V21.4922C0.65625 21.3867 0.574219 21.2988 0.46875 21.2988Z'
        fill='#231F20'
      />
      <path
        d='M29.7246 12.6387C29.7246 12.6211 29.7129 12.5332 29.666 12.4922L26.1797 9.19922V6.375C26.1797 5.22656 25.248 4.25977 24.0645 4.25977H20.9531L17.0566 0.580078C16.2422 -0.1875 14.9648 -0.1875 14.1504 0.580078L10.2598 4.25391H7.14844C6.00586 4.25391 5.0332 5.16797 5.0332 6.36914V9.19336L1.54688 12.4863C1.50586 12.5215 1.48828 12.5801 1.48828 12.627V27.8789C1.48828 29.0449 2.44336 29.9941 3.60352 29.9941H27.6094C28.7695 29.9941 29.7246 29.0449 29.7246 27.8789C29.7246 27.8672 29.7246 12.6211 29.7246 12.6387ZM29.2383 12.6094C29.2676 12.5859 28.8984 12.8672 26.1738 14.9355V9.71484L29.2383 12.6094ZM14.4141 0.855469C15.082 0.222656 16.1309 0.222656 16.7988 0.855469L20.3965 4.25391H10.8164L14.4141 0.855469ZM7.14844 4.63477H24.0586C25.0312 4.63477 25.793 5.43164 25.793 6.36914V15.2285C25.6816 15.3105 15.8613 22.7871 15.6035 22.9863C3.46289 13.7461 5.68359 15.4395 5.41406 15.2285V6.36914C5.41406 5.39648 6.20508 4.63477 7.14844 4.63477ZM5.0332 9.7207V14.9414C4.91016 14.8477 2.29102 12.8555 1.96875 12.6152L5.0332 9.7207ZM1.86328 13.0137C2.88281 13.7871 -1.11914 10.7402 13.166 21.6094L2.30273 29.0273C2.0332 28.7227 1.86914 28.3184 1.86914 27.8789C1.86328 27.8789 1.86328 13.0137 1.86328 13.0137ZM3.59766 29.6191C3.22266 29.6191 2.87695 29.4961 2.58984 29.2969L13.4883 21.8555L15.4922 23.3789C15.5625 23.4316 15.6562 23.4316 15.7207 23.3789C18.4629 21.293 17.5137 22.0137 17.7246 21.8555L28.6172 29.291C28.3301 29.4961 27.9844 29.6133 27.6094 29.6133L3.59766 29.6191ZM29.3438 27.8789C29.3438 28.3184 29.1797 28.7227 28.9043 29.0273L18.041 21.6094C18.1992 21.4863 16.8691 22.5 29.3438 13.0137V27.8789Z'
        fill='#231F20'
      />
      <path
        d='M24.2227 3.43359C25.1954 3.48633 26.0801 4.01953 26.584 4.85742C26.6368 4.94531 26.754 4.97461 26.8477 4.92187C26.9356 4.86914 26.9649 4.75195 26.9122 4.6582C26.3438 3.70898 25.3418 3.10547 24.2286 3.04688C24.1231 3.04688 24.0411 3.12891 24.0411 3.23437C24.0352 3.33984 24.1172 3.42773 24.2227 3.43359Z'
        fill='#231F20'
      />
      <path
        d='M27.0058 6.43354C27.0058 6.53901 27.0937 6.6269 27.1992 6.6269C27.3046 6.6269 27.3925 6.53901 27.3925 6.43354C27.3925 6.38081 27.4101 6.11714 27.3281 5.73042C27.3046 5.62495 27.205 5.56049 27.1054 5.57807C26.9999 5.60151 26.9355 5.70112 26.9531 5.80073C27.0117 6.123 27.0058 6.40425 27.0058 6.43354Z'
        fill='#231F20'
      />
      <path
        d='M8.29688 7.2832H22.9101C23.0156 7.2832 23.1035 7.19531 23.1035 7.08984C23.1035 6.98438 23.0156 6.89648 22.9101 6.89648H8.29688C8.19141 6.89648 8.10352 6.98438 8.10352 7.08984C8.10352 7.19531 8.19141 7.2832 8.29688 7.2832Z'
        fill='#231F20'
      />
      <path
        d='M8.29688 10.0488H22.9101C23.0156 10.0488 23.1035 9.96094 23.1035 9.85547C23.1035 9.75 23.0156 9.66211 22.9101 9.66211H8.29688C8.19141 9.66211 8.10352 9.75 8.10352 9.85547C8.10352 9.96094 8.19141 10.0488 8.29688 10.0488Z'
        fill='#231F20'
      />
      <path
        d='M8.29688 12.8145H22.9101C23.0156 12.8145 23.1035 12.7266 23.1035 12.6211C23.1035 12.5156 23.0156 12.4277 22.9101 12.4277H8.29688C8.19141 12.4277 8.10352 12.5156 8.10352 12.6211C8.10352 12.7266 8.19141 12.8145 8.29688 12.8145Z'
        fill='#231F20'
      />
      <path
        d='M8.29688 15.5802H22.9101C23.0156 15.5802 23.1035 15.4923 23.1035 15.3868C23.1035 15.2814 23.0156 15.1935 22.9101 15.1935H8.29688C8.19141 15.1935 8.10352 15.2814 8.10352 15.3868C8.10352 15.4923 8.19141 15.5802 8.29688 15.5802Z'
        fill='#231F20'
      />
    </svg>
  );
};
