import React from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { usePath } from '@packs/layouts/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { calculateTimeAgo } from '@packs/screens/DashboardLayout/components/LeaderboardNavigation/LeaderboardNavigation';
import PersonalizeResults from '@packs/screens/PersonalizeResults/RenewalDetailsFormWithHooks';
import { useStore } from '@packs/stores';
import useContainerStyles from '@packs/styles/Global/container';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const { secondary, grey, dark } = colors;

const UpdateDetails = () => {
  const styles = useStyles();
  const containerStyles = useContainerStyles();
  const { currentCategory } = useStore();
  const { isMobile } = useWindowSize();
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  const { currentPolicy } = useDetails();

  const updatedAt = currentPolicy?.updatedAt;
  const lastUpdate = `Last update: ${
    dayjs().diff(updatedAt, 'month') >= 1
      ? calculateTimeAgo(currentPolicy?.updatedAt)
      : dayjs(currentPolicy?.updatedAt).format('DD/MM/YYYY')
  }`;

  return (
    <>
      <Box sx={{ backgroundColor: '#fff' }}>
        <Box sx={[containerStyles.container, { px: 0 }]}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.container}>
              <Box sx={styles.breadcrumbs}>
                <BreadcrumbsMUI
                  maxItems={isMobile ? 2 : 3}
                  routes={[{ to: DASHBOARD_MY_RESULTS_PATH, label: currentCategory.name }]}
                  currentPage='Update your details'
                />
                <Box
                  size='large'
                  component={Link}
                  to={DASHBOARD_MY_RESULTS_PATH}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: '48px', md: '56px' },
                    minWidth: { xs: '48px', md: '56px' },
                    height: { xs: '48px', md: '56px' },
                    borderRadius: '50%',
                    backgroundColor: grey[200],
                    color: dark.main,
                    fontSize: { xs: '32px', md: '40px' },
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: grey[300]
                    }
                  }}
                >
                  <CloseIcon color='dark' />
                </Box>
              </Box>

              <Box sx={styles.innerSection}>
                <Box sx={styles.categoryWrapper}>
                  <CategoryIconBox
                    containerSize={isMobile ? '40px' : '88px'}
                    borderRadius={isMobile ? '8px' : '16px'}
                    iconSize={isMobile ? '32px' : '56px'}
                    category={currentCategory}
                    backgroundColor='#EBF3FB'
                    color={secondary.main}
                    sx={{
                      position: 'relative',
                      overflow: 'hidden',
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        height: currentPolicy?.policyType === 'simple' ? '34%' : 0,
                        backgroundColor: '#69A6EC',
                        zIndex: 1
                      }
                    }}
                  />
                  <Box width={{ xs: '100%', md: 'auto' }}>
                    <Typography variant='h5' sx={styles.categoryName}>
                      Update your details in {currentCategory?.name}
                    </Typography>
                    <Typography variant='subtitle1' sx={styles.title}>
                      {currentPolicy?.policyType === 'simple' ? '34% completed!' : lastUpdate}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.personalizeWrapper}>
        <PersonalizeResults />
      </Box>
    </>
  );
};

export default UpdateDetails;
