import Member1Icon from '@packs/screens/RootCategoryLanding/membersSection/broadbandImages/member-1.webp';
import Member2Icon from '@packs/screens/RootCategoryLanding/membersSection/broadbandImages/member-2.webp';
import Member3Icon from '@packs/screens/RootCategoryLanding/membersSection/broadbandImages/member-3.webp';
import Member4Icon from '@packs/screens/RootCategoryLanding/membersSection/broadbandImages/member-4.webp';

const broadband = [
  {
    label: "Here’s why you should use BillWinner to shop for your broadband bill!'",
    title: "Here’s why you should use BillWinner to shop for your broadband bill!'",
    description: '',
    numerate: false,
    list: [
      { title: 'We compare the best providers, brokers, and price comparison sites in one place!', desc: '' },
      { title: 'See the most popular businesses on our leaderboard!', desc: '' },
      { title: 'Get customer insights and ratings for businesses!', desc: '' },
      { title: 'Get support from our members and business experts!', desc: '' },
      { title: 'Get quotes from multiple businesses within one quote request!', desc: '' }
    ],
    imgSrc: Member1Icon
  },
  {
    label: 'What are the key steps to evaluating the best broadband deals?',
    title: 'What are the key steps to evaluating the best broadband deals?',
    description:
      'Evaluating the best broadband deals requires careful consideration of various factors to ensure you choose a plan that suits your needs and budget. Here are the key steps to help you evaluate broadband deals:',
    numerate: true,
    list: [
      {
        title: 'Assess Your Needs:',
        desc: 'Determine your internet usage requirements, including download and upload speeds, data limits, and the number of devices connected simultaneously. This will help you choose the right plan.'
      },
      {
        title: 'Check Availability:',
        desc: 'Not all broadband providers and plans are available in every location. Verify which providers offer services in your area and what types of connections (e.g., DSL, cable, fiber) are available.'
      },
      {
        title: 'Compare Speeds:',
        desc: "Compare the download and upload speeds offered by different providers. Choose a speed that meets your usage demands, whether it's for basic web browsing or data-intensive activities like gaming and streaming."
      },
      {
        title: 'Consider Data Allowance:',
        desc: 'Some broadband plans have data limits or fair usage policies. Ensure the plan you choose provides sufficient data for your needs, especially if you engage in activities that consume a lot of data.'
      },
      {
        title: 'Bundle Deals:',
        desc: 'Some providers offer bundles that include broadband, TV, and phone services. Evaluate whether bundling services can save you money compared to getting them separately.'
      },
      {
        title: 'Contract Terms:',
        desc: 'Review the contract terms, including the duration of the contract, any penalties for early termination, and any price increases after promotional periods.'
      },
      {
        title: 'Cost and Fees:',
        desc: 'Compare the total cost of the plan, including installation fees, equipment rental, and any hidden charges. Consider the long-term cost, not just the introductory price.'
      },
      {
        title: 'Customer Reviews and Reputation:',
        desc: 'Research customer reviews and the reputation of the broadband providers. Look for feedback on reliability, customer service, and overall satisfaction.'
      },
      {
        title: 'Customer Support:',
        desc: 'Consider the quality of customer support and technical assistance. Reliable and responsive customer support can be crucial if you encounter issues with your service.'
      },
      {
        title: 'Contract Length and Flexibility:',
        desc: "Some providers offer no-contract or shorter-term contract options, which can be convenient if you're not looking for a long-term commitment."
      },
      {
        title: 'Discounts and Promotions:',
        desc: 'Look for special offers, discounts, and promotions that providers may be offering. These can significantly reduce your monthly costs during the initial contract period.'
      },
      {
        title: 'Upfront Costs and Equipment:',
        desc: 'Check if there are any upfront costs for equipment (such as a modem or router) and installation. Some providers may offer these for free or at a reduced cost.'
      },
      {
        title: 'Security and Extras:',
        desc: 'Consider additional features like security services, email accounts, and Wi-Fi hotspots, which some providers may include with their packages.'
      },
      {
        title: 'Future-Proofing:',
        desc: 'If possible, choose a broadband technology that is more future-proof, such as fiber-optic, as it can provide faster and more reliable connections as technology advances.'
      },
      {
        title: 'Ask for Recommendations:',
        desc: 'Seek recommendations from friends, family, or neighbours who are satisfied with their broadband providers, as personal experiences can be valuable.'
      }
    ],
    description2:
      "By following these steps, you can make an informed decision when evaluating broadband deals and select a plan that aligns with your needs and budget. Remember to read the terms and conditions carefully and ask questions if you're uncertain about any aspect of the contract.",
    imgSrc: Member2Icon
  },
  {
    label: 'What are the types of broadband connections?',
    title: 'What are the types of broadband connections?',
    description:
      'There are several types of broadband connections, each with its own characteristics and advantages. Here are some of the common types:',
    numerate: true,
    list: [
      {
        title: 'DSL (Digital Subscriber Line):',
        desc: 'DSL is a widely used broadband technology that uses existing telephone lines to deliver high-speed internet. It offers both ADSL (Asymmetric DSL) and SDSL (Symmetric DSL) options. ADSL provides faster download speeds than upload speeds, while SDSL offers symmetric speeds for both uploads and downloads.'
      },
      {
        title: 'Cable Broadband:',
        desc: 'Cable broadband uses the same infrastructure as cable TV to deliver internet service. It can offer high-speed internet and is known for its reliability. However, the actual speeds can be affected by network congestion in your area.'
      },
      {
        title: 'Fiber-Optic Broadband:',
        desc: 'Fiber-optic broadband is one of the fastest and most reliable options available. It uses thin strands of glass or plastic to transmit data using light signals. Fiber-optic connections can deliver symmetric speeds with ultra-low latency, making them ideal for demanding applications like online gaming and 4K streaming.'
      },
      {
        title: 'Satellite Broadband:',
        desc: "Satellite broadband relies on communication satellites in orbit to provide internet access. It's a viable option for areas where traditional wired or wireless connections are unavailable. However, it can be more expensive and may have latency issues due to the signal's round-trip journey to and from space."
      },
      {
        title: 'Wireless Broadband:',
        desc: "Wireless broadband, often provided through cellular networks or fixed wireless connections, offers internet access via radio signals. It's a convenient option for remote areas and can be accessed through a mobile hotspot, a USB modem, or a fixed wireless receiver."
      },
      {
        title: 'Fixed Wireless Broadband:',
        desc: "Fixed wireless broadband uses ground-based stations to transmit signals to an antenna or receiver on a customer's premises. It's similar to wireless broadband but is designed for stationary locations and can offer more stable connections."
      },
      {
        title: 'Hybrid Fiber-Coaxial (HFC):',
        desc: "HFC combines fiber-optic and coaxial cable technologies to deliver broadband services. It can provide high speeds, and it's commonly used by cable providers to upgrade their networks."
      },
      {
        title: 'Broadband over Power Lines (BPL):',
        desc: "BPL utilizes electrical power lines to deliver internet access. It's less common than other types of broadband and has faced technical challenges, but it can be an option in certain areas."
      },
      {
        title: 'T1 and T3 Lines:',
        desc: 'T1 and T3 lines are dedicated, high-speed connections typically used by businesses. T1 lines offer a symmetrical speed of 1.544 Mbps, while T3 lines provide even higher symmetrical speeds at 44.736 Mbps.'
      },
      {
        title: 'Ethernet over Copper:',
        desc: "Ethernet over Copper (EoC) uses copper lines to provide high-speed connections, especially for businesses. It's an alternative to traditional T1 lines and can offer varying speeds."
      }
    ],
    description2:
      'The choice of broadband connection type depends on factors like location, internet speed requirements, availability, and budget. Fiber-optic and cable broadband are often preferred for their high speeds and reliability, while DSL, satellite, and wireless options are suitable for areas with limited alternatives. Businesses may opt for dedicated lines like T1, T3, or EoC for their reliability and guaranteed bandwidth.',
    imgSrc: Member3Icon
  },
  {
    label: 'What are the key steps to consider when switching your broadband service?',
    title: 'What are the key steps to consider when switching your broadband service?',
    description: 'Switching your broadband service can be a straightforward process if you follow these key steps:',
    numerate: true,
    list: [
      {
        title: 'Research New Providers and Plans:',
        desc: 'Begin by researching and identifying potential broadband providers and plans in your area. Consider factors like speed, data limits, pricing, and contract terms.'
      },
      {
        title: 'Check Availability:',
        desc: "Ensure that the new provider and plan you're interested in are available in your location. Not all providers offer service everywhere."
      },
      {
        title: 'Compare Plans:',
        desc: "Compare the features, speeds, data allowances, and costs of the plans you're considering. Look at both the introductory pricing and the long-term costs."
      },
      {
        title: 'Review Customer Reviews and Reputation:',
        desc: 'Research the reputation of the new provider by reading customer reviews and checking online forums. Pay attention to feedback about reliability and customer service.'
      },
      {
        title: 'Contact Your Current Provider:',
        desc: "Before making any changes, contact your current broadband provider to inquire about your contract's end date, early termination fees, and any equipment return requirements."
      },
      {
        title: 'Notify Your Current Provider:',
        desc: "If you're switching to a new provider, inform your current one that you intend to cancel your service. They may offer you a retention deal or provide information on the cancellation process."
      },
      {
        title: 'Choose an Installation Date:',
        desc: "Coordinate the installation date with your new provider. Ensure it aligns with your current service's cancellation date to minimize downtime."
      },
      {
        title: 'Check Equipment Compatibility:',
        desc: 'If you own or have been provided with broadband equipment (e.g., modem, router), confirm that it is compatible with the new service. If not, ask the new provider if they offer equipment or if you need to purchase your own.'
      },
      {
        title: 'Backup Important Data:',
        desc: 'Before the switch, back up any critical data or settings from your current router or modem, such as Wi-Fi network names and passwords.'
      },
      {
        title: 'Review the New Contract:',
        desc: 'Carefully review the terms and conditions of the new contract. Pay attention to contract length, early termination fees, and any price increases after the promotional period.'
      },
      {
        title: 'Schedule Installation:',
        desc: 'Coordinate with the new provider to schedule the installation or activation of your new broadband service. Be prepared for a technician to come to your location if required.'
      },
      {
        title: 'Notify Other Services:',
        desc: 'If you have other services bundled with your current broadband (e.g., TV or phone), inform those providers of your change and consider whether you want to keep, change, or cancel those services.'
      },
      {
        title: 'Test the New Connection:',
        desc: "Once the new service is installed, test your internet connection to ensure it's working as expected. Check both the speed and reliability."
      },
      {
        title: 'Cancel the Old Service:',
        desc: 'After confirming that the new service is working correctly, contact your old provider to officially cancel your previous broadband service. Make sure to return any equipment if required.'
      },
      {
        title: 'Update Your Devices:',
        desc: 'Update your devices with the new Wi-Fi network information and settings. Ensure that all your devices can connect to the new network.'
      },
      {
        title: 'Monitor Billing:',
        desc: 'Keep an eye on your billing statements to ensure you are no longer being charged for the old service and that the new charges are as expected.'
      }
    ],
    description2:
      'Switching your broadband service may involve some coordination and careful planning, but following these steps will help ensure a smooth transition and that you get the most out of your new broadband service.',
    imgSrc: Member4Icon
  }
];

export default broadband;
