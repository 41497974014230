import colors from '@packs/assets/theme/base/colors';

const { reminderStatus } = colors;

export default () => ({
  wrapper: color => ({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: reminderStatus[color].main,
    boxShadow: `0px 0px 0px 4px ${reminderStatus[color].light}`
  })
});
