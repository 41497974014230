import React from 'react';

import { TranslationsContext } from '@packs/layouts/AppLayer/context';

import { injectIntl } from 'react-intl';

export const TranslationsProvider = injectIntl(({ children, defaultMessages, intl: { formatMessage } }) => {
  return (
    <TranslationsContext.Provider value={{ defaultMessages: defaultMessages || {}, formatMessage }}>
      {children}
    </TranslationsContext.Provider>
  );
});
