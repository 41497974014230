import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BadgeEmptyIcon from '@packs/images/ui/badge-empty.svg';

import useStyles from './styles';

export const BadgesEmpty = () => {
  const styles = useStyles();
  return (
    <Box>
      <Box sx={[styles.summaryWrapper, styles.wrapper]}>
        <Box>
          <img src={BadgeEmptyIcon} alt='Empty' />
        </Box>
        <Box>
          <Typography variant='body4'>At the moment, this company does not have any badges to display!</Typography>
        </Box>
      </Box>
      <Box sx={[styles.wrapper, styles.listWrapper]}>
        <Box component='img' src={BadgeEmptyIcon} alt='Empty' sx={{ width: '160px' }} />
        <Box component='img' src={BadgeEmptyIcon} alt='Empty' sx={{ width: '160px' }} />
        <Box component='img' src={BadgeEmptyIcon} alt='Empty' sx={{ width: '160px' }} />
        <Box component='img' src={BadgeEmptyIcon} alt='Empty' sx={{ width: '160px' }} />
      </Box>
    </Box>
  );
};
