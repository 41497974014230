import { createTheme } from '@mui/material/styles';
import autocomplete from '@packs/assets/theme/components/autocomplete';
import avatar from '@packs/assets/theme/components/avatar';
import button from '@packs/assets/theme/components/button';
import container from '@packs/assets/theme/components/container';
import dialog from '@packs/assets/theme/components/dialog';
import rating from '@packs/assets/theme/components/rating';
import select from '@packs/assets/theme/components/select';
import skeleton from '@packs/assets/theme/components/skeleton';
import slider from '@packs/assets/theme/components/slider';
import muiDataGrid from '@packs/assets/theme/components/table/muiDataGrid';
import tabs from '@packs/assets/theme/components/tabs';
import tab from '@packs/assets/theme/components/tabs/tab';
import textField from '@packs/assets/theme/components/textField';
import tooltip from '@packs/assets/theme/components/tooltip';
import breakpoints from '@shared/assets/theme/base/breakpoints';
import globals from '@shared/assets/theme/base/globals';

import colors from './base/colors';
import typography from './base/typography';

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals
      }
    },
    MuiButton: { ...button },
    MuiSlider: { ...slider },
    MuiTabs: { ...tabs },
    MuiTab: { ...tab },
    MuiTooltip: { ...tooltip },
    MuiAvatar: { ...avatar },
    MuiInputBase: { ...textField },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiAutocomplete: { ...autocomplete },
    MuiMenuItem: { ...select },
    MuiContainer: { ...container },
    MuiRating: { ...rating },
    MuiDialog: { ...dialog },
    MuiDataGrid: { ...muiDataGrid },
    MuiSkeleton: { ...skeleton }
  }
});
