import React, { useEffect } from 'react';
import _ from 'lodash-es';

import { CustomReactSelect } from '@packs/components';
import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { serializeCountryName } from '@packs/models/country/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Field } from 'redux-form';

const Form = ({ change, handleSubmit, formAttributes }) => {
  const { isMobile } = useWindowSize();
  const { currentCountry, launchCountries } = useStore();

  const countryOption = {
    ...currentCountry,
    label: serializeCountryName(currentCountry.enName),
    value: currentCountry.isoA2Code,
    countryCode: currentCountry.isoA2Code
  };

  const countryOptions = launchCountries.map(option => {
    return {
      ...option,
      label: serializeCountryName(option.enName),
      value: option.isoA2Code,
      countryCode: option.isoA2Code
    };
  });
  const currentCountryOption =
    _.get(formAttributes, 'country') || _(countryOptions).filter({ value: currentCountry.isoA2Code }).get('0');
  const countryStatesOptions = _.get(currentCountryOption, 'regions', []).map(option => {
    return {
      label: option.name,
      value: option.name
    };
  });

  useEffect(() => {
    change('country', countryOption);
  }, []);

  return (
    <form autoComplete='off' className='d-flex flex-column align-items-center gap-16' onSubmit={handleSubmit}>
      <div className='input-field'>
        <Field
          component={FieldWithErrors}
          type='email'
          name='email'
          autoComplete='email'
          placeholder='Email'
          disableFocusReset
        />
      </div>
      <div className='input-field'>
        <Field
          component={FieldWithErrors}
          type='text'
          name='displayName'
          autoComplete='new-password'
          placeholder='Full name'
          disableFocusReset
        />
      </div>
      <div className='input-field'>
        <Field
          component={FieldWithErrors}
          type='password'
          name='password'
          autoComplete='new-password'
          placeholder='Password'
          disableFocusReset
        />
      </div>
      <div className='input-field'>
        <Field
          component={CustomReactSelect}
          name='country'
          options={countryOptions}
          classNamePrefix='Select'
          placeholder='Select country'
          isSearchable={!isMobile}
          className='form-select'
        />
      </div>
      <div className='input-field'>
        <Field
          component={CustomReactSelect}
          name='state'
          options={countryStatesOptions}
          classNamePrefix='Select'
          placeholder={`Select ${currentCountryOption.regionType || 'region'}`}
          className='form-select'
        />
      </div>

      <div className='mt-24'>
        <button type='submit' className='btn full white'>
          <span>Submit</span>
        </button>
      </div>
    </form>
  );
};

export default Form;
