import _, { size } from 'lodash-es';

import { IUserFormAttributes } from '@packs/layouts/AppLayer/components/AuthModal/AuthModal';
import { useStore } from '@packs/stores';

export const useSetUserAttrsToStore = () => {
  const { userStore, countryCode } = useStore();

  return (userAttributes: IUserFormAttributes) => {
    const newData: IUserFormAttributes = { ...userAttributes };
    delete newData.socialName;
    delete newData.secret;
    delete newData.accessToken;
    if (!userAttributes?.showSalesCode) {
      delete newData.salesCode;
    }
    delete newData.city;
    delete newData.eirCodeAlias;
    delete newData.showSalesCode;

    if (size(newData)) {
      newData.state = _.get(userAttributes, 'state.value') || userAttributes.state;
      newData.countryCode = userAttributes.countryCode || _.get(userAttributes, 'country.countryCode') || countryCode;

      if (userAttributes.eirCodeAlias) {
        newData.eirCodeAliasId = userAttributes.eirCodeAlias.id;
      }
      if (userAttributes.city) {
        newData.cityId = userAttributes.city.id;
      }
    }

    userStore.setCurrentUser({
      ...userStore.currentUser,
      ...newData
    });
  };
};
