/* eslint-disable simple-import-sort/imports */
import React, { useRef, useState } from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

// read more https://fullcalendar.io/docs/v5/upgrading-from-v4
import '@fullcalendar/react/dist/vdom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick

import { NoDetailsCategory } from './components/NoDetailsCategory';
import { CalendarHeader } from './CalendarHeader';

import { SideRemindersList } from '@packs/components/Calendar/components/SideRemindersList';
import { TopRemindersList } from '@packs/components/Calendar/components/TopRemindersList';
import { CustomEventPopover } from '@packs/components/Calendar/CustomEventPopover';
import { GridYearPlugin } from '@packs/components/Calendar/yearGridCustomPlugin';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { FILTER_CATEGORIES } from '@packs/lib/constants';
import { createRoot } from 'react-dom/client';
import { useStore } from '@packs/stores';
import { CalendarContext } from '@packs/components/Calendar/CalendarContext';
import Box from '@mui/material/Box';
import { PageTitleWrapper } from '@packs/components/MaterialUi/PageTitleWrapper/PageTitleWrapper';
import useContainerStyles from '@packs/styles/Global/container';
import useStyles from './styles';
import Button from '@mui/material/Button';
import { AutocompleteMUI } from '@packs/components/MaterialUi/AutocompleteMUI/AutocompleteMUI';
import dayjs from 'dayjs';

export const Calendar = ({
  events,
  currentUser,
  handleSelectChange,
  filterRootCode,
  myDiaryCalendar,
  onCalendarDateClick,
  ...props
}) => {
  const stylesContainer = useContainerStyles();
  const styles = useStyles();
  const { windowSize } = useWindowSize();
  const { currentCategory } = useStore();
  const [eventsLimit, setEventsLimit] = useState(windowSize >= 1200 ? 4 : windowSize >= 992 ? 3 : 2);
  const [eventsLimitInitial] = useState(windowSize >= 1200 ? 4 : windowSize >= 992 ? 3 : 2);
  const [showMoreStore, setShowMoreStore] = useState({});
  const [showReminders, setShowReminders] = useState(false);
  const [perOtherReminders, setPerOtherReminders] = useState(4);
  const [perCategoryReminders, setPerCategoryReminders] = useState(4);
  const [currentDate, setCurrentDate] = useState(new Date());
  const initView = 'customGridDayYear';
  const [calendarTypeView, setCalendarTypeView] = useState(initView);
  const { onAddNewReminder, onEditReminder, onDeleteReminder } = useModalsContext();
  const onCalendarAddNewReminder = props.onCalendarAddNewReminder || onAddNewReminder;
  const onCalendarEditReminder = props.onCalendarEditReminder || onEditReminder;
  const onCalendarDeleteReminder = props.onCalendarDeleteReminder || onDeleteReminder;
  const fullCalendarRef = useRef();

  if (!currentCategory?.id) {
    return null;
  }

  const handleShowMore = () => {
    if (windowSize >= 1200) {
      setEventsLimit(eventsLimit + 4);
    } else if (windowSize < 1200 && windowSize >= 992) {
      setEventsLimit(eventsLimit + 3);
    } else {
      setEventsLimit(eventsLimit + 2);
    }
  };

  const handleHideAll = () => {
    if (windowSize >= 1200) {
      setEventsLimit(4);
    } else if (windowSize < 1200 && windowSize >= 992) {
      setEventsLimit(3);
    } else {
      setEventsLimit(2);
    }
  };

  const toggleShowReminders = () => {
    setShowReminders(!showReminders);
  };

  // .filter(ev => moment(ev.triggeredAt) >= moment().add(-2, 'day'))
  const sanitizedEvents = _(events)
    .filter(event => !dayjs(event?.triggeredAt).isBefore())
    .sortBy(ev => new Date(ev.triggeredAt))
    .value();

  const handleEventMount = data => {
    if (calendarTypeView !== 'dayGridMonth') {
      return;
    }

    const rowIndex = 0; // TODO data.el.__reactFiber$vtc2j0byzy.index;
    const dateStr = data.event.start.toLocaleDateString();

    if (showMoreStore[dateStr] || rowIndex <= 2) {
      const idDate = `Popover-${data.event.id}`;
      const div = document.createElement('div');

      data.el.appendChild(div);
      data.el.setAttribute('id', idDate);
      data.el.classList.add(_.get(data, 'event._def.extendedProps.category.root.code'));

      const root = createRoot(div);
      root.render(
        <CustomEventPopover
          target={idDate}
          isMiddleDay={data.event.start.getDay() === 3}
          event={{
            ..._.get(data, 'event._def.extendedProps', {}),
            id: data.event.id
          }}
          onCalendarAddNewReminder={onCalendarAddNewReminder}
          onCalendarDeleteReminder={onCalendarDeleteReminder}
          onCalendarEditReminder={onCalendarEditReminder}
        />
      );
    } else if (!showMoreStore[dateStr] && rowIndex === 3) {
      data.el.setAttribute('class', 'show-more-link');

      const root = createRoot(data.el);
      root.render(
        <div
          onClick={() => {
            showMoreStore[dateStr] = true;
            setShowMoreStore(showMoreStore);
          }}
        >
          Show More
        </div>
      );
    } else if (!showMoreStore[dateStr] && rowIndex > 3) {
      data.el.classList.add('d-none');
    }
  };

  const getCalendarContextValue = () => ({
    onCalendarAddNewReminder,
    onCalendarDeleteReminder,
    onCalendarEditReminder,
    eventClick: () => {},
    dateClick: onCalendarDateClick
  });

  return (
    <Box sx={styles.wrapper}>
      <PageTitleWrapper
        title='Reminders'
        currentPage='Reminders'
        subTitle='Manage all your bill reminders in one place!'
      />
      {/* <Box sx={stylesContainer.container}>*/}
      {/*  <NoDetailsCategory events={sanitizedEvents} />*/}
      {/* </Box>*/}

      <Box sx={stylesContainer.container}>
        <Box sx={styles.actionsBar}>
          <AutocompleteMUI
            value={FILTER_CATEGORIES.find(item => item.value === filterRootCode) || FILTER_CATEGORIES[0]}
            options={FILTER_CATEGORIES}
            onChange={handleSelectChange}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            label='Category'
            width='152px'
            size='small'
            mobileSelect
            filter
          />
          {/* ()=>onCalendarAddNewReminder() no props execute  */}
          <Button onClick={() => onCalendarAddNewReminder()} variant='contained' size='smallToMedium'>
            Add New Reminders
          </Button>
        </Box>
      </Box>
      <Box className='calendar-wrapper' sx={{ ...stylesContainer.container, ...styles.container }}>
        <div
          className={cx('calendar-container flex-grow-1', {
            customGridDayYear: calendarTypeView === 'customGridDayYear'
          })}
        >
          {windowSize < 1200 && !myDiaryCalendar && (
            <TopRemindersList
              list={sanitizedEvents}
              {...{
                showReminders,
                handleHideAll,
                handleShowMore,
                eventsLimit,
                eventsLimitInitial,
                toggleShowReminders,
                onCalendarDeleteReminder,
                onCalendarEditReminder
              }}
            />
          )}
          <CalendarContext.Provider value={getCalendarContextValue()}>
            <div className='calendar-wrapper'>
              <CalendarHeader
                FullCalendarApi={() => fullCalendarRef.current?.getApi()}
                changeCalendarView={view => setCalendarTypeView(view)}
                setCurrentDate={date => setCurrentDate(date)}
                {...{
                  events,
                  handleSelectChange,
                  currentDate,
                  calendarTypeView,
                  myDiaryCalendar
                }}
              />
              <FullCalendar
                initialView={initView}
                firstDay={currentDate}
                plugins={[GridYearPlugin, dayGridPlugin, interactionPlugin]}
                ref={fullCalendarRef}
                headerToolbar={false}
                // headerToolbar={{
                //   left: 'title',
                //   center: '',
                //   right: 'today prev,next'
                // }}
                dateClick={onCalendarDateClick}
                eventDidMount={handleEventMount}
                events={events}
              />
            </div>
          </CalendarContext.Provider>
        </div>

        {windowSize >= 1200 && !myDiaryCalendar && (
          <SideRemindersList
            list={sanitizedEvents}
            {...{
              perCategoryReminders,
              setPerCategoryReminders,
              perOtherReminders,
              setPerOtherReminders,
              onCalendarAddNewReminder,
              onCalendarDeleteReminder,
              onCalendarEditReminder
            }}
          />
        )}
      </Box>
    </Box>
  );
};
