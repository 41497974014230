import React, { FormEventHandler, useEffect, useState } from 'react';
import _, { set } from 'lodash-es';

import { EmailRounded as EmailRoundedIcon } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, IconButton, Slide, Stack, Typography } from '@mui/material';
import api from '@packs/apis/cities';
import { CheckBoxField, CustomReactSelect, FieldWithErrors } from '@packs/components';
import { Avatar } from '@packs/components/UI';
import { ICountry, IUser } from '@packs/interfaces/rootInterfaces';
import { IUserFormAttributes } from '@packs/layouts/AppLayer/components/AuthModal/AuthModal';
import { SocialButtonGroup } from '@packs/layouts/AppLayer/components/AuthModal/components/SocialButtonGroup';
import useStyles from '@packs/layouts/AppLayer/components/AuthModal/styles';
import { useModalsContext, useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { MINIMUM_PASSWORD_CHARACTERS } from '@packs/lib/constants';
import { serializeCountryName } from '@packs/models/country/helpers';
import { EIR_CODE_REGIONS } from '@packs/models/user/constants';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { getWindow } from '@shared/lib/utils';

import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

const validate = ({
  email,
  password,
  website,
  firstName,
  lastName,
  showSalesCode,
  salesCode,
  country,
  state,
  city,
  eirCodeAlias
}) => {
  const errors: Record<string, string> = {};
  if (!email) {
    errors.email = 'Required';
  }
  if (!password) {
    errors.password = 'Required';
  } else if (password.length < MINIMUM_PASSWORD_CHARACTERS) {
    errors.password = `Should me more or equal than ${MINIMUM_PASSWORD_CHARACTERS}`;
  }

  if (!firstName) {
    errors.firstName = 'Required';
  }

  if (!lastName) {
    errors.lastName = 'Required';
  }

  if (showSalesCode && !salesCode) {
    errors.salesCode = 'Required';
  }

  if (!country) {
    set(errors, 'country', 'Required');
  }

  if (!state) {
    set(errors, 'state', 'Required');
  }

  if (EIR_CODE_REGIONS.includes(state?.value || state)) {
    if (!city && eirCodeAlias) {
      set(errors, 'city', 'Required');
    }
    if (!eirCodeAlias && city) {
      set(errors, 'eirCodeAlias', 'Required');
    }
  }

  if (website && !website.match(/http?s*:\/\/.*/)) {
    errors.website = 'has invalid format';
  }
  return errors;
};

const mapStateToProps = (state, props) => ({ formAttributes: getFormValues(props.form)(state) });

const createReduxForm = reduxForm({
  form: 'registerCustomer',
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate
});
export interface IRegisterFormProps {
  handleSubmit: FormEventHandler<HTMLFormElement>; // | (args: IUserFormAttributes) => void;
  formType: 'business';
  formAttributes?: IUserFormAttributes;
  change: (key: string, v: boolean | null | string | Record<string, string>) => void;
  campaignSignUp: string;
  setShowSignUpForm: (arg: boolean) => void;
  showSignUpForm: string;
  socialUser: {
    type: 'google' | 'facebook';
    avatarUrl: string;
    firstName: string;
    lastName: string;
  };
  setSocialUser: (user: IUser) => void;
}
export const RegisterForm = createReduxForm(
  connect(mapStateToProps)(
    ({
      handleSubmit,
      formType,
      formAttributes,
      change,
      campaignSignUp,
      setShowSignUpForm,
      showSignUpForm,
      socialUser,
      setSocialUser
    }: IRegisterFormProps) => {
      const styles = useStyles();
      const { currentCountry, launchCountries } = useStore();
      const windowWidth = getWindow().innerWidth;
      const { defaultMessages, formatMessage } = useTranslationMessages();
      const { togglePrivacyModal, toggleTermsModal } = useModalsContext();
      const handleSetSocialUser = data => {
        setShowSignUpForm && setShowSignUpForm(true);
        setSocialUser(data);
      };

      useEffect(() => {
        const isRegisterBusiness = formAttributes?.registerBusiness;

        if (formType === 'business') {
          if (!isRegisterBusiness) {
            change('registerBusiness', true);
          }
        } else {
          if (isRegisterBusiness) {
            change('registerBusiness', false);
          }
        }
      }, [formAttributes, formType]);

      const isBusiness = formType === 'business';

      const countryOptions: ICountry[] = launchCountries.map((country: ICountry) => {
        return {
          ...country,
          label: serializeCountryName(country.enName),
          value: country.isoA2Code,
          countryCode: country.isoA2Code
        };
      });

      const currentCountryOption =
        formAttributes?.country || _.filter(countryOptions, { value: currentCountry.isoA2Code }).at(0)!;
      const regionPlaceholder = `Select ${currentCountryOption.regionType || 'county'}`;

      const countryStatesOptions = currentCountryOption.regions.map(option => ({
        label: option.name,
        value: option.name
      }));

      const [cityList, setCityList] = useState([]);
      const [eirCodeAliasList, setEirCodeAliasList] = useState([]);

      const selectedCountry = formAttributes?.country;
      const selectedRegion = formAttributes?.state as { value: string; label: string };

      // Reset to default current state
      useEffect(() => {
        if (!selectedCountry) {
          return;
        }
        change('state', null);
        change('city', null);
      }, [selectedCountry]);

      // Reset to default current city state
      useEffect(() => {
        if (!selectedRegion) {
          return;
        }
        change('city', null);
      }, [selectedRegion]);

      const fetchCities = async (state: string) => {
        setCityList([]);
        setEirCodeAliasList([]);

        if (EIR_CODE_REGIONS.includes(state)) {
          const res = await api.fetchEirCodeAliasList({ admName: state });
          setEirCodeAliasList(res.data.map(el => ({ label: el.alias, value: el.id, ...el })));
        } else {
          const res = await api.fetchCitiesList({ admName: state });
          setCityList(res.data.map(el => ({ label: el.name, value: el.id, ...el })));
        }
      };

      // Request cities list
      useEffect(() => {
        if (!selectedRegion) {
          return;
        }
        fetchCities(selectedRegion?.value);
      }, [selectedRegion]);

      const signUpSubTitle = () => {
        if (socialUser.type === 'google') {
          return 'Welcome to BillWinner with your Google account';
        } else if (socialUser.type === 'facebook') {
          return 'Welcome to BillWinner with your Facebook account';
        }

        return 'Please enter your details below to set up your account';
      };

      useEffect(() => {
        const region = getWindow().localStorage.getItem('region');
        const prefilledEmail = getWindow().localStorage.getItem('prefilled-email');
        const state = countryStatesOptions.find(({ value }) => value === region);

        if (state) {
          change('state', state);
        }
        if (prefilledEmail) {
          change('email', prefilledEmail);
        }
      }, []);

      useEffect(() => {
        Object.keys(socialUser).forEach(key => {
          change(key, socialUser[key]);
        });
      }, [socialUser]);

      if (!showSignUpForm) {
        return (
          <Slide direction='right' in={true} timeout={700}>
            <Box>
              <Typography variant='h5' sx={styles.formTitle}>
                Join our community!
              </Typography>
              <Typography variant='subtitle1' sx={styles.formSubTitle}>
                Please select a social network or continue with email.
              </Typography>
              <Box sx={styles.socialButtonsContainer}>
                <SocialButtonGroup change={change} setSocialUser={handleSetSocialUser} />
                <Box className='social-link email' onClick={() => setShowSignUpForm(true)}>
                  <EmailRoundedIcon />
                  Continue with Email
                </Box>
              </Box>
            </Box>
          </Slide>
        );
      }

      return (
        <>
          <Slide direction='right' in={true} timeout={700}>
            <Box className='signUp-form'>
              {!campaignSignUp ? (
                <>
                  {socialUser?.avatarUrl && (
                    <div className='avatar-container d-flex align-items-center justify-content-center'>
                      <Avatar
                        size={70}
                        avatarUrl={socialUser?.avatarUrl}
                        placeholder={socialUser?.firstName + socialUser?.lastName}
                      />
                    </div>
                  )}
                  <Typography variant='h5' sx={styles.formTitle}>
                    {socialUser?.firstName || socialUser?.lastName
                      ? socialUser?.firstName + ' ' + socialUser?.lastName
                      : 'Finish Sign Up'}
                  </Typography>
                  <Typography variant='subtitle1' sx={styles.formSubTitle}>
                    {signUpSubTitle()}
                  </Typography>
                </>
              ) : (
                <Typography variant='h5' sx={{ mb: 2 }}>
                  Finish Sign Up
                </Typography>
              )}
              <Box sx={styles.formFieldsContainer}>
                <form onSubmit={handleSubmit}>
                  <Box sx={styles.fieldsList}>
                    <Field
                      component={FieldWithErrors}
                      type='text'
                      name='firstName'
                      autoComplete='firstName'
                      label='First name'
                      disableFocusReset
                      material
                      variant='outlined'
                    />
                    <Field
                      component={FieldWithErrors}
                      type='text'
                      name='lastName'
                      autoComplete='lastName'
                      label='Last name'
                      disableFocusReset
                      material
                      variant='outlined'
                    />
                    <Field
                      component={FieldWithErrors}
                      type='email'
                      name='email'
                      autoComplete='email'
                      label={isBusiness ? 'Business email' : 'Email'}
                      disableFocusReset
                      material
                      variant='outlined'
                    />
                    <Field
                      component={FieldWithErrors}
                      type='password'
                      name='password'
                      autoComplete='new-password'
                      label={formatMessage(defaultMessages.password)}
                      disableFocusReset
                      material
                      variant='outlined'
                    />
                    <Box sx={{ display: 'none' }}>
                      <Field
                        component={CustomReactSelect}
                        name='country'
                        options={countryOptions}
                        classNamePrefix='Select'
                        placeholder='Select country'
                        isSearchable={windowWidth > 767}
                        material
                      />
                    </Box>
                    <Box>
                      <Field
                        key={selectedCountry?.value}
                        component={CustomReactSelect}
                        name='state'
                        options={countryStatesOptions}
                        classNamePrefix='Select'
                        placeholder={regionPlaceholder}
                        material
                      />
                    </Box>
                    {selectedRegion?.value && !EIR_CODE_REGIONS.includes(selectedRegion?.value) && (
                      <Box>
                        <Field
                          key={selectedRegion?.value}
                          component={CustomReactSelect}
                          name='city'
                          options={cityList}
                          classNamePrefix='Select'
                          placeholder='City area, town or village'
                          material
                        />
                      </Box>
                    )}
                    {selectedRegion?.value && EIR_CODE_REGIONS.includes(selectedRegion?.value) && (
                      <Box>
                        <Field
                          key={selectedRegion?.value}
                          component={CustomReactSelect}
                          name='eirCodeAlias'
                          options={eirCodeAliasList}
                          classNamePrefix='Select'
                          placeholder='City area, town or village'
                          material
                        />
                      </Box>
                    )}
                    <Box sx={styles.salesCode}>
                      <Field
                        component={CheckBoxField}
                        name='showSalesCode'
                        label={
                          <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            spacing={0.5}
                            sx={{ width: 1 }}
                          >
                            <Typography>Activate a unique code?</Typography>
                            <TooltipMUI
                              title='Do you have a unique code from a referral partner? If so, please add the unique code here. If not, please ignore this field. Thank you!'
                              arrow
                              placement='top-end'
                            >
                              <IconButton size='small'>
                                <InfoOutlinedIcon fontSize='medium' />
                              </IconButton>
                            </TooltipMUI>
                          </Stack>
                        }
                        labelWidth={'100%'}
                        primary
                      />
                      {formAttributes?.showSalesCode && (
                        <Field
                          component={FieldWithErrors}
                          type='text'
                          name='salesCode'
                          autoComplete='salesCode'
                          placeholder='Enter a unique code'
                          disableFocusReset
                          className='light-border mt-10'
                        />
                      )}
                    </Box>
                  </Box>

                  <Box sx={{ ...styles.btnContainer, mb: 3 }}>
                    <Button type='submit' variant='contained' size='large' fullWidth>
                      Sign up
                    </Button>
                  </Box>
                  <Typography variant='body3' component='div' sx={styles.termsPolicy}>
                    By continuing, I agree with Bill Winner’s
                    <Typography variant='body3' sx={styles.termsPolicyLink} onClick={() => toggleTermsModal()}>
                      {' Terms of Service '}
                    </Typography>
                    and acknowledge BillWinner`s
                    <Typography variant='body3' sx={styles.termsPolicyLink} onClick={() => togglePrivacyModal()}>
                      {' Privacy Policy '}
                    </Typography>
                    .
                  </Typography>
                </form>
              </Box>
            </Box>
          </Slide>
        </>
      );
    }
  )
);
