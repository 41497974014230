import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { InfoBox } from '@packs/components/MaterialUi/InfoBox/InfoBox';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import HowItWorks2Icon from '@packs/images/svg/ui/how-it-works-2.svg';
import HowItWorks3Icon from '@packs/images/svg/ui/how-it-works-3.svg';
import HowItWorks4Icon from '@packs/images/svg/ui/how-it-works-4.svg';
import HowItWorksIcon from '@packs/images/ui/landing-how-it-works.webp';

import useStyles from './styles';

const benefits = [
  {
    id: '1',
    icon: HowItWorks2Icon,
    title: 'Sign Up',
    description: 'Sign up and manage all of your bills from one place!'
  },
  {
    id: '2',
    icon: HowItWorks3Icon,
    title: 'Get Matched!',
    description: 'Personalize your results so that you can get matched with the best businesses to quote you!'
  },
  {
    id: '3',
    icon: HowItWorks4Icon,
    title: 'Request Quotes!',
    description: "Request quotes from your chosen businesses! Don't forget to vote for your winning business!"
  }
];

const { white, text, secondary } = colors;

export const HowItWorks = ({
  withoutTitle = false,
  infoIcon = HowItWorksIcon,
  infoIconWidth = '512px',
  position = 'left'
}) => {
  const styles = useStyles();
  const { currentUser } = useUserSelectors();

  return (
    <Box backgroundColor={white.main}>
      <Box sx={styles.container}>
        {!withoutTitle && (
          <>
            <Typography variant='h2' mb={2} textAlign='center'>
              Get quotes from the top voted businesses!
            </Typography>
            <Typography variant='subtitle1' textAlign='center' color={text.main}>
              Follow these simple steps to find the best businesses for you!
            </Typography>
          </>
        )}
        <InfoBox
          title='How it works?'
          description='Bill Winner is a trusted, independent and transparent platform powered by our community!'
          infoIcon={infoIcon}
          infoIconWidth={infoIconWidth}
          position={position}
          withoutMobileTitle
        >
          <Box sx={styles.benefitsContainer}>
            {benefits.map(({ id, icon, title, description }) => (
              <Box key={id} sx={styles.benefitItem}>
                <Box sx={styles.benefitItemImg}>
                  <img src={icon} alt='benefit' />
                </Box>
                <Typography variant='h6' mb={1} textAlign='center'>
                  {title}
                </Typography>
                <Typography variant='body1' textAlign='center'>
                  {description}
                </Typography>
              </Box>
            ))}
          </Box>
        </InfoBox>
        <Box display='flex' justifyContent='center' mt={4}>
          {!currentUser.id && (
            <SignUpModalLink variant='contained' size='xl' color='primary' sx={styles.startButton}>
              Start Now!
            </SignUpModalLink>
          )}
        </Box>
      </Box>
    </Box>
  );
};
