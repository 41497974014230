import React from 'react';

import { Questions } from '@packs/components/UI';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { websitePageUrl } from '@shared/helpers/urlHelper';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const TipsGeneral = observer(() => {
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  const { currentCategory } = useStore();

  return (
    <>
      <Helmet>
        <link rel='canonical' href={websitePageUrl() + DASHBOARD_MY_RESULTS_PATH.replace('/', '')} />
      </Helmet>
      <div className='tips-questions-wrapper'>
        <div className='tips-questions-container'>
          <Questions category={currentCategory} postType='tip' />
        </div>
      </div>
    </>
  );
});

export default TipsGeneral;
