import React from 'react';

import { RequestQuoteWrapper } from '@packs/components/MaterialUi/RequestQuoteWrapper/RequestQuoteWrapper';
import PersonalizeResults from '@packs/screens/PersonalizeResults/RenewalDetailsFormWithHooks';

const Details = () => {
  return (
    <>
      <RequestQuoteWrapper activeStep={0}>
        <PersonalizeResults simpleForm inModalFrame />
      </RequestQuoteWrapper>
    </>
  );
};

export default Details;
