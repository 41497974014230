import React, { useMemo } from 'react';
import _ from 'lodash-es';

import { CustomCheckBox } from '@packs/components/UI';
import FilterIcon from '@packs/images/svg/ui/filter-icon.svg';
import { useCategories } from '@packs/models/category/useCategories';

import { useProfileContext } from '../context';

export const ProfileFilters = () => {
  const context = useProfileContext();
  const categoriesFilter = context?.categoriesFilter;
  const { categories } = useCategories();

  const categoriesList = useMemo(
    () => [
      {
        label: 'All',
        value: 'CATEGORIES',
        type: 'categories'
      },
      ...categories.map(({ name, enName }) => ({
        label: name,
        value: enName,
        type: 'categories'
      }))
    ],
    [categories]
  );

  const handleChange = value => {
    return checked => {
      if (value === 'CATEGORIES') {
        if (checked) {
          context?.setCategoriesFilter(categoriesList.map(({ value }) => value));
        } else {
          context?.setCategoriesFilter([]);
        }
      } else if (checked) {
        context?.setCategoriesFilter([...categoriesFilter, value]);
      } else {
        context?.setCategoriesFilter(categoriesFilter.filter(item => item !== value));
      }
    };
  };

  return (
    <>
      <div className='d-flex align-items-center mb-16'>
        <img src={FilterIcon} alt='Filter' />
        <div className='fw-600 fs-11 tt-uc ml-8'>Filters:</div>
      </div>
      <div className='fs-11 fw-700 tt-uc mb-16'>Business category:</div>
      <div className='filters'>
        {_.map(categoriesList, (obj, index) => {
          const { label, value } = obj;
          const isChecked = categoriesFilter.includes(value);

          return (
            <div key={index}>
              <CustomCheckBox {...{ label, value }} checked={isChecked} onChange={handleChange(value)} />
            </div>
          );
        })}
      </div>
    </>
  );
};
