import broadband from '@packs/screens/RootCategoryLanding/membersSection/broadband';
import energy from '@packs/screens/RootCategoryLanding/membersSection/energy';
import general from '@packs/screens/RootCategoryLanding/membersSection/general';
import mobile from '@packs/screens/RootCategoryLanding/membersSection/mobile';

const membersData = {
  general,
  broadband,
  energy,
  mobile
};

export default membersData;
