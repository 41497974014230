import React, { useEffect, useRef } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CustomActionSnackbar from '@packs/components/NotificationPopup/CustomActionSnackbar';
import { TranslationsProvider } from '@packs/layouts/AppLayer/TranslationsProvider';
import { AppRoutes } from '@packs/layouts/AppRoutes';
import { toastr } from '@packs/lib/helpers';
import { StyledMaterialDesignContent } from '@packs/lib/helpers/toastr';
import { createSessionToken } from '@packs/lib/utils';
import { useStore } from '@packs/stores';

import theme from './assets/theme';

import 'dayjs/locale/en-gb';

import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import SlackFeedback, { themes } from 'react-slack-feedback';

export const Application = ({ toastrData, locale, messages, campaignPage, defaultTranslations }) => {
  const { countryCode } = useStore();
  const feedbackRef = useRef(null);

  useEffect(() => {
    createSessionToken();
    if (toastrData && toastrData.length) {
      toastrData.forEach(data => {
        toastr[data.type](data.message);
      });
    }
    // check if need
    // connectToSocket(countryCode);
  }, []);

  const sendToServer = (payload, success, error) => {
    return fetch(`/${countryCode}/feedback`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    })
      .then(() => {
        success && success();
        toastr.success('Thanks for your feedback!');
      })
      .catch(error);
  };

  if (!countryCode) {
    return;
  }

  return (
    <IntlProvider key={locale} {...{ locale, messages }}>
      <TranslationsProvider defaultMessages={defaultTranslations}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={4}
            autoHideDuration={6000}
            Components={{ success: StyledMaterialDesignContent, customActionSnackbar: CustomActionSnackbar }}
          >
            <BrowserRouter>
              <AppRoutes campaignPage={campaignPage} />
            </BrowserRouter>
          </SnackbarProvider>
          <div className='slack-feedback side-feedback'>
            <SlackFeedback
              open={false}
              ref={feedbackRef}
              disabled={false}
              errorTimeout={3 * 1000}
              icon={() => <div />}
              sentTimeout={2 * 1000}
              showChannel={false}
              channel='#general'
              theme={themes.default} // (optional) See src/themes/default for default theme
              user='Slack Feedback' // The logged in user (default = "Unknown User")
              onSubmit={(payload, success, error) => sendToServer(payload).then(success).catch(error)}
            />
          </div>
        </ThemeProvider>
      </TranslationsProvider>
    </IntlProvider>
  );
};
