import React from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import { DialogTitle, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import colors from '@packs/assets/theme/base/colors';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import Facebook from '@packs/images/icons/Facebook/FaceBook';
import LinkedIn from '@packs/images/icons/LinkedIn';
import { Twitter } from '@packs/images/icons/Twitter';
import WhatsApp from '@packs/images/icons/WhatsApp';
// import VoteUnsubscribeIcon from '@packs/images/svg/ui/thank-vote-unsubscribe.svg';
// import LikeIcon from '@packs/images/ui/like-icon.png';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

export const ThankVoteModal = ({ isOpen, toggle, modalProps }) => {
  const { currentUser } = useUserSelectors();
  const { company, IS_BUSINESS_PAGE, category } = modalProps;
  const { isMobile } = useWindowSize();
  const userNotConfirmed = !currentUser.isConfirmed;
  // const icon = userNotConfirmed ? VoteUnsubscribeIcon : LikeIcon;
  const title = userNotConfirmed ? 'Confirm your email address' : 'Thank you for voting!';
  const description = userNotConfirmed
    ? 'Please verify your email to activate your vote'
    : 'Your vote helps us crowdsource the best businesses.';
  const BASE_APP_URL = location?.origin + location?.pathname;
  const inviteMessage = `I just voted for ‘${company?.name}’${
    IS_BUSINESS_PAGE ? '' : ` in ‘${category?.name}’`
  } on Billwinner.com`;

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <DialogTitle sx={{ position: 'relative' }}>
        <Stack direction='row' alignItems='flex-start' justifyContent='space-between'>
          <Stack>
            <Typography variant='h6' sx={{ mb: isMobile ? 0.5 : 1 }}>
              {title}
            </Typography>
            <Typography variant='body1' color={colors.text.secondary}>
              {description}
            </Typography>
          </Stack>
          <IconButton edge='end' color='inherit' onClick={() => toggle()}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack direction='row' justifyContent='center' spacing={3} className='invite-socials-btn-list'>
          <Stack alignItems='center'>
            <FacebookShareButton url={BASE_APP_URL} quote={inviteMessage} className='invite-social-btn facebook'>
              <Facebook />
            </FacebookShareButton>
            <Typography variant='body4'>Facebook</Typography>
          </Stack>
          <Stack alignItems='center'>
            <LinkedinShareButton url={BASE_APP_URL} title={inviteMessage} className='invite-social-btn linkedin'>
              <LinkedIn />
            </LinkedinShareButton>
            <Typography variant='body4'>LinkedIn</Typography>
          </Stack>
          <Stack alignItems='center'>
            <TwitterShareButton url={BASE_APP_URL} title={inviteMessage} className='invite-social-btn twitter'>
              <Twitter />
            </TwitterShareButton>
            <Typography variant='body4'>X</Typography>
          </Stack>
          <Stack alignItems='center'>
            <WhatsappShareButton url={BASE_APP_URL} title={inviteMessage} className='invite-social-btn whatsApp'>
              <WhatsApp />
            </WhatsappShareButton>
            <Typography variant='body4'>WhatsApp</Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
