import React from 'react';

import { replacePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';

import { Navigate } from 'react-router-dom';

export const Navigator = props => {
  const { countryCode, currentCategory } = useStore();

  return <Navigate {...props} to={replacePath(props.to, { countryCode, category: currentCategory })} />;
};
