import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { CommunityBlock } from '@packs/components/MaterialUi/Landing/Hero/components/CommunityBlock/CommunityBlock';
import { PoweredBlock } from '@packs/components/MaterialUi/Landing/Hero/components/PoweredBlock/PoweredBlock';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { ICONS } from '@shared/helpers/categoriesIconsHelper';
import { SUB_ICONS } from '@shared/helpers/subCategoryIcon';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { CategoryItemPopular } from '../../Landing/components/Hero/components/Popular/components/CategoryItemPopular';

import useStyles from './styles';

export const LandingSubCategories = ({ currentCategory }) => {
  const styles = useStyles();
  const { categoryStore, currentCountry } = useStore();
  const { ADD_DETAILS_PATH_FN, DASHBOARD_MY_RESULTS_PATH_FN } = usePath();
  const { currentUser } = useUserSelectors();
  const { isMobile } = useWindowSize();

  const countryCode = currentCountry?.isoA2Code;
  const categories = currentCategory?.leafChildren || [];

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box sx={styles.categoriesWrapper}>
          {categories.map((cat, index) => (
            <>
              {index !== 0 && (
                <Divider key={cat.id + 'divider'} flexItem orientation={isMobile ? 'horizontal' : 'vertical'} />
              )}
              <CategoryItemPopular
                key={cat.id}
                categoryIcon={cat?.parentId ? SUB_ICONS({ category: cat }) : ICONS[cat.enName]}
                category={cat}
                transparent
                maxWidth='278px'
                activeHover
                customUrl={
                  categoryHavePolicy(cat, categoryStore.categoriesWithPolicy, currentUser)
                    ? DASHBOARD_MY_RESULTS_PATH_FN(cat)
                    : ADD_DETAILS_PATH_FN(cat)
                }
              />
            </>
          ))}
        </Box>
        <Box sx={styles.socialWrapper}>
          <CommunityBlock currentCategoryId={currentCategory.id} />
          <PoweredBlock {...{ currentCategory }} variant='body2' />
        </Box>
      </Box>
    </Box>
  );
};
