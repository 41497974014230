import React, { useEffect, useMemo, useState } from 'react';
import _, { isNull } from 'lodash-es';
import cx from 'classnames';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { UnitPricesModal } from '@packs/components';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { sortOptions } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { isWikiTableCategory } from '@packs/models/category/functions';
import { useCategories } from '@packs/models/category/useCategories';
import { INSURANCE_ORDER_OPTIONS, ORDER_OPTIONS } from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { MyStatsActionPanel } from '@packs/screens/MaterialUi/MyStats/components/MyStats/MyStatsActionPanel/MyStatsActionPanel';
import { MyStatsTopBar } from '@packs/screens/MaterialUi/MyStats/components/MyStatsTopBar/MyStatsTopBar';
import { useStore } from '@packs/stores';
import { INIT_FILTERS } from '@packs/stores/CompaniesStore';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { websitePageUrl } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { BarChart } from './BarChart';
import { PieChart } from './PieChart';
import { UnitPriceBarChart } from './UnitPriceBarChart';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const gasAndElectricityList = [
  {
    value: 'gas',
    label: 'Gas'
  },
  {
    value: 'electricity',
    label: 'Electricity'
  }
];

const fields = ['companyType', 'lastBuy', 'city', 'criteriaQuestions'];

export const MyStats = observer(
  ({
    category,
    tabFilters,
    renewalDetail,
    companyListTimeFilter,
    chartType,
    associationFilter,
    unitFilters,
    myStatsFilter,
    handleSetAssociation
  }) => {
    const { DASHBOARD_MY_RESULTS_PATH } = usePath();
    const stylesLeaderboard = useLeaderboardStyles();
    const { leaderboardStore, policyStore, companiesStore } = useStore();
    const { currentPolicy } = useDetails();
    const { currentUser } = useUserSelectors();
    const { currentCategory } = useCategories();
    const { isDesktop } = useWindowSize();

    const electricityAndGasCategory = category?.code === 'gas_and_electricity';
    const gasCategory = category?.code === 'gas';
    const isPersonalized = hasPolicyCriteria(currentPolicy);

    const isInsuranceCategory =
      ['insurance'].includes(currentCategory.root?.enName) || currentCategory.code?.includes('insurance');
    const sortList = sortOptions({ isInsuranceCategory, isPersonalized, currentUser });

    const value = sortList.find(item => item.value === companiesStore?.filters?.orderColumn) || sortList[0];
    const title = `${!currentUser.id ? 'Sign Up To See The Best' : `The ${value.label}`} Businesses For ${
      currentCategory.name
    } for Members Like You!`;

    const initialTaxFilter = () => {
      if (electricityAndGasCategory || gasCategory) {
        return gasAndElectricityList[0];
      }

      return gasAndElectricityList[1];
    };

    const [gasAndElectricity, setGasAndElectricity] = useState(initialTaxFilter());
    const [taxFilter, setTaxFilter] = useState(
      (electricityAndGasCategory &&
        renewalDetail?.advancedPrice &&
        renewalDetail?.advancedPrice[gasAndElectricity?.value]?.includingTaxes) ||
        true
    );

    const [unitPriceModalIsOpen, setUnitPriceModalIsOpen] = useState(false);

    const lastBuy = companiesStore.filters.lastBuy;
    const city = companiesStore.filters.city;
    const country = companiesStore.filters.country;
    const orderColumn = companiesStore.filters.orderColumn;
    const renewalDetailIsPresent = renewalDetail.id && !isNull(renewalDetail.criteriaQuestions);
    const criteriaQuestions = companiesStore?.filters?.criteriaQuestions || {};
    const categoryName = category?.name;

    const isWikiTable = useMemo(() => {
      return isWikiTableCategory(category);
    }, [category]);

    const getTaxesData = () => {
      const advancedPrice = renewalDetail?.advancedPrice || {};

      if (electricityAndGasCategory) {
        return advancedPrice[gasAndElectricity.value];
      } else if (gasCategory) {
        return advancedPrice.gas;
      }

      return advancedPrice.electricity;
    };

    const handleResetFilters = () => {
      companiesStore.clearFilters();
    };

    const toggleUnitPriceModal = () => {
      setUnitPriceModalIsOpen(!unitPriceModalIsOpen);
    };

    const unitPriceSuccessCallback = async data => {
      toggleUnitPriceModal();
      if (renewalDetail.id) {
        await policyStore.loadPolicy(renewalDetail.id);
      }
      fetchUnitPrices();
    };

    const fetchUnitPrices = () => {
      if (chartType.value === 'unit_prices') {
        leaderboardStore.fetchUnitPrices({
          policyId: renewalDetail.id ? +renewalDetail.id : null,
          costType: unitFilters?.costType?.value,
          categoryId: category?.id,
          companyType: companiesStore.filters.companyType,
          criteriaQuestions,
          lastBuy,
          city,
          country,
          includingTaxes: taxFilter,
          categoryName: electricityAndGasCategory ? gasAndElectricity?.value : null
        });
      }
    };

    const disabledResetFilters = useMemo(() => {
      return _.isEqual(_.pick(INIT_FILTERS, fields), _.pick(companiesStore.filters, fields));
    }, [companiesStore.filters]);

    const onLoadStats = (filters = null) => {
      companiesStore.setFilters(filters);
    };

    const handleResetCompanyListFilters = () => {
      if (!disabledResetFilters) {
        companiesStore.clearFilters();
        onLoadStats(null);
        handleSetAssociation(null);
      }
    };

    useEffect(() => {
      if (chartType.value === 'price') {
        leaderboardStore.fetchStats({
          dateType: tabFilters?.date?.value,
          policyId: renewalDetail.id ? +renewalDetail.id : null,
          categoryId: category?.id,
          companyType: companiesStore.filters.companyType,
          criteriaQuestions,
          lastBuy,
          city,
          country
        });
      }
    }, [
      chartType,
      tabFilters,
      _.get(renewalDetail, 'id', null),
      JSON.stringify([
        lastBuy,
        city,
        country,
        criteriaQuestions,
        associationFilter,
        companiesStore.filters.companyType,
        category?.id
      ])
    ]);

    useEffect(() => {
      fetchUnitPrices();
    }, [
      chartType,
      JSON.stringify([
        unitFilters,
        _.get(renewalDetail, 'id', null),
        lastBuy,
        city,
        country,
        criteriaQuestions,
        associationFilter,
        companiesStore.filters.companyType,
        category?.id,
        taxFilter,
        gasAndElectricity
      ])
    ]);

    useEffect(() => {
      if (chartType.value === 'businesses') {
        leaderboardStore.fetchBusiness({
          categoryId: category?.id,
          associationId: associationFilter?.value === 'all' ? null : associationFilter?.value,
          companyType: companiesStore.filters.companyType,
          criteriaQuestions,
          lastBuy,
          city,
          country,
          orderColumn: orderColumn === 'wiki_price' ? 'most_popular' : orderColumn
        });
      }
    }, [
      chartType,
      JSON.stringify([
        lastBuy,
        city,
        country,
        criteriaQuestions,
        associationFilter,
        companiesStore.filters.companyType,
        category?.id,
        orderColumn
      ])
    ]);

    useEffect(() => {
      setGasAndElectricity(initialTaxFilter());
    }, [category]);

    useEffect(() => {
      const orderList = isWikiTable ? ORDER_OPTIONS : INSURANCE_ORDER_OPTIONS;
      companiesStore.setFilters({ orderColumn: orderList[isWikiTable ? 0 : 1].value });
    }, []);

    const userData = leaderboardStore?.statsData?.userData;
    const membersData = leaderboardStore?.statsData?.membersData;

    const userDataUnitPricesData = leaderboardStore?.unitPricesData?.userData;
    const membersDataUnitPricesData = leaderboardStore?.unitPricesData?.membersData;

    const renderContent = () => {
      if (chartType.value === 'price') {
        if (!leaderboardStore?.statsData?.membersData.length || !leaderboardStore?.statsData?.userData.length) {
          return <EmptyPlaceholder description='Try different filters!' />;
        }

        return (
          <BarChart
            {...{
              userData,
              membersData,
              category
            }}
          />
        );
      }

      if (chartType.value === 'businesses') {
        const businessData = leaderboardStore?.businessData.slice();

        if (!businessData.length) {
          return <EmptyPlaceholder description='Try different filters!' />;
        }

        const labelsData = leaderboardStore?.dataCompanies.slice();

        return (
          <PieChart
            category={category}
            pieData={businessData}
            labelsData={labelsData}
            categoryName={categoryName}
            renewalDetail={renewalDetail}
          />
        );
      }

      if (chartType.value === 'unit_prices') {
        if (
          !leaderboardStore?.unitPricesData?.membersData.length ||
          !leaderboardStore?.unitPricesData?.userData.length
        ) {
          return <EmptyPlaceholder description='Try different filters!' />;
        }

        return (
          <UnitPriceBarChart
            key={JSON.stringify(companyListTimeFilter + renewalDetail?.id)}
            userData={userDataUnitPricesData}
            membersData={membersDataUnitPricesData}
            unitPricesCompanies={leaderboardStore?.unitPricesCompanies}
            {...{
              taxFilter,
              getTaxesData,
              unitFilters,
              setTaxFilter,
              toggleUnitPriceModal,
              companyListTimeFilter
            }}
          />
        );
      }
    };

    return (
      <>
        <Helmet>
          <link rel='canonical' href={websitePageUrl() + DASHBOARD_MY_RESULTS_PATH.replace('/', '')} />
        </Helmet>
        <MyStatsTopBar
          {...{
            myStatsFilter,
            disabledResetFilters,
            handleResetCompanyListFilters
          }}
          filters={{
            chartType,
            associationFilter,
            unitFilters,
            myStatsFilter
          }}
        />
        <Box sx={stylesLeaderboard.container}>
          {isDesktop && (
            <Box sx={stylesLeaderboard.filterContainer}>
              <Box sx={stylesLeaderboard.filterContainerInner}>
                <Box sx={stylesLeaderboard.filterTitle}>
                  <Typography variant='body4'>Filter by:</Typography>
                  <Button
                    variant='text'
                    size='xs'
                    color='dark'
                    sx={{ minWidth: '44px' }}
                    disabled={disabledResetFilters}
                    onClick={handleResetCompanyListFilters}
                  >
                    Reset
                  </Button>
                </Box>
                {myStatsFilter && myStatsFilter(true)}
              </Box>
            </Box>
          )}
          <Box sx={stylesLeaderboard.content}>
            <MyStatsActionPanel />
            <Typography variant='h6' sx={{ textTransform: 'capitalize', mb: 3 }}>
              {title}
            </Typography>
            <div
              className={cx('my-stats-container', {
                empty: !currentUser?.id || !renewalDetailIsPresent,
                'empty-small':
                  !leaderboardStore?.statsData?.membersData.length ||
                  !leaderboardStore?.statsData?.userData.length ||
                  !leaderboardStore?.businessData.length,
                'with-out-bg': chartType.value === 'unit_prices'
              })}
            >
              {renderContent()}
            </div>
          </Box>
        </Box>
        {unitPriceModalIsOpen && (
          <UnitPricesModal
            toggle={toggleUnitPriceModal}
            renewalDetail={renewalDetail}
            successCallback={unitPriceSuccessCallback}
            isModalOpen={unitPriceModalIsOpen}
          />
        )}
      </>
    );
  }
);
