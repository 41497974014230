import { Form } from './form';

import { reduxForm } from 'redux-form';

const validate = ({ firstName, lastName }) => {
  const errors = {};

  if (!firstName) errors.firstName = 'Required';
  if (!lastName) errors.lastName = 'Required';

  return errors;
};

const createReduxForm = reduxForm({ form: 'userSettingsUpdate', validate });

export default createReduxForm(Form);
