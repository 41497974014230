import React, { FC, ReactNode } from 'react';

import { Box } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { BreadcrumbsMUI } from '@packs/components/MaterialUi/BreadcrumbsMUI/BreadcrumbsMUI';
import HeroDecorIcon from '@packs/images/svg/ui/category-hero-decor.svg';
import HeroDecorDarkIcon from '@packs/images/svg/ui/category-hero-decor-dark.svg';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

const { white } = colors;

type HeroProps = {
  breadcrumbLabel: string;
  heroBackgroundImg?: string;
  cornerIcon?: string;
  children?: ReactNode;
  comingSoon?: boolean;
  endContent?: ReactNode;
};

export const Hero: FC<HeroProps> = ({
  breadcrumbLabel,
  children,
  heroBackgroundImg,
  cornerIcon,
  comingSoon = false,
  endContent = ''
}) => {
  const styles = useStyles();
  const { isMobile } = useWindowSize();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box sx={{ mb: { xs: 3, lg: 0 } }}>
          <BreadcrumbsMUI currentPage={breadcrumbLabel} color={white.main} />
        </Box>
        <Box width='100%' sx={{ alignSelf: { xs: 'center', lg: 'flex-start' } }}>
          {children}
        </Box>
        <Box>{endContent}</Box>
      </Box>

      <Box sx={styles.decorWrapper}>
        <Box sx={styles.decorSection}>
          <img src={comingSoon ? HeroDecorDarkIcon : HeroDecorIcon} alt='i' />
        </Box>
        {heroBackgroundImg && (
          <Box sx={{ ...styles.imageSection, ...(comingSoon ? { filter: 'grayscale(100%)' } : {}) }}>
            <img src={heroBackgroundImg} alt='Hero' />
          </Box>
        )}
      </Box>
      {!isMobile && cornerIcon && (
        <Box sx={styles.cornerIcon}>
          <img src={cornerIcon} alt='' />
        </Box>
      )}
    </Box>
  );
};
