import React from 'react';

// Images
import HowItWorks1 from '@packs/images/ui/how-it-works-1.jpg';
import HowItWorks2 from '@packs/images/ui/how-it-works-2.jpg';
import HowItWorks3 from '@packs/images/ui/how-it-works-3.jpg';
import HowItWorks4 from '@packs/images/ui/how-it-works-4.jpg';

import { Helmet } from 'react-helmet';

const description =
  'Our Mission : To create the smartest place in the world for managing and making decisions on your bills!';

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About us</title>
        <meta name='description' content={description} />
        <meta name='og:description' content={description} />
      </Helmet>
      <div className='app-main-container'>
        <div className='main-wrapper'>
          <div className='about-us-container'>
            <div className='about-us-section'>
              <div className='body-container'>
                <div className='about-us-title'>
                  Our Mission : To create the smartest place in the world for managing and making decisions on your
                  bills!
                </div>
                <div className='about-us-sub-title'>What drives us </div>
                <p>
                  Bill Winner was set up by a team who wanted to make the world a better place for shopping for bills.
                  Back in Dec 2017 we looked at the internet and saw lots of people shopping alone.. We felt people
                  could make smarter decisions if they shopped together!{' '}
                </p>
              </div>
              <div className='img-container'>
                <img src={HowItWorks1} alt='how' />
              </div>
            </div>
            <div className='about-us-section'>
              <div className='img-container'>
                <img src={HowItWorks2} alt='how' />
              </div>
              <div className='body-container'>
                <div className='about-us-title'>
                  Imagine creating a place where we could shop together so that we could all make smarter decisions on
                  our bills
                </div>
                <div className='about-us-sub-title'>the big idea...</div>
              </div>
            </div>
            <div className='about-us-section'>
              <div className='body-container'>
                <div className='about-us-title'>
                  Bill Winner’s goal is to build the world’s most trusted and independent platform for making decisions
                  on bills!{' '}
                </div>
                <div className='about-us-sub-title'>Why?</div>
                <p>Our leaderboards and insights are powered by you the people and not by some fancy algorithm..</p>
              </div>
              <div className='img-container'>
                <img src={HowItWorks3} alt='how' />
              </div>
            </div>
            <div className='about-us-section'>
              <div className='img-container'>
                <img src={HowItWorks4} alt='how' />
              </div>
              <div className='body-container'>
                <div className='about-us-title'>Our Global Directory For Bills </div>
                <p>
                  We are building the world’s most comprehensive directory for bills so that our decision engine can
                  match you with the best insurance, broadband, mobile, energy, subscriptions and banking businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
