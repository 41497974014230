import React, { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash-es';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FeaturedOffers } from '@packs/components/MaterialUi/Offers/FeaturedOffers/FeaturedOffers';
import { MyOffersActionPanel } from '@packs/components/MaterialUi/Offers/MyOffersActionPanel/MyOffersActionPanel';
import { MyOffersFilter } from '@packs/components/MaterialUi/Offers/MyOffersFilters/MyOffersFilter';
import { MyOffersTopBar } from '@packs/components/MaterialUi/Offers/MyOffersTopBar/MyOffersTopBar';
import { OffersList } from '@packs/components/MaterialUi/Offers/OffersList/OffersList';
import { usePath } from '@packs/layouts/constants';
import { useStore } from '@packs/stores';
import { INIT_FILTERS } from '@packs/stores/OffersStore';
import useLeaderboardStyles from '@packs/styles/Leaderboard/leaderboardWrapper';
import { websitePageUrl } from '@shared/helpers/urlHelper';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const showFilters = ['offerType', 'keyWord', 'customerType'];

export const MyOffers = observer(() => {
  const { DASHBOARD_MY_RESULTS_PATH } = usePath();
  const { isDesktop } = useWindowSize();
  const wrapperRef = useRef(null);
  const { offersStore } = useStore();
  const { currentCategory } = useStore();
  const styles = useStyles();
  const stylesLeaderboard = useLeaderboardStyles();

  const defaultCategory = {
    value: currentCategory.id,
    label: currentCategory.name
  };

  const disabledRemove = useMemo(() => {
    return _.isEqual(
      _.pick({ ...INIT_FILTERS }, showFilters),
      _.pick({ categoryId: defaultCategory, ...offersStore.filters }, showFilters)
    );
  }, [offersStore.filters]);

  const onResetFilters = () => {
    if (!disabledRemove) {
      offersStore.load({ ...INIT_FILTERS, categoryId: defaultCategory });
    }
  };

  const handleScrollToBegin = () => {
    window.scrollTo({
      top: wrapperRef?.current?.offsetTop - 98,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    offersStore.load({ categoryId: defaultCategory }, false);
  }, [currentCategory?.id]);

  useEffect(() => {
    return () => {
      offersStore.clearFilters();
    };
  }, []);

  useEffect(() => {
    handleScrollToBegin();
  }, [offersStore.filters.page]);

  return (
    <>
      <Helmet>
        <link rel='canonical' href={websitePageUrl() + DASHBOARD_MY_RESULTS_PATH.replace('/', '')} />
      </Helmet>
      <Box sx={{ width: '100%' }}>
        <Box sx={styles.wrapper} ref={wrapperRef}>
          <MyOffersTopBar {...{ defaultCategory, showFilters, onResetFilters, disabledRemove }} />
          <Box sx={stylesLeaderboard.container}>
            {isDesktop && (
              <Box sx={stylesLeaderboard.filterContainer}>
                <Box sx={stylesLeaderboard.filterContainerInner}>
                  <Box sx={stylesLeaderboard.filterTitle}>
                    <Typography variant='body4'>Filter by:</Typography>
                    <Button
                      variant='text'
                      size='xs'
                      color='dark'
                      sx={{ minWidth: '44px' }}
                      disabled={disabledRemove}
                      onClick={onResetFilters}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
                <MyOffersFilter {...{ showFilters }} sidebarFilters />
              </Box>
            )}
            <Box sx={stylesLeaderboard.content}>
              <MyOffersActionPanel {...{ showFilters }} />
              <FeaturedOffers
                categoryId={currentCategory?.id}
                openFilters
                containerStyles={{ mb: 3 }}
                withOutCategory
              />
              <Typography variant='h6' sx={{ mb: 2 }}>
                All offers
              </Typography>
              <OffersList openFilters withOutCategory />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});
