import contained from './contained';
import filledTonal from './filledTonal';
import outlined from './outlined';
import root from './root';
import buttonText from './text';

const button = {
  defaultProps: {
    disableRipple: false,
    textTransform: 'none'
  },
  styleOverrides: {
    root: { ...root },
    contained: { ...contained.base },
    containedSizeXs: { ...contained.xs },
    containedSizeSmall: { ...contained.small },
    containedSizeSmallToMedium: { ...contained.smallToMedium },
    containedSizeMedium: { ...contained.medium },
    containedSizeLarge: { ...contained.large },
    containedSizeXl: { ...contained.xl },
    containedPrimary: { ...contained.primary },
    containedSecondary: { ...contained.secondary },
    containedDark: { ...contained.dark },
    containedWhite: { ...contained.white },
    filledTonal: { ...filledTonal.base },
    filledTonalSizeSmall: { ...filledTonal.small },
    filledTonalSizeXs: { ...filledTonal.xs },
    filledTonalSizeSmallToMedium: { ...filledTonal.smallToMedium },
    filledTonalSizeMedium: { ...filledTonal.medium },
    filledTonalSizeLarge: { ...filledTonal.large },
    filledTonalPrimary: { ...filledTonal.primary },
    filledTonalSecondary: { ...filledTonal.secondary },
    filledTonalWhite: { ...filledTonal.white },
    outlined: { ...outlined.base },
    outlinedSizeSmall: { ...outlined.small },
    outlinedSizeXs: { ...outlined.xs },
    outlinedSizeSmallToMedium: { ...outlined.smallToMedium },
    outlinedSizeMedium: { ...outlined.medium },
    outlinedSizeLarge: { ...outlined.large },
    outlinedPrimary: { ...outlined.primary },
    outlinedSecondary: { ...outlined.secondary },
    outlinedDark: { ...outlined.dark },
    outlinedDefaultGrey: { ...outlined.defaultGrey },
    text: { ...buttonText.base },
    textSizeSmall: { ...buttonText.small },
    textSizeXs: { ...buttonText.xs },
    textSizeSmallToMedium: { ...buttonText.smallToMedium },
    textSizeMedium: { ...buttonText.medium },
    textSizeLarge: { ...buttonText.large },
    textPrimary: { ...buttonText.primary },
    textSecondary: { ...buttonText.secondary },
    textWhite: { ...buttonText.white }
  }
};

export default button;
