import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { NavigationInnerPages } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import MyProfileIcon from '@packs/images/icons/UI/MyProfile';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { ProfileContext } from './context';
import { MyProfileHeader } from './MyProfileHeader';

import { observer } from 'mobx-react';
import BlockUi from 'react-block-ui';
import { Outlet } from 'react-router-dom';

export const ProfileLayout = observer(() => {
  const { userStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { isDesktop } = useWindowSize();

  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const filters = useMemo(() => {
    return {
      categoriesFilter,
      setCategoriesFilter
    };
  }, [categoriesFilter]);

  useEffect(() => {
    userStore.fetchProfileContributionCounter();
  }, []);

  if (!currentUser.id) {
    return null;
  }

  return (
    <Box className='profile-page' sx={{ pb: { xs: 4, lg: 6 } }}>
      {isDesktop && <NavigationInnerPages pageIcon={<MyProfileIcon />} pageTitle='My Profile' withOutTheme />}
      <div className='profile-container'>
        <MyProfileHeader />

        <div className='profile-inner-container d-flex align-items-start'>
          <ProfileContext.Provider value={filters}>
            <BlockUi tag='div' blocking={false} className='w-100'>
              <Outlet />
            </BlockUi>
          </ProfileContext.Provider>
        </div>
      </div>
    </Box>
  );
});
