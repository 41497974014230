import colors from '@packs/assets/theme/base/colors';

const { secondary, white } = colors;

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    alignItems: 'stretch',
    gap: 4
  },
  selectCategoriesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    gap: 2,
    borderRadius: '12px',
    border: '2px solid rgba(0, 0, 0, 0.08)',
    background: white.main,
    px: 2,
    py: 5,
    textAlign: 'center'
  },
  emptySidebarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
    borderRadius: '16px',
    width: {
      xs: '100%',
      md: '345px'
    },
    minWidth: '345px',
    px: 2,
    py: 3,
    border: '2px solid rgba(0, 85, 217, 0.12)',
    backgroundColor: 'rgba(0, 85, 217, 0.04)'
  },
  emptySidebarItem: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 1
  },
  emptySidebarIndex: {
    minWidth: '44px',
    color: secondary.light
  }
});
