import React, { useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { usePath } from '@packs/layouts/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useCategories } from '@packs/models/category/useCategories';
import { useStore } from '@packs/stores';
import { rootCategoriesLanding } from '@shared/constants/categories';

import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const { white, dark } = colors;

export const CategoryMobileSelect = observer(({ children }) => {
  const { categoryStore, currentCategory, companiesStore } = useStore();
  const { categories } = useCategories();
  const { currentUser } = useUserSelectors();
  const { DASHBOARD_MY_RESULTS_PATH_FN, DASHBOARD_LANDING_PATH_FN, DASHBOARD_ROOT_LANDING_PATH_FN } = usePath();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsOpen(open);
  };

  const categoryUrl = category => {
    let url = DASHBOARD_LANDING_PATH_FN(category);
    const isPopularRootCategory = rootCategoriesLanding.some(popular => popular === category.root.code);

    if (categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)) {
      url = DASHBOARD_MY_RESULTS_PATH_FN(category);
    } else if (isPopularRootCategory) {
      url = DASHBOARD_ROOT_LANDING_PATH_FN(category);
    }

    return url;
  };

  const onClickCategory = category => () => {
    if (currentCategory.id !== category.id) {
      categoryStore.setCurrentCategory(category);
      companiesStore.clearFilters();
      setIsOpen(false);
    }
  };

  return (
    <>
      <Box onClick={toggleDrawer(true)}>{children}</Box>
      <Drawer anchor='bottom' open={isOpen} onClose={toggleDrawer(false)}>
        <Box display='flex' flexDirection='column' height='80vh'>
          <Box display='flex' justifyContent='flex-end' px={2} py={1}>
            <IconButton onClick={toggleDrawer(false)} size='small'>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Box flexGrow={1} px={3} sx={{ overflowY: 'auto' }}>
            {categories.map(rootCat => (
              <Box key={rootCat.id} pb={3}>
                <Typography
                  variant='h6'
                  position='sticky'
                  top={0}
                  left={0}
                  zIndex={1}
                  sx={{ backgroundColor: white.main }}
                >
                  {rootCat.name}
                </Typography>
                <Box>
                  {rootCat.leafChildren.map(cat => (
                    <Box
                      key={cat.id}
                      component={NavLink}
                      to={categoryUrl(cat)}
                      onClick={onClickCategory(cat)}
                      display='block'
                      p={1}
                    >
                      <Typography variant='body1' color={dark.main}>
                        {cat.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>
    </>
  );
});
