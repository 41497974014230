import { useState } from 'react';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Actions } from '@packs/screens/MyHome/components/MyRenewals/components/ActionsDropDown/Actions';

export const ActionsDropDown = ({
  category,
  item,
  renewalDetail,
  toggleMyBill,
  onClickEditReminder,
  toggleReminderFormModal,
  handleCreateReminderCallback,
  disabledCategory,
  toggleIsAutoQuote,
  filterCategory
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton size='small' onClick={handleClick}>
        <MoreHorizRoundedIcon />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Actions
          handleClose={handleClose}
          item={item}
          renewalDetail={renewalDetail}
          category={category}
          toggleMyBill={toggleMyBill}
          onClickEditReminder={onClickEditReminder}
          toggleReminderFormModal={toggleReminderFormModal}
          handleCreateReminderCallback={handleCreateReminderCallback}
          disabledCategory={disabledCategory}
          toggleIsAutoQuote={toggleIsAutoQuote}
          filterCategory={filterCategory}
        />
      </Menu>
    </Box>
  );
};
