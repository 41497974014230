import React, { FC, ReactElement } from 'react';

import Box from '@mui/material/Box';
import AvatarIcon1 from '@packs/images/svg/ui/avatars/avatar-1.png';
import AvatarIcon2 from '@packs/images/svg/ui/avatars/avatar-2.png';
import AvatarIcon3 from '@packs/images/svg/ui/avatars/avatar-3.png';
import AvatarIcon4 from '@packs/images/svg/ui/avatars/avatar-4.png';
import AvatarIcon5 from '@packs/images/svg/ui/avatars/avatar-5.png';
import AvatarIcon6 from '@packs/images/svg/ui/avatars/avatar-6.png';
import AvatarIcon7 from '@packs/images/svg/ui/avatars/avatar-7.png';
import AvatarIcon8 from '@packs/images/svg/ui/avatars/avatar-8.png';
import AvatarIcon9 from '@packs/images/svg/ui/avatars/avatar-9.png';
import AvatarIcon10 from '@packs/images/svg/ui/avatars/avatar-10.png';
import AvatarIcon11 from '@packs/images/svg/ui/avatars/avatar-11.png';
import AvatarIcon12 from '@packs/images/svg/ui/avatars/avatar-12.png';
import AvatarIcon13 from '@packs/images/svg/ui/avatars/avatar-13.png';
import AvatarIcon14 from '@packs/images/svg/ui/avatars/avatar-14.png';
import AvatarIcon15 from '@packs/images/svg/ui/avatars/avatar-15.png';
import AvatarIcon16 from '@packs/images/svg/ui/avatars/avatar-16.png';
import AvatarIcon17 from '@packs/images/svg/ui/avatars/avatar-17.png';
import AvatarIcon18 from '@packs/images/svg/ui/avatars/avatar-18.png';
import AvatarIcon19 from '@packs/images/svg/ui/avatars/avatar-19.png';
import AvatarIcon20 from '@packs/images/svg/ui/avatars/avatar-20.png';

import useStyles from './styles';

const allImages = [
  AvatarIcon1,
  AvatarIcon2,
  AvatarIcon3,
  AvatarIcon4,
  AvatarIcon5,
  AvatarIcon6,
  AvatarIcon7,
  AvatarIcon8,
  AvatarIcon9,
  AvatarIcon10,
  AvatarIcon11,
  AvatarIcon12,
  AvatarIcon13,
  AvatarIcon14,
  AvatarIcon15,
  AvatarIcon16,
  AvatarIcon17,
  AvatarIcon18,
  AvatarIcon19,
  AvatarIcon20
];

const pseudoRandom = seed => {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
};

const generateRandomSequence = (length, seed) => {
  return Array.from({ length }, (_, i) => pseudoRandom(seed + i));
};

const getRandomImages = (images, count, seed) => {
  const randomSequence = generateRandomSequence(images.length, seed);

  const shuffled = [...images]
    .map((value, index) => ({ value, sort: randomSequence[index] }))
    .sort((a, b) => a.sort - b.sort)
    .map(item => item.value);
  return shuffled.slice(0, count);
};

type CommunityBlockProps = {
  currentCategoryId: number;
  imagesCount?: number;
  children?: ReactElement;
};

export const CommunityBlock: FC<CommunityBlockProps> = ({ children, currentCategoryId, imagesCount = 5 }) => {
  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.imagesList}>
        {getRandomImages(allImages, imagesCount, currentCategoryId).map(item => (
          <img key={item} src={item} alt='Avatar' />
        ))}
      </Box>
      {children}
    </Box>
  );
};
