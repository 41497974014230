import { useEffect, useState } from 'react';
import cx from 'classnames';

import api from '@packs/apis/myOffers';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { MyOffersItem } from '../../../MyOffers/components/MyOffersItem';
import { MyOffersListSkeleton } from '../../../MyOffers/components/MyOffersList/components/MyOffersListSkeleton';

import InfiniteScroll from 'react-infinite-scroll-component';

export const OtherOffers = ({ offer }) => {
  const { isMobile } = useWindowSize();
  const [otherOffers, setOtherOffers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);

  const hasMore = totalCount > otherOffers.length;

  const fetchData = async fetchMore => {
    setLoader(true);
    const resp = await api.fetchOtherOffers({ id: offer.id, page: fetchMore ? page + 1 : page, perPage: 4 });
    setTotalCount(resp.totalCount);

    if (fetchMore) {
      setOtherOffers([...otherOffers, ...resp.list]);
    } else {
      setOtherOffers(resp.list);
    }
    setLoader(false);
    setPage(prevPage => prevPage + 1);
  };

  const loadInfinityScroll = () => {
    fetchData(true);
  };

  useEffect(() => {
    fetchData(false);
  }, []);

  if (otherOffers.length === 0 && !loader) return null;

  return (
    <div className='other-offers-container'>
      <div className='d-flex align-items-center gap-4 fs-11 fw-600 mb-8'>
        <span className='tt-uc'>Other offers company:</span>
        <span className='secondary-text'>{`(Found ${totalCount})`}</span>
      </div>
      <InfiniteScroll
        dataLength={otherOffers.length}
        next={loadInfinityScroll}
        hasMore={hasMore}
        className={cx(otherOffers.length === 0 && 'empty')}
        loader={loader}
        scrollThreshold={isMobile ? 0.2 : 0.5}
      >
        <div className='my-offers-list'>
          {otherOffers.map(offer => (
            <MyOffersItem key={offer.id} offer={offer} />
          ))}
        </div>
        {loader && <MyOffersListSkeleton count={2} />}
      </InfiniteScroll>
    </div>
  );
};
