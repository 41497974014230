import React, { useState } from 'react';

import { AssociationMemberForm } from '@packs/components/UI';
import { useStore } from '@packs/stores/base';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const MyAssociationsModal = ({ isOpen, toggle, modalProps: { onSuccess } }) => {
  const { associationStore } = useStore();
  const [inProcess, setInProcess] = useState(false);

  const onSuccessSubmit = res => {
    setInProcess(false);
    toggle();
    associationStore.fetchUserAssociations();
    onSuccess && onSuccess(res);
  };

  const onError = () => {
    setInProcess(false);
  };

  const submitHandler = variables => {
    setInProcess(true);
    associationStore.addUserAssociationsCategory({
      attr: {
        associationId: variables?.associationId,
        code: variables?.code
      },
      onSuccess: res => onSuccessSubmit(res),
      onError
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='my-associations-modal'>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <div className='title-container'>
          <div className='title mb-20'>Add An Association</div>
          <div className='description'>
            Are you a member of an association & interested in obtaining great member offers for your bills?
          </div>
          <div className='form-title'>Great, please choose your association here</div>
        </div>
        <AssociationMemberForm
          initialValues={{ code: '' }}
          mode='modal'
          toggle={toggle}
          onSubmit={submitHandler}
          {...{ inProcess }}
        />
      </ModalBody>
    </Modal>
  );
};
