import React from 'react';

import SentimentIcon from '@shared/images/company/sentiment-icon.svg';

import { SentimentAnalysisMetric } from '../SentimentAnalysisMetric/SentimentAnalysisMetric';
import { SidebarTitle } from '../SidebarTitle/SidebarTitle';

import isNumber from 'lodash-es/isNumber';

export const SentimentAnalysis = ({ insightsCompanyStore }) => {
  const sentimentAnalysis = insightsCompanyStore?.object?.sentimentAnalysis;

  if (!isNumber(sentimentAnalysis?.score)) {
    return null;
  }

  return (
    <SidebarTitle icon={<img src={SentimentIcon} alt='Chart' />} title='Sentiment Analysis'>
      <SentimentAnalysisMetric sentimentAnalysis={sentimentAnalysis} />
    </SidebarTitle>
  );
};
