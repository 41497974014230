import React, { useEffect, useState } from 'react';
import { size } from 'lodash-es';

import Dialog from '@mui/material/Dialog';
import { renewalDetailApi } from '@packs/apis';
import { I18n } from '@packs/components/I18n';
import { useSetUserAttrsToStore } from '@packs/hooks/useSetUserAttrsToStore';
import { ICity, ICountry, IEirCodeAlias } from '@packs/interfaces/rootInterfaces';
import { useModalsContext, useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { pathGen } from '@packs/layouts/constants';
import { toastr } from '@packs/lib/helpers';
import { clearAllCategoryCriteria } from '@packs/models/category/helpers';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';
import { getWindow } from '@shared/lib/utils';

import { SignInSignUpContainer } from './SignInSignUpContainer';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

export interface IUserFormAttributes {
  email: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  registerEvent?: 'home' | 'trust_network' | 'my-associations' | 'vote-';
  socialName?: string;
  secret?: string;
  accessToken?: string;
  invitationToken?: string;
  socialInvitationToken?: string;
  registerBusiness?: boolean;
  country?: ICountry;
  city?: ICity;
  eirCodeAlias?: IEirCodeAlias;
  address?: string;
  countryCode?: string;
  state?: string | { value: string; label: string };
  eirCodeAliasId?: number;
  cityId?: number;
  showSalesCode?: boolean;
  salesCode?: string;
}

export const AuthModal = observer(
  ({ formAttributesCustomer, formAttributesBusiness, onSignUpSuccess, isOpen, modalAuthProps }) => {
    const { userStore, categoryStore, currentCategory, countryCode } = useStore();
    const category = currentCategory;
    const { defaultMessages, formatMessage } = useTranslationMessages();
    const { toggleSignUpModal, toggleSignInModal } = useModalsContext();
    const [mounted, setMounted] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const setUserAttrsToStore = useSetUserAttrsToStore();

    const changeSignUpModalType = t => {
      userStore.setSignUpModalType(t);
    };

    const toggleModalType = type => {
      // userStore.authModalType === 'login' ? 'signup' : 'login'
      userStore.setAuthModalType(type);
    };

    useEffect(() => {
      setMounted(true);
      return () => {
        setMounted(false);
      };
    }, []);

    const loginSubmitHandler = ({ email, password }) => {
      getWindow().localStorage.removeItem('X-Authorization');

      userStore
        .signIn({ email, password })
        .then(user => {
          const { country } = user;
          // CALLBACK FUCTIONS RUN ORDER IS IMPORTANT
          getWindow().localStorage.removeItem('region');
          getWindow().localStorage.removeItem('prefilled-email');
          getWindow().localStorage.removeItem('criteriaQuestions');
          toggleSignInModal();
          onSignUpSuccess && onSignUpSuccess('login');
          toastr.success(<I18n text={defaultMessages.deviseSessionsSignedIn} />);
          categoryStore.loadCategories(countryCode);
          clearAllCategoryCriteria();
        })
        .catch(errors => {
          toastr.error(errors[0]);
        });
    };

    const signUpHandler = (userAttributes: IUserFormAttributes = {} as IUserFormAttributes) => {
      getWindow().localStorage.removeItem('X-Authorization');
      setUserAttrsToStore(userAttributes);

      setLoading(true);

      if (!categoryStore.categoriesAttributes.length) {
        categoryStore.markCategorySelect({ id: +category.id, selected: true });
      }

      userStore
        .currentUserSignUp()
        .then(user => {
          // CALLBACK FUCTIONS RUN ORDER IS IMPORTANT
          toggleSignUpModal();
          onSignUpSuccess && onSignUpSuccess('signUp');
          toastr.success('Thank you for registering. We have sent you a confirmation email.');
          getWindow().localStorage.removeItem('region');
          getWindow().localStorage.removeItem('prefilled-email');

          const criteriaQuestionsLocalStore = getWindow().localStorage.getItem('criteriaQuestions');
          const criteria = criteriaQuestionsLocalStore ? JSON.parse(criteriaQuestionsLocalStore) : {};
          if (size(criteria)) {
            Object.keys(criteria).forEach(key => {
              const newFormData = {
                categoryId: key,
                criteriaQuestions: criteria[key]
              };
              renewalDetailApi.updateOrCreateBillDetails(newFormData);
            });
          }

          if (!modalAuthProps?.skipRedirect && user.registerEvent === 'home') {
            navigate(pathGen('MY_HOME', { countryCode, category }));
          }

          getWindow().localStorage.removeItem('criteriaQuestions');
          if (userAttributes?.accessToken && userAttributes?.secret) {
            userStore.connectSocial({
              type: userAttributes?.socialName,
              secret: userAttributes?.secret,
              accessToken: userAttributes?.accessToken
            });
          }
          categoryStore.loadCategories(countryCode);
          clearAllCategoryCriteria();
        })
        .catch(errors => {
          toastr.error(errors[0]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const forgotPasswordHandler = () => {
      toggleModalType('login');
      toggleSignInModal();
      toastr.success(formatMessage(defaultMessages.devisePasswordsSendInstructions));
    };

    const { isMobile } = useWindowSize();

    const toggle = userStore.authModalType === 'login' ? toggleSignInModal : toggleSignUpModal;

    return (
      <Dialog
        maxWidth={isMobile ? false : 'xl'}
        fullWidth
        fullScreen={isMobile}
        open={isOpen}
        onClose={toggle}
        PaperProps={{
          style: isMobile
            ? {
                margin: 0,
                width: '100%'
              }
            : {
                maxWidth: '1440px',
                height: 'calc(100% - 82px)',
                width: '100%',
                margin: '24px 16px',
                borderRadius: '24px',
                overflow: 'hidden'
              }
        }}
      >
        {mounted && (
          <SignInSignUpContainer
            modalType={userStore.authModalType as 'login' | 'signup' | 'forgotPassword'}
            signUpWithUniqCode={modalAuthProps?.signUpWithUniqCode}
            {...{
              toggle,
              loading,
              signUpHandler,
              loginSubmitHandler,
              forgotPasswordHandler,
              onSignUpSuccess,
              changeSignUpModalType,
              toggleModalType,
              toggleSignInModal,
              toggleSignUpModal
            }}
          />
        )}
      </Dialog>
    );
  }
);
