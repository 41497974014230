import React, { useMemo } from 'react';
import cx from 'classnames';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { SocialSync } from '../SocialSync/SocialSync';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ConnectSocialModal = ({
  isOpen,
  toggle,
  className,
  modalProps: { onSubmit, allowSocials = [], submitBtnText }
}) => {
  const { currentUser } = useUserSelectors();
  const { isDesktop } = useWindowSize();

  const disabled = useMemo(() => {
    return allowSocials.some(key => !currentUser?.socialProviders[key]);
  }, [allowSocials, currentUser]);

  const handleSubmit = () => {
    if (!disabled) {
      onSubmit();
      toggle();
    }
  };

  return (
    <Modal {...{ isOpen, toggle }} className={cx('connect-social-modal', className)}>
      <ModalHeader {...{ toggle }} charCode='x' />
      <ModalBody>
        <div className='title'>Linked accounts</div>
        <SocialSync allowSocials={allowSocials} withConnectButton />
      </ModalBody>
      <ModalFooter>
        <button className='btn cancel empty black back' onClick={toggle}>
          {isDesktop && <ArrowBackIosIcon />}
          <span>Back</span>
        </button>
        <button className={cx('btn full black submit', { disabled })} disabled={disabled} onClick={handleSubmit}>
          <span>{submitBtnText}</span>
        </button>
      </ModalFooter>
    </Modal>
  );
};
