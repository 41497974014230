import colors from '@packs/assets/theme/base/colors';
import VoteDecor from '@packs/images/svg/ui/decor-vote.svg';

const { secondary, white } = colors;

export default () => ({
  heroWrapper: {
    pt: 4,
    backgroundImage: {
      xs: secondary.gradient,
      md: `url(${VoteDecor}), ${secondary.gradient}`
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: white.main
  },
  heroContainer: {
    maxWidth: '1164px'
  },
  title: {
    mt: {
      xs: 3,
      md: 8
    },
    color: white.main,
    textAlign: 'center'
  },
  votingCategoriesWrapper: {
    pt: {
      xs: 4,
      md: 8
    },
    pb: {
      xs: 2,
      md: 5
    },
    backgroundImage: {
      xs: 'none',
      md: 'linear-gradient(180deg, rgba(0, 102, 204, 0.00) 0%, #06C 39.06%)'
    }
  },
  votingTitle: {
    mb: {
      xs: 2,
      md: 4
    },
    color: white.main,
    textAlign: 'center'
  },
  benefitsContainer: {
    py: {
      xs: 6,
      md: 8,
      lg: 15
    },
    textAlign: 'center'
  },
  benefitsTitle: {
    mb: 2
  },
  benefitsList: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(1, 1fr)',
      sm: 'repeat(2, 1fr)',
      lg: 'repeat(4, 1fr)'
    },
    gap: 6,
    mt: {
      xs: 6,
      sm: 8,
      lg: 12
    }
  },
  benefitItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  benefitIconWrapper: {
    mb: 2
  },
  benefitItemTitle: {
    mb: 1
  }
});
