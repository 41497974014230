import React, { useMemo } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, ButtonProps, IconButton, Stack, Typography, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import colors from '@packs/assets/theme/base/colors';
import EditIcon from '@packs/images/icons/EditIcon';
import { numberWithCommas } from '@packs/lib/constants';
import { useCategories } from '@packs/models/category/useCategories';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/styles';
import { categoriesWithLowestPrice } from '@packs/screens/MyHome/constants';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import find from 'lodash-es/find';
import isUndefined from 'lodash-es/isUndefined';
import { observer } from 'mobx-react';

const { text } = colors;

const rangeIndicators = [
  {
    name: 'low',
    titleAverage: 'Paying below average',
    titleLowest: 'Paying below lowest'
  },
  {
    name: 'average',
    titleAverage: 'Paying average price',
    titleLowest: 'Paying average price'
  },
  {
    name: 'high',
    titleAverage: 'Paying above average',
    titleLowest: 'Paying above lowest'
  }
];

const calcActiveRange = (averagePrice, currentPrice) => {
  let activeRange = '';

  if (currentPrice < averagePrice) {
    activeRange = 'low';
  } else if (currentPrice === averagePrice) {
    activeRange = 'average';
  } else if (currentPrice > averagePrice) {
    activeRange = 'high';
  }

  return activeRange;
};

export const RenderRenewalPrice = observer(
  ({ item, selectedCost, selectedPolicy, currency, toggleMyBill, category }) => {
    const { homeStore } = useStore();
    const details = homeStore.userDetails;
    const renewalsPriceData = homeStore.renewalsPriceData;
    const { isDesktop } = useWindowSize();
    const { flattenCategories } = useCategories();
    const styles = useStyles();

    const isCategoryWithLowestPrice = categoriesWithLowestPrice.some(
      item => category?.root?.code === item || category?.code === item
    );

    const { id } = item;
    const { averageYearly, averageMonthly } = renewalsPriceData[id]?.averagePriceData || {};
    const { minMonthly, minYearly } = renewalsPriceData[id]?.minPriceData || {};
    const renewalDetail = details.find(detail => detail.id === selectedPolicy[id]) || {};
    const currentPrice = +renewalDetail[selectedCost.value] || 0;
    const isEmpty = !renewalDetail[selectedCost.value];
    const categoryItem = find(flattenCategories, { id });
    const period = useMemo(() => {
      return selectedCost.value === 'renewalCostMonthly' ? 'p/m' : 'p/y';
    }, [selectedCost]);

    const averagePrice = {
      renewalCostYearly: averageYearly,
      renewalCostMonthly: averageMonthly
    }[selectedCost.value];

    const lowestPrice = selectedCost.value === 'renewalCostMonthly' ? minMonthly : minYearly;
    const averageLowestPrice = isCategoryWithLowestPrice ? lowestPrice : averagePrice;
    const activeRange = calcActiveRange(averageLowestPrice, currentPrice);
    const range = rangeIndicators.find(item => item.name === activeRange);

    const rangeTitle = range
      ? range[isCategoryWithLowestPrice ? 'titleLowest' : 'titleAverage']
      : isCategoryWithLowestPrice
      ? 'Lowest is not available yet'
      : 'Average is not available yet';
    const price = `${currency} ${numberWithCommas(currentPrice.toFixed(2))}`;

    if (renewalDetail.id && isUndefined(renewalsPriceData[id])) {
      return (
        <Box>
          <Skeleton variant='rounded' width={105} height={16} />
          <Skeleton variant='rounded' width={130} height={16} sx={{ mt: 0.5 }} />
          <Skeleton variant='rounded' width={160} height={16} sx={{ mt: 0.5 }} />
        </Box>
      );
    }

    return (
      <Stack
        direction='column'
        justifyContent='space-between'
        sx={{
          '&:hover': {
            '.billModalCTA': {
              opacity: 1
            },
            ...(averageLowestPrice && isDesktop
              ? {
                  '.defaultTitle': { display: 'none' },
                  '.priceTitle': { display: 'block' }
                }
              : {})
          }
        }}
      >
        {isEmpty ? (
          <Box>
            <Button
              variant='text'
              color={'dark' as ButtonProps['color']}
              size='xs'
              startIcon={<AddRoundedIcon />}
              onClick={() => {
                toggleMyBill(categoryItem, renewalDetail, item);
              }}
            >
              Add Cost
            </Button>
          </Box>
        ) : (
          <Stack direction='row' alignItems='center'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant={'body3' as TypographyProps['variant']}>{price}</Typography>
              <Typography
                variant={'body3' as TypographyProps['variant']}
                sx={{ ml: 0.5 }}
                color={colors.text.secondary}
              >
                {period}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                toggleMyBill(categoryItem, renewalDetail, item);
              }}
              sx={{ opacity: { lg: 0 }, ml: isDesktop ? 0 : 'auto' }}
              className='billModalCTA'
            >
              <EditIcon />
            </IconButton>
          </Stack>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <Box sx={styles.rangeIndicatorList}>
            {rangeIndicators.map((item, index) => {
              const activeIndex = rangeIndicators.findIndex(range => range.name === activeRange);

              return (
                <Box
                  key={item.name}
                  sx={styles.rangeIndicatorItem(
                    rangeIndicators[activeIndex]?.name || 'default',
                    isEmpty ? false : activeIndex >= index
                  )}
                />
              );
            })}
          </Box>
          <Box sx={{ minHeight: { xs: '12px', lg: '36px' } }}>
            {isEmpty ? (
              <Typography variant='body5' sx={{ color: text.secondary }}>
                {`See ${isCategoryWithLowestPrice ? 'lowest' : 'average'} after adding cost`}
              </Typography>
            ) : (
              <Box>
                <Typography variant='body5' sx={{ color: text.secondary }} className='defaultTitle'>
                  {rangeTitle}
                </Typography>
                <Typography
                  variant='body5'
                  sx={{ display: { xs: 'block', lg: 'none' }, color: text.secondary }}
                  className='priceTitle'
                >
                  {`${
                    isCategoryWithLowestPrice ? 'The lowest' : 'Average'
                  } cost for members like you is ${currency} ${numberWithCommas(
                    averageLowestPrice ? averageLowestPrice.toFixed(2) : 0
                  )} ${period}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Stack>
    );
  }
);
