const textField = {
  styleOverrides: {
    input: {
      fontSize: '14px',
      lineHeight: '22px'
    }
  }
};

export default textField;
