import Box from '@mui/material/Box';
import CropLeftTopIcon from '@packs/images/svg/ui/crop-left-top.svg';
import CropRightBottomIcon from '@packs/images/svg/ui/crop-right-bottom.svg';

export const SideCropImage = ({ children, sx }) => {
  return (
    <Box position='relative' sx={sx}>
      <Box position='absolute' left={0} top={0} zIndex={1}>
        <img src={CropLeftTopIcon} alt='crop' />
      </Box>
      {children}
      <Box position='absolute' right={-1} bottom={0} zIndex={1}>
        <img src={CropRightBottomIcon} alt='crop' />
      </Box>
    </Box>
  );
};
