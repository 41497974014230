import React from 'react';

import { ButtonBase, Stack, Switch, Typography } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { useWindowSize } from '@shared/hooks/useWindowSize';

const { dark, text } = colors;

const SwitcherText = ({ onText = 'On', offText = 'Off', value, onChange }) => {
  const { isMobile } = useWindowSize();

  return (
    <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, lg: 1 }}>
      <ButtonBase
        component={Typography}
        variant={!value ? 'body2' : 'body1'}
        sx={{
          color: !value ? dark.main : text.main,
          textAlign: 'center',
          fontSize: isMobile ? '14px' : 'inherit'
        }}
        onClick={() => onChange(false)}
      >
        {offText}
      </ButtonBase>
      <Switch
        name='haveProvider'
        size={isMobile ? 'small' : 'normal'}
        value={value}
        onChange={e => onChange(e.target.checked)}
        checked={value}
      />
      <ButtonBase
        component={Typography}
        variant={value ? 'body2' : 'body1'}
        sx={{
          color: value ? dark.main : text.main,
          textAlign: 'center',
          fontSize: isMobile ? '14px' : 'inherit'
        }}
        onClick={() => onChange(true)}
      >
        {onText}
      </ButtonBase>
    </Stack>
  );
};

export default SwitcherText;
