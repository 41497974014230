import React from 'react';
import { size } from 'lodash-es';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BlockedLeaderboard } from '@packs/components/MaterialUi/Leaderboard/BlockedLeaderboard/BlockedLeaderboard';
import { CompanyItem } from '@packs/components/MaterialUi/Leaderboard/CompanyItem/CompanyItem';
import { LeaderboardHowItsWork } from '@packs/components/MaterialUi/Leaderboard/LeaderboardHowItsWork/LeaderboardHowItsWork';
import { LeaderboardListSkeleton } from '@packs/components/MaterialUi/Leaderboard/LeaderboardListSkeleton/LeaderboardListSkeleton';
import { LeaderboardVotedSection } from '@packs/components/MaterialUi/Leaderboard/LeaderboardVotedSection/LeaderboardVotedSection';
import { useCategories } from '@packs/models/category/useCategories';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { Pagination } from '@shared/components/Pagination/Pagination';
import { categoriesWithoutPersonalizeFlow, subCategoriesWithoutPersonalizeFlow } from '@shared/constants/categories';

import { observer } from 'mobx-react';

export const LeaderboardList = observer(({ isLoading }) => {
  const { companiesStore, categoryStore } = useStore();
  const { currentPolicy } = useDetails();
  const { currentCategory } = useCategories();

  const count = Math.ceil(companiesStore.total / companiesStore.filters.perPage);
  const page = companiesStore.filters.page;
  const isCategoriesWithoutPersonalizeFlow = categoriesWithoutPersonalizeFlow.includes(currentCategory?.root?.code);
  const isSubCategoriesWithoutPersonalizeFlow = subCategoriesWithoutPersonalizeFlow.includes(currentCategory.code);

  const showBlockedLeaderboard =
    !currentPolicy?.id &&
    !categoryStore.categoriesWithPolicy[currentCategory?.id] &&
    !isLoading &&
    !isCategoriesWithoutPersonalizeFlow &&
    !isSubCategoriesWithoutPersonalizeFlow;
  const list =
    !currentPolicy?.id && page === 1 && !isCategoriesWithoutPersonalizeFlow && !isSubCategoriesWithoutPersonalizeFlow
      ? companiesStore.list.slice(3)
      : companiesStore.list;

  const handlePagination = page => {
    companiesStore.loadPage(page);
  };

  if (!(companiesStore.loading || isLoading) && size(list) === 0) {
    if (showBlockedLeaderboard) {
      return <BlockedLeaderboard currentCategory={currentCategory} />;
    }
    return (
      <EmptyPlaceholder description='We could not find what you searched for. Please change the settings on your filter for a successful search. Thank you!' />
    );
  }

  return (
    <>
      <Box>
        {showBlockedLeaderboard && <BlockedLeaderboard currentCategory={currentCategory} />}
        {showBlockedLeaderboard && (
          <Typography variant='h6' sx={{ mt: 4, mb: 2 }}>
            Other Businesses
          </Typography>
        )}
        {
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 4, lg: 3 } }}>
            {companiesStore.loading || isLoading ? (
              <LeaderboardListSkeleton />
            ) : (
              list.map((company, index) => (
                <>
                  {index === 3 && <LeaderboardHowItsWork key={company.id + 'extra'} />}
                  {index === 7 && <LeaderboardVotedSection key={company.id + 'extra'} />}
                  <CompanyItem
                    key={company.id}
                    company={company}
                    index={index}
                    withAnimation
                    showPlaceLabel={page === 1}
                    showBlockedLeaderboard={showBlockedLeaderboard}
                  />
                </>
              ))
            )}
            <Pagination count={count} page={page} onChange={handlePagination} />
          </Box>
        }
      </Box>
    </>
  );
});
