import colors from '@packs/assets/theme/base/colors';

export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRadius: '16px',
    border: `1px solid ${colors.borderColor.secondary}`,
    pb: 2
  },
  container: {
    px: { xs: 2, lg: 3 }
  }
});
