import colors from '@packs/assets/theme/base/colors';

const { primary } = colors;

export default () => ({
  typeSwitcherWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  activeTypeSwitcher: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      transform: 'translateY(-100%)',
      left: 0,
      right: '0',
      height: '4px',
      borderRadius: '4px 4px 0 0',
      backgroundColor: primary.main,
      zIndex: 2
    }
  }
});
