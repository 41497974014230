import React from 'react';

import { FieldWithErrors } from '@packs/components/FieldWithErrors';
import { useTranslationMessages } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';

import { Link } from 'react-router-dom';
import { Field } from 'redux-form';

const Form = ({ handleSubmit }) => {
  const { PROFILE_MY_REMINDERS_PATH } = usePath();
  const { defaultMessages, formatMessage } = useTranslationMessages();

  return (
    <form onSubmit={handleSubmit}>
      <div className='input-field'>
        <Field
          name='email'
          component={FieldWithErrors}
          type='text'
          placeholder={formatMessage(defaultMessages.email)}
        />
      </div>
      <div className='input-field'>
        <Field
          name='password'
          component={FieldWithErrors}
          type='password'
          placeholder={formatMessage(defaultMessages.password)}
        />
      </div>
      <Link to={PROFILE_MY_REMINDERS_PATH} className='forgot-text'>
        {formatMessage(defaultMessages.forgotPassword)}
      </Link>
      <button type='submit' className='btn auth-btn'>
        {formatMessage(defaultMessages.login)}
      </button>
    </form>
  );
};
export default Form;
