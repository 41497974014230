import { forwardRef, ReactNode } from 'react';

import { CloseRounded as CloseIcon } from '@mui/icons-material';
import { Alert, Button, ButtonProps, IconButton, IconButtonProps, Stack, Typography } from '@mui/material';

import { SnackbarContent, useSnackbar } from 'notistack';

interface Props {
  id: number;
  children?: ReactNode;
  btnVariant?: ButtonProps['variant'];
  btnColor?: ButtonProps['color'];
  callback?: () => void;
  btnMessage?: string;
  message: string;
}
export type Ref = HTMLDivElement;

const CustomActionSnackbar = forwardRef<Ref, Props>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref}>
      <Alert sx={{ alignItems: 'center' }} severity='info' variant='filled'>
        <Stack spacing={2} direction='row' alignItems='center'>
          <Typography>{props.message}</Typography>
          <Button
            variant={props.btnVariant || 'contained'}
            color={props?.btnColor || ('white' as ButtonProps['color'])}
            size='small'
            onClick={() => {
              if (props?.callback) {
                props?.callback();
              }
              closeSnackbar();
            }}
          >
            {props?.btnMessage || 'Submit'}
          </Button>
          <IconButton color={'white' as IconButtonProps['color']} size='small' onClick={() => closeSnackbar(id)}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Alert>
    </SnackbarContent>
  );
});

export default CustomActionSnackbar;
