import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { dateFilterOptions, energyAdvancedPricesList } from '@packs/lib/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import { MyStats } from './components/MyStats';
import { MyStatsFilters } from './components/MyStatsFilters';
import useStyles from './styles';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

const chartTypes = [
  {
    value: 'businesses',
    label: 'All stats'
  },
  {
    value: 'price',
    label: 'Price Analysis'
  }
];

const DashboardMyStats = observer(() => {
  const { currentCategory, companiesStore } = useStore();
  const styles = useStyles();

  const { currentPolicy } = useDetails();

  const energyCategory = currentCategory?.root?.code === 'energy';
  const getChartTypes = useMemo(() => {
    if (energyCategory) {
      return [
        ...chartTypes,
        {
          value: 'unit_prices',
          label: 'Unit Prices'
        }
      ];
    }

    return chartTypes;
  }, [energyCategory]);
  const [associationFilter, setAssociationFilter] = useState(null);
  const [chartType, setChartType] = useState(chartTypes[0]);
  const [unitFilters, setUnitFilters] = useState({ costType: energyAdvancedPricesList[0] });
  const [tabFilters, setTabFilters] = useState({
    date: currentCategory?.root?.enName === 'broadband' ? dateFilterOptions[1] : dateFilterOptions[0]
  });

  const handleChangeChartType = data => {
    setChartType(data);
  };

  const handleSetAssociation = data => {
    setAssociationFilter(data);
  };

  const handleChangeAdvancedPrices = data => {
    setUnitFilters({
      ...unitFilters,
      costType: data
    });
  };

  const handleChangeDate = date => {
    setTabFilters({ ...tabFilters, date });
  };

  const myStatsFilter = (sidebarFilter = false) => (
    <MyStatsFilters
      {...{
        chartType,
        unitFilters,
        associationFilter,
        tabFilters,
        handleChangeChartType,
        handleSetAssociation,
        handleChangeAdvancedPrices,
        handleChangeDate,
        sidebarFilter
      }}
      chartTypes={getChartTypes}
    />
  );

  useEffect(() => {
    companiesStore.setFilters({ companyType: 'all' });
  }, []);

  return (
    <>
      <Helmet>
        <title>My Stats</title>
      </Helmet>
      <Box sx={styles.wrapper}>
        <MyStats
          category={currentCategory}
          renewalDetail={currentPolicy}
          {...{
            chartType,
            unitFilters,
            associationFilter,
            handleSetAssociation,
            tabFilters,
            myStatsFilter
          }}
        />
      </Box>
    </>
  );
});

export default DashboardMyStats;
