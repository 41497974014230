import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ProfileSkeleton } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { EmptyState } from '@packs/screens/Profile/components/EmptyState';
import { useStore } from '@packs/stores';

import { InsightItem } from '../../Insights/InsightItem';

import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';

export const ReviewsList = observer(
  ({
    filter,
    category,
    companyId,
    emptyTitle = '',
    emptyDescription = '',
    isProfile = false,
    IS_BUSINESS_PAGE = false,
    withOutInsightCompany = false
  }) => {
    const insightContainerRef = useRef(null);
    const { insightsCompanyStore } = useStore();
    const { currentUser } = useUserSelectors();
    const [page, setPage] = useState(1);

    const list = useMemo(() => {
      return insightsCompanyStore?.posts;
    }, [insightsCompanyStore?.posts]);
    const totalCount = useMemo(() => {
      return insightsCompanyStore?.postsTotalCount;
    }, [insightsCompanyStore?.postsTotalCount]);
    const isLoading = useMemo(() => {
      return insightsCompanyStore?.loading;
    }, [insightsCompanyStore?.loading]);

    const loadRenewalDetailsList = (page = page, more = false) => {
      const opts = { companyId, categoryId: IS_BUSINESS_PAGE ? null : category.id };
      if (isProfile) {
        insightsCompanyStore.loadProfilePosts({ ...{ ...filter, page } }, more);
      } else {
        insightsCompanyStore.loadPosts({ ...{ filter, page }, ...opts }, more);
      }

      setPage(page);
    };

    const loadMorePage = () => {
      loadRenewalDetailsList(page + 1, true);
    };

    useEffect(() => {
      setPage(1);
      loadRenewalDetailsList(1);
    }, [
      filter?.userId,
      filter?.categories,
      filter?.satisfaction,
      filter?.feedbackType,
      filter?.orderDirection,
      filter?.years,
      companyId
    ]);

    return (
      <div className='position-relative'>
        {!isLoading && list.length === 0 ? (
          <EmptyState
            title={emptyTitle || 'You have not created any insights!'}
            description={emptyDescription || 'All your Insights will be displayed here.'}
          />
        ) : (
          <InfiniteScroll dataLength={totalCount} next={loadMorePage} hasMore={totalCount > list.length}>
            <div className='reviews-list' ref={insightContainerRef}>
              {list.map((item, index) => (
                <InsightItem
                  key={item?.id}
                  insight={item}
                  owner={isProfile ? currentUser : null}
                  first={index === 0}
                  isProfile={isProfile}
                  postType={item.type || 'insight'}
                  questionsContainerOffset={insightContainerRef?.current?.offsetTop}
                  withOutInsightCompany={withOutInsightCompany}
                  withOutCategoryMode
                />
              ))}
            </div>
            {isLoading && <ProfileSkeleton />}
          </InfiniteScroll>
        )}
      </div>
    );
  }
);
