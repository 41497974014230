import React, { useEffect, useRef } from 'react';

import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { useWindowSize } from '@shared/hooks/useWindowSize';

export const CategoriesTreeNav = ({ categoriesWithExcluded, activeCategory, setActiveCategory, toggle }) => {
  const refContainer = useRef(null);
  const itemsRef = useRef([]);
  const { isDesktop } = useWindowSize();

  useEffect(() => {
    if (!isDesktop) {
      refContainer.current.scrollTo({
        top: 0,
        left: itemsRef.current[activeCategory].offsetLeft,
        behavior: 'smooth'
      });
    }
  }, [activeCategory]);

  useEffect(() => {
    if (!isDesktop) {
      itemsRef.current = itemsRef.current.slice(0, categoriesWithExcluded.length);
    }
  }, [categoriesWithExcluded]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', lg: '260px' },
        minWidth: { xs: '100%', lg: '260px' },
        borderRadius: '15px 0 0 15px',
        padding: { xs: 0, lg: 5 }
      }}
    >
      {!isDesktop && (
        <Box sx={{ mb: 3, px: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 2 }}>
            <IconButton onClick={toggle} size='medium' sx={{ color: colors.dark.main }}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Typography variant='h6' sx={{ textAlign: 'center' }}>
            What categories do you wish to save money on?
          </Typography>
        </Box>
      )}

      <Box sx={{ paddingLeft: { xs: 2, lg: 0 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', lg: 'column' },
            overflowX: 'auto'
          }}
          ref={refContainer}
        >
          {categoriesWithExcluded.map((item, index) => {
            const active = index === activeCategory;

            if (!isDesktop) {
              return (
                <Box
                  key={item.id}
                  ref={el => (itemsRef.current[index] = el)}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 1, cursor: 'pointer' }}
                  onClick={() => setActiveCategory(index)}
                >
                  <Typography
                    sx={{
                      position: 'relative',
                      px: 2,
                      color: colors.dark.main,
                      fontWeight: '700',
                      fontSize: '18px',
                      whiteSpace: 'nowrap',
                      '&:after': {
                        content: "''",
                        position: 'absolute',
                        bottom: '-8px',
                        left: 0,
                        right: 0,
                        height: '4px',
                        borderRadius: '8px 8px 0 0',
                        backgroundColor: active ? colors.primary.main : 'transparent'
                      }
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              );
            }

            return (
              <Box
                key={item.id}
                ref={el => (itemsRef.current[index] = el)}
                sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 1, cursor: 'pointer' }}
                onClick={() => setActiveCategory(index)}
              >
                <Typography
                  variant={active ? 'body4' : 'body3'}
                  sx={{ color: active ? colors.secondary.main : colors.dark.main }}
                >
                  {item.name}
                </Typography>
                {active && (
                  <ArrowRightAltRoundedIcon sx={{ color: active ? colors.secondary.main : colors.dark.main }} />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
