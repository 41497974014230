import React from 'react';
import cx from 'classnames';

import AddNewBusinessIcon from '@packs/images/svg/ui/addNewBusiness.svg';
import { useStore } from '@packs/stores';

import ModalForm from './Form';

import { Modal, ModalHeader } from 'reactstrap';

export const NewProviderModal = ({
  isNewCompanyModalOpen,
  companyModalType,
  toggle,
  enableUnAuthBusiness,
  onCreate,
  newCompanyName
}) => {
  const { currentCategory } = useStore();

  if (!currentCategory?.id) {
    return null;
  }

  return (
    <Modal
      isOpen={isNewCompanyModalOpen}
      toggle={() => toggle('isNewCompanyModalOpen')}
      className={cx('new-provider-modal', currentCategory.root.enName)}
    >
      <ModalHeader toggle={() => toggle('isNewCompanyModalOpen')}>
        <div className='d-flex align-items-center'>
          <div className='size-30 mr-20'>
            <img src={AddNewBusinessIcon} alt='add' />
          </div>
          <div className='fw-700'>Add new Business</div>
        </div>
      </ModalHeader>

      <ModalForm
        initialValues={{
          name: newCompanyName,
          categoryId: currentCategory.id,
          companyModalType: companyModalType || 'provider',
          location: null
        }}
        toggle={() => toggle('isNewCompanyModalOpen')}
        {...{
          category: currentCategory,
          enableUnAuthBusiness,
          onCreate
        }}
      />
    </Modal>
  );
};
