const skeleton = {
  variants: [
    {
      props: { variant: 'rounded' },
      style: {
        borderRadius: '8px'
      }
    }
  ]
};

export default skeleton;
