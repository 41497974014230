import colors from '@packs/assets/theme/base/colors';

const { primary } = colors;
const autocomplete = {
  styleOverrides: {
    option: {
      '&:hover': {
        backgroundColor: `${primary.tonal} !important`
      },
      '&[aria-selected="true"]': {
        backgroundColor: `${primary.main} !important`
      }
    }
  }
};

export default autocomplete;
