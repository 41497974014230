import React, { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/company';
import { SidebarTitle } from '@packs/components/MaterialUi/CompanyPage/components/Sidebar/components/SidebarTitle/SidebarTitle';
import DefaultBusinessIcon from '@packs/images/svg/default_business.svg';
import { pathGen } from '@packs/layouts/constants';
import { defaultBusinessImage } from '@packs/lib/constants';
import { divideStarRating } from '@packs/lib/helpers';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';
import { RatingMUI } from '@shared/components/RatingMUI/RatingMUI';
import MembersIcon from '@shared/images/company/members.svg';

import useStyles from './styles';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const MembersAlsoLooked = observer(({ companyId, category }) => {
  const styles = useStyles();
  const [companies, setCompanies] = useState([]);
  const { countryCode } = useStore();

  const onVisitCompanyPage = company => () => {
    handleVisitCompany(company);
  };

  const fetchData = () => {
    api
      .fetchCompaniesProduct({
        categoryId: category.id,
        page: 1,
        perPage: 3,
        excludeCompanyId: +companyId
      })
      .then(res => {
        if (res?.errors.length === 0) {
          setCompanies(res.companies);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [companyId]);

  if (companies.length === 0) {
    return null;
  }
  return (
    <SidebarTitle title='Members also looked at' icon={<img src={MembersIcon} alt='Members' />}>
      <Box>
        {companies.map(item => {
          const avatarUrl = item.avatarUrl === defaultBusinessImage ? DefaultBusinessIcon : item.avatarUrl;
          const path = pathGen('BUSINESS', { countryCode, company: item });
          return (
            <Box key={path} component={Link} sx={styles.categoryWrapper} to={path} onClick={onVisitCompanyPage(item)}>
              <Box sx={{ ...styles.categorySection, gap: 1 }}>
                <Avatar src={avatarUrl} sx={{ width: '30px', height: '30px' }} variant='rounded' />
                <Typography variant='body1'>{item.name}</Typography>
              </Box>
              <Box sx={{ ...styles.categorySection, gap: 0.5 }}>
                <RatingMUI value={1} max={1} />
                <Typography variant='body3'>
                  {item.additionalData.rating ? divideStarRating(item.additionalData.rating).toFixed(1) : '0.0'}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </SidebarTitle>
  );
});
