import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Form } from '@packs/screens/BecomeAPartner/components/Form/form';

export const MediaPartners = ({ inProcess, submitHandler }) => (
  <Box sx={{ maxWidth: '900px', mx: 'auto', pt: { xs: 3, md: 4 } }}>
    <Typography variant='h4' sx={{ mb: 3, textAlign: 'center' }}>
      Media Partners
    </Typography>
    <Typography variant='subtitle2' sx={{ mb: 5, textAlign: 'center' }}>
      At Bill Winner we love partnering with great media companies. Our application is a win-win for both our media
      partners and their customers. Our application ensures that media companies are partnering with businesses that
      provide great quotes & service to their customers. Please enter your details below and a member of our customer
      service team will contact you shortly. Thank you!
    </Typography>
    <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
      <Form handleSubmit={submitHandler} inProcess={inProcess} />
    </Box>
  </Box>
);
