import { makeSelectOptions } from '@shared/helpers/select';

import get from 'lodash-es/get';

export const categoryData = (currentCategory, regions, initialSubCategory, blockHero) => {
  return {
    energy: {
      title: blockHero?.title,
      communityBlock: false,
      description:
        blockHero?.description ||
        'Choose your category so that you can compare the best deals from providers, brokers and price comparison sites in one place!',
      formType: 'subCategories'
    },
    broadband: {
      title: blockHero?.title,
      description: blockHero?.description || "Let's make smarter decisions together!",
      communityBlock: true,
      formType: 'criteria',
      fields: [
        {
          name: 'category',
          type: 'select',
          label: 'Select category',
          placeholder: 'Select category',
          options: makeSelectOptions(currentCategory.leafChildren)
        },
        {
          name: 'city',
          type: 'select',
          label: 'Location',
          placeholder: 'Location',
          options: regions
        }
      ]
    },
    mobile: {
      title: blockHero?.title,
      description: blockHero?.description || "Let's make smarter decisions together!",
      communityBlock: true,
      formType: 'criteria',
      fields: [
        {
          name: 'category',
          type: 'select',
          label: 'Select category',
          placeholder: 'Select category',
          options: makeSelectOptions(currentCategory.leafChildren)
        },
        {
          name: 'city',
          type: 'select',
          label: 'Location',
          placeholder: 'Location',
          options: regions
        }
      ]
    }
  };
};

export const getCategoryData = ({ currentCategory, regions, initialSubCategory, blockHero }) => {
  return get(categoryData(currentCategory, regions, initialSubCategory, blockHero), `${currentCategory.code}`, {});
};

export const modifySimpleCriteria = simpleCriteria => {
  return simpleCriteria.map(item => ({
    ...item,
    name: `criteriaQuestions.${item.name}`,
    options: makeSelectOptions(item.options)
  }));
};
