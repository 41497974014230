import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AppliedFilters } from '@packs/components/MaterialUi/Leaderboard/AppliedFilters/AppliedFilters';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { sortOptions } from '@packs/lib/helpers/sortSubcategoriesDesktop';
import { isWikiTableCategory } from '@packs/models/category/functions';
import {
  appliedFiltersLabels,
  COMPANY_TYPE_EACH_TYPE,
  COMPANY_TYPE_OPTIONS,
  TIME_OPTIONS
} from '@packs/models/company/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';
import { INIT_FILTERS } from '@packs/stores/CompaniesStore';
import useStyles from '@packs/styles/Leaderboard/topBarStyles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { observer } from 'mobx-react';

const fields = ['companyType', 'lastBuy', 'city', 'criteriaQuestions'];

export const MyStatsActionPanel = observer(() => {
  const styles = useStyles();
  const { isDesktop } = useWindowSize();
  const { companiesStore, currentCategory, currentCountry } = useStore();
  const { currentPolicy, formOptions } = useDetails();
  const { currentUser } = useUserSelectors();
  const isPersonalized = hasPolicyCriteria(currentPolicy);

  const criteriaFilters = formOptions;

  const isInsuranceCategory =
    ['insurance'].includes(currentCategory.root?.enName) || currentCategory.code?.includes('insurance');
  const sortList = sortOptions({ isInsuranceCategory, isPersonalized, currentUser });
  const personalized = hasPolicyCriteria(currentPolicy);
  const companyTypeOptions = useMemo(() => {
    let list = isWikiTableCategory(currentCategory)
      ? [...COMPANY_TYPE_OPTIONS, COMPANY_TYPE_EACH_TYPE]
      : [COMPANY_TYPE_EACH_TYPE, ...COMPANY_TYPE_OPTIONS];

    if (!isWikiTableCategory(currentCategory) && !personalized) {
      list = list.filter(item => item.value !== 'all_types');
    }

    if (currentCategory?.root?.code === 'subscriptions') {
      return [{ value: 'provider', label: 'Top Providers' }];
    }

    return list;
  }, [currentCategory]);

  const appliedFilters = fields.reduce((acc, item) => {
    const filterItem = companiesStore.filters[item];

    if (item === 'criteriaQuestions') {
      const criteriaCount = Object.values(filterItem).filter(Boolean).length;
      return acc + criteriaCount;
    }

    const isObjectFilter = typeof filterItem === 'object' && filterItem?.value && filterItem?.value !== 'all';
    const isStringFilter = typeof filterItem === 'string' && INIT_FILTERS[item] !== filterItem && filterItem !== 'all';

    if (isObjectFilter || isStringFilter) {
      return acc + 1;
    }

    return acc;
  }, 0);

  const onLoadCompanies = (filters = null, noCriteria = false) => {
    if (filters) {
      companiesStore.load(filters);
    } else {
      companiesStore.load({ noCriteria });
    }
  };

  const onChangeSort = (_, data) => {
    if (data?.value) {
      onLoadCompanies({ filters: { orderColumn: data.value } });
    }
  };

  const value = sortList.find(item => item.value === companiesStore?.filters?.orderColumn) || sortList[0];

  return (
    <Box sx={styles.actionPanelWrapper}>
      <Box sx={styles.container}>
        {isDesktop && (
          <Box sx={styles.switcherWrapper}>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <SelectMUI
                width='230px'
                size='small'
                label='Sort by'
                value={value}
                options={sortList}
                onChange={onChangeSort}
              />
            </Box>
          </Box>
        )}
      </Box>
      <AppliedFilters
        filters={appliedFiltersLabels(companiesStore?.filters, fields, criteriaFilters, [
          ...TIME_OPTIONS,
          ...companyTypeOptions
        ])}
      />
    </Box>
  );
});
