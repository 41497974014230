import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import Button from '@mui/material/Button';
import { usePath } from '@packs/layouts/constants';
import { saveState } from '@packs/lib/utils/localStorage';
import { ContentWrapper } from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/components/ContentWrapper';
import useStyles from '@packs/screens/MyHome/components/MyRenewals/components/AdditionalSection/styles';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { Link } from 'react-router-dom';

export const PersonalizedOffers = ({ item, data, isLoading, setCurrentCategory, category }) => {
  const { isDesktop } = useWindowSize();
  const styles = useStyles();
  const { DASHBOARD_MY_RESULTS_PATH_FN, DASHBOARD_MY_OFFERS_PATH_FN } = usePath();
  const totalCount = data?.totalCount || 0;
  const isEmpty = totalCount === 0;

  const handleRedirectToLeaderboard = () => {
    setCurrentCategory();
    saveState('openDashboardItemId', item.id);
  };

  return (
    <ContentWrapper
      wrapperSx={styles.shortSectionItemWrapper}
      title={isEmpty ? 'No Personal Offers Yet!' : 'Personalized Offers'}
      counter={`${totalCount} Offer${totalCount === 1 ? '' : 's'}`}
      sectionLabelIcon={<LocalOfferRoundedIcon />}
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyText='New money-saving offers coming soon!'
      hideButton={isEmpty}
      component={Link}
      to={
        isEmpty
          ? DASHBOARD_MY_OFFERS_PATH_FN(category)
          : DASHBOARD_MY_RESULTS_PATH_FN(category).concat('?scrollTo=personalizedOffersSection')
      }
      onClick={handleRedirectToLeaderboard}
      bottomButton={
        <Button
          size={isDesktop ? 'xs' : 'small'}
          variant='contained'
          fullWidth
          sx={styles.sectionButton}
          className='sectionButton'
        >
          See All Offers
        </Button>
      }
    />
  );
};
