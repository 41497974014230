import React from 'react';
import cx from 'classnames';

import { AvatarUploader } from '@packs/components/MaterialUi/AvatarUploader/AvatarUploader';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import SettingsIcon from '@packs/images/icons/SettingsIcon';
import LocationIcon from '@packs/images/svg/ui/location-icon-gray.svg';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { shortSecondName } from '@packs/lib/helpers/additionalHelpers';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

export const MyProfileHeader = observer(() => {
  const { toggleSettingsModal } = useModalsContext();
  const { currentUser } = useUserSelectors();
  const { userStore } = useStore();
  const { PROFILE_MY_ALL_CONTRIBUTIONS_PATH, PROFILE_MY_REVIEWS_PATH, PROFILE_QA_PATH, PROFILE_REQUESTS_PATH } =
    usePath();
  const {
    totalCount = 0,
    renewalDetailListCount = 0,
    qaPostListCount = 0,
    qaTipListCount = 0,
    userCategoryQuoteRequestListCount = 0
  } = userStore.contributionCounter;

  const myProfileTabs = [
    {
      label: 'My Contributions',
      url: PROFILE_MY_ALL_CONTRIBUTIONS_PATH
    },
    {
      label: 'My Votes',
      url: PROFILE_MY_REVIEWS_PATH
    },
    {
      label: 'My Community',
      url: PROFILE_QA_PATH
    },
    {
      label: 'My Quote Requests',
      url: PROFILE_REQUESTS_PATH
    }
  ];

  return (
    <div className='my-profile-header'>
      <div className='my-profile-cover'>
        <div className='d-flex align-items-center justify-content-between'>
          <div />
          <div className='settings-btn' onClick={toggleSettingsModal}>
            <SettingsIcon />
          </div>
        </div>
      </div>
      <div className='my-profile-user-data'>
        <div className='d-flex flex-column align-items-center'>
          <AvatarUploader />
          <div className='fs-19 fw-700 mt-16 lh-9'>{shortSecondName(currentUser?.displayName)}</div>
          <div className='d-flex align-items-center'>
            <img src={LocationIcon} alt='L' className='mr-5' />
            <div className='fs-13 fw-500 lh-9 secondary-text'>{currentUser?.state}</div>
          </div>
        </div>
        <div className='user-data d-flex justify-content-center mt-16'>
          {/* <div className='user-data-item'>*/}
          {/*  <div className='label fs-11 fw-500 tt-uc'>Points:</div>*/}
          {/*  <div className='label fs-23 fw-700 lh-9 tt-uc'>{currentUser?.bonusPoints || 0}</div>*/}
          {/* </div>*/}
          <div className='user-data-item'>
            <div className='label fs-11 fw-500 tt-uc'>My Contributions:</div>
            <div className='label fs-23 fw-700 lh-9 tt-uc'>{totalCount}</div>
          </div>
          <div className='user-data-item'>
            <div className='label fs-11 fw-500 tt-uc'>My Votes:</div>
            <div className='label fs-23 fw-700 lh-9 tt-uc'>{renewalDetailListCount}</div>
          </div>
          <div className='user-data-item'>
            <div className='label fs-11 fw-500 tt-uc'>My posts:</div>
            <div className='label fs-23 fw-700 lh-9 tt-uc'>{qaPostListCount + qaTipListCount || 0}</div>
          </div>
          <div className='user-data-item'>
            <div className='label fs-11 fw-500 tt-uc'>My Quote requests:</div>
            <div className='label fs-23 fw-700 lh-9 tt-uc'>{userCategoryQuoteRequestListCount}</div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center tabs-ui-scroll pl-8 pr-8'>
        <div className='tabs-ui with-scroll medium mb-24'>
          {myProfileTabs.map(({ url, label }) => (
            <NavLink key={url} className={cx('tab-item-ui')} to={url}>
              {label}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
});
