import React, { FC, useEffect, useMemo } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, ButtonProps, IconButton, Stack, Typography, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import colors from '@packs/assets/theme/base/colors';
import { SelectMUI } from '@packs/components/MaterialUi/SelectMUI/SelectMUI';
import EditIcon from '@packs/images/icons/EditIcon/editIcon';
import { ICategory, IOption, IPolicy } from '@packs/interfaces/rootInterfaces';
import { usePath } from '@packs/layouts/constants';
import { hasPolicyCriteria } from '@packs/lib/helpers/additionalHelpers';
import { saveState } from '@packs/lib/utils/localStorage';
import { useStore } from '@packs/stores';
import { TooltipMUI } from '@shared/components/TooltipMUI/TooltipMUI';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import dayjs from 'dayjs';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const { secondary, text } = colors;

type CategoriesPolicyProps = {
  item: any;
  onSelect: (val: IOption) => void;
  options: IOption[];
  renewalDetail: IPolicy;
  category: ICategory;
  disabled: boolean;
};
export const CategoriesPolicy: FC<CategoriesPolicyProps> = ({
  item,
  onSelect,
  options,
  renewalDetail,
  category,
  disabled
}) => {
  const { isDesktop } = useWindowSize();
  const { DASHBOARD_LANDING_PATH_FN, MY_DETAILS_PATH_FN, ADD_DETAILS_PATH_FN } = usePath();
  const navigate = useNavigate();
  const { categoryStore } = useStore();
  const criteriaQuestions = omit(renewalDetail?.criteriaQuestions, ['location']);
  const location = useLocation();

  const progress = useMemo(() => {
    if (renewalDetail?.policyType === 'simple') {
      return 34;
    }

    if (
      renewalDetail?.pricesQuestions?.price?.value &&
      !hasPolicyCriteria(renewalDetail) &&
      renewalDetail?.smartReminder?.id
    ) {
      return 15;
    }

    if (!hasPolicyCriteria(renewalDetail)) {
      if (renewalDetail?.pricesQuestions?.price?.value) {
        return 5;
      }

      if (renewalDetail?.smartReminder?.id) {
        return 10;
      }
    }

    return 100;
  }, [
    renewalDetail?.criteriaQuestions,
    renewalDetail?.policyType,
    renewalDetail?.pricesQuestions?.price?.value,
    renewalDetail?.smartReminder?.id,
    criteriaQuestions
  ]);

  const completedPolicy = progress === 100;

  const onChangeCategory = () => {
    if (!disabled) {
      categoryStore.setCurrentCategory(category);
      saveState('openDashboardItemId', item.id);
    }
  };

  const onGetMatched = () => {
    if (!disabled) {
      onChangeCategory();
      navigate(ADD_DETAILS_PATH_FN(category, location.pathname));
    } else {
      navigate(DASHBOARD_LANDING_PATH_FN(category));
    }
  };

  const onChange = value => {
    onSelect && onSelect(value);
  };

  if (options.length === 0) {
    return (
      <Box sx={{ pt: { xs: 0, lg: 1.5 } }}>
        <TooltipMUI
          title='Add your policy details so that you can request quotes from businesses.'
          arrow
          placement='top'
        >
          <Button
            variant='text'
            color={'dark' as ButtonProps['color']}
            size='small'
            startIcon={<AddRoundedIcon />}
            onClick={onGetMatched}
            disabled={disabled}
          >
            Add Policy Details
          </Button>
        </TooltipMUI>
      </Box>
    );
  }

  return (
    <Stack
      direction='column'
      justifyContent='flex-start'
      sx={{
        gap: isDesktop ? 0 : 0.5,
        '@media (min-width: 992px)': {
          '&:hover .updateDetailsCTA': { opacity: 1 },
          '&:hover': {
            '.lastUpdate': {
              display: 'flex'
            },
            '.policyProgress': {
              display: 'none'
            }
          }
        }
      }}
    >
      {isEmpty(criteriaQuestions) || renewalDetail?.policyType === 'simple' ? (
        <Box>
          <Button
            variant='text'
            color={'dark' as ButtonProps['color']}
            size='xs'
            startIcon={<AddRoundedIcon />}
            onClick={onGetMatched}
            disabled={disabled}
            sx={{ mb: 1.5 }}
          >
            Add Policy Details
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
          {options.length === 1 ? (
            <Typography variant={'body3' as TypographyProps['variant']} sx={{ flexGrow: isDesktop ? 0 : 1 }}>
              {options[0].label || 'Policy 1'}
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                width: isDesktop ? 'auto' : '100%',
                '& .MuiInput-root': {
                  mt: 0
                },
                '& > div': {
                  width: isDesktop ? 'auto' : '100%'
                }
              }}
            >
              <SelectMUI
                size='small'
                variant='standard'
                placeholder='Select'
                disabled={disabled}
                onChange={(e, val) => onChange(val)}
                options={options}
                width={'100%'}
                sx={{ maxWidth: isDesktop ? 200 : '100%' }}
                value={options.find(item => item.value === renewalDetail?.id) || options[0]}
              />
            </Box>
          )}
          {!disabled && (
            <IconButton
              component={Link}
              to={MY_DETAILS_PATH_FN(renewalDetail, category, location.pathname)}
              onClick={onChangeCategory}
              sx={{ mt: '2px', opacity: { lg: 0 } }}
              className='updateDetailsCTA'
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: {
            lg: 'center'
          },
          flexDirection: { xs: 'column', lg: 'row' },
          gap: 1,
          color: text.secondary
        }}
      >
        <Box className={completedPolicy ? 'policyProgress' : ''} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: '64px', height: '6px', borderRadius: '8px', backgroundColor: '#F4F4F4' }}>
            <Box
              sx={{ width: `${progress}%`, height: '100%', backgroundColor: secondary.light, borderRadius: '8px' }}
            />
          </Box>
          <Typography variant='body5'>{progress}% completed</Typography>
        </Box>
        {completedPolicy && (
          <Typography variant='body5' className='lastUpdate' sx={{ display: { lg: 'none' } }}>{`Last update: ${dayjs(
            renewalDetail?.updatedAt
          ).format('DD MMM YYYY')}`}</Typography>
        )}
      </Box>
    </Stack>
  );
};
