import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import currentUserApi from '@packs/apis/currentUser';
import { AbstractModal } from '@packs/components/AbstractModal';
import { AppHeader } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { AppModalsProvider } from '@packs/layouts/AppLayer/AppModalsProvider';
import { CLOSED_ALL_MODALS } from '@packs/layouts/AppLayer/constants';
import { usePath } from '@packs/layouts/constants';
import { isLandingPage, toastr } from '@packs/lib/helpers';
import { LandingFooter } from '@packs/screens/Landing/components/LandingFooter';
import { useStore } from '@packs/stores';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import { AllModals } from './components';

import camelcaseKeys from 'camelcase-keys';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import Drawer from 'react-modern-drawer';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

if (import.meta.env.VITE_SERVER_TYPE === 'prod') {
  ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_CODE);
}

export const AppLayer = observer(() => {
  const { pathname } = useLocation();
  const [modalState, _setModalState] = useState({
    ...CLOSED_ALL_MODALS,
    onSignUpSuccess: null,
    newCompanyModalProps: {},
    modalProps: {},
    drawerProps: {}
  });
  const [mounted, setMounted] = useState(false);
  const { rootStore, currentCategory, smartRemindersStore, userStore, categoryStore } = useStore();
  const { currentUser } = useUserSelectors();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const { INVITE_FRIENDS_PATH } = usePath();

  if (import.meta.env.MODE === 'development') {
    window.rootStore = rootStore;
  }

  useEffect(() => {
    if (import.meta.env.VITE_SERVER_TYPE === 'prod') {
      ReactGA.pageview(location?.pathname + location?.search);
    }
  }, [location]);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    // AUTH
    if (currentUser.id) {
      smartRemindersStore.fetchSmartRemindersCount({ userId: currentUser?.id });
      categoryStore.loadCategoriesWithPolicy();
    }
  }, [currentUser.id]);

  useEffect(() => {
    const { email, googleUid, facebookUid, twitterUid, linkedinUid, terms, privacy, socialInvitationToken } =
      camelcaseKeys(queryString.parse(location.search));
    if (email && (googleUid || facebookUid || twitterUid || linkedinUid)) {
      userStore.setCurrentUser({
        email,
        googleUid,
        facebookUid,
        twitterUid,
        linkedinUid
      });
      toggleDoudleModal(null, 'login');
    }

    if (location.search.match('invitationToken') || socialInvitationToken) {
      setModalState({
        isOpenAcceptInviteModal: !modalState.isOpenAcceptInviteModal,
        modalProps: {}
      });
    }

    if (location.search.match('reset_password_token')) {
      setModalState({ isChangePasswordModalOpen: true });
    }

    if (terms === 'true') {
      setModalState({ isOpenTermsModal: !modalState.isOpenTermsModal });
    }

    if (privacy === 'true') {
      setModalState({ isOpenPrivacyModal: !modalState.isOpenPrivacyModal });
    }
  }, []);

  const friendshipAccept = async () => {
    const { inviterEmail } = camelcaseKeys(queryString.parse(location.search));
    const resp = await currentUserApi.friendshipAccept({ inviterEmail });

    if (resp?.status === 'success') {
      toastr.success(`You're joined to the ${inviterEmail} network on BillWinner.com!`);
      navigate(INVITE_FRIENDS_PATH);
    } else {
      toastr.error(resp.errors);
    }
  };

  useEffect(() => {
    const { inviterEmail, signUpWithUniqCode } = camelcaseKeys(queryString.parse(location.search));

    if (inviterEmail) {
      if (currentUser?.id) {
        friendshipAccept();
      } else {
        if (!modalState.isOpenDoubleModal) {
          toggleDoudleModal(null, 'login');
        }
      }
    }

    if (!currentUser?.id && !!signUpWithUniqCode) {
      toggleDoudleModal(null, 'signup', { signUpWithUniqCode });
    }
  }, [currentUser.id]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const setModalState = values => _setModalState(state => ({ ...state, ...values }));

  const toggleDoudleModal = (onSignUpSuccess, modalType, modalAuthProps) => {
    userStore.setAuthModalType(modalType);

    if (modalState.isOpenDoubleModal) {
      mounted && setModalState({ ...CLOSED_ALL_MODALS, onSignUpSuccess: null });
    } else {
      setModalState({
        ...CLOSED_ALL_MODALS,
        isOpenDoubleModal: true,
        modalType,
        onSignUpSuccess,
        modalAuthProps
      });
    }
  };

  const toggleDrawer = drawerProps => {
    setModalState({ isDrawerOpen: !modalState.isDrawerOpen, drawerProps });
  };

  const votePage = location.pathname.match('/vote');
  const unsubscribePage = location.pathname.includes('/users/unsubscribe');

  return (
    <AppModalsProvider modalState={modalState} setModalState={setModalState} toggleDoudleModal={toggleDoudleModal}>
      <div
        key={currentUser.id}
        className={cx('app-container', currentCategory?.root?.enName, {
          ['auth-app']: currentUser.id,
          ['with-out-gap']: isLandingPage()
        })}
      >
        {/* {!currentCategory?.id && <Loader />}*/}
        <AppHeader />

        {isMobile ? (
          <main className={cx('main-app-wrapper-mobile', (votePage || unsubscribePage) && 'pb-0')}>
            <Outlet />
          </main>
        ) : (
          <main className='main-app-wrapper'>
            <Outlet />
          </main>
        )}

        <LandingFooter />

        <Drawer
          open={modalState.isDrawerOpen}
          direction='right'
          size={560}
          style={{ opacity: modalState?.isOpenDoubleModal ? '0' : '1' }}
          className='global-drawer'
          onClose={toggleDrawer}
        >
          {modalState?.drawerProps?.content && modalState.drawerProps.content}
        </Drawer>
      </div>

      <AllModals
        category={currentCategory}
        currentUser={currentUser}
        modalState={modalState}
        setModalState={setModalState}
      />

      <AbstractModal />
    </AppModalsProvider>
  );
});
