import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import { InfoBox } from '@packs/components/MaterialUi/InfoBox/InfoBox';
import { SignUpModalLink } from '@packs/components/SignUpModalLink';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import FAQIcon from '@packs/images/ui/landing-faq.webp';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { useWindowSize } from '@shared/hooks/useWindowSize';

const { white, text, secondary, primary } = colors;

export const FAQ = ({ title, description, options = [], hide = false }) => {
  const { isMobile } = useWindowSize();
  const { currentUser } = useUserSelectors();

  const [expanded, setExpanded] = useState(0);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (hide) {
    return <Box sx={{ minHeight: '200px', backgroundColor: primary.main }} />;
  }

  return (
    <Box backgroundColor={white.main}>
      <Box
        maxWidth='1162px'
        mx='auto'
        px={2}
        sx={{
          py: {
            xs: 6,
            lg: 15
          }
        }}
      >
        <Typography variant='h2' mb={2} textAlign='center'>
          {title}
        </Typography>
        <Typography
          variant='subtitle1'
          textAlign='center'
          color={text.main}
          sx={{
            mb: { xs: 4, lg: 0 }
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <InfoBox
          title='FAQ'
          description=" If you haven't found the answer to your question. You can contact us and we will help you."
          infoIcon={FAQIcon}
          infoIconWidth='505px'
          position='right'
          withoutMobileTitle
        >
          <Box
            display='flex'
            flexDirection='column'
            borderRadius='24px'
            width='100%'
            maxWidth='995px'
            mx='auto'
            border='1px solid rgba(0, 0, 0, 0.16)'
            position='relative'
            overflow='hidden'
            zIndex='3'
            sx={{
              backgroundColor: white.main,
              mt: { xs: '-205px', md: '-125px' },
              px: { md: 12 },
              py: { md: 10 }
            }}
          >
            {options.map((option, index) => (
              <Accordion
                key={option?.title + index}
                expanded={expanded === index}
                onChange={handleChange(index)}
                sx={{
                  boxShadow: 'none',
                  '&.Mui-expanded:before': {
                    opacity: 1
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <IconBox
                      containerSize={isMobile ? '32px' : '48px'}
                      backgroundColor={secondary.transparent}
                      color={secondary.main}
                      iconSize={isMobile ? '16px' : '24px'}
                      onClick={() => {}}
                      icon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                    />
                  }
                  sx={{
                    my: { xs: 2, lg: 3 },
                    '& .MuiAccordionSummary-content': {
                      m: '0 !important',
                      pr: 1
                    }
                  }}
                >
                  <Typography variant='h6'>{option?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    pt: 0
                  }}
                >
                  <Typography variant='body1' dangerouslySetInnerHTML={{ __html: option?.description }} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </InfoBox>
        {!currentUser.id && (
          <Box display='flex' justifyContent='center' mt={4}>
            <SignUpModalLink
              variant='contained'
              size='xl'
              color='primary'
              sx={{ position: 'relative', minWidth: { xs: '256px', md: '280px' }, zIndex: 3 }}
            >
              Start Now!
            </SignUpModalLink>
          </Box>
        )}
      </Box>
    </Box>
  );
};
