import { fetchCitiesList, fetchEirCodeAliasList } from '@packs/graphql/queries/cities';
import { graphQLRequest } from '@packs/lib/utils';

const api = {
  fetchCitiesList: variables =>
    graphQLRequest({
      query: fetchCitiesList,
      variables
    }),
  fetchEirCodeAliasList: variables =>
    graphQLRequest({
      query: fetchEirCodeAliasList,
      variables
    })
};

export default api;
