import React, { useState } from 'react';
import cx from 'classnames';

import { useStore } from '@packs/stores/base';

export const AssociationCode = ({ association, onSuccess = () => {} }) => {
  const { associationStore } = useStore();

  const [value, setValue] = useState('');

  const onChange = event => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    if (value && association?.value) {
      associationStore.addUserAssociationsCategory({
        attr: {
          associationId: association.value,
          code: value
        },
        onSuccess
      });
    }
  };

  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='fs-22 fw-700 lh-5 mb-25'>Verify your membership!</div>
      <input
        type='text'
        value={value}
        className='form-input with-border'
        placeholder='Code / membership number'
        onChange={onChange}
      />
      <div className='mt-20'>
        <button className={cx('btn full', { disabled: !value })} disabled={!value} onClick={handleSubmit}>
          <span>Submit</span>
        </button>
      </div>
    </div>
  );
};
