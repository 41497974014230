import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from '@packs/assets/theme/base/colors';
import Logo from '@packs/images/icons/LogoBillWinner';
import FooterDecorIcon from '@packs/images/svg/ui/footer-decor.svg';
import { useModalsContext } from '@packs/layouts/AppLayer/context';
import { usePath } from '@packs/layouts/constants';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { businessPageUrl } from '@shared/helpers/urlHelper';

import { FooterLink } from './components/FooterLink';
import { TitleBox } from './components/TitleBox';

const { white, primary } = colors;

const socials = [
  { href: 'https://www.instagram.com/billwinnerapp/', icon: <InstagramIcon /> },
  { href: 'https://www.facebook.com/Billwinnerapp', icon: <FacebookIcon /> },
  { href: 'https://www.linkedin.com/company/billwinnerapp/', icon: <LinkedInIcon /> },
  { href: 'https://twitter.com/Billwinnerapp', icon: <TwitterIcon /> }
];

export const LandingFooter = () => {
  const {
    BECOME_A_PARTNER_PATH,
    ABOUT_US_PATH,
    ASSOCIATION_PATH,
    VOTE_PATH,
    OUR_SHARED_MISSION_PATH,
    MY_OFFERS_PATH,
    MY_NOTES_PATH,
    PROFILE_MY_REMINDERS_PATH
  } = usePath();

  const { togglePrivacyModal, toggleTermsModal } = useModalsContext();

  return (
    <Box
      component='footer'
      sx={{
        pt: {
          xs: 5,
          md: 10
        },
        backgroundColor: '#0043C2',
        backgroundImage: {
          md: `url(${FooterDecorIcon})`
        },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundSize: 'contain'
      }}
    >
      <Box
        display='grid'
        gap={4}
        width='100%'
        maxWidth='1128px'
        mx='auto'
        px={2}
        sx={{
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)'
          }
        }}
      >
        <Box>
          <Box mb={3}>
            <Logo />
          </Box>
          <TitleBox title='Email:'>Support@billwinner.com</TitleBox>
          <TitleBox title='Find as at:'>Suite 1307, 26 Pembroke Street Upper, Dublin, Ireland. D02 X361</TitleBox>
          <TitleBox title='Social network:' last>
            <Stack direction='row' spacing={1} mt={1}>
              {socials.map(({ href, icon }) => (
                <Link
                  key={href}
                  href={href}
                  target='_blank'
                  rel='noreferrer'
                  sx={{
                    '&:hover svg': {
                      fill: primary.main
                    }
                  }}
                >
                  <IconBox
                    containerSize='40px'
                    iconSize='22px'
                    icon={icon}
                    backgroundColor={white.tonal}
                    cursor='pointer'
                  />
                </Link>
              ))}
            </Stack>
          </TitleBox>
        </Box>
        <Box>
          <Typography variant='body2' color={white.main} mb={3}>
            My Bills:
          </Typography>
          <FooterLink to={PROFILE_MY_REMINDERS_PATH} label='Reminders' />
          <FooterLink to={MY_OFFERS_PATH} label='Offers' />
          <FooterLink to={MY_NOTES_PATH} label='Documents and Notes' />
          <FooterLink to={VOTE_PATH} label='Vote for a business!' />
          <FooterLink to={ASSOCIATION_PATH} label='Associations' />
        </Box>
        <Box>
          <Typography variant='body2' color={white.main} mb={3}>
            About us:
          </Typography>
          <FooterLink to={ABOUT_US_PATH} label='About us' />
          <FooterLink to={OUR_SHARED_MISSION_PATH} label='Community Awards' />
          <FooterLink to={BECOME_A_PARTNER_PATH} label='Pricing' />
          <FooterLink to={businessPageUrl()} label='Business sign up' target='_blank' />
        </Box>
        <Box>
          <Typography variant='body2' color={white.main} mb={3}>
            Resources:
          </Typography>
          <FooterLink label='Terms & Conditions' onClick={toggleTermsModal} />
          <FooterLink label='Privacy policy' onClick={togglePrivacyModal} />
        </Box>
      </Box>
      <Box display='flex' justifyContent='center' py={2}>
        <Typography variant='body5' textAlign='center' color={white.main} sx={{ opacity: '0.6' }}>
          © Bill Winner. All rights reserved
        </Typography>
      </Box>
    </Box>
  );
};
