import colors from '@packs/assets/theme/base/colors';

export default () => ({
  container: {
    px: { xs: 2, lg: 3 }
  },
  wrapper: {
    borderRadius: '16px',
    border: `1px solid ${colors.borderColor.secondary}`,
    px: { xs: 2, lg: 3 },
    py: { xs: 3, lg: 4 }
  },
  summaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 3,
    mb: 3
  },
  listWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 1,
    rowGap: 2
  }
});
