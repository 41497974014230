import colors from '@packs/assets/theme/base/colors';

const { text } = colors;

export default () => ({
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2
  },
  content: {
    color: text.secondary
  }
});
