import colors from '@packs/assets/theme/base/colors';

const { borderColor, white, grey, rangeIndicator, secondary, primary } = colors;

export default () => ({
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: {
      xs: 'center',
      lg: 'flex-start'
    },
    gap: 2,
    mb: 2
  },
  desktopItemWrapper: (active = false) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '12px',
    border: `2px solid ${active ? primary.main : borderColor.main}`,
    p: 2,
    background: white.main
  }),
  desktopTopInfoWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 3
  },
  categoryCell: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: {
      lg: '165px',
      xl: '185px'
    },
    minWidth: {
      lg: '165px',
      xl: '185px'
    }
  },
  policyCell: {
    width: {
      lg: '140px',
      xl: '170px'
    },
    minWidth: {
      lg: '140px',
      xl: '170px'
    }
  },
  costCell: {
    width: {
      lg: '145px',
      xl: '175px'
    },
    minWidth: {
      lg: '145px',
      xl: '175px'
    }
  },
  reminderCell: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: {
      lg: '240px',
      xl: '280px'
    },
    minWidth: {
      md: '240px',
      lg: '280px'
    }
  },
  rangeIndicatorList: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    width: '80px',
    minWidth: '80px'
  },
  rangeIndicatorItem: (color = 'default', active) => ({
    flexGrow: 1,
    height: '6px',
    borderRadius: '10px',
    backgroundColor: active && color ? rangeIndicator[color].main : grey[300]
  }),
  totalWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    minWidth: '277px',
    width: {
      xs: '100%',
      sm: 'auto'
    },
    borderRadius: '12px',
    px: {
      xs: 2,
      md: 3
    },
    py: 2,
    backgroundColor: secondary.main
  },
  separator: {
    width: '2px',
    height: '24px',
    borderRadius: '2px',
    background: white.tonal
  },
  totalBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 0.5
  }
});
