export default () => ({
  wrapper: {
    py: {
      xs: 3,
      md: 6
    },
    backgroundImage: 'linear-gradient(180deg, #FFD029 0%, #FB0 100%)'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
      xs: 'center',
      md: 'space-between'
    },
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 3,
    textAlign: {
      xs: 'center',
      md: 'left'
    }
  },
  sharedWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 1
  }
});
