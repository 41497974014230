import React from 'react';

import Box from '@mui/material/Box';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';

import { SliderList } from './components/SliderList';

import size from 'lodash-es/size';
import { observer } from 'mobx-react';

export const LeaderboardListCompare = observer(({ isLoading, allTypesList }) => {
  const { companiesStore } = useStore();

  if (
    !(companiesStore.loading || isLoading) &&
    size(allTypesList.provider) === 0 &&
    size(allTypesList.broker) === 0 &&
    size(allTypesList.comparison_site) === 0
  ) {
    return (
      <EmptyPlaceholder description='We could not find what you searched for. Please change the settings on your filter for a successful search. Thank you!' />
    );
  }

  const onChangeFilter = data => {
    companiesStore.load({ filters: { companyType: typeof data === 'object' ? data?.value : data } });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <SliderList
            title='Top providers:'
            list={allTypesList.provider}
            isLoading={companiesStore.loading || isLoading}
            onChangeFilter={onChangeFilter}
            filterType='provider'
            btnText='View all Providers'
          />
          <SliderList
            title='Top Agents/Brokers:'
            list={allTypesList.broker}
            isLoading={companiesStore.loading || isLoading}
            onChangeFilter={onChangeFilter}
            filterType='broker'
            btnText='View all Agents/Brokers'
          />
          <SliderList
            title='Top price comparison sites:'
            list={allTypesList.comparison_site}
            isLoading={companiesStore.loading || isLoading}
            onChangeFilter={onChangeFilter}
            filterType='comparison_site'
            btnText='View all Comparison sites'
          />
        </Box>
      </Box>
    </>
  );
});
